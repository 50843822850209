// Type's
export const COMPANY_GET_ALL_REQUEST = 'COMPANY_GET_ALL_REQUEST'
export const COMPANY_GET_ALL_SUCCESS = 'COMPANY_GET_ALL_SUCCESS'
export const COMPANY_GET_ALL_FAILURE = 'COMPANY_GET_ALL_FAILURE'

export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST'
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS'
export const COMPANY_CREATE_FAILURE = 'COMPANY_CREATE_FAILURE'

export const COMPANY_GET_ONE_REQUEST = 'COMPANY_GET_ONE_REQUEST'
export const COMPANY_GET_ONE_SUCCESS = 'COMPANY_GET_ONE_SUCCESS'
export const COMPANY_GET_ONE_FAILURE = 'COMPANY_GET_ONE_FAILURE'

export const COMPANY_EDIT_REQUEST = 'COMPANY_EDIT_REQUEST'
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS'
export const COMPANY_EDIT_FAILURE = 'COMPANY_EDIT_FAILURE'

export const COMPANY_REMOVE_REQUEST = 'COMPANY_REMOVE_REQUEST'
export const COMPANY_REMOVE_SUCCESS = 'COMPANY_REMOVE_SUCCESS'
export const COMPANY_REMOVE_FAILURE = 'COMPANY_REMOVE_FAILURE'

export const COMPANY_REACTIVATE_REQUEST = 'COMPANY_REACTIVATE_REQUEST'
export const COMPANY_REACTIVATE_SUCCESS = 'COMPANY_REACTIVATE_SUCCESS'
export const COMPANY_REACTIVATE_FAILURE = 'COMPANY_REACTIVATE_FAILURE'

export const COMPANY_FILTER_CHANGE_REQUEST = 'COMPANY_FILTER_CHANGE_REQUEST'
export const COMPANY_FILTER_CHANGE_SUCCESS = 'COMPANY_FILTER_CHANGE_SUCCESS'
export const COMPANY_FILTER_CHANGE_FAILURE = 'COMPANY_FILTER_CHANGE_FAILURE'

export const COMPANY_GET_TOTALS_REQUEST = 'COMPANY_GET_TOTALS_REQUEST'
export const COMPANY_GET_TOTALS_SUCCESS = 'COMPANY_GET_TOTALS_SUCCESS'
export const COMPANY_GET_TOTALS_FAILURE = 'COMPANY_GET_TOTALS_FAILURE'

export const COMPANY_GET_CATALOGS_REQUEST = 'COMPANY_GET_CATALOGS_REQUEST'
export const COMPANY_GET_CATALOGS_SUCCESS = 'COMPANY_GET_CATALOGS_SUCCESS'
export const COMPANY_GET_CATALOGS_FAILURE = 'COMPANY_GET_CATALOGS_FAILURE'

export const COMPANY_GET_STATES_REQUEST = 'COMPANY_GET_STATES_REQUEST'
export const COMPANY_GET_STATES_SUCCESS = 'COMPANY_GET_STATES_SUCCESS'
export const COMPANY_GET_STATES_FAILURE = 'COMPANY_GET_STATES_FAILURE'

export const COMPANY_GET_CITIES_REQUEST = 'COMPANY_GET_CITIES_REQUEST'
export const COMPANY_GET_CITIES_SUCCESS = 'COMPANY_GET_CITIES_SUCCESS'
export const COMPANY_GET_CITIES_FAILURE = 'COMPANY_GET_CITIES_FAILURE'

// Action's
export const companyGetAllRequest = (filters) => ({ type: COMPANY_GET_ALL_REQUEST, filters })
export const companyGetAllSuccess = (payload) => ({
  type: COMPANY_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: COMPANY_GET_ALL_REQUEST,
  error
})
export const companyFiltersChangeRequest = (filters) => ({
  type: COMPANY_FILTER_CHANGE_REQUEST,
  filters
})

export const companyCreateRequest = ({ values }) => ({
  type: COMPANY_CREATE_REQUEST,
  payload: values
})
export const companyEditRequest = ({ values }) => ({
  type: COMPANY_EDIT_REQUEST,
  payload: values
})
export const companyGetOneRequest = (payload) => ({ type: COMPANY_GET_ONE_REQUEST, payload })
export const companyGetOneSuccess = (payload) => ({ type: COMPANY_GET_ONE_SUCCESS, payload })
export const companyGetOneFailure = (payload) => ({ type: COMPANY_GET_ONE_FAILURE, payload })
export const companyDeactivateRequest = (payload) => ({
  type: COMPANY_REMOVE_REQUEST,
  payload
})
export const companyReactivateRequest = (payload) => ({
  type: COMPANY_REACTIVATE_REQUEST,
  payload
})
export const companyCreateSuccess = (payload) => ({ type: COMPANY_CREATE_SUCCESS, payload })
export const companyCreateFailure = (payload) => ({ type: COMPANY_CREATE_FAILURE, payload })
export const companyGetTotals = (payload) => ({ type: COMPANY_GET_TOTALS_REQUEST, payload })
export const companyGetCatalogs = (filters) => ({
  type: COMPANY_GET_CATALOGS_REQUEST,
  filters
})
export const companyGetStates = (payload) => ({ type: COMPANY_GET_STATES_REQUEST, payload })
export const companyGetCities = (country, state) => ({
  type: COMPANY_GET_CITIES_REQUEST,
  country,
  state
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: {},
  totals: {},
  catalogs: {},
  states: [],
  cities: []
}

export function companies(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMPANY_GET_ALL_REQUEST:
    case COMPANY_CREATE_REQUEST:
    case COMPANY_GET_ONE_REQUEST:
    case COMPANY_EDIT_REQUEST:
    case COMPANY_GET_TOTALS_REQUEST:
    case COMPANY_GET_CATALOGS_REQUEST:
    case COMPANY_REMOVE_REQUEST:
    case COMPANY_GET_STATES_REQUEST:
    case COMPANY_GET_CITIES_REQUEST:
    case COMPANY_REACTIVATE_REQUEST:
      return { ...state, error: null, loading: true }

    case COMPANY_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case COMPANY_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case COMPANY_EDIT_FAILURE:
      return { ...state, loading: false, error: action.error }
    case COMPANY_REMOVE_SUCCESS:
    case COMPANY_REACTIVATE_SUCCESS:
    case COMPANY_EDIT_SUCCESS:
    case COMPANY_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case COMPANY_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }
    case COMPANY_GET_CATALOGS_SUCCESS:
      return { ...state, error: null, loading: false, catalogs: { ...action.payload } }
    case COMPANY_GET_STATES_SUCCESS:
      return { ...state, error: null, loading: false, states: { ...action.payload } }
    case COMPANY_GET_CITIES_SUCCESS:
      return { ...state, error: null, loading: false, cities: { ...action.payload } }

    case COMPANY_GET_CITIES_FAILURE:
    case COMPANY_GET_ALL_FAILURE:
    case COMPANY_GET_STATES_FAILURE:
    case COMPANY_GET_CATALOGS_FAILURE:
    case COMPANY_GET_TOTALS_FAILURE:
    case COMPANY_REACTIVATE_FAILURE:
    case COMPANY_CREATE_FAILURE:
    case COMPANY_GET_ONE_FAILURE:
    case COMPANY_REMOVE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
