import AxesGlobalPactList from './AxesGlobalPactList'
import AxesGlobalPactCreate from './AxesGlobalPactCreate'
import AxesGlobalPactEdit from './AxesGlobalPactEdit'
import AxesGlobalPactView from './AxesGlobalPactView'

const axesGlobalPactRoutes = [
  { path: '/axesGlobalPact', component: AxesGlobalPactList },
  { path: '/axesGlobalPact/create', component: AxesGlobalPactCreate },
  { path: '/axesGlobalPact/:_id', component: AxesGlobalPactView },
  { path: '/axesGlobalPact/:id/edit', component: AxesGlobalPactEdit }
]
export default axesGlobalPactRoutes
