import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import { axesGlobalPactCreateRequest } from './axesGlobalPact.redux'
import AxesGlobalPactForm from './AxesGlobalPactForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

function AxesGlobalPactCreate(props) {
  return (
    <Layout icon='fas fa-fw fa-cog' title='Eje del Pacto Global'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <br />
      <CardBig title={'Agregar'}>
        <AxesGlobalPactForm mode='create' loading={false} user={undefined} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = ({ axesGlobalPact }) => ({ axesGlobalPact })
const mapDispatchToProps = {
  createAxesGlobalPact: axesGlobalPactCreateRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AxesGlobalPactCreate)
