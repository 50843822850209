import { generateActiveIcon, generateStatusIcon } from 'components/Theme/Table/helpers'
import { mdy } from 'utils/dates'

export const columns = [
  {
    dataField: 'number',
    text: 'Número',
    align: 'center',
    sort: true
  },
  {
    dataField: 'name',
    text: 'Nombre de la solución',
    sort: true
  },
  {
    dataField: 'gta',
    text: 'Grupo de trabajo Agenda 2030',
    sort: true
  },
  {
    dataField: 'workAxis',
    text: 'Eje de Trabajo',
    sort: true
  },
  {
    dataField: 'businessType',
    text: 'Ámbito de implementación',
    formatter: (cell, row) => cell.map(item => item).join(', '),
    sort: true
  },
  {
    dataField: 'objective',
    text: 'Objetivo (ODS)',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'status',
    align: 'center',
    width: '210',
    text: 'Status',
    formatter: cell => generateStatusIcon(cell),
    sort: true
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
