// Type's
export const GOODPRACTICES_GET_ALL_REQUEST = 'GOODPRACTICES_GET_ALL_REQUEST'
export const GOODPRACTICES_GET_ALL_SUCCESS = 'GOODPRACTICES_GET_ALL_SUCCESS'
export const GOODPRACTICES_GET_ALL_FAILURE = 'GOODPRACTICES_GET_ALL_FAILURE'
export const GOODPRACTICES_RELOAD = '[RELOADs] GOODPRACTICES_RELOAD'

export const GOODPRACTICES_GET_LIST_REQUEST = 'GOODPRACTICES_GET_LIST_REQUEST'
export const GOODPRACTICES_GET_LIST_SUCCESS = 'GOODPRACTICES_GET_LIST_SUCCESS'
export const GOODPRACTICES_GET_LIST_FAILURE = 'GOODPRACTICES_GET_LIST_FAILURE'

export const GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_REQUEST =
  'GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_REQUEST'
export const GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_SUCCESS =
  'GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_SUCCESS'
export const GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_FAILURE =
  'GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_FAILURE'
export const GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_DROP =
  'GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_DROP'

export const GOODPRACTICES_CREATE_REQUEST = 'GOODPRACTICES_CREATE_REQUEST'
export const GOODPRACTICES_CREATE_SUCCESS = 'GOODPRACTICES_CREATE_SUCCESS'
export const GOODPRACTICES_CREATE_FAILURE = 'GOODPRACTICES_CREATE_FAILURE'

export const GOODPRACTICES_CREATE_CAROUSEL_REQUEST = 'GOODPRACTICES_CREATE_CAROUSEL_REQUEST'
export const GOODPRACTICES_CREATE_CAROUSEL_SUCCESS = 'GOODPRACTICES_CREATE_CAROUSEL_SUCCESS'
export const GOODPRACTICES_CREATE_CAROUSEL_FAILURE = 'GOODPRACTICES_CREATE_CAROUSEL_FAILURE'

export const GOODPRACTICES_GET_ONE_REQUEST = 'GOODPRACTICES_GET_ONE_REQUEST'
export const GOODPRACTICES_GET_ONE_SUCCESS = 'GOODPRACTICES_GET_ONE_SUCCESS'
export const GOODPRACTICES_GET_ONE_FAILURE = 'GOODPRACTICES_GET_ONE_FAILURE'

export const GOODPRACTICES_SELECT_REQUEST = 'GOODPRACTICES_SELECT_REQUEST'
export const GOODPRACTICES_EDIT_REQUEST = 'GOODPRACTICES_EDIT_REQUEST'
export const GOODPRACTICES_EDIT_SUCCESS = 'GOODPRACTICES_EDIT_SUCCESS'
export const GOODPRACTICES_EDIT_FAILURE = 'GOODPRACTICES_EDIT_FAILURE'

export const GOODPRACTICES_REMOVE_REQUEST = 'GOODPRACTICES_REMOVE_REQUEST'
export const GOODPRACTICES_REMOVE_SUCCESS = 'GOODPRACTICES_REMOVE_SUCCESS'
export const GOODPRACTICES_REMOVE_FAILURE = 'GOODPRACTICES_REMOVE_FAILURE'

export const GOODPRACTICES_REACTIVATE_REQUEST = 'GOODPRACTICES_REACTIVATE_REQUEST'
export const GOODPRACTICES_REACTIVATE_SUCCESS = 'GOODPRACTICES_REACTIVATE_SUCCESS'
export const GOODPRACTICES_REACTIVATE_FAILURE = 'GOODPRACTICES_REACTIVATE_FAILURE'

export const GOODPRACTICES_FILTER_CHANGE_REQUEST = 'GOODPRACTICES_FILTER_CHANGE_REQUEST'
export const GOODPRACTICES_FILTER_CHANGE_SUCCESS = 'GOODPRACTICES_FILTER_CHANGE_SUCCESS'
export const GOODPRACTICES_FILTER_CHANGE_FAILURE = 'GOODPRACTICES_FILTER_CHANGE_FAILURE'

export const GOODPRACTICES_LOAD_MORE_REQUEST = 'GOODPRACTICES_LOAD_MORE_REQUEST'
export const GOODPRACTICES_LOAD_MORE_SUCCESS = 'GOODPRACTICES_LOAD_MORE_SUCCESS'
export const GOODPRACTICES_LOAD_MORE_FAILURE = 'GOODPRACTICES_LOAD_MORE_FAILURE'

export const GOODPRACTICES_GET_TOTALS_REQUEST = 'GOODPRACTICES_GET_TOTALS_REQUEST'
export const GOODPRACTICES_GET_TOTALS_SUCCESS = 'GOODPRACTICES_GET_TOTALS_SUCCESS'
export const GOODPRACTICES_GET_TOTALS_FAILURE = 'GOODPRACTICES_GET_TOTALS_FAILURE'

export const GOODPRACTICES_GET_CATALOGS_REQUEST = 'GOODPRACTICES_GET_CATALOGS_REQUEST'
export const GOODPRACTICES_GET_CATALOGS_SUCCESS = 'GOODPRACTICES_GET_CATALOGS_SUCCESS'
export const GOODPRACTICES_GET_CATALOGS_FAILURE = 'GOODPRACTICES_GET_CATALOGS_FAILURE'

export const GOODPRACTICES_GET_COMMENTS_DETAIL_REQUEST =
  'GOODPRACTICES_GET_COMMENTS_DETAIL_REQUEST'
export const GOODPRACTICES_GET_COMMENTS_DETAIL_SUCCESS =
  'GOODPRACTICES_GET_COMMENTS_DETAIL_SUCCESS'
export const GOODPRACTICES_GET_COMMENTS_DETAIL_FAILURE =
  'GOODPRACTICES_GET_COMMENTS_DETAIL_FAILURE'

export const GOODPRACTICES_ACCEPT_REQUEST = 'GOODPRACTICES_ACCEPT_REQUEST'
export const GOODPRACTICES_ACCEPT_SUCCESS = 'GOODPRACTICES_ACCEPT_SUCCESS'
export const GOODPRACTICES_ACCEPT_FAILURE = 'GOODPRACTICES_ACCEPT_FAILURE'

export const GOODPRACTICES_REJECT_REQUEST = 'GOODPRACTICES_REJECT_REQUEST'
export const GOODPRACTICES_REJECT_SUCCESS = 'GOODPRACTICES_REJECT_SUCCESS'
export const GOODPRACTICES_REJECT_FAILURE = 'GOODPRACTICES_REJECT_FAILURE'

// Action's
export const goodPracticesGetCommentsDetailRequest = (filters, payload) => ({
  type: GOODPRACTICES_GET_COMMENTS_DETAIL_REQUEST,
  filters,
  payload
})
export const goodPracticesGetCommentsDetailSuccess = payload => ({
  type: GOODPRACTICES_GET_COMMENTS_DETAIL_SUCCESS,
  payload
})
export const goodPracticesReload = () => ({
  type: GOODPRACTICES_RELOAD
})
export const goodPracticesGetCommentsDetailFailure = error => ({
  type: GOODPRACTICES_GET_COMMENTS_DETAIL_FAILURE,
  error
})
export const goodPracticesGetAllWithoutCarouselDrop = () => ({
  type: GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_DROP
})

export const goodPracticesGetAllRequest = (filters = {}) => ({
  type: GOODPRACTICES_GET_ALL_REQUEST,
  filters
})

export const goodPracticesGetListRequest = (filters = {}) => ({
  type: GOODPRACTICES_GET_LIST_REQUEST,
  filters
})

export const goodPracticesGetAllWithOutCarouselRequest = (filters = {}) => ({
  type: GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_REQUEST,
  filters
})
export const goodPracticesGetAllSuccess = payload => ({
  type: GOODPRACTICES_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = error => ({
  type: GOODPRACTICES_GET_ALL_REQUEST,
  error
})

export const goodPracticesFiltersChangeRequest = filters => ({
  type: GOODPRACTICES_FILTER_CHANGE_REQUEST,
  filters
})

export const goodPracticesloadMoreRequest = payload => ({
  type: GOODPRACTICES_LOAD_MORE_REQUEST,
  payload
})

export const goodPracticesCreateRequest = ({ values }) => ({
  type: GOODPRACTICES_CREATE_REQUEST,
  payload: values
})

export const goodPracticesCreateCarouselRequest = ({ values }) => ({
  type: GOODPRACTICES_CREATE_CAROUSEL_REQUEST,
  values
})

export const goodPracticesEditRequest = ({ values }) => ({
  type: GOODPRACTICES_EDIT_REQUEST,
  payload: values
})
export const goodPracticesSelectRequest = payload => ({
  type: GOODPRACTICES_SELECT_REQUEST,
  payload: payload
})
export const goodPracticesGetOneRequest = payload => ({
  type: GOODPRACTICES_GET_ONE_REQUEST,
  payload
})
export const goodPracticesGetOneSuccess = payload => ({
  type: GOODPRACTICES_GET_ONE_SUCCESS,
  payload
})
export const goodPracticesGetOneFailure = payload => ({
  type: GOODPRACTICES_GET_ONE_FAILURE,
  payload
})
export const goodPracticesDeactivateRequest = payload => ({
  type: GOODPRACTICES_REMOVE_REQUEST,
  payload
})
export const goodPracticesReactivateRequest = payload => ({
  type: GOODPRACTICES_REACTIVATE_REQUEST,
  payload
})
export const goodPracticesCreateSuccess = payload => ({
  type: GOODPRACTICES_CREATE_SUCCESS,
  payload
})
export const goodPracticesCreateFailure = payload => ({
  type: GOODPRACTICES_CREATE_FAILURE,
  payload
})
export const goodPracticesGetTotals = payload => ({
  type: GOODPRACTICES_GET_TOTALS_REQUEST,
  payload
})
export const goodPracticesGetCatalogs = filters => ({
  type: GOODPRACTICES_GET_CATALOGS_REQUEST,
  filters
})

export const acceptGoodPracticeRequest = newStatus => ({
  type: GOODPRACTICES_ACCEPT_REQUEST,
  newStatus
})
export const rejectGoodPracticeListRequest = newStatus => ({
  type: GOODPRACTICES_REJECT_REQUEST,
  newStatus
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  listSelected: [],
  comments: {},
  loading: false,
  error: null,
  filters: {},
  totals: {},
  catalogs: {}
}

export function goodPractices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GOODPRACTICES_GET_ALL_REQUEST:
    case GOODPRACTICES_GET_LIST_REQUEST:
    case GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_REQUEST:
    case GOODPRACTICES_CREATE_REQUEST:
    case GOODPRACTICES_CREATE_CAROUSEL_REQUEST:
    case GOODPRACTICES_GET_ONE_REQUEST:
    case GOODPRACTICES_EDIT_REQUEST:
    case GOODPRACTICES_SELECT_REQUEST:
    case GOODPRACTICES_REMOVE_REQUEST:
    case GOODPRACTICES_REACTIVATE_REQUEST:
    case GOODPRACTICES_GET_TOTALS_REQUEST:
    case GOODPRACTICES_GET_COMMENTS_DETAIL_REQUEST:
    case GOODPRACTICES_ACCEPT_REQUEST:
    case GOODPRACTICES_REJECT_REQUEST:
      return { ...state, error: null, loading: true }

    case GOODPRACTICES_GET_ONE_FAILURE:
    case GOODPRACTICES_CREATE_FAILURE:
    case GOODPRACTICES_CREATE_CAROUSEL_FAILURE:
    case GOODPRACTICES_GET_ALL_FAILURE:
    case GOODPRACTICES_GET_LIST_FAILURE:
    case GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_FAILURE:
    case GOODPRACTICES_EDIT_FAILURE:
    case GOODPRACTICES_REMOVE_FAILURE:
    case GOODPRACTICES_REACTIVATE_FAILURE:
    case GOODPRACTICES_GET_TOTALS_FAILURE:
    case GOODPRACTICES_GET_CATALOGS_FAILURE:
    case GOODPRACTICES_GET_COMMENTS_DETAIL_FAILURE:
    case GOODPRACTICES_ACCEPT_FAILURE:
    case GOODPRACTICES_REJECT_FAILURE:
      return { ...state, loading: false, error: action.error }

    case GOODPRACTICES_GET_ONE_SUCCESS:
    case GOODPRACTICES_EDIT_SUCCESS:
    case GOODPRACTICES_REMOVE_SUCCESS:
    case GOODPRACTICES_REACTIVATE_SUCCESS:
    case GOODPRACTICES_ACCEPT_SUCCESS:
    case GOODPRACTICES_REJECT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }

    case GOODPRACTICES_GET_COMMENTS_DETAIL_SUCCESS:
    case GOODPRACTICES_CREATE_CAROUSEL_SUCCESS:
      return { ...state, error: null, loading: false, comments: { ...action.payload } }
    case GOODPRACTICES_GET_ALL_SUCCESS:
    case GOODPRACTICES_GET_LIST_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_SUCCESS:
      return { ...state, error: null, loading: false, listSelected: { ...action.payload } }
    case GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_DROP:
      return { ...state, error: null, loading: false, all: {} }
    case GOODPRACTICES_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case GOODPRACTICES_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }
    case GOODPRACTICES_GET_CATALOGS_SUCCESS:
      return { ...state, error: null, loading: false, catalogs: { ...action.payload } }
    case GOODPRACTICES_LOAD_MORE_REQUEST:
      return { ...state, loading: true, count: action.payload }
    case GOODPRACTICES_RELOAD:
      return { ...INITIAL_STATE, loading: true }

    case GOODPRACTICES_LOAD_MORE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action
      // eslint-disable-next-line no-case-declarations
      const { cardsList } = state
      payload.data.docs = [...cardsList.docs, ...payload.data.docs]
      return {
        ...state,
        loading: false,
        cardsList: payload.data
      }
    default:
      return state
  }
}
