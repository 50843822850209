// Type's
export const CONTACT_GET_ALL_REQUEST = 'CONTACT_GET_ALL_REQUEST'
export const CONTACT_GET_ALL_SUCCESS = 'CONTACT_GET_ALL_SUCCESS'
export const CONTACT_GET_ALL_FAILURE = 'CONTACT_GET_ALL_FAILURE'

export const CONTACT_CREATE_REQUEST = 'CONTACT_CREATE_REQUEST'
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS'
export const CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE'

export const CONTACT_GET_ONE_REQUEST = 'CONTACT_GET_ONE_REQUEST'
export const CONTACT_GET_ONE_SUCCESS = 'CONTACT_GET_ONE_SUCCESS'
export const CONTACT_GET_ONE_FAILURE = 'CONTACT_GET_ONE_FAILURE'

export const CONTACT_GET_TOTALS_REQUEST = 'CONTACT_GET_TOTALS_REQUEST'
export const CONTACT_GET_TOTALS_SUCCESS = 'CONTACT_GET_TOTALS_SUCCESS'
export const CONTACT_GET_TOTALS_FAILURE = 'CONTACT_GET_TOTALS_FAILURE'

export const CONTACT_EDIT_REQUEST = 'CONTACT_EDIT_REQUEST'
export const CONTACT_EDIT_SUCCESS = 'CONTACT_EDIT_SUCCESS'
export const CONTACT_EDIT_FAILURE = 'CONTACT_EDIT_FAILURE'

export const CONTACT_REMOVE_REQUEST = 'CONTACT_REMOVE_REQUEST'
export const CONTACT_REMOVE_SUCCESS = 'CONTACT_REMOVE_SUCCESS'
export const CONTACT_REMOVE_FAILURE = 'CONTACT_REMOVE_FAILURE'

export const CONTACT_FILTER_CHANGE_REQUEST = 'CONTACT_FILTER_CHANGE_REQUEST'
export const CONTACT_FILTER_CHANGE_SUCCESS = 'CONTACT_FILTER_CHANGE_SUCCESS'
export const CONTACT_FILTER_CHANGE_FAILURE = 'CONTACT_FILTER_CHANGE_FAILURE'

// Action's
export const contactGetAllRequest = (filters) => ({
  type: CONTACT_GET_ALL_REQUEST,
  filters
})
export const contactGetAllSuccess = (payload) => ({
  type: CONTACT_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: CONTACT_GET_ALL_REQUEST,
  error
})
export const contactFiltersChangeRequest = (filters) => ({
  type: CONTACT_FILTER_CHANGE_REQUEST,
  filters
})
export const contactGetOneRequest = (payload) => ({ type: CONTACT_GET_ONE_REQUEST, payload })
export const contactGetOneSuccess = (payload) => ({ type: CONTACT_GET_ONE_SUCCESS, payload })
export const contactGetOneFailure = (payload) => ({ type: CONTACT_GET_ONE_FAILURE, payload })
export const contactGetTotals = (payload) => ({ type: CONTACT_GET_TOTALS_REQUEST, payload })
export const contactCreateRequest = (payload) => ({ type: CONTACT_CREATE_REQUEST, payload })
export const contactCreateSuccess = (payload) => ({ type: CONTACT_CREATE_SUCCESS, payload })
export const contactCreateFailure = (payload) => ({ type: CONTACT_CREATE_FAILURE, payload })

// Reducer
const INITIAL_STATE = {
  loading: false,
  error: null,
  filters: { active: true },
  totals: {},
  one: {},
  all: []
}

export function contacts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTACT_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case CONTACT_GET_ALL_REQUEST:
    case CONTACT_GET_TOTALS_REQUEST:
    case CONTACT_CREATE_REQUEST:
    case CONTACT_GET_ONE_REQUEST:
    case CONTACT_EDIT_REQUEST:
    case CONTACT_REMOVE_REQUEST:
      return { ...state, error: null, loading: true }

    case CONTACT_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case CONTACT_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }
    case CONTACT_EDIT_SUCCESS:
    case CONTACT_GET_ONE_SUCCESS:
    case CONTACT_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CONTACT_EDIT_FAILURE:
      return { ...state, loading: false, error: action.error }

    case CONTACT_GET_ALL_FAILURE:
    case CONTACT_CREATE_FAILURE:
    case CONTACT_GET_ONE_FAILURE:
    case CONTACT_GET_TOTALS_FAILURE:
    case CONTACT_REMOVE_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}
