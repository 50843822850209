import React from 'react'
import PriorizationBanner from 'components/Modules/Priorization/PriorizationBanner'
import PriorizationList from 'components/Modules/Priorization/PriorizationList'
import Layout from 'components/Layout'

export const PagePrioritization = () => {
  return (
    <div className='priorization-container'>
      <Layout icon='fas fa-star' title='Priorización'>
        <div className='priorization container'>
          <PriorizationBanner />
          <PriorizationList />
        </div>
      </Layout>
    </div>
  )
}

export default PagePrioritization
