import GtaCreate from './GtaCreate'
import GtaEdit from './GtaEdit'
import GtaList from './GtaList'
import GtaView from './GtaView'

const gtaRoutes = [
  { path: '/gtas', component: GtaList },
  { path: '/gtas/create', component: GtaCreate },
  { path: '/gtas/:_id', component: GtaView },
  { path: '/gtas/:id/edit', component: GtaEdit }
]

export default gtaRoutes
