// Type's

export const typePermissions = {
  PERMISSIONS_GET_REQUEST: '[PERMISSIONS] PERMISSIONS_GET_REQUEST',
  PERMISSIONS_GET_ALL_SUCCESS: '[PERMISSIONS] PERMISSIONS_GET_ALL_SUCCESS',
  PERMISSIONS_GET_ALL_FAILURE: '[PERMISSIONS] PERMISSIONS_GET_ALL_FAILURE',
  PERMISSIONS_GET_CONFIG_REQUEST: '[PERMISSIONS] PERMISSIONS_GET_CONFIG_REQUEST',
  PERMISSIONS_GET_CONFIG_SUCCESS: '[PERMISSIONS] PERMISSIONS_GET_CONFIG_SUCCESS',
  PERMISSIONS_GET_CONFIG_FAILURE: '[PERMISSIONS] PERMISSIONS_GET_CONFIG_FAILURE'
}

// Action's
export const permissionsGetAllRequest = (admin) => ({
  type: typePermissions.PERMISSIONS_GET_REQUEST,
  admin
})
export const permissionsGetAllSuccess = (payload) => ({
  type: typePermissions.PERMISSIONS_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: typePermissions.PERMISSIONS_GET_ALL_FAILURE,
  error
})

export const permissionsGetConfigRequest = (admin) => ({
  type: typePermissions.PERMISSIONS_GET_CONFIG_REQUEST,
  admin
})
export const permissionsGetConfigSuccess = (payload) => ({
  type: typePermissions.PERMISSIONS_GET_CONFIG_SUCCESS,
  payload
})
export const postgetConfigFailure = (error) => ({
  type: typePermissions.PERMISSIONS_GET_CONFIG_FAILURE,
  error
})

// Reducer
const INITIAL_STATE = {
  loading: false,
  error: null,
  list: []
}

export function permissions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case typePermissions.PERMISSIONS_GET_REQUEST:
    case typePermissions.PERMISSIONS_GET_CONFIG_REQUEST:
      return { ...state, error: null, loading: true }
    case typePermissions.PERMISSIONS_GET_ALL_SUCCESS:
    case typePermissions.PERMISSIONS_GET_CONFIG_SUCCESS:
      return { ...state, error: null, loading: false, list: [...action.payload] }
    case typePermissions.PERMISSIONS_GET_ALL_FAILURE:
    case typePermissions.PERMISSIONS_GET_CONFIG_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
