import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  FILTER_GET_ALL_REQUEST,
  FILTER_GET_ALL_FAILURE,
  FILTER_GET_ALL_SUCCESS,
  FILTER_CREATE_SUCCESS,
  FILTER_CREATE_FAILURE,
  FILTER_CREATE_REQUEST,
  FILTER_GET_ONE_FAILURE,
  FILTER_GET_ONE_SUCCESS,
  FILTER_GET_ONE_REQUEST,
  FILTER_REMOVE_SUCCESS,
  FILTER_REMOVE_FAILURE,
  FILTER_REMOVE_REQUEST,
  FILTER_EDIT_FAILURE,
  FILTER_EDIT_SUCCESS,
  FILTER_EDIT_REQUEST,
  FILTER_FILTER_CHANGE_SUCCESS,
  FILTER_FILTER_CHANGE_FAILURE,
  FILTER_FILTER_CHANGE_REQUEST,
  FILTER_REACTIVATE_SUCCESS,
  FILTER_REACTIVATE_FAILURE,
  FILTER_REACTIVATE_REQUEST,
  FILTER_GET_TOTALS_SUCCESS,
  FILTER_GET_TOTALS_FAILURE,
  FILTER_GET_TOTALS_REQUEST,
  FILTER_GET_CATALOGS_SUCCESS,
  FILTER_GET_CATALOGS_FAILURE,
  FILTER_GET_CATALOGS_REQUEST
} from './filter.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

function* getAllFilterSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/filters?${filterString}`)
    yield delay(1000)
    yield put({ type: FILTER_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: FILTER_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneFilterSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/filters/${_id}`)
    yield delay(1000)
    yield put({ type: FILTER_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: FILTER_GET_ONE_FAILURE, error: error.response })
  }
}
function* createFilterSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/filters', payload)
    yield delay(1500)
    yield put({ type: FILTER_CREATE_SUCCESS, payload: insert })
    yield put(push('/filters'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: FILTER_CREATE_FAILURE, error: error.response })
  }
}

function* getFilterFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: FILTER_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: FILTER_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: FILTER_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editFilterSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())

    const historical = { description }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/filters/' + payload._id, payload)
    yield put({ type: FILTER_EDIT_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/filters'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: FILTER_CREATE_FAILURE, error: error.response })
  }
}

function* removeFilterSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(axios.put, '/api/filters/' + payload.id + '/deactivate', payload)
    yield put({ type: FILTER_REMOVE_SUCCESS, payload: insert })
    yield put({ type: FILTER_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/filters'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: FILTER_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateFilterSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, '/api/filters/' + payload.id + '/reactivate', payload)
    yield put({ type: FILTER_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: FILTER_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/filters'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: FILTER_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/filters/totals`)
    yield delay(1000)
    yield put({ type: FILTER_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: FILTER_GET_TOTALS_FAILURE, error: error.response })
  }
}
function* getCatalogsSaga() {
  try {
    const { data: filterTypes } = yield call(axios.get, '/api/filters/types')
    const payload = { filterTypes }
    yield put({ type: FILTER_GET_CATALOGS_SUCCESS, payload: payload })
  } catch (error) {
    yield put({ type: FILTER_GET_CATALOGS_FAILURE, error: error.response })
  }
}

export default function* filterSagas() {
  yield takeLatest(FILTER_GET_ALL_REQUEST, getAllFilterSaga)
  yield takeLatest(FILTER_GET_ONE_REQUEST, getOneFilterSaga)
  yield takeLatest(FILTER_CREATE_REQUEST, createFilterSaga)
  yield takeLatest(FILTER_EDIT_REQUEST, editFilterSaga)
  yield takeLatest(FILTER_GET_ALL_FAILURE, manageError)
  yield takeLatest(FILTER_GET_ONE_FAILURE, manageError)
  yield takeLatest(FILTER_EDIT_FAILURE, manageError)
  yield takeLatest(FILTER_CREATE_FAILURE, manageError)
  yield takeLatest(FILTER_REMOVE_REQUEST, removeFilterSaga)
  yield takeLatest(FILTER_REMOVE_FAILURE, manageError)
  yield takeLatest(FILTER_FILTER_CHANGE_REQUEST, getFilterFiltersSaga)
  yield takeLatest(FILTER_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(FILTER_REACTIVATE_REQUEST, reactivateFilterSaga)
  yield takeLatest(FILTER_REACTIVATE_FAILURE, manageError)
  yield takeLatest(FILTER_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(FILTER_GET_TOTALS_FAILURE, manageError)
  yield takeLatest(FILTER_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(FILTER_GET_CATALOGS_FAILURE, manageError)
}
