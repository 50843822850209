import Acl from 'components/ACL/Acl'
import React, { Fragment } from 'react'
import NavItem from './NavigationItem'

const NavigationSection = ({ ItemList, title }) => {
  return (
    <Fragment>
      {/* <!-- Divider --> */}
      {/* <hr className='sidebar-divider my-0' />
      <span style={{ fontSize: 15.5, color: '#ffff' }}>{title}</span> */}
      <div>
        <hr className='sidebar-divider' />
        <div className='sidebar-heading'>{title}</div>
        <hr className='sidebar-divider' />
      </div>
      {/* <!-- Nav Item - Dashboard --> */}
      {ItemList.map((prop, key) => {
        return (
          <Acl module={prop.module} action={prop.action}>
          <NavItem key={key} link={prop.link} icon={prop.icon} id={prop.id} title={prop.title} />
        </Acl>
        )
      })}
    </Fragment>
  )
}

export default NavigationSection
