import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  WORK_AXIS_GET_ALL_REQUEST,
  WORK_AXIS_GET_ALL_FAILURE,
  WORK_AXIS_GET_ALL_SUCCESS,
  WORK_AXIS_CREATE_SUCCESS,
  WORK_AXIS_CREATE_FAILURE,
  WORK_AXIS_CREATE_REQUEST,
  WORK_AXIS_GET_ONE_FAILURE,
  WORK_AXIS_GET_ONE_SUCCESS,
  WORK_AXIS_GET_ONE_REQUEST,
  WORK_AXIS_REMOVE_SUCCESS,
  WORK_AXIS_REMOVE_FAILURE,
  WORK_AXIS_REMOVE_REQUEST,
  WORK_AXIS_EDIT_SUCCESS,
  WORK_AXIS_EDIT_REQUEST,
  WORK_AXIS_FILTER_CHANGE_SUCCESS,
  WORK_AXIS_FILTER_CHANGE_FAILURE,
  WORK_AXIS_FILTER_CHANGE_REQUEST,
  WORK_AXIS_REACTIVATE_SUCCESS,
  WORK_AXIS_REACTIVATE_FAILURE,
  WORK_AXIS_REACTIVATE_REQUEST,
  WORK_AXIS_GET_TOTALS_SUCCESS,
  WORK_AXIS_GET_TOTALS_FAILURE,
  WORK_AXIS_GET_TOTALS_REQUEST,
  WORK_AXIS_GET_CATALOGS_SUCCESS,
  WORK_AXIS_GET_CATALOGS_FAILURE,
  WORK_AXIS_GET_CATALOGS_REQUEST
} from './workAxis.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

function* getAllWorkAxisSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/workAxes/?${filterString}`)
    yield delay(1000)
    yield put({ type: WORK_AXIS_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: WORK_AXIS_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneWorkAxisSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/workAxes/${_id}`)
    yield delay(1000)
    yield put({ type: WORK_AXIS_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: WORK_AXIS_GET_ONE_FAILURE, error: error.response })
  }
}
function* createWorkAxisSaga({ payload }) {
  try {
    yield put(showLoading())
    let formData = new FormData()
    formData.append('image', payload.imageFile)
    formData.append('objective', payload.objective)
    formData.append('gta', payload.gta)

    const insert = yield call(axios.post, '/api/workAxes', formData)
    yield delay(1500)
    yield put({ type: WORK_AXIS_CREATE_SUCCESS, payload: insert })
    yield put(push('/workAxes'))
    // Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: WORK_AXIS_CREATE_FAILURE, error: error.response })
  }
}

function* getWorkAxisFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: WORK_AXIS_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: WORK_AXIS_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: WORK_AXIS_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editWorkAxisSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('_id', payload._id)
    formData.append('objective', payload.objective)
    formData.append('gta', payload.gta)
    if (payload.imageFile) {
      formData.append('image', payload.imageFile)
    }

    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())
    formData.append('description', description)

    const insert = yield call(axios.put, '/api/workAxes/' + payload._id, formData)
    yield put({ type: WORK_AXIS_EDIT_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/workAxes'))
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: WORK_AXIS_CREATE_FAILURE, error: error.response })
  }
}

function* removeWorkAxisSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/workAxes/' + payload.id + '/deactivate',
      payload
    )
    yield put({ type: WORK_AXIS_REMOVE_SUCCESS, payload: insert })
    yield put({ type: WORK_AXIS_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/workAxes'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: WORK_AXIS_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateWorkAxisSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/workAxes/' + payload.id + '/reactivate',
      payload
    )
    yield put({ type: WORK_AXIS_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: WORK_AXIS_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/workAxes'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: WORK_AXIS_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/workAxes/totals`)
    yield delay(1000)
    yield put({ type: WORK_AXIS_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: WORK_AXIS_GET_TOTALS_FAILURE, error: error.response })
  }
}

function* getCatalogsSaga({ filters = { active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data: gtas } = yield call(axios.get, `/api/gtas/all?${filterString}`)
    const payload = { gtas }
    yield put({ type: WORK_AXIS_GET_CATALOGS_SUCCESS, payload: payload })
  } catch (error) {
    yield put({ type: WORK_AXIS_GET_CATALOGS_FAILURE, error: error.response })
  }
}

export default function* workAxisSagas() {
  yield takeLatest(WORK_AXIS_GET_ALL_REQUEST, getAllWorkAxisSaga)
  yield takeLatest(WORK_AXIS_GET_ONE_REQUEST, getOneWorkAxisSaga)
  yield takeLatest(WORK_AXIS_CREATE_REQUEST, createWorkAxisSaga)
  yield takeLatest(WORK_AXIS_EDIT_REQUEST, editWorkAxisSaga)
  yield takeLatest(WORK_AXIS_GET_ALL_FAILURE, manageError)
  yield takeLatest(WORK_AXIS_GET_ONE_FAILURE, manageError)
  yield takeLatest(WORK_AXIS_REMOVE_REQUEST, removeWorkAxisSaga)
  yield takeLatest(WORK_AXIS_REMOVE_FAILURE, manageError)
  yield takeLatest(WORK_AXIS_FILTER_CHANGE_REQUEST, getWorkAxisFiltersSaga)
  yield takeLatest(WORK_AXIS_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(WORK_AXIS_REACTIVATE_REQUEST, reactivateWorkAxisSaga)
  yield takeLatest(WORK_AXIS_REACTIVATE_FAILURE, manageError)
  yield takeLatest(WORK_AXIS_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(WORK_AXIS_GET_TOTALS_FAILURE, manageError)
  yield takeLatest(WORK_AXIS_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(WORK_AXIS_GET_CATALOGS_FAILURE, manageError)
}
