/* PLOP_INJECT_IMPORT */
// import { post } from 'components/Post/post.redux'
import { combineReducers } from 'redux'
import { areas } from 'components/Modules/Catalogues/Area/area.redux'
import { historicals } from 'components/Historicals/historical.redux'
import { climbs } from 'components/Modules/Catalogues/Climb/climb.redux'
import { gtas, gta, gtasDocsDownload } from 'components/Modules/Catalogues/Gta/gta.redux'
import { businessTypes } from 'components/Modules/Catalogues/BusinessType/businessType.redux'
import { businessJoinTypes } from 'components/Modules/Catalogues/BusinessTypeJoin/businessJoinType.redux'
import { businessJoinSubTypes } from 'components/Modules/Catalogues/BusinessJoinSubType/businessJoinSubType.redux'
import { sectors } from 'components/Modules/Catalogues/Sector/sector.redux'
import { workAxis } from 'components/Modules/Catalogues/WorkAxis/workAxis.redux'
import { objective } from 'components/Modules/Catalogues/Objective/objective.redux'
// import { carousel } from 'components/Modules/CarouselCards/carousel.redux'
import { users } from 'components/Modules/Users/user.redux'
import { admins, admin, catalogs } from 'components/Modules/Admins/admin.redux'
import { cards } from 'components/Modules/Cards/cards.redux'
import { acl } from 'components/ACL/acl.redux'
import { formCards } from 'components/Modules/FormCard/formCard.redux'
import { app } from './app.reducer'
import { router } from './router.reducer'
import { companies } from 'components/Modules/Catalogues/Company/company.redux'
import { loading } from 'components/SplashScreen/splashscreen.redux'
import { auth } from 'components/Modules/Auth/auth.redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { contacts } from 'components/Modules/Contacts/contact.redux'
import { priorizations } from 'components/Modules/Priorization/priorization.redux'
import { flashcards } from 'components/Modules/Flashcards/flashcards.redux'
import { filters } from 'components/Modules/Catalogues/Filters/filter.redux'
import { permissions } from 'components/Modules/Catalogues/Permissions/permissions.redux'
import { timeInitiatives } from 'components/Modules/Catalogues/TimeInitiatives/timeInitiatives.redux'
import { notifications } from 'components/Theme/Layout/Header/notifications.redux'
import { comments } from 'components/Modules/Comments/comments.redux'
import { savedCards } from 'components/Modules/SavedCards/savedCards.redux'
import { modules } from 'components/Modules/Catalogues/Modules/modules.redux'
import { goodPractices } from 'components/Modules/GoodPractices/goodPractices.redux'
import { axesGlobalPact } from 'components/Modules/Catalogues/AxesGlobalPact/axesGlobalPact.redux'

export default combineReducers({
  /* PLOP_INJECT_EXPORT */
  acl,
  admin,
  admins,
  app,
  areas,
  modules,
  auth,
  businessTypes,
  businessJoinTypes,
  businessJoinSubTypes,
  cards,
  // carousel,
  catalogs,
  climbs,
  companies,
  contacts,
  flashcards,
  filters,
  formCards,
  gtas,
  gta,
  gtasDocsDownload,
  goodPractices,
  historicals,
  loading,
  loadingBar: loadingBarReducer,
  priorizations,
  permissions,
  router,
  sectors,
  timeInitiatives,
  users,
  workAxis,
  objective,
  notifications,
  comments,
  axesGlobalPact,
  savedCards
})
