import React, { useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import {
  workAxisCreateRequest,
  workAxisEditRequest,
  workAxisGetCatalogs
} from './../WorkAxis/workAxis.redux'
import FormInput from './../../../FormInput'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export const WorkAxisForm = ({ mode, workAxis, loading, catalogs, ...props }) => {
  useEffect(() => {
    props.workAxisGetCatalogs()
  }, [props.workAxisGetCatalogs])

  const handleCreateWorkAxis = values => {
    props.workAxisCreateRequest({ values })
  }
  const handleEditWorkAxis = values => {
    props.workAxisEditRequest({ values })
  }

  let initialValues = {
    gta: '',
    objective: ''
  }
  if (mode === 'edit') {
    initialValues = {
      _id: workAxis._id,
      gta: workAxis.gta?._id,
      objective: workAxis.objective
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          if (mode === 'create') {
            const requiredFields = ['gta', 'objective']
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.objective.length >= 1 && values.objective.length < 5) {
              errors.objective = 'Debe ser mayor de 5 caracteres'
            }
            if (values.objective.length > 50) {
              errors.objective = 'Debe ser menor de 50 caracteres'
            }
          } else {
            const requiredFields = ['gta', 'objective']
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.objective.length >= 1 && values.objective.length < 5) {
              errors.objective = 'Debe ser mayor de 5 caracteres'
            }
            if (values.objective.length > 50) {
              errors.objective = 'Debe ser menor de 50 caracteres'
            }
          }
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateWorkAxis(values)
            }
            if (mode === 'edit') {
              handleEditWorkAxis(values)
            }
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, handleSubmit, isSubmitting, handleChange }) => (
          <Form enctype='multipart/form-data' className='form ' onSubmit={handleSubmit}>
            <Row>
              <Col lg='6'>
                <FormInput
                  id='gta'
                  label={'Grupo de Trabajo Agenda 2030'}
                  type='select'
                  onChange={handleChange}
                  dataOptions={
                    catalogs.gtas &&
                    catalogs.gtas.data.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.gta}
                />
              </Col>
              <Col lg='6'>
                <FormInput id='objective' label={'Eje de Trabajo'} value={values.objective} />
              </Col>
            </Row>
            <Button color='primary' type='submit' disabled={isSubmitting}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.workAxis.all,
    one: state.workAxis.one,
    error: state.workAxis.error,
    filters: state.workAxis.filters,
    catalogs: state.workAxis.catalogs
  }
}

const mapDispatchToProps = {
  workAxisCreateRequest: workAxisCreateRequest,
  workAxisEditRequest: workAxisEditRequest,
  workAxisGetCatalogs: workAxisGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkAxisForm)
