import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, Field } from 'formik'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'reactstrap'
import addImage from './../../assets/img/addImage.svg'
import Swal from 'sweetalert2'

export const validateImage = file => {
  const { size, type } = file
  const validTypes = ['image/svg+xml', 'image/jpeg', 'image/png', 'image/webp']

  if (size > 1000000 || !validTypes.includes(type)) {
    Swal.fire({
      title: 'Archivo no válido',
      icon: 'warning',
      html: 'La imagen debe ser de tipo:<br/><span style="color:#e89349; font-weight:bold">jpeg, png, svg o webp.</span><br/><br/>Y debe tener un tamaño inferior a:<br/><span style="color:#e89349; font-weight:bold"> 1 MB</span>',
      showCancelButton: false,
      showConfirmButton: true
    })
    return false
  }
  return true
}

export const ButtonUploadContent = styled.div.attrs({
  // className: "btn btn-prmiary"
})`
  display: grid;
  align-items: center;
  justify-items: center;
  height: 140px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin: 1px 0px 30px 0px;
  color: #709dc8;
`

export const FormField = styled(Field)`
  border: none;
  border-radius: 5px;
  margin-top: 5px !important;
  margin-bottom: 12px;
  padding: 13px 10px;
  display: block;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  &:focus {
    outline: none;
  }
`

export const ButtonDeleteImg = styled.button.attrs({
  className: 'btn btn-primary btn-block mt-3'
})`
  margin: 0px 0 0 13px !important;
`

export const SelectForm = styled.select.attrs({
  className: 'form-select custom-select-filter'
})`
  border: none;
  border-radius: 5px;
  margin-top: 5px !important;
  margin-bottom: 12px;
  padding: 13px 10px;
  display: block;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
`

export const RowForm = styled(Row).attrs({
  // className: "form-label auth__label"
})`
  max-width: calc(100% + 44px);
  width: calc(100% + 21px);
`

export const FormLabel = styled.label.attrs({
  // className: "form-label auth__label"
})`
  margin-top: 30px;
  font-weight: 500;
  @media screen and (max-width: 575px) {
    ${props =>
      props.joinActions &&
      css`
        width: 100% !important;
      `}
  }
`
export const FormLabelWhite = styled.label.attrs({
  // className: "form-label auth__label"
})`
  margin-top: 30px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
`
export const ContentCenter = styled.div.attrs({
  // className: "btn btn-prmiary"
})`
  display: grid;
  align-items: center;
  justify-items: center;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  margin: 10px 0px;
  @media screen and (max-width: 575px) {
    ${props =>
      props.joinActions &&
      css`
        width: 40%;
      `}
  }
`

export const UploadJoinIcon = styled(FontAwesomeIcon)`
  font-size: 61px;
  color: #709dc8;
  margin-right: 15px;
`

export const AddImg = styled.img`
  margin-right: 8px;
  height: 62px;
  margin-left: 12px;
  @media screen and (max-width: 575px) {
    height: 40px;
  }
`
export const ParraphTitle = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
`

export const ParraphText = styled.p`
  font-size: 13px;
  line-height: 16px;
  @media screen and (max-width: 575px) {
    font-size: 11px;
    padding-right: 40px;
  }
`

export const ParraphContent = styled.div`
  padding: 0px;
  min-width: 201px;
  @media screen and (max-width: 575px) {
    min-width: 170px;
  }
`
export const ImgContent = styled.div`
  padding: 0 28px 0 0;
  /* min-width: 201px; */
  @media screen and (max-width: 575px) {
    font-size: 12px;
  }
`

export const ImgUploades = styled.img`
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  float: left;
`

export const LabelUploadIcon = styled.label`
  display: flex;
  cursor: pointer;
  line-height: 21px;
  font-size: 20px;
  font-weight: normal;
  padding-left: 15px;
  align-items: center;
`

const DragAndDropUploadFile = ({
  idImage,
  leyend,
  value,
  onChange,
  handleChange,
  isImageUploaded
}) => {
  const readURL = input => {
    onChange(input)
    if (input.target.files && input.target.files[0]) {
      var output = document.getElementById(`uploadedFile${idImage}`)
      output.src = URL.createObjectURL(input.target.files[0])
    }
  }

  return (
    <ButtonUploadContent for='inputTag'>
      <LabelUploadIcon style={{ display: isImageUploaded ? 'none' : 'flex' }}>
        <AddImg src={addImage} alt='profilePicture' />
        <ImgContent>Adjúntalo aquí</ImgContent>
        {leyend && (
          <ParraphContent>
            <ParraphTitle>
              Medidas
              <br />
            </ParraphTitle>
            <ParraphText>
              Imágenes horizontales
              <br />
              Medida aproximada: 1920 x 300
            </ParraphText>
          </ParraphContent>
        )}
        <Field
          style={{ display: 'none' }}
          type='file'
          onChange={e => readURL(e)}
          id={idImage}
          value={value}
        />
        <ErrorMessage
          name={idImage}
          component='div'
          className='animate__animated animate__fadeIn animate__faster error-message'
        />
      </LabelUploadIcon>
      <LabelUploadIcon style={{ display: isImageUploaded ? 'flex' : 'none' }}>
        <ImgUploades id={`uploadedFile${idImage}`} src='#' alt='imageUploaded' />
        <ParraphContent>
          <ButtonDeleteImg onClick={handleChange}>{'Eliminar imagen'}</ButtonDeleteImg>
        </ParraphContent>
      </LabelUploadIcon>
    </ButtonUploadContent>
  )
}

DragAndDropUploadFile.propTypes = {
  leyend: PropTypes.bool,
  id: PropTypes.string.isRequired
}

DragAndDropUploadFile.defaultProps = {
  leyend: false
}

export default DragAndDropUploadFile
