import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { publicRoutes, routes } from './routes'

import { ConnectedRouter } from 'connected-react-router'
import { history } from 'redux/configureStore'

import ScrollTop from 'components/ScrollTop'
import { connect } from 'react-redux'

function Router({ isLogin, verifedLogin }) {
  const location = window.location
  const pathName = location.pathname.split('/')[2]

  return (
    (verifedLogin || pathName === 'updatepassword') && (
      <ConnectedRouter history={history}>
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route key={index} exact path={route.path} component={route.component} />
          ))}
          {isLogin || pathName === 'updatepassword' ? (
            routes.map((route, index) => (
              <Route key={index} exact path={route.path} component={route.component} />
            ))
          ) : (
            <Redirect to='/' />
          )}
        </Switch>
        <ScrollTop />
      </ConnectedRouter>
    )
  )
}

const mapStateToProps = state => {
  return {
    isLogin: state.auth.isLogin,
    loading: state.auth.loading,
    verifedLogin: state.auth.verifedLogin
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Router)
