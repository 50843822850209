import { generateActiveIcon, generateDocumentIcon } from 'components/Theme/Table/helpers'
import { mdy, mdyhm } from 'utils/dates'

export const columns = [
  {
    dataField: 'title',
    text: 'Título',
    sort: true
  },
  ,
  {
    dataField: 'document',
    text: 'Guía',
    formatter: cell => generateDocumentIcon(cell),
    sort: true
  },
  {
    dataField: 'nomenclature',
    text: 'Nomenclatura',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
export const columnsDocs = [
  {
    dataField: 'gta.title',
    text: 'Guía GTA2030',
    sort: true
  },
  {
    dataField: 'user.name',
    text: 'Usuario que descargó',
    formatter: (cell, row) => `${row.user.name} ${row.user.lastName}`,
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Fecha de descarga',
    sort: true,
    formatter: mdyhm
  }
]
