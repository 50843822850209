import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import SectorForm from './SectorForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

export const SectorEdit = ({ one }) => {
  return (
    <Layout icon="fas fa-fw fa-puzzle-piece" title="Sector">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />{' '}
      <CardBig title={'Editar'}>
        <SectorForm mode="edit" sector={one} loading={false} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.sectors.all,
    one: state.sectors.one,
    error: state.sectors.error,
    loading: state.sectors.loading
  }
}

export default connect(mapStateToProps)(SectorEdit)
