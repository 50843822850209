import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import { objectiveCreateRequest } from './objective.redux'
import ObjectiveForm from './ObjectiveForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

function ObjectiveCreate(props) {
  return (
    <Layout icon='fas fa-bullseye' title='Objetivo de Desarrollo Sostenible'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <br />
      <CardBig title={'Agregar'}>
        <ObjectiveForm mode='create' loading={false} user={undefined} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = ({ objective }) => ({ objective })
const mapDispatchToProps = {
  createObjective: objectiveCreateRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectiveCreate)
