import { isEmpty } from 'utils/crud'

const isLoading = type => {
  if (
    !isEmpty(type) &&
    (type.includes('GET_ONE') || type.includes('GET_ALL')) &&
    type.substring(type.length - 8) === '_REQUEST'
  ) {
    return true
  } else {
    return false
  }
}

export const loading = (state = false, action) => isLoading(action.type)
