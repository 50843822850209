// Type's
export const GTA_GET_ALL_REQUEST = 'GTA_GET_ALL_REQUEST'
export const GTA_GET_ALL_SUCCESS = 'GTA_GET_ALL_SUCCESS'
export const GTA_GET_ALL_FAILURE = 'GTA_GET_ALL_FAILURE'

export const GTA_GET_ALL_DOCS_REQUEST = 'GTA_GET_ALL_DOCS_REQUEST'
export const GTA_GET_ALL_DOCS_SUCCESS = 'GTA_GET_ALL_DOCS_SUCCESS'
export const GTA_GET_ALL_DOCS_FAILURE = 'GTA_GET_ALL_DOCS_FAILURE'

export const GTA_CREATE_REQUEST = 'GTA_CREATE_REQUEST'
export const GTA_CREATE_SUCCESS = 'GTA_CREATE_SUCCESS'
export const GTA_CREATE_FAILURE = 'GTA_CREATE_FAILURE'

export const GTA_IMAGE_CREATE_REQUEST = 'GTA_IMAGE_CREATE_REQUEST'
export const GTA_IMAGE_CREATE_SUCCESS = 'GTA_IMAGE_CREATE_SUCCESS'
export const GTA_IMAGE_CREATE_FAILURE = 'GTA_IMAGE_CREATE_FAILURE'

export const GTA_GET_ONE_REQUEST = 'GTA_GET_ONE_REQUEST'
export const GTA_GET_ONE_SUCCESS = 'GTA_GET_ONE_SUCCESS'
export const GTA_GET_ONE_FAILURE = 'GTA_GET_ONE_FAILURE'

export const GTA_ADMIN_GET_REQUEST = 'GTA_ADMIN_GET_REQUEST'
export const GTA_ADMIN_GET_SUCCESS = 'GTA_ADMIN_GET_SUCCESS'
export const GTA_ADMIN_GET_RESET = 'GTA_ADMIN_GET_RESET'
export const GTA_ADMIN_GET_FAILURE = 'GTA_ADMIN_GET_FAILURE'

export const GTA_EDIT_REQUEST = 'GTA_EDIT_REQUEST'
export const GTA_EDIT_SUCCESS = 'GTA_EDIT_SUCCESS'
export const GTA_EDIT_FAILURE = 'GTA_EDIT_FAILURE'

export const GTA_REMOVE_REQUEST = 'GTA_REMOVE_REQUEST'
export const GTA_REMOVE_SUCCESS = 'GTA_REMOVE_SUCCESS'
export const GTA_REMOVE_FAILURE = 'GTA_REMOVE_FAILURE'

export const GTA_FILTER_CHANGE_REQUEST = 'GTA_FILTER_CHANGE_REQUEST'
export const GTA_FILTER_CHANGE_SUCCESS = 'GTA_FILTER_CHANGE_SUCCESS'
export const GTA_FILTER_CHANGE_FAILURE = 'GTA_FILTER_CHANGE_FAILURE'

export const GTA_REACTIVATE_REQUEST = 'GTA_REACTIVATE_REQUEST'
export const GTA_REACTIVATE_SUCCESS = 'GTA_REACTIVATE_SUCCESS'
export const GTA_REACTIVATE_FAILURE = 'GTA_REACTIVATE_FAILURE'

export const GTA_DEACTIVATE_REQUEST = 'GTA_DEACTIVATE_REQUEST'
export const GTA_DEACTIVATE_SUCCESS = 'GTA_DEACTIVATE_SUCCESS'
export const GTA_DEACTIVATE_FAILURE = 'GTA_DEACTIVATE_FAILURE'

export const GTA_GET_TOTALS_REQUEST = 'GTA_GET_TOTALS_REQUEST'
export const GTA_GET_TOTALS_SUCCESS = 'GTA_GET_TOTALS_SUCCESS'
export const GTA_GET_TOTALS_FAILURE = 'GTA_GET_TOTALS_FAILURE'
// Action's

export const gtaDeactivateRequest = payload => ({
  type: GTA_DEACTIVATE_REQUEST,
  payload
})
export const gtaReactivateRequest = payload => ({
  type: GTA_REACTIVATE_REQUEST,
  payload
})
export const gtaGetTotalsRequest = payload => ({ type: GTA_GET_TOTALS_REQUEST, payload })
export const gtaGetAllRequest = filters => ({ type: GTA_GET_ALL_REQUEST, filters })
export const gtaGetAllDocsRequest = filters => ({ type: GTA_GET_ALL_DOCS_REQUEST, filters })
export const gtaGetAllSuccess = payload => ({
  type: GTA_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = error => ({
  type: GTA_GET_ALL_REQUEST,
  error
})
export const gtaFiltersChangeRequest = filters => ({
  type: GTA_FILTER_CHANGE_REQUEST,
  filters
})
export const gtaGetOneRequest = payload => ({ type: GTA_GET_ONE_REQUEST, payload })
export const gtaGetOneSuccess = payload => ({ type: GTA_GET_ONE_SUCCESS, payload })
export const gtaGetOneFailure = payload => ({ type: GTA_GET_ONE_FAILURE, payload })

export const gtaAdminGetRequest = gta => ({ type: GTA_ADMIN_GET_REQUEST, gta })

export const gtaCreateRequest = ({ values }) => ({ type: GTA_CREATE_REQUEST, payload: values })
export const gtaCreateSuccess = payload => ({ type: GTA_CREATE_SUCCESS, payload })
export const gtaCreateFailure = payload => ({ type: GTA_CREATE_FAILURE, payload })

export const gtaImageCreateRequest = ({ values }) => ({
  type: GTA_IMAGE_CREATE_REQUEST,
  payload: values
})
export const gtaImageCreateSuccess = payload => ({ type: GTA_IMAGE_CREATE_SUCCESS, payload })
export const gtaImageCreateFailure = payload => ({ type: GTA_IMAGE_CREATE_FAILURE, payload })

export const gtaEditRequest = ({ values }) => ({
  type: GTA_EDIT_REQUEST,
  payload: values
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: { active: true },
  totals: {}
}
const INITIAL_STATE_DOCS = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: { active: true },
  totals: {}
}
const INITIAL_STATE_ADMIN = {
  loading: false,
  error: null
}

export function gtas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GTA_REMOVE_REQUEST:
    case GTA_GET_ALL_REQUEST:
    case GTA_CREATE_REQUEST:
    case GTA_EDIT_REQUEST:
    case GTA_GET_ONE_REQUEST:
    case GTA_DEACTIVATE_REQUEST:
    case GTA_REACTIVATE_REQUEST:
    case GTA_GET_TOTALS_REQUEST:
    case GTA_IMAGE_CREATE_REQUEST:
      return { ...state, error: null, loading: true }

    case GTA_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case GTA_CREATE_SUCCESS:
    case GTA_IMAGE_CREATE_SUCCESS:
    case GTA_DEACTIVATE_SUCCESS:
    case GTA_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case GTA_EDIT_SUCCESS:
    case GTA_GET_ONE_SUCCESS:
    case GTA_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case GTA_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }

    case GTA_GET_ALL_FAILURE:
    case GTA_CREATE_FAILURE:
    case GTA_IMAGE_CREATE_FAILURE:
    case GTA_GET_ONE_FAILURE:
    case GTA_EDIT_FAILURE:
    case GTA_REMOVE_FAILURE:
    case GTA_DEACTIVATE_FAILURE:
    case GTA_REACTIVATE_FAILURE:
    case GTA_GET_TOTALS_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
export function gta(state = INITIAL_STATE_ADMIN, action) {
  switch (action.type) {
    case GTA_ADMIN_GET_REQUEST:
      return { ...state, error: null, loading: true }
    case GTA_ADMIN_GET_SUCCESS:
      return { ...state, error: null, loading: false, ...action.payload }
    case GTA_ADMIN_GET_RESET:
      return INITIAL_STATE_ADMIN
    case GTA_ADMIN_GET_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export function gtasDocsDownload(state = INITIAL_STATE_DOCS, action) {
  switch (action.type) {
    case GTA_GET_ALL_DOCS_REQUEST:
      return { ...state, error: null, loading: true }
    case GTA_GET_ALL_DOCS_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case GTA_GET_ALL_DOCS_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
