import { put, call, takeLatest, delay } from 'redux-saga/effects'
import { serviceUrl } from 'utils/serviceUrl'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  GTA_GET_ALL_REQUEST,
  GTA_GET_ALL_FAILURE,
  GTA_GET_ALL_SUCCESS,
  GTA_CREATE_SUCCESS,
  GTA_CREATE_FAILURE,
  GTA_CREATE_REQUEST,
  GTA_GET_ONE_FAILURE,
  GTA_GET_ONE_SUCCESS,
  GTA_GET_ONE_REQUEST,
  GTA_REMOVE_SUCCESS,
  GTA_REMOVE_FAILURE,
  GTA_REMOVE_REQUEST,
  GTA_EDIT_REQUEST,
  GTA_FILTER_CHANGE_SUCCESS,
  GTA_FILTER_CHANGE_FAILURE,
  GTA_FILTER_CHANGE_REQUEST,
  GTA_DEACTIVATE_SUCCESS,
  GTA_DEACTIVATE_FAILURE,
  GTA_REACTIVATE_SUCCESS,
  GTA_GET_TOTALS_SUCCESS,
  GTA_GET_TOTALS_FAILURE,
  GTA_DEACTIVATE_REQUEST,
  GTA_REACTIVATE_REQUEST,
  GTA_GET_TOTALS_REQUEST,
  GTA_ADMIN_GET_REQUEST,
  GTA_ADMIN_GET_FAILURE,
  GTA_ADMIN_GET_SUCCESS,
  GTA_GET_ALL_DOCS_REQUEST,
  GTA_GET_ALL_DOCS_SUCCESS,
  GTA_GET_ALL_DOCS_FAILURE
} from './gta.redux.js'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

export function* getAllGtaSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/gtas/?${filterString}`)
    yield delay(1000)
    yield put({ type: GTA_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GTA_GET_ALL_FAILURE, error: error.response })
  }
}

export function* getAllDocsGtaSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/gtasDocsDownloads/?${filterString}`)
    yield put({ type: GTA_GET_ALL_DOCS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GTA_GET_ALL_DOCS_FAILURE, error: error.response })
  }
}

export function* getOneGtaSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/gtas/${_id}`)
    yield delay(1000)
    yield put({ type: GTA_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GTA_GET_ONE_FAILURE, error: error.response })
  }
}

export function* getAdminGtaGetSaga({ gta }) {
  try {
    const { data } = yield call(axios.get, `/api/gtas/${gta}`)
    yield delay(1000)
    yield put({ type: GTA_ADMIN_GET_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GTA_ADMIN_GET_FAILURE, error: error.response })
  }
}
export function* createGtaSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('nomenclature', payload.nomenclature)
    formData.append('title', payload.title)
    formData.append('subtitle', payload.subtitle)
    formData.append('goal', payload.goal)
    formData.append('actionLines', payload.actionLines)
    formData.append('author', payload.author)
    formData.append('number', payload.number)
    formData.append('color', payload.color)
    formData.append('position', payload.position)
    formData.append('logoColor', payload.imageColorFile)
    formData.append('logo', payload.logoFile)

    yield put(showLoading())
    const insert = yield call(axios.post, '/api/gtas', formData)
    yield delay(1500)
    yield put({ type: GTA_CREATE_SUCCESS, payload: insert })
    yield put(push('/gtas'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GTA_CREATE_FAILURE, error: error.response })
  }
}

export function* getGtaFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: GTA_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: GTA_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: GTA_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
export function* editGtaSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('_id', payload._id)
    formData.append('nomenclature', payload.nomenclature)
    formData.append('title', payload.title)
    formData.append('subtitle', payload.subtitle)
    formData.append('goal', payload.goal)
    formData.append('actionLines', payload.actionLines)
    formData.append('author', payload.author)
    formData.append('position', payload.position)
    formData.append('number', payload.number)
    formData.append('color', payload.color)
    if (payload.imageColorFile) {
      formData.append('logoColor', payload.imageColorFile)
    }
    if (payload.logoFile) {
      formData.append('logo', payload.logoFile)
    }
    if (payload.documentFile) {
      formData.append('document', payload.documentFile)
    }
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))

    if (!description) return
    yield put(showLoading())
    formData.append('description', description)

    const insert = yield call(axios.put, '/api/gtas/' + payload._id, formData)
    yield put({ type: GTA_CREATE_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/gtas'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GTA_CREATE_FAILURE, error: error.response })
  }
}

export function* removeGtaSaga({ id }) {
  try {
    const insert = yield call(axios.delete, serviceUrl.gta + '/' + id)
    yield put({ type: GTA_REMOVE_SUCCESS, payload: insert })
    yield put({ type: GTA_GET_ALL_REQUEST })
    yield put(showLoading())
    yield delay(3000)
    yield put(push('/gtas'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GTA_REMOVE_FAILURE, error: error.response })
  }
}

function* deactivateGtaSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(axios.put, '/api/gtas/' + payload.id + '/deactivate', payload)
    yield put({ type: GTA_DEACTIVATE_SUCCESS, payload: insert })
    yield put({ type: GTA_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/gtas'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GTA_DEACTIVATE_FAILURE, error: error.response })
  }
}

function* reactivateGtaSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, '/api/gtas/' + payload.id + '/reactivate', payload)
    yield put({ type: GTA_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: GTA_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/gtas'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GTA_DEACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/gtas/totals`)
    yield delay(1000)
    yield put({ type: GTA_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GTA_GET_TOTALS_FAILURE, error: error.response })
  }
}
export default function* gtaSagas() {
  yield takeLatest(GTA_GET_ALL_REQUEST, getAllGtaSaga)
  yield takeLatest(GTA_GET_ALL_DOCS_REQUEST, getAllDocsGtaSaga)
  yield takeLatest(GTA_GET_ONE_REQUEST, getOneGtaSaga)
  yield takeLatest(GTA_ADMIN_GET_REQUEST, getAdminGtaGetSaga)
  yield takeLatest(GTA_CREATE_REQUEST, createGtaSaga)
  yield takeLatest(GTA_EDIT_REQUEST, editGtaSaga)
  yield takeLatest(GTA_REMOVE_REQUEST, removeGtaSaga)
  yield takeLatest(GTA_GET_ALL_FAILURE, manageError)
  yield takeLatest(GTA_GET_ONE_FAILURE, manageError)
  yield takeLatest(GTA_REMOVE_FAILURE, manageError)
  yield takeLatest(GTA_FILTER_CHANGE_REQUEST, getGtaFiltersSaga)
  yield takeLatest(GTA_DEACTIVATE_REQUEST, deactivateGtaSaga)
  yield takeLatest(GTA_REACTIVATE_REQUEST, reactivateGtaSaga)
  yield takeLatest(GTA_GET_TOTALS_REQUEST, getTotalsSaga)
}
