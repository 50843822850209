import React, { Fragment } from 'react'
import { ErrorMessage, Field, Formik } from 'formik'
import { connect } from 'react-redux'
import { LoginValidationiSchema } from './LoginValidationiSchema'
import { Button, Col, Form, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { loginAdminRequest, loginVerifyRequest } from './auth.redux.js'
import './two-step.css'

function AuthForm({ loading, firstStepDone, email, loginAdminRequest, loginVerifyRequest }) {
  let verifyValue = { c1: '', c2: '', c3: '', c4: '', c5: '', c6: '' }
  let initialValuesData = { email: '', password: '' }
  function focusChange(e) {
    if (e.target.value.length >= e.target.getAttribute('maxlength')) {
      if (e.target.nextSibling) e.target.nextSibling.focus()
    }
  }

  return (
    <Fragment>
      {firstStepDone && (
        <Formik
          initialValues={verifyValue}
          onSubmit={(values, { setSubmitting }) => {
            const token = {
              token: (
                values.c1 +
                values.c2 +
                values.c3 +
                values.c4 +
                values.c5 +
                values.c6
              ).toUpperCase(),
              email
            }

            setTimeout(() => {
              loginVerifyRequest(token)
              setSubmitting(false)
            }, 600)
          }}>
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Col className='verification-center'>
                <p>
                  Ingrese el código que recibio en su correo. Es posible que se encuentre en la
                  carpeta Spam o correo no deseado
                </p>

                <Row className='verification-center'>
                  <input
                    type='text'
                    className='verification-input'
                    value={values.c1}
                    maxLength={1}
                    onChange={handleChange}
                    id='c1'
                    required
                    onInput={e => focusChange(e)}
                  />
                  <input
                    type='text'
                    className='verification-input'
                    value={values.c2}
                    maxLength={1}
                    onChange={handleChange}
                    id='c2'
                    required
                    onInput={e => focusChange(e)}
                  />
                  <input
                    type='text'
                    className='verification-input'
                    value={values.c3}
                    maxLength={1}
                    onChange={handleChange}
                    id='c3'
                    required
                    onInput={e => focusChange(e)}
                  />
                  <input
                    type='text'
                    className='verification-input'
                    value={values.c4}
                    maxLength={1}
                    onChange={handleChange}
                    id='c4'
                    required
                    onInput={e => focusChange(e)}
                  />
                  <input
                    type='text'
                    className='verification-input'
                    value={values.c5}
                    maxLength={1}
                    onChange={handleChange}
                    id='c5'
                    required
                    onInput={e => focusChange(e)}
                  />
                  <input
                    type='text'
                    className='verification-input'
                    value={values.c6}
                    maxLength={1}
                    onChange={handleChange}
                    id='c6'
                    required
                  />
                </Row>
              </Col>
              <br />
              <Button color='primary' type='submit' disabled={loading}>
                {loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{ marginLeft: '35px', marginRight: '35px' }}
                    />
                  </span>
                )}
                {!loading && <span> Iniciar sesión</span>}
              </Button>{' '}
            </Form>
          )}
        </Formik>
      )}
      {!firstStepDone && (
        <Fragment>
          <Formik
            validationSchema={LoginValidationiSchema}
            initialValues={initialValuesData}
            validate={values => {
              const errors = {}
              for (const key in values) if (!values[key]) errors[key] = 'Campo requerido'
              if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              )
                errors.email = 'Correo no válido'
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                loginAdminRequest(values)
                setSubmitting(false)
              }, 600)
            }}>
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <h6 className='label-form'>Usuario:</h6>

                    <Field
                      id='email'
                      name='email'
                      className='form-control'
                      placeholder='Ingrese su email'
                      autoComplete='off'
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className='animate__animated animate__fadeIn animate__faster error-message'
                    />
                    <h6 className='label-form'>Contraseña:</h6>

                    <Field
                      type='password'
                      id='password'
                      name='password'
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      className='form-control'
                      style={{
                        fontSize: '.8rem'
                      }}
                    />
                    <ErrorMessage
                      name='password'
                      component='div'
                      className='animate__animated animate__fadeIn animate__faster error-message'
                    />
                  </Col>
                </Row>
                <br />
                <div>
                  <button
                    type='submit'
                    className='btn btn-primary btn-block mt-3'
                    disabled={loading}>
                    Enviar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Fragment>
      )}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    loading: state.auth.loading,
    firstStepDone: state.auth.firstStepDone,
    message: state.auth.message,
    email: state.auth.email
  }
}

const mapDispatchToProps = { loginAdminRequest, loginVerifyRequest }

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm)
