import { LOCALES } from 'i18n/locales'

export default {
  [LOCALES.ENGLISH]: {
    'dashboard-create-user': 'Create user',
    'dashboard-user-module-name': 'Users',

    //DASHBOARD MENU
    'db-m-home': 'Dashboard',
    'db-m-users': 'Users',
    'db-m-users-public': 'Public Users',
    'db-m-users-dashboard': 'GTA Users',
    'db-m-work-area': 'Ejes de trabajo',
    'db-m-questionnaires-sent': 'Questionnaires Sent',
    'db-m-questionnaires': 'Questionnaires',
    'db-m-flashcards': 'Flashcards',
    'db-m-evaluations': 'Evaluations',
    'db-m-traffic-light': 'Implementation Traffic Light',
    'db-m-catalogues': 'Catalogues',
    'db-m-catalogues-area': 'Areas',
    'db-m-catalogues-climb': 'CLIMB',
    'db-m-catalogues-companies': 'Companies',
    'db-m-catalogues-gta': 'GTA2030',
    'db-m-catalogues-sector': 'Sectors',
    'db-m-reports': 'Reports',
    'db-m-config': 'Configuration'
  }
}
