import React, { useEffect } from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { Formik, Form, Field } from 'formik'
import { connect } from 'react-redux'
import FormInput from 'components/FormInput'
import {
  adminCreateRequest,
  adminEditRequest,
  adminGetCatalogs,
  adminGetCities,
  adminGetStates
} from './admin.redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { Row } from 'reactstrap'
import { Col } from 'reactstrap'
import AdminCreatePermissions from './AdminCreatePermissions'
import { modulesGetAllRequest } from 'components/Modules/Catalogues/Modules/modules.redux'
import { showWarningMessage } from 'components/Theme/Alerts/exceptionUtils'

function AdminFormCreate({
  mode,
  one,
  user,
  modules,
  loading,
  catalogs,
  adminGetCatalogs,
  modulesGetAllRequest,
  adminGetStates,
  adminGetCities,
  adminCreateRequest,
  ...props
}) {
  useEffect(() => {
    adminGetCatalogs()
    modulesGetAllRequest()
    if (mode === 'edit') {
      if (user.country) adminGetStates(user.country)
      if (user.state) adminGetCities(user.country, user.state)
    }
  }, [modulesGetAllRequest, adminGetCatalogs, adminGetStates, adminGetCities, mode, user])

  const handleCreateAdmin = values => {
    adminCreateRequest({ values })
  }
  const handleEditAdmin = values => {
    props.adminEditRequest({ values })
  }
  const getState = event => {
    adminGetStates(event.value)
  }
  const getCities = (country, event) => {
    adminGetCities(country, event.value)
  }
  const changeCountry = (e, values) => {
    values.country = e.value
  }
  const changeCompany = (e, values) => {
    values.company = e.value
  }
  const changeState = (e, values) => {
    values.state = e.value
  }
  const roles = [
    { id: 0, text: 'Super Usuario', value: 0 },
    { id: 1, text: 'Comité editorial', value: 1 },
    { id: 2, text: 'Participante', value: 2 }
  ]

  const allGtas = (
    <option key={'all'} value={'Todos'}>
      Todos
    </option>
  )

  const countries = catalogs.countries?.data?.map(({ name, Iso3 }) => ({
    value: name,
    label: name === 'Mexico' ? 'México' : name,
    text: name === 'Mexico' ? 'México' : name,
    id: name,
    key: Iso3
  }))

  const statesList = catalogs.states?.data?.states?.map(({ name }) => ({
    value: name,
    label: name,
    id: name,
    key: name
  }))

  const citiesList = catalogs.cities.data?.map(city => ({
    value: city,
    label: city,
    id: city,
    key: city
  }))

  const genders = [
    { id: '1', value: 'Femenino', text: 'Femenino' },
    { id: '2', value: 'Masculino', text: 'Masculino' },
    { id: '3', value: 'Otro', text: 'Otro' }
  ]

  let initialValuesData = {
    name: '',
    lastName: '',
    email: '',
    country: '',
    company: '',
    role: '',
    gender: '',
    gta: '',
    state: '',
    city: '',
    thumbnail: '',
    permissions: [...modules],
    imageFile: {}
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        validate={values => {
          console.log('values.role')
          console.log(values.role)
          const errors = {}
          if (mode === 'create') {
            const requiredFields = [
              'name',
              'lastName',
              'email',
              'country',
              'company',
              'role',
              'gender',
              'gta'
            ]
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.name.length >= 1 && values.name.length < 2) {
              errors.name = 'Debe ser mayor de 5 caracteres'
            }
            if (values.name.length > 50) {
              errors.name = 'Debe ser menor de 50 caracteres'
            }
            if (values.lastName.length >= 1 && values.lastName.length < 2) {
              errors.lastName = 'Debe ser mayor de 5 caracteres'
            }
            if (values.lastName.length > 50) {
              errors.lastName = 'Debe ser menor de 50 caracteres'
            }
            if (!values.email) {
              errors.email = 'Campo Requerido'
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Ingrese un correo válido'
            }
          } else {
            const requiredFields = [
              'name',
              'lastName',
              'email',
              'country',
              'company',
              'role',
              'gender',
              'gta'
            ]
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.name.length >= 1 && values.name.length < 2) {
              errors.name = 'Debe ser mayor de 5 caracteres'
            }
            if (values.name.length > 50) {
              errors.name = 'Debe ser menor de 50 caracteres'
            }
            if (values.lastName.length >= 1 && values.lastName.length < 2) {
              errors.lastName = 'Debe ser mayor de 5 caracteres'
            }
            if (values.lastName.length > 50) {
              errors.lastName = 'Debe ser menor de 50 caracteres'
            }
            if (!values.email) {
              errors.email = 'Campo Requerido'
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Ingrese un correo válido'
            }
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!values.imageFile.name && mode === 'create') {
            showWarningMessage('Aviso', 'Ingrese una imagen de perfil antes de guardar')
            setSubmitting(false)
            return
          }
          if (!values.country && mode === 'create') {
            showWarningMessage('Aviso', 'Selecciona un país.')
            setSubmitting(false)
            return
          }
          if (!values.state && mode === 'create') {
            showWarningMessage('Aviso', 'Selecciona un estado.')
            setSubmitting(false)
            return
          }
          if (!values.city && mode === 'create') {
            showWarningMessage('Aviso', 'Selecciona una ciudad.')
            setSubmitting(false)
            return
          }
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateAdmin(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleEditAdmin(values)
              setSubmitting(false)
            }
          }, 600)
        }}>
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Form className='form' encType='multipart/form-data'>
            <fieldset className='container'>
              <legend className='fieldset-gta' style={{ marginBottom: '15px' }}>
                Información general
              </legend>
              <Row>
                <Col md={6}>
                  <label className='form-label auth__label'>Imagen de perfil</label>
                  <ImgCrop rotate grid>
                    <Field
                      component={Upload}
                      error={false}
                      method={false}
                      listType='picture-card'
                      fileList={values.avatars}
                      value={values.thumbnail}
                      onChange={({ fileList: newFileList }) => {
                        if (!newFileList[0]) {
                          setFieldValue('imageFile', '', values)
                          setFieldValue('thumbnail', '', values)
                        } else {
                          setFieldValue('imageFile', newFileList[0].originFileObj, values)
                          setFieldValue('thumbnail', newFileList[0].name, values)
                        }
                      }}>
                      {values.thumbnail.length < 1 && '+ Agregar imagen'}
                    </Field>
                  </ImgCrop>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg='6'>
                  <FormInput id='name' label='Nombre' value={values.name} />
                </Col>
                <Col lg='6'>
                  <FormInput id='lastName' label={'Apellido'} value={values.lastName} />
                </Col>
              </Row>
              <Row>
                <Col lg='6'>
                  <FormInput
                    id='role'
                    label={'Rol'}
                    type='select'
                    onChange={handleChange}
                    dataOptions={roles}
                    value={values.role}
                  />
                </Col>
                <Col lg='6'>
                  <FormInput
                    id='gta'
                    label='GTA2030'
                    type='select'
                    onChange={handleChange}
                    allGtas={'Todos'}
                    dataOptions={
                      catalogs.gta &&
                      catalogs.gta.data.map(({ title, _id }) => ({
                        value: _id,
                        text: title,
                        id: _id,
                        key: _id
                      }))
                    }
                    value={values.gta}
                  />
                </Col>
              </Row>
              <Row>
                <div className='col-lg-4'>
                  <div className='form-group'>
                    <label className='form-label auth__label'>País</label>
                    <Select
                      {...props}
                      id='country'
                      name='country'
                      onChange={e => {
                        changeCountry(e, values)
                        getState(e)
                      }}
                      options={countries && countries}
                      value={countries?.filter(({ value }) => value === values.country)}
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='form-group'>
                    <label className='form-label auth__label'>Estado</label>
                    <Select
                      {...props}
                      id='state'
                      name='state'
                      onChange={e => {
                        changeState(e, values)
                        getCities(values.country, e)
                      }}
                      options={catalogs.states && statesList}
                      value={statesList?.filter(({ value }) => value === values.state)}
                    />
                  </div>
                </div>
                <Col lg='4'>
                  <FormInput
                    id='city'
                    label='Ciudad'
                    type='select'
                    onChange={handleChange}
                    dataOptions={
                      citiesList &&
                      citiesList.map(({ value }) => ({
                        value: value,
                        text: value,
                        id: value,
                        key: value
                      }))
                    }
                    value={values.city}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg='4'>
                  <FormInput
                    label='Empresa u organización'
                    type='multiselectsmall'
                    css={'form-inputs-sector-company'}
                    tooltip='Si no encuentra la empresa que busca, puede escribirla manualmente dentro del campo de Empresa u organización y dar clic en "Agregar nueva empresa..."'
                    placeholder='Seleccione'
                    defaultValue={''}
                    name='company'
                    id='company'
                    onChange={e => {
                      changeCompany(e, values)
                    }}
                    options={
                      catalogs.companies &&
                      catalogs.companies.data.map(({ _id, name }) => ({
                        value: name,
                        label: name,
                        _id
                      }))
                    }
                  />
                </Col>
                <Col lg='4'>
                  <FormInput id='email' label='Correo' value={values.email} />
                </Col>
                <Col lg='4'>
                  <FormInput
                    id='gender'
                    label='Género'
                    type='radioButton'
                    radioButton={genders}
                    placeholder=''
                  />
                </Col>
              </Row>
            </fieldset>
            {values.role !== '0' && (
              <fieldset className='container'>
                <legend className='fieldset-gta'>Permisos asignados</legend>
                {modules?.length > 0 ? (
                  <AdminCreatePermissions
                    mode='edit'
                    modules={values.permissions}
                    admin={one}
                  />
                ) : (
                  <div className='text-center'>
                    Cargando permisos
                    <span className='animate__animated  animate__flash'>...</span>
                  </div>
                )}
              </fieldset>
            )}

            {values.role === '0' && (
              <fieldset className='container'>
                <legend className='fieldset-gta'>Permisos </legend>
                <div className='text-center'>
                  El Super usuario cuenta con todos los permisos
                </div>
              </fieldset>
            )}
            <hr className='mt-4 mb-4' style={{ paddingLeft: '12px' }} />
            <Button color='primary' type='submit' disabled={isSubmitting}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.admins.all,
    one: state.admin.one,
    modules: state.modules.all,
    permissions: state.permissions,
    error: state.admins.error,
    userLoading: state.admins.loading,
    filters: state.admins.filters,
    catalogs: state.catalogs
  }
}
const mapDispatchToProps = {
  adminCreateRequest,
  adminEditRequest,
  adminGetCatalogs,
  adminGetStates,
  adminGetCities,
  modulesGetAllRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminFormCreate)
