import React from 'react'
import Layout from 'components/Layout'
import AreaForm from './AreaForm'
import LoadingBar from 'react-redux-loading-bar'

function AreaCreate(props) {
  return (
    <Layout icon="fa fa-fw fa-arrows" title="Área Responsable">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <AreaForm
        {...props}
        title="Agregar"
        mode="create"
        loading={false}
        user={undefined}
      />
    </Layout>
  )
}

export default AreaCreate
