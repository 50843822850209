import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { carouselValidationScheme } from 'components/Modules/FormCard/formCardCarouselValidation'
import Swal from 'sweetalert2'
import FormInput from 'components/FormInput'
import { Card } from './Card.js'
import { Button } from 'reactstrap'
import { formCardCreateCarouselRequest } from 'components/Modules/FormCard/formCard.redux'
import { useDispatch } from 'react-redux'
export const CardsSelectedCarousel = props => {
  let initialValuesData = {
    startDate: '',
    endDate: ''
  }

  const dispatch = useDispatch()
  const lengthSelected = props.data.length

  const submit = values => {
    values.cards = props.data
    if (!values.cards.length) {
      Swal.fire('Debe seleccionar almenos una flashcard antes de guardar', '', 'error')
      return
    }
    dispatch(formCardCreateCarouselRequest({ values }))
  }

  return (
    <>
      <div className='carousel-div-dndcontext'>
        <div className='carousel-div-dndcontext-div'>
          <Formik
            enableReinitialize={true}
            initialValues={initialValuesData}
            validationSchema={carouselValidationScheme}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                submit(values)
                setSubmitting(false)
              }, 600)
            }}>
            {({ values }) => (
              <Form className='form' style={{ marginLeft: '-6px' }}>
                <div className='row gutters' style={{ display: 'inline' }}>
                  <label className='carousel-label-dndcontext'>Tarjetas seleccionadas</label>
                  <div className='carousel-date-inputs'>
                    <FormInput
                      type='date'
                      id='startDate'
                      label='Fecha inicial'
                      value={values.startDate}
                    />
                  </div>
                  <div className='carousel-date-inputs'>
                    <FormInput
                      type='date'
                      id='endDate'
                      label='Fecha final'
                      value={values.endDate}
                    />
                  </div>
                  <div className='carousel-date-inputs'>
                    <Button
                      color='primary'
                      style={{ margin: '0px 0px 4px -9px' }}
                      type='submit'>
                      <span>Guardar</span>
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className='task-container carousel-display-flex'>
          {props.data?.map(card => (
            <Card
              key={card._id}
              data={card}
              lenghtList={lengthSelected}
              action={'delete'}
              selection={true}
            />
          ))}
        </div>
      </div>
    </>
  )
}

CardsSelectedCarousel.propTypes = {
  data: PropTypes.object
}

CardsSelectedCarousel.defaultProps = {
  data: {}
}

export default CardsSelectedCarousel
