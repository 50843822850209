import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator'
import overlayFactory from 'react-bootstrap-table2-overlay'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import OverlayTable from './OverlayTable'
import ExportExcel from './ExportExcel'
import ReportPdf from './PdfButton'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const iconSearch = <FontAwesomeIcon size='1x' color={'white'} icon={faSearch} />

const overlaySpinner = overlayFactory({
  spinner: true,
  background: 'rgba(192,192,192,0.3)'
})

const Table = props => {
  const {
    data,
    pageAct,
    filter = {},
    limit,
    expand = true,
    expandableRow,
    onTableChange,
    loading = false,
    totalDocs,
    structure,
    rowEvents,
    rowStyle,
    rowClasses,
    enableExportPdf = false,
    enableExport = false,
    searchBar = true
  } = props

  const handleTableChange = (type, { page, sizePerPage }) => {
    filter.page = page
    onTableChange(filter)
  }
  const MySearch = props => {
    const [stateFilter, setstateFilter] = useState(filter?.search)
    let input

    const handleClick = () => {
      filter.page = 1
      filter.search = stateFilter
      onTableChange(filter)
    }

    return (
      <div>
        <input
          key='SearchEditor1'
          id='SearchEditor1'
          className='form-control search-bar-input'
          placeholder='Barra de búsqueda'
          ref={n => (input = n)}
          onChange={() => setstateFilter(input.value)}
          value={stateFilter}
          type='text'
        />
        <button
          className='btn btn-primary ml-2'
          style={{ margin: '-5px 0 0 0px' }}
          onClick={handleClick}>
          {iconSearch}
          <span style={{ margin: '0 0 0 8px' }}>Buscar</span>
        </button>
      </div>
    )
  }

  const RemotePagination = props => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: pageAct,
          sizePerPage: limit,
          totalSize: totalDocs
        })}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField='_id'
            search={{ searchFormatted: true }}
            data={data}
            columns={structure}
            bootstrap4>
            {props => (
              <div>
                {enableExportPdf ? <ReportPdf {...props} /> : null}
                {enableExport ? <ExportExcel {...props} /> : null}
                {searchBar ? <MySearch {...props} /> : null}
                <BootstrapTable
                  remote
                  keyField='id'
                  data={data}
                  rowEvents={rowEvents}
                  rowStyle={rowStyle}
                  rowClasses={rowClasses}
                  columns={structure}
                  onTableChange={handleTableChange}
                  {...paginationTableProps}
                  // {...props.baseProps} // Crea conflicto en paginación si se activa
                  noDataIndication={() => <OverlayTable loading={loading} />}
                  expandRow={expand && expandableRow}
                  overlay={overlaySpinner}
                  filter={filterFactory()}
                  bordered
                  hover
                  {...paginationTableProps}
                />
                <PaginationListStandalone {...paginationProps} />
                <p
                  style={{
                    float: 'right',
                    margin: '6px 0 0 0',
                    fontSize: '15px',
                    color: '#747474'
                  }}>
                  Total registros:
                  <span className='span-total'>{paginationProps.totalSize}</span>
                </p>
                <br />
              </div>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </div>
  )

  return (
    <RemotePagination
      data={data}
      page={pageAct}
      sizePerPage={totalDocs}
      totalSize={data.length}
      onTableChange={onTableChange}
    />
  )
}

export default Table
