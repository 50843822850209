import WorkAxisList from './WorkAxisList'
import WorkAxisView from './WorkAxisView'
import WorkAxisCreate from './WorkAxisCreate'
import WorkAxisEdit from './WorkAxisEdit'

const workAxisRoutes = [
  { path: '/workAxes', component: WorkAxisList },
  { path: '/workAxes/create', component: WorkAxisCreate },
  { path: '/workAxes/:_id', component: WorkAxisView },
  { path: '/workAxes/:id/edit', component: WorkAxisEdit }
]
export default workAxisRoutes
