import React, { useEffect } from 'react'
import { columns, columnsDocs } from './gta.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { gtaGetAllDocsRequest, gtaGetAllRequest, gtaGetTotalsRequest } from './gta.redux.js'

function GtaList({ gtaGetAllRequest, gtaGetAllDocsRequest, gtaGetTotalsRequest, ...props }) {
  useEffect(() => {
    gtaGetAllRequest()
    gtaGetAllDocsRequest()
    gtaGetTotalsRequest()
  }, [gtaGetAllRequest, gtaGetTotalsRequest])

  function handleClick(type) {
    props.filters.search = ''
    if (type === 'all') {
      props.filters.active = null
      props.filters.page = 1
      gtaGetAllRequest()
    }
    if (type === 'active') {
      props.filters.page = 1
      props.filters.active = true
      gtaGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      props.filters.page = 1
      props.filters.active = false
      gtaGetAllRequest({ active: false })
    }
  }

  const gta = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit
  const gtaDocs = props.allDocs?.docs || []

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/gtas/${row._id}`)
    }
  }

  return (
    <Layout icon='fas fa-fw fa-globe' title='GTA2030'>
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer title='GTAS' subtitle='Lista' route='/gtas/create' showAddbuton={true}>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={props.filters}
          onTableChange={gtaGetAllRequest}
          data={gta}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
      <div style={{ height: '25px' }} />
      {/* Historial de guías descargadas */}
      <TableContainer
        title='Voluntarios'
        subtitle='Estadística de descargas'
        buttonAdd={false}>
        <DataTable
          {...props}
          limit={10}
          pageAct={props.allDocs?.page}
          totalDocs={props.allDocs?.totalDocs}
          filter={props.filters}
          searchBar={false}
          data={gtaDocs}
          structure={columnsDocs}
        />
      </TableContainer>
      <div style={{ height: '50px' }} />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.gtas.all,
    one: state.gtas.one,
    error: state.gtas.error,
    userLoading: state.gtas.loading,
    filters: state.gtas.filters,
    totals: state.gtas.totals,
    allDocs: state.gtasDocsDownload.all,
    state: state,
    filtersDocs: state.gtasDocsDownload.filters
  }
}

const mapDispatchToProps = {
  gtaGetAllRequest,
  gtaGetTotalsRequest,
  gtaGetAllDocsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(GtaList)
