import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import TimeInitiativeForm from './TimeInitiativeForm'
import LoadingBar from 'react-redux-loading-bar'

function TimeInitiativeCreate({ one, ...props }) {
  return (
    <Layout icon='far fa-fw fa-clock' title='Duración de la iniciativa'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <TimeInitiativeForm
        {...props}
        title='Editar'
        mode='edit'
        loading={false}
        timeInitiative={one}
      />
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    one: state.timeInitiatives.one
  }
}

export default connect(mapStateToProps)(TimeInitiativeCreate)
