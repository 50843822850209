import React from 'react'
import { isEmpty } from 'utils/crud'

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import moment from 'moment'
import { causeData, iconStarOfLife } from 'utils/flashcards'
import CardHistoricalTime from './CardHistoricalTime'
function HistoricalTimeline({ values, ...props }) {
  if (isEmpty(values)) return <h5 className='text-center my-4'>Sin cambios registrados</h5>
  return (
    <>
      <VerticalTimeline>
        {values?.map(item => {
          const indexCause = `${item?.cause}`
          return (
            <VerticalTimelineElement
              key={item._id}
              className='vertical-timeline-element--work'
              date={moment(item.createdAt).fromNow()}
              iconStyle={{
                background: causeData[indexCause]?.color,
                color: '#fff'
              }}
              icon={causeData[indexCause]?.icon}>
              <CardHistoricalTime
                item={item}
                indexCause={indexCause}
                seeMore={props.seeMore}
              />
            </VerticalTimelineElement>
          )
        })}
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={iconStarOfLife}
        />
      </VerticalTimeline>
    </>
  )
}

HistoricalTimeline.defaultProps = {
  values: [],
  seeMore: false
}

export default HistoricalTimeline
