import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  areaDeactivateRequest,
  areaGetOneRequest,
  areaReactivateRequest
} from './area.redux'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function AreaView({ areaGetOneRequest, historicalListRequest, ...props }) {
  const { historical } = props
  const { _id } = props.match.params
  const area = props.one || {}
  useEffect(() => {
    areaGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [areaGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (area.active) {
      props.areaDeactivateRequest({ id: _id })
    }
    if (!area.active) {
      props.areaReactivateRequest({ id: _id })
    }
  }
  return (
    <Layout icon="fa fa-fw fa-arrows" title="Área Responsable">
      <TableContainer
        title="Área Responsable"
        subtitle="Información del registro"
        buttonAdd={false}
        view={`/areas/${_id}/edit`}
        actionButton={true}
        active={area.active}
        onToggle={handleActiveToggle}
      >
        {!isEmpty(area) && (
          <Row id="view-area">
            <Col>
              <ul>
                <ListItem label="Nombre" value={area.name} />
                <ListItem label="Descripción" value={area.description} />
                <ListItem label="Activo" value={area.active} />
                <ListItem label="Creado" value={mdy(area.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className="overflow-box"
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}
      >
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.areas.all,
    one: state.areas.one,
    error: state.areas.error,
    userLoading: state.areas.loading,
    filters: state.areas.filters,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  areaGetOneRequest,
  areaDeactivateRequest,
  areaReactivateRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(AreaView)
