import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'reactstrap'
import { DatePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'

export const FirstDatePicker = ({ name, value, onChange, placeholder }) => {
  return (
    <DatePicker
      placeholder={placeholder}
      oneTap
      style={{ width: 200 }}
      onChange={val => {
        onChange(name, val)
      }}
    />
  )
}

export const curr = new Date()
curr.setDate(curr.getDate() + 3)
export const date = curr.toISOString().substr(0, 10)

export const t = new Date()
export const firstDay = new Date(t.getFullYear(), t.getMonth() + 0, 0, 23, 59, 59)
  .toISOString()
  .substr(0, 10)
export const lastDay = new Date(t.getFullYear(), t.getMonth() + 0, 30, 23, 59, 59)
  .toISOString()
  .substr(0, 10)

const PriorizationHeader = ({ handleChange }) => {
  let initialValues = {
    startDate: firstDay,
    endDate: lastDay
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleChange(values)
            setSubmitting(false)
          }, 600)
        }}>
        {({ values, setFieldValue }) => (
          <Form className='form'>
            <div className='priorization-header'>
              <div className='priorization-header_calendar'>
                <div className='priorization-calendar'>
                  <FirstDatePicker
                    name='startDate'
                    value={values.startDate}
                    onChange={setFieldValue}
                    placeholder={firstDay}
                  />
                </div>
                <div className='priorization--calendar'>
                  <FirstDatePicker
                    name='endDate'
                    value={values.endDate}
                    onChange={setFieldValue}
                    placeholder={lastDay}
                  />
                </div>
                <div className='priorization-calendar'>
                  <Button color='primary' type='submit'>
                    Buscar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PriorizationHeader
