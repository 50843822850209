import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import AdminForm from 'components/Modules/Admins/AdminForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

export const PageAdminEdit = ({ one }) => {
  return (
    <Layout icon="fas fa-user-cog" title="Administrador">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        className="loadingbar-style"
      />{' '}
      <CardBig title={'Editar'}>
        <AdminForm mode="edit" user={one} loading={false} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.admins.one,
    permissions: state.permissions
  }
}

export default connect(mapStateToProps)(PageAdminEdit)
