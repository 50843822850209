import React, { useState, useEffect } from 'react'
import Router from 'components/Router'
import { Il8nProvider, LOCALES } from './i18n/index'
import { io } from 'socket.io-client'
import { connect } from 'react-redux'
import { LoadScript } from '@react-google-maps/api'
import {
  notificationsAllRequest,
  notificationsListRequest
} from 'components/Theme/Layout/Header/notifications.redux'
import { checkloginRequest } from 'components/Modules/Auth/auth.redux.js'
import CheckBrowser from 'components/CheckBrowser/CheckBrowser'
import Swal from 'sweetalert2'

export const socket = io(process.env.REACT_APP_API_URL + '/socket.io', {
  transports: ['websocket']
})

const App = ({ getUnreadNotifications, isLogin, checkloginRequest }) => {
  const [locale] = useState(LOCALES.SPANISH)
  const API_KEY = 'AIzaSyBRNyoPFLDczARsnD5h1vPAzOA0KAtVCME'

  // Se agrega handler personalizado de mensaje en caso de no ser un navegador permitido (Chrome, Safari o Firefox)
  const handlerCheckBrowser = noValidate => {
    noValidate &&
      Swal.fire({
        title: 'Navegador no soportado',
        icon: 'warning',
        html: 'Algunas funcionalidades pueden no estár disponibles si usa el navegador actual.<br/><br/>Se recomienda utilizar la versión más reciente del navegador <a href="https://www.google.com/chrome">Chrome</a>, <a href="https://www.mozilla.org/firefox">Firefox</a> o <a href="https://www.apple.com/mx/safari">Safari</a>.<br/><br/><br/>',
        showCancelButton: false, // There won't be any cancel button
        showConfirmButton: false // There won't be any confirm button
      })
  }

  useEffect(() => {
    // listen for socket events
    try {
      checkloginRequest()
      if (isLogin) {
        if (socket.disconnected) {
          socket.connect()
        }
        socket.on('notificationSocket', () => {
          getUnreadNotifications()
        })
      }
    } catch (error) {
      throw error
    }
  }, [checkloginRequest, getUnreadNotifications, isLogin])

  return (
    <>
      <Il8nProvider locale={locale}>
        <LoadScript googleMapsApiKey={API_KEY} libraries={['places']} loadingElement={<div />}>
          <CheckBrowser
            browsers={['Chrome', 'Firefox', 'Safari']}
            handlerCheckBrowser={handlerCheckBrowser}
          />
          <Router />
        </LoadScript>
      </Il8nProvider>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isLogin: state.auth.isLogin
  }
}

const mapDispatchToProps = {
  getNotifications: notificationsAllRequest,
  getUnreadNotifications: notificationsListRequest,
  checkloginRequest: checkloginRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
