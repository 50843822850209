import React from 'react'
import CardAmbitList from './CardAmbitList'

function CardDetailFront(props) {
  const p = { ...props.data }
  const { gta, workAxis, name, area, number, businessType } = p

  return (
    <div className='card-detail-content-front' style={{ backgroundColor: gta?.color }}>
      <div className='row flashcard-detail-row'>
        <div className='flashcard-detail-front-header'>
          <img
            className='flashcard-detail-front-icon'
            width='100'
            alt='GTA2030 logo'
            src={gta?.logo ? gta?.logo : require(`./../../../assets/img/spinner.gif`)}
          />
        </div>

        <div className='flashcard-detail-front-title'>
          <span className='flashcard-detail-front-gta'>Grupo de Trabajo Agenda 2030</span>
          <span className='flashcard-detail-front-gta2'>{gta?.title}</span>
        </div>

        <div className='flashcard-detail-front-line'></div>
        {/* -- NOMBRE ------------------------------------------------- */}
        <div
          className='flashcard-detail-front-div-solution'
          style={{
            height: `${businessType === undefined ? 372 : 284 - businessType.length * 14}px`
          }}>
          <div className='flashcard-detail-front-solution'>{name}</div>
        </div>
        <div className='flashcard-detail-front-line' />

        {/* -- ÁMBITO DE IMPLEMENTACIÓN ------------------------------- */}
        <div className='flashcard-detail-div-image-eje'>
          <div className='flashcard-detail-div-ambito-front-title'>
            Ámbito de implementación
          </div>
          <CardAmbitList list={businessType} />
        </div>
        <div className='flashcard-detail-front-line' />

        {/* -- EJE DE TRABAJO ------------------------------- */}
        <div className='flashcard-detail-front-footer'>
          {/* <span className='flashcard-detail-front-footer-right'>{area?.name}</span> */}
          <div className='flashcard-front-div-eje-center'>
            <img
              className='flashcard-detail-img-eje'
              width='100'
              src={
                workAxis?.position
                  ? require(`assets/img/ejesDeTrabajo/workAxis${workAxis?.position}.svg`)
                  : require(`assets/img/ejesDeTrabajo/workAxis1.svg`)
              }
              alt='google button'
            />
            <div className='flashcard-detail-footer-front-grupo'>{workAxis?.objective}</div>
          </div>
        </div>
        <div className='flashcard-detail-front-line' />
        <div className='flashcard-detail-front-footer'>
          <span className='flashcard-detail-front-footer-left'>FLASHCARDS CLIMB</span>
          <span className='flashcard-detail-front-footer-number-right'>#{number}</span>
        </div>
      </div>
    </div>
  )
}

export default CardDetailFront
