import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import FormInput from 'components/FormInput'
import { gtaCreateRequest, gtaEditRequest } from './gta.redux.js'
import pdf from 'assets/img/pdf2.png'
import { connect, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { showWarningMessage } from 'components/Theme/Alerts/exceptionUtils'

function GtaForm({ mode, gta }) {
  const dispatch = useDispatch()
  const selectLogo = (event, values) => {
    values.logoFile = event.target.files[0]
  }
  const selectLogoColor = (event, values) => {
    values.imageColorFile = event.target.files[0]
  }
  const selectDocument = (event, values) => {
    values.documentFile = event.target.files[0]
  }

  const handleCreateGta = values => {
    let formData = new FormData()
    formData.append('file', values.logoFile)
    formData.append('imageColorFile', values.imageColorFile)
    dispatch(gtaCreateRequest({ values }))
  }

  const handleEditGta = values => {
    dispatch(gtaEditRequest({ values }))
  }

  let initialValuesData = {
    title: '',
    goal: '',
    actionLines: '',
    nomenclature: '',
    author: '',
    position: '',
    logo: '',
    logoFile: {},
    imageColor: '',
    imageColorFile: {},
    document: '',
    documentFile: {},
    color: '#555555',
    number: 10
  }
  if (mode === 'edit') {
    initialValuesData = {
      nomenclature: gta.nomenclature,
      number: gta.number,
      title: gta.title,
      goal: gta.goal,
      imageColor: '',
      imageColorFile: {},
      logo: '',
      logoFile: {},
      document: '',
      documentFile: {},
      color: gta.color,
      author: gta.author,
      position: gta.position,
      actionLines: gta.actionLines,
      _id: gta._id
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        validate={values => {
          const errors = {}
          if (mode === 'create') {
            const requiredFields = [
              'title',
              'goal',
              'author',
              'position',
              'actionLines',
              'nomenclature',
              'color',
              'number',
              'logo',
              'imageColor'
            ]
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.title.length >= 1 && values.title.length < 5) {
              errors.title = 'Debe ser mayor de 5 caracteres'
            }
            if (values.title.length > 50) {
              errors.title = 'Debe ser menor de 50 caracteres'
            }
            if (values.goal.length >= 1 && values.goal.length < 5) {
              errors.goal = 'Debe ser mayor de 5 caracteres'
            }
            if (values.goal.length > 430) {
              errors.goal = 'Debe ser menor de 430 caracteres'
            }
            if (values.nomenclature.length >= 1 && values.nomenclature.length < 1) {
              errors.nomenclature = 'Campo Requerido'
            }
            if (values.nomenclature.length > 50) {
              errors.nomenclature = 'Debe ser menor de 50 caracteres'
            }
            if (values.author.length >= 1 && values.author.length < 5) {
              errors.author = 'Debe ser mayor de 5 caracteres'
            }
            if (values.author.length > 50) {
              errors.author = 'Debe ser menor de 50 caracteres'
            }
            if (values.position.length >= 1 && values.position.length < 5) {
              errors.position = 'Debe ser mayor de 5 caracteres'
            }
            if (values.position.length > 50) {
              errors.position = 'Debe ser menor de 50 caracteres'
            }
            if (values.actionLines.length >= 1 && values.actionLines.length < 5) {
              errors.actionLines = 'Debe ser mayor de 5 caracteres'
            }
            if (values.actionLines.length > 430) {
              errors.actionLines = 'Debe ser menor de 430 caracteres'
            }
          } else {
            const requiredFields = [
              'title',
              'goal',
              'author',
              'position',
              'actionLines',
              'nomenclature',
              'color',
              'number'
            ]
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.title.length >= 1 && values.title.length < 5) {
              errors.title = 'Debe ser mayor de 5 caracteres'
            }
            if (values.title.length > 50) {
              errors.title = 'Debe ser menor de 50 caracteres'
            }
            if (values.goal.length >= 1 && values.goal.length < 5) {
              errors.goal = 'Debe ser mayor de 5 caracteres'
            }
            if (values.goal.length > 430) {
              errors.goal = 'Debe ser menor de 430 caracteres'
            }
            if (values.nomenclature.length < 1) {
              errors.nomenclature = 'Campo Requerido'
            }
            if (values.nomenclature.length > 50) {
              errors.nomenclature = 'Debe ser menor de 50 caracteres'
            }
            if (values.author.length >= 1 && values.author.length < 5) {
              errors.author = 'Debe ser mayor de 5 caracteres'
            }
            if (values.author.length > 50) {
              errors.author = 'Debe ser menor de 50 caracteres'
            }
            if (values.position.length >= 1 && values.position.length < 5) {
              errors.position = 'Debe ser mayor de 5 caracteres'
            }
            if (values.position.length > 250) {
              errors.position = 'Debe ser menor de 250 caracteres'
            }
            if (values.actionLines.length >= 1 && values.actionLines.length < 5) {
              errors.actionLines = 'Debe ser mayor de 5 caracteres'
            }
            if (values.actionLines.length > 430) {
              errors.actionLines = 'Debe ser menor de 430 caracteres'
            }
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          const errors = []
          if (!values.imageColorFile.name && mode === 'create') {
            errors.push({
              msg: 'Ingrese una imagen con su silueta color antes de guardar.'
            })
          }
          if (!values.logoFile.name && mode === 'create') {
            errors.push({
              msg: 'Ingrese una imagen con su silueta color antes de guardar.'
            })
          }
          if (errors.length > 0) {
            const rowsMessagesSnackBar = errors =>
              errors.map((error, index) => (
                <li
                  // @ts-ignore
                  type='square'
                  key={index}
                  className='muted-rubik-text-tr'>
                  <span style={{ color: '#1d1d1d' }}> {error.msg}</span>
                </li>
              ))
            const errorsList = rowsMessagesSnackBar(errors)
            showWarningMessage('Aviso', errorsList)
            setSubmitting(false)
            return
          }
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateGta(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleEditGta(values)
              setSubmitting(false)
            }
          }, 600)
        }}>
        {({ values, handleSubmit, handleChange, errors, touched }) => (
          <Form encType='multipart/form-data' className='form' onSubmit={handleSubmit}>
            <div className='row gutters container'>
              <fieldset style={{ marginTop: '10px' }}>
                <legend className='fieldset-gta'>Información general</legend>
                <FormInput id='title' label='Título' value={values.title} />
                <FormInput
                  id='goal'
                  type='textarea'
                  label='Eje de trabajo'
                  value={values.goal}
                />
                <FormInput
                  id='nomenclature'
                  label='Nomenclatura'
                  value={values.nomenclature}
                />
              </fieldset>
              <fieldset style={{ marginTop: '15px' }}>
                <legend className='fieldset-gta'>
                  Datos para el catálogo del sitio público
                </legend>
                <FormInput
                  id='actionLines'
                  type='textarea'
                  label='Líneas de actuación'
                  value={values.actionLines}
                />
                <FormInput id='author' label='Autor' value={values.author} />
                <FormInput id='position' label='Posición' value={values.position} />
              </fieldset>
              <fieldset style={{ marginTop: '18px' }}>
                <div>
                  {mode === 'edit' && <img src={gta.logo} height={'80px'} alt='logo' />}
                  <div className='form-group'>
                    <label className='form-label auth__label'>
                      Logo GTA2030 con fondo a color
                    </label>
                    <Field
                      className='auth__input form-control'
                      type='file'
                      id='logo'
                      label='Logo GTA2030 con fondo a color'
                      value={values.logo}
                      onChange={e => {
                        selectLogo(e, values)
                        handleChange(e)
                      }}
                    />
                  </div>
                  <ErrorMessage
                    name='logo'
                    component='div'
                    className='animate__animated animate__fadeIn animate__faster error-message'
                  />
                </div>
                <div style={{ margin: '22px 0px 25px 0px' }}>
                  {mode === 'edit' && (
                    <img src={gta.logoColor} height={'80px'} alt='logoColor' />
                  )}
                  <div className='form-group'>
                    <label className='form-label auth__label'>
                      Logo GTA2030 con fondo blanco
                    </label>
                    <Field
                      className='auth__input form-control'
                      type='file'
                      id='imageColor'
                      label='Logo GTA2030 con fondo blanco'
                      value={values.imageColor}
                      onChange={e => {
                        selectLogoColor(e, values)
                        handleChange(e)
                      }}
                    />
                    <ErrorMessage
                      name='imageColor'
                      component='div'
                      className='animate__animated animate__fadeIn animate__faster error-message'
                    />
                  </div>
                </div>

                <div>
                  {mode === 'edit' && gta.document && (
                    <a id={`toolTipGtaPdf`} href={gta.document} target='_blank'>
                      <img
                        placeholder='Abrir PDF...'
                        src={pdf}
                        height={'80px'}
                        className='gta-file-download'
                        alt='logoPdf'
                      />
                    </a>
                  )}
                  <div className='form-group' style={{ marginBottom: '45px' }}>
                    <label className='form-label auth__label'>
                      Archivo de la Guía del GTA2030
                    </label>
                    <Field
                      className=' form-control'
                      type='file'
                      id='document'
                      label='Archivo de la Guía del GTA2030'
                      value={values.document}
                      onChange={e => {
                        selectDocument(e, values)
                        handleChange(e)
                      }}
                    />
                    <ErrorMessage
                      name='document'
                      component='div'
                      className='animate__animated animate__fadeIn animate__faster error-message'
                    />
                  </div>

                  <FormInput
                    type='color'
                    id='color'
                    label='Color del GTA2030'
                    value={values.color}
                  />
                </div>
              </fieldset>
            </div>
            <hr className='mt-4 mb-4' style={{ paddingLeft: '12px' }} />
            <Button className='ml-4' color='primary' type='submit'>
              <span>Guardar</span>
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.gtas.all,
    one: state.gtas.one,
    error: state.gtas.error,
    userLoading: state.gtas.loading,
    filters: state.gtas.filters
  }
}

const mapDispatchToProps = {
  gtaCreateRequest: gtaCreateRequest,
  gtaEditRequest: gtaEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(GtaForm)
