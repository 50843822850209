import React from 'react'
import Layout from 'components/Layout'
import TimeInitiativeForm from './TimeInitiativeForm'
import LoadingBar from 'react-redux-loading-bar'

function TimeInitiativeCreate(props) {
  return (
    <Layout icon='far fa-fw fa-clock' title='Duración de las iniciativas'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <TimeInitiativeForm
        {...props}
        title='Agregar'
        mode='create'
        loading={false}
        user={undefined}
      />
    </Layout>
  )
}

export default TimeInitiativeCreate
