import React, { useEffect } from 'react'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import UsersTable from 'components/Modules/Users/UsersTable'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import {
  userGetAllRequest,
  userGetTotals
} from 'components/Modules/Users/user.redux'

function PageUser({ userGetAllRequest, userGetTotals, filters, ...props }) {
  useEffect(() => {
    userGetAllRequest(filters)
    userGetTotals()
  }, [userGetAllRequest, userGetTotals, filters])

  function handleClick(type) {
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      userGetAllRequest()
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      userGetAllRequest(filters)
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      userGetAllRequest(filters)
    }
  }
  return (
    <Layout icon="fas fa-user" title="Usuarios">
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <UsersTable />
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    all: state.users.all,
    one: state.users.one,
    error: state.users.error,
    userLoading: state.users.loading,
    filters: state.users.filters,
    totals: state.users.totals
  }
}

const mapDispatchToProps = {
  userGetTotals: userGetTotals,
  userGetAllRequest: userGetAllRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(PageUser)
