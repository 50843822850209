import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { formCardGetCatalogs } from 'components/Modules/FormCard/formCard.redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormInput from 'components/FormInput'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'reactstrap'
import Select from 'react-select'
import { cardsFiltersChangeRequest } from './cards.redux.js'

function CardsFilterBar({ filters, catalogs, formCardGetCatalogs, gtaAdmin, ...props }) {
  useEffect(() => {
    formCardGetCatalogs()
  }, [formCardGetCatalogs])

  const workAxes = catalogs.workAxes?.data
  const [workAxis, setWorkAxis] = useState([])

  function handleChangeGta(event) {
    const value = event.target.value
    const filters = workAxes.filter(item => item.gta === value)
    setWorkAxis(filters)
  }

  const WA = workAxes?.filter(item => item.gta === gtaAdmin._id)

  const changeGta = event => {
    filters.gta = event.target.value
  }
  const changeAxis = event => {
    filters.workAxis = event.target.value
  }

  const changeObjectives = event => {
    filters.objectives = event.target.value
  }

  const changeTimeInitiatives = event => {
    filters.timeInitiatives = event.target.value
  }

  const handleFilter = filters => {
    props.cardsFiltersChangeRequest(filters)
  }

  const changeBusinessType = (e, values) => {
    values.businessType = e.map(item => item.value)
  }
  const changeClimbsType = (e, values) => {
    values.climb = e.map(item => item.value)
  }

  const changeAreasType = (e, values) => {
    values.areas = e.map(item => item.value)
  }

  const iconSearch = <FontAwesomeIcon icon={faSearch} className='filter-inline-search' />

  let initialValuesData = {
    gta: '',
    workAxis: '',
    timeInitiatives: '',
    objectives: '',
    businessType: [],
    climb: [],
    areas: []
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleFilter(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, isSubmitting, handleChange }) => (
          <Form className='form' style={{ marginLeft: '-30px', paddingBottom: '30px' }}>
            <Row>
              <Col lg='3'>
                {gtaAdmin._id ? (
                  <FormInput
                    id='gta'
                    label='Grupo de trabajo Agenda 2030'
                    value={gtaAdmin.title}
                    disabled
                  />
                ) : (
                  <FormInput
                    id='gta'
                    label={'Grupo de trabajo Agenda 2030'}
                    type='select'
                    onChange={e => {
                      handleChangeGta(e)
                      handleChange(e)
                      changeGta(e)
                    }}
                    dataOptions={
                      catalogs.gtas &&
                      catalogs.gtas.data.map(({ title, _id }) => ({
                        value: _id,
                        text: title,
                        id: _id,
                        key: _id
                      }))
                    }
                    value={values.gta}
                  />
                )}
              </Col>
              {/* -- Eje de trabajo--------------------------------- */}
              <Col lg='3'>
                {gtaAdmin._id ? (
                  <FormInput
                    id='workAxis'
                    label={'Eje de trabajo'}
                    type='select'
                    onChange={e => {
                      changeAxis(e)
                      handleChange(e)
                    }}
                    dataOptions={WA?.map(({ objective, _id }) => ({
                      value: _id,
                      text: objective,
                      id: _id,
                      key: _id
                    }))}
                    value={values.workAxis}
                  />
                ) : (
                  <FormInput
                    id='workAxis'
                    label={'Eje de trabajo'}
                    type='select'
                    onChange={e => {
                      changeAxis(e)
                      handleChange(e)
                    }}
                    dataOptions={workAxis.map(({ objective, _id }) => ({
                      value: _id,
                      text: objective,
                      id: _id,
                      key: _id
                    }))}
                    value={values.workAxis}
                  />
                )}
              </Col>
              {/* -- Ámbito de implementación --------------------------------- */}
              <Col lg='3'>
                <div>
                  <div className='form-group' style={{ color: '#1e3250' }}>
                    <label className='form-label auth__label' style={{ color: '#1e3250' }}>
                      Ámbito de implementación
                    </label>
                    <Select
                      id='businessType'
                      name='businessType'
                      style={{ color: 'black' }}
                      placeholder='Seleccione'
                      isMulti
                      onChange={e => {
                        changeBusinessType(e, values)
                      }}
                      options={
                        catalogs.businessTypes &&
                        catalogs.businessTypes.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                    />
                  </div>
                </div>
              </Col>
              {/* -- CLIMB --------------------------------- */}
              <Col lg='3' style={{ paddingRight: '40px' }}>
                <div>
                  <div className='form-group' style={{ color: '#1e3250' }}>
                    <label className='form-label auth__label' style={{ color: '#1e3250' }}>
                      CLIMB
                    </label>
                    <Select
                      id='climb'
                      name='climb'
                      style={{ color: 'black' }}
                      placeholder='Seleccione'
                      isMulti
                      onChange={e => {
                        changeClimbsType(e, values)
                      }}
                      options={
                        catalogs.climbs &&
                        catalogs.climbs.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                    />
                  </div>
                </div>
              </Col>
              {/* --- ODS ----------------------------------------------------------------------------- */}
              <Col lg='3'>
                <FormInput
                  id='objectives'
                  label='ODS'
                  type='select'
                  onChange={e => {
                    changeObjectives(e)
                    handleChange(e)
                  }}
                  dataOptions={
                    catalogs.objectives &&
                    catalogs.objectives.data.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id,
                      name: _id
                    }))
                  }
                  value={values.objectives}
                />
              </Col>
              {/* --- Tiempo de implementación ----------------------------------------------------------------------------- */}
              <Col lg='3'>
                <FormInput
                  id='timeInitiatives'
                  label='Tiempo de implementación'
                  type='select'
                  onChange={e => {
                    changeTimeInitiatives(e)
                    handleChange(e)
                  }}
                  dataOptions={
                    catalogs.timeInitiatives &&
                    catalogs.timeInitiatives.data.map(({ name, _id }) => ({
                      value: name,
                      text: name,
                      id: name,
                      key: name,
                      name: name
                    }))
                  }
                  value={values.timeInitiatives}
                />
              </Col>
              {/* -- Área responsable --------------------------------- */}
              <Col lg='3'>
                <div>
                  <div className='form-group' style={{ color: '#1e3250' }}>
                    <label className='form-label auth__label' style={{ color: '#1e3250' }}>
                      Área responsable
                    </label>
                    <Select
                      id='areas'
                      name='areas'
                      style={{ color: 'black' }}
                      placeholder='Seleccione'
                      isMulti
                      onChange={e => {
                        changeAreasType(e, values)
                      }}
                      options={
                        catalogs.areas &&
                        catalogs.areas.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col lg='1'>
                <Button className='search' type='submit' disabled={isSubmitting}>
                  <span>{iconSearch}</span>
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.formCards.loading,
    error: state.formCards.error,
    filters: state.formCards.filters,
    catalogs: state.formCards.catalogs,
    gtaAdmin: state.gta
  }
}

const mapDispatchToProps = {
  cardsFiltersChangeRequest,
  formCardGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsFilterBar)
