//TYPES
export const typesSavedCards = {
  savedCardsByUserRequest: '[SAVEDCARDS] SAVED_CARDS_BY_USER_REQUEST',
  savedCardsByUserSuccess: '[SAVEDCARDS] SAVED_CARDS_BY_USER_SUCCESS',
  savedCardsByUserFailure: '[SAVEDCARDS] SAVED_CARDS_BY_USER_FAILURE'
}

// ACTIONS

export const savedCardsByUserRequest = (payload) => ({
  type: typesSavedCards.savedCardsByUserRequest,
  payload
})

const initialState = {
  all: [],
  one: [],
  error: '',
  loading: false,
  filters: {}
}

export function savedCards(state = initialState, action) {
  switch (action.type) {
    case typesSavedCards.savedCardsByUserRequest:
      return { ...state, loader: true, error: null }
    case typesSavedCards.savedCardsByUserFailure:
      return { ...state, loading: false, error: action.payload, all: {} }
    case typesSavedCards.savedCardsByUserSuccess:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    default:
      return state
  }
}
