import { put, call, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'

import { typeAcl } from './acl.redux'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { logoutRequest } from 'components/Modules/Auth/auth.saga'

function* getAllAclPermissionsSaga({ admin }) {
  try {
    yield put(showLoading())
    const { data } = yield call(axios.get, `/api/permissions/all?admin=${admin}`)
    yield put({ type: typeAcl.ACL_GET_CONFIG_SUCCESS, payload: data?.data })
    yield put(hideLoading())
  } catch (error) {
    if (error.response.data.data.errors[0].msg === 'Usuario desactivado') {
      yield call(logoutRequest)
    }
    Swal.fire('Error', error, 'error')
    yield put({ type: typeAcl.ACL_GET_CONFIG_FAILURE, error: error.response })
  }
}

export default function* aclSagas() {
  yield takeLatest(typeAcl.ACL_GET_CONFIG_REQUEST, getAllAclPermissionsSaga)
}
