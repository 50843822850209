import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const NavigationItem = (props) => {
  return (
    <Fragment>
 
        {/* <!-- Nav Item - Tables --> */}
        <li className='nav-item'>
          <Link className='nav-link py-2' to={props.link}>
          <i className={props.icon} style={{ color: '#ffff' }} />
            <span style={{ fontSize: 15.5, color: '#ffff' }}>{props.title}</span>
          </Link>
        </li>

    </Fragment>
  )
}

export default NavigationItem
