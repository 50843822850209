import React, { useEffect } from 'react'
import { columns } from './workAxis.helper'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { workAxisGetAllRequest, workAxisGetTotals } from './workAxis.redux'

function WorkAxisList({
  workAxisGetAllRequest,
  workAxisGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    workAxisGetAllRequest(filters)
    workAxisGetTotals()
  }, [workAxisGetAllRequest, workAxisGetTotals, filters])

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      workAxisGetAllRequest()
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      workAxisGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      workAxisGetAllRequest({ active: false })
    }
  }

  const workAxis = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/workAxes/${row._id}`)
    }
  }

  return (
    <Layout icon="fas fa-user-cog" title="Eje de trabajo">
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title="Eje de trabajo"
        subtitle="Lista"
        route="/workAxes/create"
        showAddbuton={true}
      >
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={filters}
          onTableChange={workAxisGetAllRequest}
          data={workAxis}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.workAxis.all,
    one: state.workAxis.one,
    error: state.workAxis.error,
    userLoading: state.workAxis.loading,
    filters: state.workAxis.filters,
    totals: state.workAxis.totals
  }
}

const mapDispatchToProps = {
  workAxisGetAllRequest: workAxisGetAllRequest,
  workAxisGetTotals: workAxisGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkAxisList)
