import React, { Fragment } from 'react'
import { IntlProvider } from 'react-intl'
import { LOCALES } from './locales'
import PropTypes from 'prop-types';

import messages from './messages'

const IntProvider = ({ children, locale = LOCALES.SPANISH }) => {
  return(
  <IntlProvider
    locale={locale}
    textComponent={Fragment}
    messages={messages[locale]}
  >
    {children}
  </IntlProvider>
  )
}
IntProvider.displayName = 'I18nProvider';

IntProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  locale: PropTypes.oneOf(Object.values(LOCALES)),
};

IntProvider.defaultProps = {
  locale: LOCALES.SPANISH,
}
export default IntProvider