import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollTop() {
  const { pathname } = useLocation()
  useEffect(() => {
    const dontScrollRoutes = []
    if (!dontScrollRoutes.includes(pathname)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [pathname])

  return (
    <div className='scroll-to-top rounded' >
      <i className='fas fa-angle-up'></i>
    </div>
  )
}
