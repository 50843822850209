import { put, call, takeLatest, delay } from 'redux-saga/effects'
import { serviceUrl } from 'utils/serviceUrl'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  CONTACT_GET_ALL_REQUEST,
  CONTACT_GET_ALL_FAILURE,
  CONTACT_GET_ALL_SUCCESS,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_FAILURE,
  CONTACT_CREATE_REQUEST,
  CONTACT_GET_ONE_FAILURE,
  CONTACT_GET_ONE_SUCCESS,
  CONTACT_GET_ONE_REQUEST,
  CONTACT_REMOVE_SUCCESS,
  CONTACT_REMOVE_FAILURE,
  CONTACT_REMOVE_REQUEST,
  CONTACT_EDIT_FAILURE,
  CONTACT_EDIT_REQUEST,
  CONTACT_FILTER_CHANGE_SUCCESS,
  CONTACT_FILTER_CHANGE_FAILURE,
  CONTACT_FILTER_CHANGE_REQUEST,
  CONTACT_GET_TOTALS_REQUEST,
  CONTACT_GET_TOTALS_FAILURE,
  CONTACT_GET_TOTALS_SUCCESS
} from './contact.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

export function* getAllContactSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/contacts?${filterString}`)
    yield put({ type: CONTACT_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CONTACT_GET_ALL_FAILURE, error: error.response })
  }
}

export function* getOneContactSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/contacts/${_id}`)
    yield delay(1000)
    yield put({ type: CONTACT_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CONTACT_GET_ONE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/contacts/totals`)
    yield put({ type: CONTACT_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CONTACT_GET_TOTALS_FAILURE, error: error.response })
  }
}

export function* createContactSaga({ payload }) {
  try {
    yield put(showLoading())
    yield delay(3000)
    yield put(push('/contacts'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CONTACT_CREATE_FAILURE, error: error.response })
  }
}

export function* getContactFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: CONTACT_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: CONTACT_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: CONTACT_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
export function* editContactSaga({ payload }) {
  try {
    const insert = yield call(axios.put, serviceUrl.contact + '/' + payload._id, payload)
    yield put({ type: CONTACT_CREATE_SUCCESS, payload: insert })
    yield put(showLoading())
    yield delay(3000)
    yield put(push('/contacts'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CONTACT_CREATE_FAILURE, error: error.response })
  }
}

export function* removeContactSaga({ id }) {
  try {
    const insert = yield call(axios.delete, serviceUrl.contact + '/' + id)
    yield put({ type: CONTACT_REMOVE_SUCCESS, payload: insert })
    yield put({ type: CONTACT_GET_ALL_REQUEST })
    yield put(showLoading())
    yield delay(3000)
    yield put(push('/contacts'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CONTACT_REMOVE_FAILURE, error: error.response })
  }
}
export default function* contactSagas() {
  yield takeLatest(CONTACT_GET_ALL_REQUEST, getAllContactSaga)
  yield takeLatest(CONTACT_GET_ONE_REQUEST, getOneContactSaga)
  yield takeLatest(CONTACT_CREATE_REQUEST, createContactSaga)
  yield takeLatest(CONTACT_EDIT_REQUEST, editContactSaga)
  yield takeLatest(CONTACT_REMOVE_REQUEST, removeContactSaga)
  yield takeLatest(CONTACT_GET_ALL_FAILURE, manageError)
  yield takeLatest(CONTACT_GET_ONE_FAILURE, manageError)
  yield takeLatest(CONTACT_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(CONTACT_EDIT_FAILURE, manageError)
  yield takeLatest(CONTACT_CREATE_FAILURE, manageError)
  yield takeLatest(CONTACT_REMOVE_FAILURE, manageError)
  yield takeLatest(CONTACT_FILTER_CHANGE_REQUEST, getContactFiltersSaga)
  yield takeLatest(CONTACT_FILTER_CHANGE_FAILURE, manageError)
}
