import React, { useEffect } from 'react'
import { columns } from './climb.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { climbGetAllRequest, climbGetTotalsRequest } from './climb.redux'
import { connect } from 'react-redux'

function ClimbList({ climbGetAllRequest, climbGetTotals, ...props }) {
  useEffect(() => {
    climbGetAllRequest()
    climbGetTotals()
  }, [climbGetAllRequest, climbGetTotals])

  function handleClick(type) {
    props.filters.search = ''
    if (type === 'all') {
      props.filters.active = null
      props.filters.page = 1
      climbGetAllRequest()
    }
    if (type === 'active') {
      props.filters.page = 1
      props.filters.active = true
      climbGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      props.filters.page = 1
      props.filters.active = false
      climbGetAllRequest({ active: false })
    }
  }

  const climb = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/climbs/${row._id}`)
    }
  }

  return (
    <Layout icon="fas fa-fw fa-cubes" title="CLIMB">
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title="CLIMB"
        subtitle="Lista"
        route="/climbs/create"
        showAddbuton={true}
      >
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={props.filters}
          onTableChange={climbGetAllRequest}
          data={climb}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    all: state.climbs.all,
    one: state.climbs.one,
    error: state.climbs.error,
    userLoading: state.climbs.loading,
    filters: state.climbs.filters,
    totals: state.climbs.totals
  }
}

const mapDispatchToProps = {
  climbGetAllRequest: climbGetAllRequest,
  climbGetTotals: climbGetTotalsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ClimbList)
