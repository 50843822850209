import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import {
  cardsListError,
  cardsListSuccess,
  CARDS_CREATE_FAILURE,
  CARDS_CREATE_REQUEST,
  CARDS_CREATE_SUCCESS,
  CARDS_EDIT_FAILURE,
  CARDS_EDIT_REQUEST,
  CARDS_FILTER_CHANGE_FAILURE,
  CARDS_FILTER_CHANGE_REQUEST,
  CARDS_FILTER_CHANGE_SUCCESS,
  CARDS_GET_ALL_FAILURE,
  CARDS_GET_ALL_REQUEST,
  CARDS_GET_ALL_SUCCESS,
  CARDS_GET_ONE_FAILURE,
  CARDS_GET_ONE_REQUEST,
  CARDS_GET_ONE_SUCCESS,
  CARDS_REMOVE_FAILURE,
  CARDS_REMOVE_REQUEST,
  CARDS_REMOVE_SUCCESS,
  loadMoreCardsSuccess,
  typesCards,
  cardDetailSuccess,
  cardDetailError,
  CARDS_SEND_SUCCESS,
  CARDS_SEND_FAILURE,
  CARDS_ACCEPT_SUCCESS,
  CARDS_ACCEPT_FAILURE,
  CARDS_REJECT_SUCCESS,
  CARDS_REJECT_FAILURE,
  CARDS_SEND_REQUEST,
  CARDS_ACCEPT_REQUEST,
  CARDS_REJECT_REQUEST,
  CARDS_GET_WITHOUT_CARRUSEL_REQUEST,
  CARDS_GET_WITHOUT_CARRUSEL_SUCCESS,
  CARDS_GET_WITHOUT_CARRUSEL_FAILURE
} from './cards.redux.js'
import axios from 'axios'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  encodeFiltersData,
  getUserGta,
  getUserId,
  manageError
} from 'redux/helpers/handlers.js'
import { justificationComponent } from 'components/Justification'

function* cardsListRequest({ filters = {} }) {
  try {
    const user = yield select(u => getUserId(u))
    filters.user = user
    const filterString = encodeFiltersData(filters)
    yield put(showLoading())
    const { data } = filterString
      ? yield call(axios.get, `/api/flashcards?${filterString}`)
      : yield call(axios.get, `/api/flashcards`)
    yield put(cardsListSuccess(data.data))
    yield put(hideLoading())
  } catch (error) {
    yield put(cardsListError(error.message))
  }
}

function* cardDetailRequest({ card }) {
  const _id = card._id
  try {
    const { data } = yield call(axios.get, `/api/flashcards/${_id}`)
    yield put(cardDetailSuccess(data?.data))
  } catch (error) {
    yield put(cardDetailError(error.message))
  }
}

function* loadMoreCardsRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = filterString
      ? yield call(axios.get, `/api/flashcards?${filterString}`)
      : yield call(axios.get, `/api/flashcards`)
    yield delay(3000)
    yield put(loadMoreCardsSuccess({ data: data.data }))
  } catch (error) {
    yield put(cardsListError(error.message))
  }
}

export function* getAllCardsSaga({ page = 1, active = true, status = 2, gta }) {
  try {
    const user = yield select(u => getUserId(u))
    const gtaAdmin = yield select(g => getUserGta(g))
    const { data } = gtaAdmin
      ? yield call(
          axios.get,
          `/api/flashcards?page=${page}&active=${active}&status=${status}&user=${user}&gta=${gtaAdmin}`
        )
      : yield call(
          axios.get,
          `/api/flashcards?page=${page}&active=${active}&status=${status}&user=${user}`
        )
    yield put({ type: CARDS_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CARDS_GET_ALL_FAILURE, error: error.response })
  }
}

export function* getWithoutCarruselCardsSaga({ page = 1 }) {
  try {
    const { data } = yield call(
      axios.get,
      `/api/flashcards?page=${page}&selected=false&active=true&status=2`
    )
    yield put({ type: CARDS_GET_WITHOUT_CARRUSEL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({
      type: CARDS_GET_WITHOUT_CARRUSEL_FAILURE,
      error: error.response
    })
  }
}

// TODO PARECE QUE NO SE USA
export function* createCardSaga({ payload }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    payload.createdBy = user
    const insert = yield call(axios.post, '/api/flashcards', payload)
    yield put({ type: CARDS_CREATE_SUCCESS, payload: insert })
    yield put(push('/questionnaire'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: CARDS_CREATE_FAILURE, error: error.response })
  }
}

export function* removeCardsSaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/flashcards/' + id)
    yield delay(3000)
    yield put({ type: CARDS_REMOVE_SUCCESS, payload: insert })
    yield put({ type: CARDS_GET_ALL_REQUEST })
    yield put(showLoading())
    yield delay(3000)
    yield put(push('/admins/card'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CARDS_REMOVE_FAILURE, error: error.response })
  }
}
export function* getOneCardSaga({ id = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/flashcards/${id}`)
    yield delay(3000)
    yield put({ type: CARDS_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CARDS_GET_ONE_FAILURE, error: error.response })
  }
}
export function* editCardsSaga({ payload }) {
  try {
    const description = 'Cambio de estatus'
    const user = yield select(u => getUserId(u))
    const historical = { description, adminId: user }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/flashcards/' + payload._id, payload)
    yield put(showLoading())
    yield put({ type: CARDS_CREATE_SUCCESS, payload: insert })
    yield put(push('/formCards'))
    Swal.fire('Flashcard actualizada', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: CARDS_CREATE_FAILURE, error: error.response })
  }
}

export function* sendFlashcardSaga({ newStatus }) {
  try {
    const description = 'Enviar a revisión'
    const user = yield select(u => getUserId(u))
    const historical = { description, adminId: user }
    newStatus.historical = historical

    const insert = yield call(axios.put, `/api/flashcards/${newStatus._id}/send`, newStatus)
    yield put(showLoading())
    yield put({ type: CARDS_SEND_SUCCESS, payload: insert })
    yield put(push('/formCards'))
    Swal.fire('Flashcard enviada a revisión', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: CARDS_SEND_FAILURE, error: error.response })
  }
}

export function* acceptFlashcardSaga({ newStatus }) {
  try {
    const description = 'Aceptar flashcard'
    const user = yield select(u => getUserId(u))
    const historical = { description, adminId: user }
    newStatus.historical = historical

    const insert = yield call(axios.put, `/api/flashcards/${newStatus._id}/accept`, newStatus)
    yield put(showLoading())
    yield put({ type: CARDS_ACCEPT_SUCCESS, payload: insert })
    yield put(push('/formCards'))
    Swal.fire('Flashcard aprobada', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: CARDS_ACCEPT_FAILURE, error: error.response })
  }
}

export function* rejectFlashcardSaga({ newStatus }) {
  try {
    const user = yield select(u => getUserId(u))
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description, adminId: user }
    newStatus.historical = historical

    const insert = yield call(axios.put, `/api/flashcards/${newStatus._id}/reject`, newStatus)
    yield put(showLoading())
    yield put({ type: CARDS_REJECT_SUCCESS, payload: insert })
    yield put(push('/formCards'))
    Swal.fire('Flashcard rechazada', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: CARDS_REJECT_FAILURE, error: error.response })
  }
}
export function* getCardFiltersSaga({ filters }) {
  try {
    filters.page = 1
    filters.active = true
    filters.status = 2
    yield delay(3000)
    yield put({ type: CARDS_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: typesCards.cardsListRequest, filters })
  } catch (error) {
    yield put({ type: CARDS_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
export default function* cardsSagas() {
  yield takeLatest(typesCards.cardsListRequest, cardsListRequest)
  yield takeLatest(typesCards.cardDetailRequest, cardDetailRequest)
  yield takeLatest(typesCards.loadMoreCardsRequest, loadMoreCardsRequest)
  yield takeLatest(CARDS_CREATE_REQUEST, createCardSaga)
  yield takeLatest(CARDS_CREATE_FAILURE, manageError)
  yield takeLatest(CARDS_GET_ALL_REQUEST, getAllCardsSaga)
  yield takeLatest(CARDS_GET_WITHOUT_CARRUSEL_REQUEST, getWithoutCarruselCardsSaga)
  yield takeLatest(CARDS_GET_ALL_FAILURE, manageError)
  yield takeLatest(CARDS_REMOVE_REQUEST, removeCardsSaga)
  yield takeLatest(CARDS_REMOVE_FAILURE, manageError)
  yield takeLatest(CARDS_EDIT_REQUEST, editCardsSaga)
  yield takeLatest(CARDS_EDIT_FAILURE, manageError)
  yield takeLatest(CARDS_GET_ONE_REQUEST, getOneCardSaga)
  yield takeLatest(CARDS_GET_ONE_FAILURE, manageError)
  yield takeLatest(CARDS_FILTER_CHANGE_REQUEST, getCardFiltersSaga)
  yield takeLatest(CARDS_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(CARDS_SEND_REQUEST, sendFlashcardSaga)
  yield takeLatest(CARDS_ACCEPT_REQUEST, acceptFlashcardSaga)
  yield takeLatest(CARDS_REJECT_REQUEST, rejectFlashcardSaga)
}
