import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import FormCardForm from './FormCardForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

export const FormCardEdit = ({ one }) => {
  return (
    <Layout icon="fas fa-question-circle" title="Flashcard Creada">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />{' '}
      <CardBig title={'Editar'}>
        <FormCardForm mode="edit" formCard={one} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.formCards.one
  }
}

export default connect(mapStateToProps)(FormCardEdit)
