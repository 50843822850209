import BusinessTypeList from './BusinessTypeList'
import BusinessTypeView from './BusinessTypeView'
import BusinessTypeCreate from './BusinessTypeCreate'
import BusinessTypeEdit from './BusinessTypeEdit'

const routes = [
  { path: '/business-types', component: BusinessTypeList },
  { path: '/business-types/create', component: BusinessTypeCreate },
  { path: '/business-types/:_id', component: BusinessTypeView },
  { path: '/business-types/:_id/edit', component: BusinessTypeEdit }
]

export default routes
