import { put, call, takeLatest } from 'redux-saga/effects'

import {
  typesHistorical,
  historicalListSuccess,
  historicalListError
} from './historical.redux'

import axios from 'axios'
import Swal from 'sweetalert2'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { encodeFiltersData } from 'redux/helpers/handlers.js'

function* historicalListRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    yield put(showLoading())
    const { data } = yield call(axios.get, `/api/historicals?${filterString}`)
    yield put(historicalListSuccess(data.data))
    yield put(hideLoading())
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(historicalListError(error.message))
  }
}

export default function* cardsSagas() {
  yield takeLatest(typesHistorical.historicalListRequest, historicalListRequest)
}
