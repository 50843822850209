import React from 'react'
import ImageBorder from 'components/Theme/Images/ImageBorder'
import { mdy } from 'utils/dates'
import { generateActiveIcon } from 'components/Theme/Table/helpers'
// @ts-ignore
import thumbnail from 'assets/media/profile.png'
const roles = ['Super Usuario', 'Comité editorial', 'Participante']
export const columns = [
  {
    dataField: 'thumbnail',
    style: () => ({
      padding: 0
    }),
    formatter: (cell, row) => (
      <ImageBorder src={cell ? cell : thumbnail} alt={row.name} height='70' width='70' />
    )
  },
  {
    dataField: '_id',
    text: 'ID',
    hidden: true
  },
  {
    dataField: 'name',
    text: 'Nombre',
    sort: true
  },
  {
    dataField: 'email',
    text: 'Correo',
    sort: true
  },
  { dataField: 'role', text: 'Rol', formatter: cell => roles[cell], sort: true },
  {
    text: 'Compañía',
    dataField: 'company',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
