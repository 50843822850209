import { setLocale } from 'yup'

//Homologacion de validaciones
setLocale({
  // use constant translation keys for messages without values
  mixed: {
    required: 'Campo Requerido'
  },
  string: {
    min: 'Debe ser mayor de ${min} caracteres',
    max: 'Debe ser menor de ${max} caracteres'
  }
})
