import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { FilterValidationSchema } from './FilterValidation'
import FormInput from 'components/FormInput'
import {
  filterCreateRequest,
  filterEditRequest,
  filterGetCatalogs
} from './filter.redux'
import { Button, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function FilterForm({
  mode,
  filter,
  loading,
  catalogs,
  filterGetCatalogs,
  ...props
}) {
  useEffect(() => {
    filterGetCatalogs()
  }, [filterGetCatalogs])
  const handleCreateFilter = values => {
    props.filterCreateRequest({ values })
  }
  const handleEditFilter = values => {
    props.filterEditRequest({ values })
  }

  let initialValuesData = {
    value: '',
    filterType: ''
  }
  if (mode === 'edit') {
    initialValuesData = {
      value: filter?.value,
      filterType: filter?.filterType,
      _id: filter?._id
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={FilterValidationSchema}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateFilter(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleEditFilter(values)
              setSubmitting(false)
            }
          }, 600)
        }}
      >
        {({ values, isSubmitting, handleChange }) => (
          <Form className="form">
            <div className="row gutters">
              <Col lg="6">
                <FormInput id="value" label="Valor" value={values.value} />
              </Col>
              <Col lg="6">
                <FormInput
                  id="filterType"
                  label="Tipo filtro"
                  type="select"
                  onChange={handleChange}
                  dataOptions={
                    catalogs.filterTypes &&
                    catalogs.filterTypes.data.map(({ name, _id }) => ({
                      value: _id,
                      text: name,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.filterType}
                />
              </Col>
            </div>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.filters.all,
    one: state.filters.one,
    error: state.filters.error,
    filterLoading: state.filters.loading,
    filters: state.filters.filters,
    catalogs: state.filters.catalogs
  }
}

const mapDispatchToProps = {
  filterCreateRequest: filterCreateRequest,
  filterEditRequest: filterEditRequest,
  filterGetCatalogs: filterGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm)
