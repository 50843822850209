import React from 'react'

export const ListItem = props => {
  const { strong = true, label, value } = props
  const icon = value ? 'fas fa-check-square' : 'fas fa-times'
  const color = value ? '#43d087' : '#ef6462'

  if (typeof value === 'boolean') {
    return (
      <li className='list-unstyled' style={{ padding: '.1rem', fontSize: '16px' }}>
        <i
          className={`${icon} mr-2`}
          style={{ fontSize: '1.35rem', color, fontSize: '16px' }}
        />
        <strong>{label}</strong>
      </li>
    )
  }

  return (
    <li className='list-unstyled' style={{ padding: '.1rem', fontSize: '16px' }}>
      {strong ? <span style={{ fontWeight: 'bold' }}>{label}:</span> : label} {value || 'N/A'}
    </li>
  )
}
