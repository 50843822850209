import React from 'react'
import { mdy } from 'utils/dates'
import ImageBorder from 'components/Theme/Images/ImageBorder'
// @ts-ignore
import thumbnail from 'assets/media/profile.png'

export const columns = [
  {
    dataField: 'thumbnail',
    style: () => ({
      padding: 0
    }),
    formatter: (cell, row) => (
      <ImageBorder
        src={cell ? cell : thumbnail}
        alt={row.name}
        height="70"
        width="70"
      />
    )
  },
  {
    dataField: 'createdBy',
    text: 'Creado por',
    sort: true
  },
  {
    dataField: 'description',
    text: 'Descripción',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  }
]
