import React from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { SectorValidationSchema } from './SectorValidation'
import FormInput from 'components/FormInput'
import { sectorCreateRequest, sectorEditRequest } from './sector.redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function SectorForm({ mode, sector, loading, ...props }) {
  const handleCreateSector = values => {
    props.sectorCreateRequest({ values })
  }
  const handleEditSector = values => {
    props.sectorEditRequest({ values })
  }

  let initialValuesData = {
    name: '',
    description: ''
  }
  if (mode === 'edit') {
    initialValuesData = {
      name: sector?.name,
      description: sector?.description,
      _id: sector?._id
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={SectorValidationSchema}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateSector(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleEditSector(values)
              setSubmitting(false)
            }
          }, 600)
        }}>
        {({ values, isSubmitting }) => (
          <Form className='form'>
            <div className='row gutters'>
              <FormInput id='name' label='Nombre' value={values.name} />
              <FormInput
                type='textarea'
                id='description'
                label={'Descripción'}
                value={values.description}
              />
            </div>
            <Button color='primary' type='submit' disabled={isSubmitting}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.sectors.all,
    one: state.sectors.one,
    error: state.sectors.error,
    sectorLoading: state.sectors.loading,
    filters: state.sectors.filters
  }
}

const mapDispatchToProps = {
  sectorCreateRequest: sectorCreateRequest,
  sectorEditRequest: sectorEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorForm)
