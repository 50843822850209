import React, { useEffect } from 'react'
import { columns } from './businessJoinType.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { connect } from 'react-redux'
import {
  businessJoinTypeGetAllRequest,
  businessJoinTypeGetTotals
} from './businessJoinType.redux'

function BusinessJoinTypeList({
  businessJoinTypeGetAllRequest,
  businessJoinTypeGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    businessJoinTypeGetAllRequest(filters)
    businessJoinTypeGetTotals()
  }, [businessJoinTypeGetAllRequest, businessJoinTypeGetTotals, filters])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/business-join-types/${row._id}`)
    }
  }

  const businessType = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      businessJoinTypeGetAllRequest(filters)
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      businessJoinTypeGetAllRequest(filters)
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      businessJoinTypeGetAllRequest(filters)
    }
  }
  return (
    <Layout icon="fa fa-fw fa-briefcase" title="Ámbitos de implementación">
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title="Ámbitos de implementación"
        subtitle="Lista"
        route="/business-join-types/create"
        showAddbuton={true}
      >
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          filter={filters}
          totalDocs={totalDocs}
          onTableChange={businessJoinTypeGetAllRequest}
          data={businessType}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.businessJoinTypes.all,
    one: state.businessJoinTypes.one,
    error: state.businessJoinTypes.error,
    userLoading: state.businessJoinTypes.loading,
    filters: state.businessJoinTypes.filters,
    totals: state.businessJoinTypes.totals
  }
}
const mapDispatchToProps = {
  businessJoinTypeGetAllRequest: businessJoinTypeGetAllRequest,
  businessJoinTypeGetTotals: businessJoinTypeGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessJoinTypeList)
