import React from 'react'
import { Formik, Form } from 'formik'
import FormInput from 'components/FormInput'
import { Button, Col } from 'reactstrap'
import { climbCreateRequest, climbEditRequest } from './climb.redux'
import { connect, useDispatch } from 'react-redux'
import { ClimbValidationSchema } from './ClimbValidation'

function ClimbForm({ mode, loading, climb }) {
  const dispatch = useDispatch()

  const handleCreateClimb = values => {
    dispatch(climbCreateRequest({ values }))
  }
  const handleEditCompany = values => {
    dispatch(climbEditRequest({ values }))
  }

  let initialValuesData = {
    nomenclature: '',
    name: '',
    description: ''
  }
  if (mode === 'edit') {
    initialValuesData = {
      nomenclature: climb.nomenclature,
      name: climb.name,
      description: climb.description,
      _id: climb._id
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={ClimbValidationSchema}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateClimb(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleEditCompany(values)
              setSubmitting(false)
            }
          }, 600)
        }}
      >
        {({ values }) => (
          <Form className="form">
            <div className="row gutters">
              <Col lg="6">
                <FormInput
                  id="nomenclature"
                  label="Nomenclatura"
                  value={values.nomenclature}
                />
              </Col>
              <Col lg="6">
                <FormInput id="name" label="Nombre" value={values.name} />
              </Col>
              <FormInput
                id="description"
                type="textarea"
                label={'Descripción'}
                value={values.description}
              />
            </div>
            <Button color="primary" type="submit" disabled={loading}>
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.climbs.all,
    one: state.climbs.one,
    error: state.climbs.error,
    userLoading: state.climbs.loading,
    filters: state.climbs.filters
  }
}

const mapDispatchToProps = {
  climbCreateRequest: climbCreateRequest,
  climbEditRequest: climbEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ClimbForm)
