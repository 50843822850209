// TYPES
export const typesHistorical = {
  historicalListRequest: '[ HISTORICAL ] HISTORICAL_LIST_REQUEST',
  historicalListSuccess: '[ HISTORICAL ] HISTORICAL_LIST_SUCESS',
  historicalListError: '[ HISTORICAL ] HISTORICAL_LIST_ERROR'
}

// ACTIONS
export const historicalListRequest = filters => ({
  type: typesHistorical.historicalListRequest,
  filters
})
export const historicalListSuccess = payload => ({
  type: typesHistorical.historicalListSuccess,
  payload
})
export const historicalListError = payload => ({
  type: typesHistorical.historicalListError,
  payload
})

// REDUCER
const initialState = {
  view: {},
  list: [],
  user: {},
  filtered: [],
  filter: new Date()
}

export function historicals(state = initialState, action) {
  switch (action.type) {
    case typesHistorical.historicalListRequest:
      return { ...state, loading: true }
    case typesHistorical.historicalListSuccess:
      return { ...state, loading: false, list: action.payload }
    case typesHistorical.historicalListError:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
