import React from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { CONFIGURATION_EDIT_REQUEST } from './configuration.redux'
import FormInput from 'components/FormInput'

export const ConfigurationContactForm = ({ loading, conf }) => {
  const handleConfiguration = values => {
    return values
  }

  const initialValues = {
    _id: conf?._id,
    contactEmail: conf?.contactEmail
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'required'
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleConfiguration(values)
            setSubmitting(false)
            loading = false
          }, 400)
        }}
      >
        {({ values, isSubmitting, handleChange }) => (
          <Form className="form ">
            <div className="row gutters">
              <FormInput id="contactEmail" label={'Correo'} />
            </div>
            <Button color="primary" type="submit">
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span> Actualizar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const eventChangeConfiguration = payload => ({
  type: CONFIGURATION_EDIT_REQUEST,
  payload: payload.values
})

export default connect(eventChangeConfiguration, null)(ConfigurationContactForm)
