import BusinessJoinSubTypeList from './BusinessJoinSubTypeList'
import BusinessJoinSubTypeView from './BusinessJoinSubTypeView'
import BusinessJoinSubTypeCreate from './BusinessJoinSubTypeCreate'
import BusinessJoinSubTypeEdit from './BusinessJoinSubTypeEdit'

const routes = [
  { path: '/business-joinsub-types', component: BusinessJoinSubTypeList },
  { path: '/business-joinsub-types/create', component: BusinessJoinSubTypeCreate },
  { path: '/business-joinsub-types/:_id', component: BusinessJoinSubTypeView },
  { path: '/business-joinsub-types/:_id/edit', component: BusinessJoinSubTypeEdit }
]

export default routes
