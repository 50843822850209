import React from 'react'
import { Row, Col } from 'reactstrap'
import Label from '../../../../node_modules/reactstrap/es/Label'
const GoodPracticeRowDetail = ({ title, text }) => {
  return (
    <>
        <Row style={{ paddingBottom: '0px' }}>
            <Col style={{ maxWidth: '190px'}}>
                <Label className='sumate-table-label' style={{ fontWeight: '700 !important' }}>{title}</Label>
            </Col>
            <Col>
                {text}
            </Col>
        </Row>
        <hr />
    </>
    
  )
}

export default GoodPracticeRowDetail