// Type's
export const COMMENTS_GET_BY_USER_REQUEST = 'COMMENTS_GET_BY_USER_REQUEST'
export const COMMENTS_GET_BY_USER_SUCCESS = 'COMMENTS_GET_BY_USER_SUCCESS'
export const COMMENTS_GET_BY_USER_FAILURE = 'COMMENTS_GET_BY_USER_FAILURE'

export const COMMENTS_GET_ONE_REQUEST = 'COMMENTS_GET_ONE_REQUEST'
export const COMMENTS_GET_ONE_SUCCESS = 'COMMENTS_GET_ONE_SUCCESS'
export const COMMENTS_GET_ONE_FAILURE = 'COMMENTS_GET_ONE_FAILURE'

export const COMMENTS_REACTIVATE_REQUEST = 'COMMENTS_REACTIVATE_REQUEST'
export const COMMENTS_REACTIVATE_SUCCESS = 'COMMENTS_REACTIVATE_SUCCESS'
export const COMMENTS_REACTIVATE_FAILURE = 'COMMENTS_REACTIVATE_FAILURE'

export const COMMENTS_DEACTIVATE_REQUEST = 'COMMENTS_DEACTIVATE_REQUEST'
export const COMMENTS_DEACTIVATE_SUCCESS = 'COMMENTS_DEACTIVATE_SUCCESS'
export const COMMENTS_DEACTIVATE_FAILURE = 'COMMENTS_DEACTIVATE_FAILURE'
// Action's
export const commentsGetByUserRequest = (payload) => ({
  type: COMMENTS_GET_BY_USER_REQUEST,
  payload
})
export const commentsGetByUserSuccess = (payload) => ({
  type: COMMENTS_GET_BY_USER_SUCCESS,
  payload
})
export const commentsGetByUserFailure = (error) => ({
  type: COMMENTS_GET_BY_USER_REQUEST,
  error
})

export const commentGetOneRequest = (payload) => ({ type: COMMENTS_GET_ONE_REQUEST, payload })
export const commentGetOneSuccess = (payload) => ({ type: COMMENTS_GET_ONE_SUCCESS, payload })
export const commentGetOneFailure = (payload) => ({ type: COMMENTS_GET_ONE_FAILURE, payload })
export const commentDeactivateRequest = (payload) => ({
  type: COMMENTS_DEACTIVATE_REQUEST,
  payload
})
export const commentReactivateRequest = (payload) => ({
  type: COMMENTS_REACTIVATE_REQUEST,
  payload
})

// Reducer
const INITIAL_STATE = {
  all: [],
  loading: false,
  error: null,
  filters: {}
}

export function comments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMMENTS_GET_BY_USER_REQUEST:
    case COMMENTS_GET_ONE_REQUEST:
      return { ...state, error: null, loading: true }
    case COMMENTS_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case COMMENTS_GET_BY_USER_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case COMMENTS_GET_BY_USER_FAILURE:
    case COMMENTS_GET_ONE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
