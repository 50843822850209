import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  MODULES_GET_ALL_REQUEST,
  MODULES_GET_ALL_FAILURE,
  MODULES_GET_ALL_SUCCESS,
  MODULES_CREATE_SUCCESS,
  MODULES_CREATE_FAILURE,
  MODULES_CREATE_REQUEST
} from './modules.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export function* getAllClimbSaga() {
  try {
    const { data } = yield call(axios.get, `/api/modules/all?active=true`)
    const modules = data?.data.map(m => ({
      ...m,
      read: false,
      create: false,
      update: false,
      delete: false,
      approve: false
    }))
    yield put({ type: MODULES_GET_ALL_SUCCESS, payload: modules })
  } catch (error) {
    yield put({ type: MODULES_GET_ALL_FAILURE, error: error.response })
  }
}

export function* createClimbSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/modules', payload)
    yield delay(1500)
    yield put({ type: MODULES_CREATE_SUCCESS, payload: insert })
    yield put(push('/moduless'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: MODULES_CREATE_FAILURE, error: error.response })
  }
}

export default function* modulesSagas() {
  yield takeLatest(MODULES_GET_ALL_REQUEST, getAllClimbSaga)
  yield takeLatest(MODULES_CREATE_REQUEST, createClimbSaga)
}
