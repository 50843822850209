// Type's
export const CAROUSEL_GET_ALL_REQUEST = 'CAROUSEL_GET_ALL_REQUEST'
export const CAROUSEL_GET_ALL_SUCCESS = 'CAROUSEL_GET_ALL_SUCCESS'
export const CAROUSEL_GET_ALL_FAILURE = 'CAROUSEL_GET_ALL_FAILURE'

export const CAROUSEL_CREATE_REQUEST = 'CAROUSEL_CREATE_REQUEST'
export const CAROUSEL_CREATE_SUCCESS = 'CAROUSEL_CREATE_SUCCESS'
export const CAROUSEL_CREATE_FAILURE = 'CAROUSEL_CREATE_FAILURE'

export const CAROUSEL_GET_ONE_REQUEST = 'CAROUSEL_GET_ONE_REQUEST'
export const CAROUSEL_GET_ONE_SUCCESS = 'CAROUSEL_GET_ONE_SUCCESS'
export const CAROUSEL_GET_ONE_FAILURE = 'CAROUSEL_GET_ONE_FAILURE'

export const CAROUSEL_EDIT_REQUEST = 'CAROUSEL_EDIT_REQUEST'
export const CAROUSEL_EDIT_SUCCESS = 'CAROUSEL_EDIT_SUCCESS'
export const CAROUSEL_EDIT_FAILURE = 'CAROUSEL_EDIT_FAILURE'

export const CAROUSEL_REMOVE_REQUEST = 'CAROUSEL_REMOVE_REQUEST'
export const CAROUSEL_REMOVE_SUCCESS = 'CAROUSEL_REMOVE_SUCCESS'
export const CAROUSEL_REMOVE_FAILURE = 'CAROUSEL_REMOVE_FAILURE'

export const CAROUSEL_REACTIVATE_REQUEST = 'CAROUSEL_REACTIVATE_REQUEST'
export const CAROUSEL_REACTIVATE_SUCCESS = 'CAROUSEL_REACTIVATE_SUCCESS'
export const CAROUSEL_REACTIVATE_FAILURE = 'CAROUSEL_REACTIVATE_FAILURE'

export const CAROUSEL_FILTER_CHANGE_REQUEST = 'CAROUSEL_FILTER_CHANGE_REQUEST'
export const CAROUSEL_FILTER_CHANGE_SUCCESS = 'CAROUSEL_FILTER_CHANGE_SUCCESS'
export const CAROUSEL_FILTER_CHANGE_FAILURE = 'CAROUSEL_FILTER_CHANGE_FAILURE'

// Action's
export const carouselGetAllRequest = () => ({
  type: CAROUSEL_GET_ALL_REQUEST
})
export const carouselGetAllSuccess = payload => ({
  type: CAROUSEL_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = error => ({
  type: CAROUSEL_GET_ALL_REQUEST,
  error
})
export const carouselFiltersChangeRequest = filters => ({
  type: CAROUSEL_FILTER_CHANGE_REQUEST,
  filters
})
export const eventAddNewCarousel = payload => ({
  type: CAROUSEL_CREATE_REQUEST,
  payload: payload.values
})
export const eventEditCarousel = payload => ({
  type: CAROUSEL_EDIT_REQUEST,
  payload: payload.values
})
export const carouselCreateSuccess = payload => ({
  type: CAROUSEL_CREATE_SUCCESS,
  payload
})
export const carouselCreateFailure = payload => ({
  type: CAROUSEL_CREATE_FAILURE,
  payload
})
export const carouselGetOneRequest = payload => ({
  type: CAROUSEL_GET_ONE_REQUEST,
  payload
})
export const carouselDeactivateRequest = payload => ({
  type: CAROUSEL_REMOVE_REQUEST,
  payload
})
export const carouselReactivateRequest = payload => ({
  type: CAROUSEL_REACTIVATE_REQUEST,
  payload
})

// Reducer
const INITIAL_STATE = {
  one: {},
  docs: [],
  loading: false,
  error: null,
  filters: { active: true }
}

export function carousel(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CAROUSEL_REMOVE_REQUEST:
    case CAROUSEL_GET_ONE_REQUEST:
    case CAROUSEL_EDIT_REQUEST:
    case CAROUSEL_CREATE_REQUEST:
    case CAROUSEL_GET_ALL_REQUEST:
      return { ...state, error: null, loading: true }

    case CAROUSEL_REMOVE_SUCCESS:
    case CAROUSEL_EDIT_SUCCESS:
    case CAROUSEL_GET_ONE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        one: { ...action.payload }
      }
    case CAROUSEL_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case CAROUSEL_GET_ALL_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        docs: [...action.payload]
      }

    case CAROUSEL_REMOVE_FAILURE:
    case CAROUSEL_GET_ONE_FAILURE:
    case CAROUSEL_EDIT_FAILURE:
    case CAROUSEL_CREATE_FAILURE:
    case CAROUSEL_GET_ALL_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
