import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  sectorGetOneRequest,
  sectorDeactivateRequest,
  sectorReactivateRequest
} from './sector.redux'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function SectorView({ sectorGetOneRequest, historicalListRequest, ...props }) {
  const { historical } = props
  const { _id } = props.match.params

  useEffect(() => {
    sectorGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [sectorGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (sector.active) {
      props.sectorDeactivateRequest({ id: _id })
    }
    if (!sector.active) {
      props.sectorReactivateRequest({ id: _id })
    }
  }

  const sector = props.one || {}

  return (
    <Layout icon="fas fa-fw fa-puzzle-piece" title="Sector">
      <TableContainer
        title="Sector"
        subtitle="Información del registro"
        buttonAdd={false}
        view={`/sectors/${_id}/edit`}
        actionButton={true}
        active={sector.active}
        onToggle={handleActiveToggle}
      >
        {!isEmpty(sector) && (
          <Row id="view-sector">
            <Col>
              <ul>
                <ListItem label="Nombre" value={sector.name} />
                <ListItem label="Descripción" value={sector.description} />
                <ListItem label="Activo" value={sector.active} />
                <ListItem label="Creado" value={mdy(sector.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className="overflow-box"
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}
      >
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.sectors.all,
    one: state.sectors.one,
    error: state.sectors.error,
    userLoading: state.sectors.loading,
    filters: state.sectors.filters,
    historical: state.historicals.list
  }
}

const mapDispatchToProps = {
  sectorGetOneRequest: sectorGetOneRequest,
  sectorReactivateRequest: sectorReactivateRequest,
  sectorDeactivateRequest: sectorDeactivateRequest,
  historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorView)
