import FormCardList from './FormCardList'
import FormCardView from './FormCardView'
import FormCardCreate from './FormCardCreate'
import FormCardEdit from './FormCardEdit'
import FormCardDetail from './FormCardDetail'

const routes = [
  { path: '/formCards', component: FormCardList },
  { path: '/flashcards/create', component: FormCardCreate },
  { path: '/formCards/:_id', component: FormCardView },
  { path: '/flashcards/:_id', component: FormCardView },
  { path: '/flashcards/:_id/detail', component: FormCardDetail },
  { path: '/flashcards/:id/edit', component: FormCardEdit }
]

export default routes
