import React from 'react'
import PropTypes from 'prop-types'

const StarsRanking = ({ rank }) => {
  return (
    <div className='socials tex-center'>
      {
        // @ts-ignore
        [...Array(5).keys()].map((value, index) => (
          <i
            key={index}
            className={rank > index ? 'fa fa-star ' : 'fa fa-star-o'}
            style={{ color: '#f1a545', fontSize: '17px' }}
          />
        ))
      }
    </div>
  )
}

StarsRanking.propTypes = {
  rank: PropTypes.number.isRequired
}

StarsRanking.defaultProps = {
  rank: 0
}

export default StarsRanking
