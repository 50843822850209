import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  acceptGoodPracticeRequest,
  rejectGoodPracticeListRequest
} from 'components/Modules/GoodPractices/goodPractices.redux'

function ChangeStatus(props) {
  const handleAccept = newStatus => {
    props.one.status = newStatus
    props.acceptGoodPracticeRequest(props.one)
  }
  const handleReject = newStatus => {
    props.one.status = newStatus
    props.rejectGoodPracticeListRequest(props.one)
  }
  switch (props?.status) {
    case 0:
      return (
        <>
          <button
            className='btn btn-outline-success '
            onClick={() => handleAccept(1)}
            id='good-practice-button-success'
            style={{
              margin: '9px 10px 12px 34px'
            }}>
            <i className='fas fa-thumbs-up mr-1' />
            Aprobar
          </button>
          <button
            className='btn btn-outline-danger'
            id='good-practice-button-reject'
            onClick={() => handleReject(2)}
            style={{
              margin: '9px 0px 12px 34px'
            }}>
            <i className='fas fa-times mr-1' />
            Rechazar
          </button>
        </>
      )

    case 2:
      return (
        <>
          <i
            className='fas fa-clipboard-check mr-2'
            aria-hidden='true'
            style={{
              margin: '-9px 10px 12px 34px',
              fontSize: '1.35rem',
              color: 'rgb(67, 208, 135)'
            }}></i>
          <span style={{ color: 'green', fontWeight: 'bold' }}>Aprobado</span>
        </>
      )
    case 3:
      return (
        <>
          <i
            className='fas fa-times-circle mr-2'
            aria-hidden='true'
            style={{
              margin: '-9px 10px 12px 34px',
              fontSize: '1.35rem',
              color: '#ff6b7d'
            }}></i>
          <span style={{ color: 'red', fontWeight: 'bold' }}>Rechazado</span>
        </>
      )
    default:
      return <></>
  }
}
ChangeStatus.propTypes = {
  status: PropTypes.number,
  functionOnClick: PropTypes.func
}

ChangeStatus.defaultProps = {
  functionOnClick: null
}

const mapStateToProps = state => {
  return {
    one: state.goodPractices.one
  }
}

const mapDispatchToProps = {
  acceptGoodPracticeRequest,
  rejectGoodPracticeListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus)
