import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  FORMCARD_GET_ALL_REQUEST,
  FORMCARD_RELOAD,
  FORMCARD_GET_LIST_REQUEST,
  FORMCARD_GET_ALL_WITHOUT_CAROUSEL_REQUEST,
  FORMCARD_GET_ALL_WITHOUT_CAROUSEL_SUCCESS,
  FORMCARD_GET_ALL_WITHOUT_CAROUSEL_FAILURE,
  FORMCARD_GET_ALL_FAILURE,
  FORMCARD_GET_ALL_SUCCESS,
  FORMCARD_CREATE_SUCCESS,
  FORMCARD_CREATE_FAILURE,
  FORMCARD_CREATE_REQUEST,
  FORMCARD_GET_ONE_FAILURE,
  FORMCARD_GET_ONE_SUCCESS,
  FORMCARD_GET_ONE_REQUEST,
  FORMCARD_REMOVE_SUCCESS,
  FORMCARD_REMOVE_FAILURE,
  FORMCARD_REMOVE_REQUEST,
  FORMCARD_EDIT_SUCCESS,
  FORMCARD_EDIT_REQUEST,
  FORMCARD_EDIT_FAILURE,
  FORMCARD_FILTER_CHANGE_SUCCESS,
  FORMCARD_FILTER_CHANGE_FAILURE,
  FORMCARD_FILTER_CHANGE_REQUEST,
  FORMCARD_REACTIVATE_SUCCESS,
  FORMCARD_REACTIVATE_FAILURE,
  FORMCARD_REACTIVATE_REQUEST,
  FORMCARD_GET_TOTALS_SUCCESS,
  FORMCARD_GET_TOTALS_FAILURE,
  FORMCARD_GET_TOTALS_REQUEST,
  FORMCARD_GET_CATALOGS_SUCCESS,
  FORMCARD_GET_CATALOGS_FAILURE,
  FORMCARD_GET_CATALOGS_REQUEST,
  FORMCARD_GET_COMMENTS_DETAIL_SUCCESS,
  FORMCARD_GET_COMMENTS_DETAIL_FAILURE,
  FORMCARD_GET_COMMENTS_DETAIL_REQUEST,
  FORMCARD_CREATE_CAROUSEL_REQUEST,
  FORMCARD_CREATE_CAROUSEL_SUCCESS,
  FORMCARD_CREATE_CAROUSEL_FAILURE,
  FORMCARD_LOAD_MORE_REQUEST,
  FORMCARD_LOAD_MORE_SUCCESS,
  FORMCARD_LOAD_MORE_FAILURE,
  FORMCARD_SELECT_REQUEST
} from './formCard.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { getUserId, manageError, encodeFiltersData } from 'redux/helpers/handlers.js'
import { showSuccessMessage } from 'components/Theme/Alerts/exceptionUtils'
import { CARDS_GET_WITHOUT_CARRUSEL_REQUEST } from 'components/Modules/Cards/cards.redux.js'
import { CAROUSEL_GET_ALL_REQUEST } from 'components/Modules/CarouselCards/carousel.redux'

function* getAllFormCardSaga({ filters }) {
  try {
    yield put({ type: FORMCARD_RELOAD })
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/flashcards/?${filterString}`)
    yield put({ type: FORMCARD_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: FORMCARD_GET_ALL_FAILURE, error: error.response })
  }
}

function* getListFormCardSaga({ filters }) {
  try {
    yield put({ type: FORMCARD_RELOAD })
    const user = yield select(u => getUserId(u))
    filters.page = filters.page || 1
    filters.user = user
    yield put({ type: FORMCARD_GET_TOTALS_REQUEST })
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/flashcards/list?${filterString}`)
    yield put({ type: FORMCARD_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: FORMCARD_GET_ALL_FAILURE, error: error.response })
  }
}

function* getAllFormCardWithOutCarouselSaga({ filters }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(
      axios.get,
      `/api/getAllFlashcardsWithOutCarousel/?${filterString}`
    )
    yield put({
      type: FORMCARD_GET_ALL_WITHOUT_CAROUSEL_SUCCESS,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: FORMCARD_GET_ALL_WITHOUT_CAROUSEL_FAILURE,
      error: error.response
    })
  }
}

function* formCardGetCommentsDetailRequestSaga({ filters, payload }) {
  try {
    yield put({ type: FORMCARD_RELOAD })
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.post, `/api/comments-by-date?${filterString}`, payload)
    yield put({
      type: FORMCARD_GET_COMMENTS_DETAIL_SUCCESS,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: FORMCARD_GET_COMMENTS_DETAIL_FAILURE,
      error: error.response
    })
  }
}

function* getOneFormCardSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/flashcards/${_id}`)
    yield delay(1000)
    yield put({ type: FORMCARD_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: FORMCARD_GET_ONE_FAILURE, error: error.response })
  }
}

function* createFormCardSaga({ payload }) {
  try {
    yield put(showLoading())

    const user = yield select(u => getUserId(u))
    payload.createdBy = user
    const insert = yield call(axios.post, '/api/flashcards', payload)
    yield delay(1500)
    yield put({ type: FORMCARD_CREATE_SUCCESS, payload: insert })
    yield put(push('/formCards'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: FORMCARD_CREATE_FAILURE, error: error.response })
  }
}

function* createFormCardCarouselSaga({ values }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    const { cards } = values

    yield axios.post('/api/carouselCards/drop')
    yield cards.map(async card => {
      try {
        const carouselCard = {
          flashcard: card?.flashcard?._id || card._id,
          startDate: values.startDate,
          endDate: values.endDate,
          createdBy: user
        }
        const insert = await axios.post('/api/carouselCards', carouselCard)
        put({ type: FORMCARD_CREATE_CAROUSEL_SUCCESS, payload: insert })
      } catch (error) {
        Swal.fire('Error', error, 'error')
        put({ type: FORMCARD_CREATE_CAROUSEL_FAILURE, error: error.response })
      }
    })

    yield delay(1500)
    Swal.fire('Carussel Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: FORMCARD_CREATE_CAROUSEL_FAILURE, error: error.response })
  }
}

function* getFormCardFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield put({ type: FORMCARD_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: FORMCARD_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: FORMCARD_FILTER_CHANGE_FAILURE, error: error.response })
  }
}

function* loadMoreFormCardsRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = filterString
      ? yield call(axios.get, `/api/flashcards?${filterString}`)
      : yield call(axios.get, `/api/flashcards`)
    yield delay(3000)
    yield put({ type: FORMCARD_LOAD_MORE_SUCCESS, data: data.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: FORMCARD_LOAD_MORE_FAILURE, error: error.response })
  }
}
function* editFormCardSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    const historical = { description, adminId: user }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/flashcards/' + payload._id, payload)
    yield put({ type: FORMCARD_EDIT_SUCCESS, payload: insert.data.data })
    yield delay(1500)
    yield put(push('/formCards'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: FORMCARD_EDIT_FAILURE, error: error.response })
  }
}

function* selectFormCardSaga({ payload }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    const historical = {
      description: 'Se selecciona card para carrusel',
      adminId: user
    }
    payload.historical = historical
    yield call(axios.put, '/api/flashcards/' + payload._id, payload)
    yield put({
      type: CARDS_GET_WITHOUT_CARRUSEL_REQUEST,
      payload: { page: 1, selected: true }
    })
    yield put({ type: CAROUSEL_GET_ALL_REQUEST })
    payload.selected === true
      ? showSuccessMessage('Aviso', 'Tarjeta actualizada')
      : showSuccessMessage('Aviso', 'Tarjeta deseleccionada')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: FORMCARD_CREATE_FAILURE, error: error.response })
  }
}

function* removeFormCardSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(axios.put, `/api/flashcards/${payload.id}/deactivate`, payload)

    yield put({ type: FORMCARD_REMOVE_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/formCards'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: FORMCARD_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateFormCardSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, `/api/flashcards/${payload.id}/reactivate`, payload)

    yield put({ type: FORMCARD_REACTIVATE_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/formCards'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: FORMCARD_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/flashcards/totals`)
    yield delay(1000)
    yield put({ type: FORMCARD_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: FORMCARD_GET_TOTALS_FAILURE, error: error.response })
  }
}

function* getCatalogsSaga({ filters = { active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data: workAxes } = yield call(axios.get, `/api/workAxes/all?${filterString}`)
    const { data: gtas } = yield call(axios.get, `/api/gtas/all?${filterString}`)
    const { data: areas } = yield call(axios.get, `/api/areas/all?${filterString}`)
    const { data: climbs } = yield call(axios.get, `/api/climbs/all?${filterString}`)
    const { data: timeInitiatives } = yield call(
      axios.get,
      `/api/timeInitiatives/all?${filterString}`
    )
    const { data: businessTypes } = yield call(
      axios.get,
      `/api/business-types/all?${filterString}`
    )
    const { data: objectives } = yield call(axios.get, `/api/objectives/all?${filterString}`)
    const payload = {
      workAxes,
      gtas,
      areas,
      climbs,
      businessTypes,
      objectives,
      timeInitiatives
    }
    yield put({ type: FORMCARD_GET_CATALOGS_SUCCESS, payload: payload })
  } catch (error) {
    yield put({ type: FORMCARD_GET_CATALOGS_FAILURE, error: error.response })
  }
}

export default function* formCardSagas() {
  yield takeLatest(FORMCARD_GET_ALL_REQUEST, getAllFormCardSaga)
  yield takeLatest(FORMCARD_GET_LIST_REQUEST, getListFormCardSaga)
  yield takeLatest(
    FORMCARD_GET_ALL_WITHOUT_CAROUSEL_REQUEST,
    getAllFormCardWithOutCarouselSaga
  )
  yield takeLatest(FORMCARD_GET_COMMENTS_DETAIL_REQUEST, formCardGetCommentsDetailRequestSaga)
  yield takeLatest(FORMCARD_GET_ONE_REQUEST, getOneFormCardSaga)
  yield takeLatest(FORMCARD_CREATE_REQUEST, createFormCardSaga)
  yield takeLatest(FORMCARD_CREATE_CAROUSEL_REQUEST, createFormCardCarouselSaga)
  yield takeLatest(FORMCARD_EDIT_REQUEST, editFormCardSaga)
  yield takeLatest(FORMCARD_SELECT_REQUEST, selectFormCardSaga)
  yield takeLatest(FORMCARD_REMOVE_REQUEST, removeFormCardSaga)
  yield takeLatest(FORMCARD_FILTER_CHANGE_REQUEST, getFormCardFiltersSaga)
  yield takeLatest(FORMCARD_LOAD_MORE_REQUEST, loadMoreFormCardsRequest)
  yield takeLatest(FORMCARD_REACTIVATE_REQUEST, reactivateFormCardSaga)
  yield takeLatest(FORMCARD_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(FORMCARD_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(FORMCARD_REACTIVATE_FAILURE, manageError)
  yield takeLatest(FORMCARD_REMOVE_FAILURE, manageError)
  yield takeLatest(FORMCARD_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(FORMCARD_LOAD_MORE_FAILURE, manageError)
  yield takeLatest(FORMCARD_GET_TOTALS_FAILURE, manageError)
  yield takeLatest(FORMCARD_GET_ALL_FAILURE, manageError)
  yield takeLatest(FORMCARD_GET_ONE_FAILURE, manageError)
  yield takeLatest(FORMCARD_GET_CATALOGS_FAILURE, manageError)
}
