// Type's
export const LOGIN_ADMIN_REQUEST = 'LOGIN_ADMIN_REQUEST'
export const LOGIN_ADMIN_SUCCESS = 'LOGIN_ADMIN_SUCCESS'
export const LOGIN_ADMIN_VERIFED = 'LOGIN_ADMIN_VERIFED'
export const LOGIN_ADMIN_FAILURE = 'LOGIN_ADMIN_FAILURE'

export const LOGIN_ADMIN_VERIFY_REQUEST = 'LOGIN_ADMIN_VERIFY_REQUEST'
export const LOGIN_ADMIN_VERIFY_SUCCESS = 'LOGIN_ADMIN_VERIFY_SUCCESS'
export const LOGIN_ADMIN_VERIFY_FAILURE = 'LOGIN_ADMIN_VERIFY_FAILURE'

export const RECOVER_ADMIN_REQUEST_REQUEST = 'RECOVER_ADMIN_REQUEST_REQUEST'
export const RECOVER_ADMIN_REQUEST_SUCCESS = 'RECOVER_ADMIN_REQUEST_SUCCESS'
export const RECOVER_ADMIN_REQUEST_FAILURE = 'RECOVER_ADMIN_REQUEST_FAILURE'

export const RECOVER_ADMIN_REQUEST = 'RECOVER_ADMIN_REQUEST'
export const RECOVER_ADMIN_SUCCESS = 'RECOVER_ADMIN_SUCCESS'
export const RECOVER_ADMIN_FAILURE = 'RECOVER_ADMIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const CHECK_LOGIN_REQUEST = 'CHECK_LOGIN_REQUEST'
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS'
export const CHECK_LOGIN_FAILURE = 'CHECK_LOGIN_FAILURE'

// Action's
export const loginAdminRequest = payload => ({
  type: LOGIN_ADMIN_REQUEST,
  payload: payload
})
export const logoutRequest = payload => ({
  type: LOGOUT_REQUEST,
  payload: payload
})
export const checkloginRequest = () => ({
  type: CHECK_LOGIN_REQUEST
})

export const recoverRequestRequest = payload => ({
  type: RECOVER_ADMIN_REQUEST_REQUEST,
  payload: payload
})

export const loginVerifyRequest = payload => ({
  type: LOGIN_ADMIN_VERIFY_REQUEST,
  payload: payload
})

export const recoverAdminRequest = payload => ({
  type: RECOVER_ADMIN_REQUEST,
  payload: payload
})

// Reducer
const INITIAL_STATE = {
  firstStepDone: false,
  message: '',
  loading: false,
  error: null,
  isLogin: false,
  verifedLogin: false
}

export function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_ADMIN_VERIFED:
      return { ...state, error: null, loading: false, verifedLogin: true }
    case LOGIN_ADMIN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { user, ...newPayload } = action.payload
      return {
        ...state,
        error: null,
        loading: false,
        ...newPayload,
        isLogin: action.isLogin,
        firstStepDone: action.firstStepDone,
        email: action.email,
        verifedLogin: true
      }
    case LOGOUT_SUCCESS:
      return {
        firstStepDone: false,
        message: '',
        loading: false,
        error: null,
        isLogin: false,
        verifedLogin: true
      }
    case LOGIN_ADMIN_REQUEST:
    case RECOVER_ADMIN_REQUEST:
    case RECOVER_ADMIN_REQUEST_REQUEST:
      return { ...state, error: null, loading: true }

    case RECOVER_ADMIN_REQUEST_SUCCESS:
      return { ...state, error: null, loading: false, ...action.payload }
    case RECOVER_ADMIN_SUCCESS:
      return { ...state, error: null, loading: false, message: action.payload }

    case RECOVER_ADMIN_FAILURE:
    case RECOVER_ADMIN_REQUEST_FAILURE:
    case LOGIN_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.error, verifedLogin: true }
    default:
      return state
  }
}
