import React, { Fragment } from 'react'
import './button-filter.css'

const ButtonFilter = ({ all, active, inactive, handleClickAll }) => {
  return (
    <Fragment>
      <div className='row mx-3'>
        <div className='col-xl-4 col-md-4 mb-4'>
          <div className='card shadow' id='cardFilter'>
            <div className='card-blue'>
              <div className='card-body-filter' onClick={() => handleClickAll('all')}>
                <div className='button-all'>
                  <button>
                    <i className='fas fa-file' />
                  </button>
                </div>
                <div className='div-content' id='all'>
                  <h2>{all}</h2>
                  <p>Total registros</p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-4 col-md-4 mb-4'>
          <div className='card shadow' id='cardFilter'>
            <div className='card-green'>
              <div className='card-body-filter' onClick={() => handleClickAll('active')}>
                <div className='button-active'>
                  <button>
                    <i className='fas fa-check' />
                  </button>
                </div>
                <div className='div-content' id='active'>
                  <h2>{active}</h2>
                  <p>Total activos</p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>{' '}
        <div className='col-xl-4 col-md-4 mb-4'>
          <div className='card shadow' id='cardFilter'>
            <div className='card-red'>
              <div className='card-body-filter' onClick={() => handleClickAll('inactive')}>
                <div className='button-inactive'>
                  <button>
                    <i className='fas fa-exclamation' />
                  </button>
                </div>
                <div className='div-content' id='inactive'>
                  <h2>{inactive}</h2>
                  <p>Total inactivos</p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ButtonFilter
