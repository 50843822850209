// Type's
export const ADMIN_GET_ALL_REQUEST = 'ADMIN_GET_ALL_REQUEST'
export const ADMIN_GET_ALL_SUCCESS = 'ADMIN_GET_ALL_SUCCESS'
export const ADMIN_GET_ALL_FAILURE = 'ADMIN_GET_ALL_FAILURE'

export const ADMIN_CREATE_REQUEST = 'ADMIN_CREATE_REQUEST'
export const ADMIN_CREATE_SUCCESS = 'ADMIN_CREATE_SUCCESS'
export const ADMIN_CREATE_FAILURE = 'ADMIN_CREATE_FAILURE'

export const ADMIN_GET_ONE_REQUEST = 'ADMIN_GET_ONE_REQUEST'
export const ADMIN_GET_ONE_SUCCESS = 'ADMIN_GET_ONE_SUCCESS'
export const ADMIN_GET_ONE_FAILURE = 'ADMIN_GET_ONE_FAILURE'

export const ADMIN_LOGIN_GET_REQUEST = 'ADMIN_LOGIN_GET_REQUEST'
export const ADMIN_LOGIN_GET_SUCCESS = 'ADMIN_LOGIN_GET_SUCCESS'
export const ADMIN_LOGIN_GET_FAILURE = 'ADMIN_LOGIN_GET_FAILURE'

export const ADMIN_EDIT_REQUEST = 'ADMIN_EDIT_REQUEST'
export const ADMIN_EDIT_SUCCESS = 'ADMIN_EDIT_SUCCESS'
export const ADMIN_EDIT_FAILURE = 'ADMIN_EDIT_FAILURE'

export const ADMIN_EDIT_PERMITS_REQUEST = 'ADMIN_EDIT_PERMITS_REQUEST'
export const ADMIN_EDIT_PERMITS_SUCCESS = 'ADMIN_EDIT_PERMITS_SUCCESS'
export const ADMIN_EDIT_PERMITS_FAILURE = 'ADMIN_EDIT_PERMITS_FAILURE'

export const ADMIN_REMOVE_REQUEST = 'ADMIN_REMOVE_REQUEST'
export const ADMIN_REMOVE_SUCCESS = 'ADMIN_REMOVE_SUCCESS'
export const ADMIN_REMOVE_FAILURE = 'ADMIN_REMOVE_FAILURE'

export const ADMIN_REACTIVATE_REQUEST = 'ADMIN_REACTIVATE_REQUEST'
export const ADMIN_REACTIVATE_SUCCESS = 'ADMIN_REACTIVATE_SUCCESS'
export const ADMIN_REACTIVATE_FAILURE = 'ADMIN_REACTIVATE_FAILURE'

export const ADMIN_FILTER_CHANGE_REQUEST = 'ADMIN_FILTER_CHANGE_REQUEST'
export const ADMIN_FILTER_CHANGE_SUCCESS = 'ADMIN_FILTER_CHANGE_SUCCESS'
export const ADMIN_FILTER_CHANGE_FAILURE = 'ADMIN_FILTER_CHANGE_FAILURE'

export const ADMIN_GET_TOTALS_REQUEST = 'ADMIN_GET_TOTALS_REQUEST'
export const ADMIN_GET_TOTALS_SUCCESS = 'ADMIN_GET_TOTALS_SUCCESS'
export const ADMIN_GET_TOTALS_FAILURE = 'ADMIN_GET_TOTALS_FAILURE'

export const ADMIN_GET_CATALOGS_REQUEST = 'ADMIN_GET_CATALOGS_REQUEST'
export const ADMIN_GET_CATALOGS_SUCCESS = 'ADMIN_GET_CATALOGS_SUCCESS'
export const ADMIN_GET_CATALOGS_FAILURE = 'ADMIN_GET_CATALOGS_FAILURE'

export const ADMIN_GET_STATES_REQUEST = 'ADMIN_GET_STATES_REQUEST'
export const ADMIN_GET_STATES_SUCCESS = 'ADMIN_GET_STATES_SUCCESS'
export const ADMIN_GET_STATES_FAILURE = 'ADMIN_GET_STATES_FAILURE'

export const ADMIN_GET_CITIES_REQUEST = 'ADMIN_GET_CITIES_REQUEST'
export const ADMIN_GET_CITIES_SUCCESS = 'ADMIN_GET_CITIES_SUCCESS'
export const ADMIN_GET_CITIES_FAILURE = 'ADMIN_GET_CITIES_FAILURE'

// Action's
export const adminGetAllRequest = (filters) => ({ type: ADMIN_GET_ALL_REQUEST, filters })
export const adminGetAllSuccess = (payload) => ({
  type: ADMIN_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: ADMIN_GET_ALL_REQUEST,
  error
})
export const adminFiltersChangeRequest = (filters) => ({
  type: ADMIN_FILTER_CHANGE_REQUEST,
  filters
})
export const adminGetOneRequest = (payload) => ({ type: ADMIN_GET_ONE_REQUEST, payload })
export const adminGetOneSuccess = (payload) => ({ type: ADMIN_GET_ONE_SUCCESS, payload })
export const adminGetOneFailure = (payload) => ({ type: ADMIN_GET_ONE_FAILURE, payload })
export const adminDeactivateRequest = (payload) => ({ type: ADMIN_REMOVE_REQUEST, payload })
export const adminReactivateRequest = (payload) => ({
  type: ADMIN_REACTIVATE_REQUEST,
  payload
})
export const adminCreateRequest = ({ values }) => ({
  type: ADMIN_CREATE_REQUEST,
  payload: values
})
export const adminEditRequest = ({ values }) => ({
  type: ADMIN_EDIT_REQUEST,
  payload: values
})
export const adminEditPermitsRequest = (payload) => ({
  type: ADMIN_EDIT_PERMITS_REQUEST,
  payload
})
export const adminCreateSuccess = (payload) => ({ type: ADMIN_CREATE_SUCCESS, payload })
export const adminCreateFailure = (payload) => ({ type: ADMIN_CREATE_FAILURE, payload })
export const adminGetTotals = (payload) => ({ type: ADMIN_GET_TOTALS_REQUEST, payload })
export const adminGetCatalogs = (payload) => ({ type: ADMIN_GET_CATALOGS_REQUEST, payload })
export const adminGetStates = (payload) => ({ type: ADMIN_GET_STATES_REQUEST, payload })
export const adminGetCities = (country, state) => ({
  type: ADMIN_GET_CITIES_REQUEST,
  country,
  state
})

// Reducer
const INITIAL_STATE = {
  loading: false,
  error: null,
  filters: {},
  totals: {},
  states: [],
  one: {},
  all: [],
  cities: []
}
const INITIAL_STATE_UNIT = {
  loading: false,
  error: null,
  item: []
}

export function catalogs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADMIN_GET_CITIES_REQUEST:
    case ADMIN_GET_CATALOGS_REQUEST:
      return { ...state, error: null, loading: true }

    case ADMIN_GET_CATALOGS_SUCCESS:
      return { ...state, error: null, loading: false, ...action.payload }
    case ADMIN_GET_STATES_SUCCESS:
      return { ...state, error: null, loading: false, states: { ...action.payload } }
    case ADMIN_GET_STATES_REQUEST:
      return { ...state, error: null, loading: true }
    case ADMIN_GET_CITIES_SUCCESS:
      return { ...state, error: null, loading: false, cities: { ...action.payload } }

    case ADMIN_GET_CATALOGS_FAILURE:
    case ADMIN_GET_STATES_FAILURE:
    case ADMIN_GET_CITIES_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export function admins(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADMIN_GET_ALL_REQUEST:
    case ADMIN_GET_TOTALS_REQUEST:
    case ADMIN_CREATE_REQUEST:
    case ADMIN_GET_ONE_REQUEST:
    case ADMIN_EDIT_REQUEST:
    case ADMIN_EDIT_PERMITS_REQUEST:
    case ADMIN_REMOVE_REQUEST:
    case ADMIN_REACTIVATE_REQUEST:
      return { ...state, error: null, loading: true }

    case ADMIN_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case ADMIN_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }
    case ADMIN_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, ...action.payload }
    case ADMIN_GET_ONE_SUCCESS:
    case ADMIN_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case ADMIN_EDIT_PERMITS_SUCCESS:
      return { ...state, error: null, loading: false }
    case ADMIN_REMOVE_SUCCESS:
    case ADMIN_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, ...action.payload }

    case ADMIN_GET_TOTALS_FAILURE:
    case ADMIN_GET_ALL_FAILURE:
    case ADMIN_GET_ONE_FAILURE:
    case ADMIN_CREATE_FAILURE:
    case ADMIN_EDIT_FAILURE:
    case ADMIN_EDIT_PERMITS_FAILURE:
    case ADMIN_REMOVE_FAILURE:
    case ADMIN_REACTIVATE_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export function admin(state = INITIAL_STATE_UNIT, action) {
  switch (action.type) {
    case ADMIN_LOGIN_GET_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {
        password,
        validated,
        recoverPasswordToken,
        recoverPasswordExpires,
        twoFactorToken,
        twoFactorExpires,
        gta,
        ...newPayload
      } = action.payload
      return { ...state, error: null, loading: false, ...newPayload }
    default:
      return state
  }
}
