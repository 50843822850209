import { put, call, takeLatest, select } from 'redux-saga/effects'
import axios from 'axios'
import Swal from 'sweetalert2'

import {
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_SUCCESS,
  LOGIN_ADMIN_FAILURE,
  LOGIN_ADMIN_VERIFY_REQUEST,
  LOGIN_ADMIN_VERIFY_FAILURE,
  RECOVER_ADMIN_REQUEST_REQUEST,
  RECOVER_ADMIN_REQUEST_SUCCESS,
  RECOVER_ADMIN_REQUEST_FAILURE,
  RECOVER_ADMIN_REQUEST,
  RECOVER_ADMIN_FAILURE,
  RECOVER_ADMIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CHECK_LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGIN_ADMIN_VERIFED
} from './auth.redux.js'
import { GTA_ADMIN_GET_RESET } from 'components/Modules/Catalogues/Gta/gta.redux'
import { push } from 'connected-react-router'
import jwt_decode from 'jwt-decode'
import { isExpired, setAuthToken } from 'utils/auth.js'
import { encodeFiltersData, getUserId } from 'redux/helpers/handlers.js'
import { aclGetConfigRequest } from 'components/ACL/acl.redux'
import { gtaAdminGetRequest } from 'components/Modules/Catalogues/Gta/gta.redux.js'
import { ADMIN_LOGIN_GET_SUCCESS } from 'components/Modules/Admins/admin.redux'
import { typesNotifications } from 'components/Theme/Layout/Header/notifications.redux'
import { socket } from 'App'

export function* dashboardLoginRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/admins/login', payload)
    if (res?.data?.code === 201) {
      yield put({
        type: LOGIN_ADMIN_SUCCESS,
        payload: res?.data?.message,
        email: payload.email,
        firstStepDone: true,
        isLogin: false
      })
    } else if (res?.data?.code === 200) {
      const { data } = res
      axios.defaults.headers.common['Authorization'] = `Bearer ${data?.data}`
      localStorage.setItem('auth_token', data?.data)
      const token = data.data

      // decoded token to get user data
      const decoded = jwt_decode(token)
      // Guardar en reducer
      yield put({
        type: LOGIN_ADMIN_SUCCESS,
        payload: decoded,
        firstStepDone: false,
        isLogin: true
      })
      yield put(push('/home'))
    } else {
      yield put({ type: LOGIN_ADMIN_FAILURE, error: res?.message })
    }
  } catch (error) {
    yield put({ type: LOGIN_ADMIN_FAILURE, error: error?.response })
  }
}

export function* loginVerifyRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/admins/verify', payload)
    if (res?.status === 200) {
      const { data } = res
      axios.defaults.headers.common['Authorization'] = `Bearer ${data?.data}`
      localStorage.setItem('auth_token', data?.data)
      const token = data.data
      // decoded token to get user data
      const decoded = jwt_decode(token)
      // Guardar en reducer
      yield put({
        type: LOGIN_ADMIN_SUCCESS,
        payload: decoded,
        firstStepDone: false,
        isLogin: true
      })
      yield put(push('/home'))
    } else {
      yield put({ type: LOGIN_ADMIN_VERIFY_FAILURE, error: res?.message })
    }
  } catch (error) {
    yield put({ type: LOGIN_ADMIN_VERIFY_FAILURE, error: error?.response })
  }
}

export function* recoverRequestRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/admins/request-recover', payload)
    if (res?.status === 200) {
      yield put({ type: RECOVER_ADMIN_REQUEST_SUCCESS, payload: res })
      Swal.fire(
        '',
        'Se ha enviado un correo con instrucciones, es posible que se encuentre en la carpeta Spam o correo no deseado.',
        'info'
      )
      yield put(push('/'))
    } else {
      yield put({ type: RECOVER_ADMIN_REQUEST_FAILURE, error: res })
    }
  } catch (error) {
    yield put({ type: RECOVER_ADMIN_REQUEST_FAILURE, error: error?.response })
  }
}

export function* recoverRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/admins/recover', payload)
    if (res?.status === 200) {
      yield put({ type: RECOVER_ADMIN_SUCCESS, payload: res.message })
      Swal.fire('', 'Se ha actualizado su contraseña', 'success')
      yield put(push('/'))
    } else {
      yield put({ type: RECOVER_ADMIN_FAILURE, error: res?.message })
    }
  } catch (error) {
    yield put({ type: RECOVER_ADMIN_FAILURE, error: error?.response })
  }
}
export function* checkLogin({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const token = localStorage.getItem('auth_token')
    if (token) {
      setAuthToken(token) // Set token to Auth header
      const decoded = jwt_decode(token) // Decode token to get user data
      // Get unread notifications
      // @ts-ignore
      if (decoded && isExpired(decoded.exp)) {
        yield put(push('/'))
        return yield put({ type: LOGOUT_SUCCESS })
      } else {
        // @ts-ignore
        // disptch al reducer de auth de que está logueado

        // Se valida que se haya realizado el login satisfactoriamente
        yield put({ type: LOGIN_ADMIN_SUCCESS, payload: decoded, isLogin: true })

        // Aquí se carga la información del usuario que viene del decode, para llenar la //// información del nodo del admin
        yield put({ type: ADMIN_LOGIN_GET_SUCCESS, payload: decoded?.user })

        // Se carga la información del ACL con información de permisos del admin logueado
        yield put(aclGetConfigRequest(decoded?.user._id))

        const { data: notifications } = yield call(
          axios.get,
          `/api/notifications/unread?${filterString}?type=notification`
        )
        // Se cargan todas las notificaciones no leídas PUT
        yield put({
          type: typesNotifications.notificationsListSuccess,
          payload: notifications?.data
        })

        // Se carga la información del GTA2030 del admin logueado
        yield put(gtaAdminGetRequest(decoded?.user.gta))
      }
    } else {
      yield put({ type: LOGIN_ADMIN_VERIFED })
    }
  } catch (error) {
    yield put({ type: LOGIN_ADMIN_FAILURE, error: error?.response })
  }
}
export function* logoutRequest() {
  try {
    yield jwt_decode(localStorage.getItem('auth_token'))

    // Obtener el id del usuario
    const user = yield select(u => getUserId(u))

    yield call(axios.put, `/api/auth/admins/logout/${user}`, { login: false })
    // Remove token from localStorage
    localStorage.removeItem('auth_token')
    // Remove the auth header for future requests
    setAuthToken(false)
    // Close the socket connection
    socket.emit('logout')
    socket.disconnect()
    // Unset the user on the redux store
    yield put({ type: LOGOUT_SUCCESS })
    yield put({ type: GTA_ADMIN_GET_RESET })
    Swal.fire('', 'Se ha cerrado su sesión', 'success')
    yield put(push('/'))
  } catch (error) {
    yield put({ type: LOGOUT_FAILURE })
  }
}
function requestFail({ error }) {
  try {
    if (error?.error?.status !== 400 && error?.error?.code !== 401) {
      //  Swal.fire('Alerta', error?.error?.data.message, 'info')
    }
  } catch (error) {
    Swal.fire('Error', 'error', 'error')
  }
}

export default function* authSagas() {
  yield takeLatest(LOGIN_ADMIN_REQUEST, dashboardLoginRequest)
  yield takeLatest(LOGIN_ADMIN_FAILURE, requestFail)
  yield takeLatest(LOGIN_ADMIN_VERIFY_REQUEST, loginVerifyRequest)
  yield takeLatest(LOGIN_ADMIN_VERIFY_FAILURE, requestFail)
  yield takeLatest(RECOVER_ADMIN_REQUEST_REQUEST, recoverRequestRequest)
  yield takeLatest(RECOVER_ADMIN_REQUEST_FAILURE, requestFail)
  yield takeLatest(RECOVER_ADMIN_REQUEST, recoverRequest)
  yield takeLatest(RECOVER_ADMIN_FAILURE, requestFail)
  yield takeLatest(CHECK_LOGIN_REQUEST, checkLogin)
  yield takeLatest(LOGOUT_REQUEST, logoutRequest)
}
