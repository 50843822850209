import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({small = false, title, fill, color = 'bg-primary'}) => {
  return (
    <>
      <div className='mb-1 small'>{title}</div>
      <div className={`progress ${small && 'progress-sm'} mb-2`}>
        <div
          className={`progress-bar ${color}`}
          role='progressbar'
          style={{ width: `${fill}%` }}
          aria-valuenow={fill}
          aria-valuemin={0}
          aria-valuemax={100}></div>
      </div>
    </>
  )
}

ProgressBar.propTypes = {
    title: PropTypes.string.isRequired,
    fill: PropTypes.number.isRequired,
    color: PropTypes.string,
    small: PropTypes.bool
}
export default ProgressBar
