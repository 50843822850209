import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import BusinessTypeForm from './BusinessTypeForm'
import LoadingBar from 'react-redux-loading-bar'

function BusinessTypeEdit({ one, ...props }) {
  return (
    <Layout icon="fa fa-fw fa-building" title="Ámbito de implementación (Flashcards)">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <BusinessTypeForm
        {...props}
        title="Editar"
        mode="edit"
        loading={false}
        businessType={one}
      />
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    one: state.businessTypes.one
  }
}

export default connect(mapStateToProps)(BusinessTypeEdit)
