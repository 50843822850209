const baseURL = '/api'
const admin = '/api/admins'
const adminLogin = '/api/auth/admins/login'
const adminLoginVerify = '/api/auth/admins/verify'
const adminRecover = '/api/auth/admins/recover'
const cards = '/api/flashcards'
const login = '/api/auth/login'
const configurations = '/api/configuration'
const userActivate = '/api/auth/activate'
const userRecoverRequest = '/api/auth/request-recover'
const userRecover = '/api/auth/recover'
export const serviceUrl = {
  baseURL,
  admin,
  adminLogin,
  adminLoginVerify,
  adminRecover,
  login,
  cards,
  configurations,
  userRecoverRequest,
  userRecover,
  userActivate
}
