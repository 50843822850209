import React, { useEffect, useState, Fragment } from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { FormCardValidationSchema } from './FormCardValidation'
import FormInput from 'components/FormInput'
import {
  formCardCreateRequest,
  formCardEditRequest,
  formCardGetCatalogs
} from './formCard.redux'
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { ErrorMessage } from 'formik'

function FormCardForm({
  mode,
  admin,
  formCard,
  loading,
  catalogs,
  formCardGetCatalogs,
  acl,
  ...props
}) {
  useEffect(() => {
    formCardGetCatalogs()
  }, [formCardGetCatalogs])

  const handleCreateFormCard = values => {
    props.formCardCreateRequest({ values })
  }
  const handleEditFormCard = values => {
    props.formCardEditRequest({ values })
  }
  const workAxes = catalogs.workAxes?.data
  const [workAxis, setWorkAxis] = useState([])

  function handleChangeGta(event, values) {
    const value = event.target.value
    const filters = workAxes.filter(item => item.gta === value && item.active === true)
    setWorkAxis(filters)
  }
  const changeArea = (e, values) => {
    values.area = e.map(item => item.value)
  }
  const changeBusinessType = (e, values) => {
    values.businessType = e.map(item => item.value)
  }
  let initialValuesData = {
    gta: '',
    workAxis: '',
    name: '',
    businessType: '',
    what: '',
    how1: '',
    how2: '',
    how3: '',
    indicator: '',
    area: '',
    implementationTime: '',
    climb: [],
    number: ''
  }

  const implementationTime = [
    { id: 'Corto plazo', text: 'Corto plazo', value: 'Corto plazo' },
    { id: 'Mediano plazo', text: 'Mediano plazo', value: 'Mediano plazo' },
    { id: 'Largo plazo', text: 'Largo plazo', value: 'Largo plazo' }
  ]

  if (mode === 'edit') {
    const initialClimb = formCard?.climb?.map(climb => climb._id)
    initialValuesData = {
      _id: formCard._id,
      gta: formCard.gta?._id,
      workAxis: formCard.workAxis?._id,
      name: formCard.name,
      businessType: formCard.businessType,
      what: formCard.what,
      how1: formCard.how1,
      how2: formCard.how2,
      how3: formCard.how3,
      indicator: formCard.indicator,
      area: formCard.area,
      implementationTime: formCard.implementationTime,
      climb: initialClimb,
      objective: formCard.objective?._id,
      number: formCard.number
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        validationSchema={FormCardValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              values.number = parseInt((Math.random() * (500 - 180) + 180).toString())
              handleCreateFormCard(values)
            }
            if (mode === 'edit') {
              handleEditFormCard(values)
            }
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Form className='form '>
            <Row>
              <Col lg='4'>
                <FormInput
                  id='gta'
                  label={'Grupo de trabajo Agenda 2030'}
                  type='select'
                  onChange={e => {
                    setFieldValue('workAxis', '', values)

                    handleChange(e)
                    handleChangeGta(e, values)
                  }}
                  dataOptions={
                    catalogs.gtas &&
                    catalogs.gtas.data.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.gta}
                />
              </Col>
              <Col lg='4'>
                <FormInput
                  id='workAxis'
                  label={'Eje de trabajo'}
                  type='select'
                  onChange={handleChange}
                  dataOptions={
                    catalogs?.workAxes &&
                    catalogs.workAxes?.data
                      .filter(item => item.gta === values.gta && item.active === true)
                      .map(({ objective, _id }) => ({
                        value: _id,
                        text: objective,
                        id: _id,
                        key: _id
                      }))
                  }
                  value={values.workAxis}
                />
              </Col>
              <Col lg='4'>
                <FormInput
                  id='objective'
                  label={'Objetivo de Desarrollo Sostenible'}
                  type='select'
                  onChange={handleChange}
                  dataOptions={
                    catalogs.objectives &&
                    catalogs.objectives.data.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.objective}
                />
              </Col>
            </Row>
            <Row>
              <Col lg='6'>
                <FormInput
                  type='text'
                  id='name'
                  label={'Nombre solución'}
                  value={values.name}
                />
              </Col>
              <Col lg='6'>
                <div>
                  <div className='form-group'>
                    <label className='form-label auth__label'>Ámbito de implementación</label>
                    <Select
                      {...props}
                      defaultValue={
                        mode === 'edit' &&
                        formCard?.businessType &&
                        formCard.businessType.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                      id='businessType'
                      name='businessType'
                      isMulti
                      onChange={e => {
                        changeBusinessType(e, values)
                      }}
                      options={
                        catalogs.businessTypes &&
                        catalogs?.businessTypes.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                      // value={catalogs.areas?.filter(({ value }) => value === values.area)}
                    />
                  </div>
                  <ErrorMessage
                    name='businessType'
                    component='div'
                    className='animate__animated animate__fadeIn animate__faster error-message'
                  />
                </div>
              </Col>
            </Row>
            <FormInput
              id='what'
              type='textarea'
              label={'¿Qué solución propones?'}
              value={values.what}
            />
            <h5 style={{ padding: '10px 0' }}>
              Describe en 3 pasos sencillos cómo implementar la solución que propusiste ¿CÓMO?
            </h5>
            <FormInput id='how1' label={'Paso 1'} type='textarea' value={values.how1} />

            <FormInput id='how2' label={'Paso 2'} type='textarea' value={values.how2} />

            <FormInput id='how3' label={'Paso 3'} type='textarea' value={values.how3} />
            <FormInput
              id='indicator'
              label={'¿Qué indicador (métrica) alcanzaste o sugieres para esta solución?'}
              type='textarea'
              value={values.indicator}
            />
            <Row>
              <Col lg='6'>
                <div>
                  <div className='form-group'>
                    <label className='form-label auth__label'>Área Responsable</label>
                    <Select
                      defaultValue={
                        mode === 'edit' &&
                        formCard?.area &&
                        formCard.area.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                      {...props}
                      id='area'
                      name='area'
                      isMulti
                      onChange={e => {
                        changeArea(e, values)
                      }}
                      options={
                        catalogs.areas &&
                        catalogs?.areas.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                    />
                  </div>
                  <ErrorMessage
                    name='area'
                    component='div'
                    className='animate__animated animate__fadeIn animate__faster error-message'
                  />
                </div>
              </Col>
              <Col lg='6'>
                <FormInput
                  id='implementationTime'
                  label={'Tiempo de implementación'}
                  type='select'
                  onChange={handleChange}
                  dataOptions={implementationTime}
                  value={values.implementationTime}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  id='climb'
                  label='¿Qué ejes de cambio sistémico necesita tu solución?'
                  value={values.climb}
                  type='multiSelect'
                  multiOptions={
                    catalogs.climbs &&
                    catalogs.climbs.data.map(({ name, _id }) => ({
                      value: _id,
                      text: name,
                      id: _id,
                      key: _id,
                      name: _id
                    }))
                  }
                />
              </Col>
            </Row>
            {mode === 'edit' ? (
              <Fragment>
                {props.one.createdBy?._id === admin?._id ||
                props.one.status === 1 ||
                admin.role === 0 ||
                acl.find(
                  p => p.module && p.module.name === 'formCards' && p.permissions['update']
                ) ? (
                  <Button color='primary' type='submit' disabled={isSubmitting}>
                    {loading && (
                      <span>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          style={{ marginLeft: '35px', marginRight: '35px' }}
                        />
                      </span>
                    )}
                    {!loading && <span>Guardar</span>}
                  </Button>
                ) : (
                  <Button
                    color='primary'
                    type='submit'
                    style={{ display: 'none' }}
                    disabled='disabled'>
                    {loading && (
                      <span>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          style={{ marginLeft: '35px', marginRight: '35px' }}
                        />
                      </span>
                    )}
                    {!loading && <span>Guardar</span>}
                  </Button>
                )}
              </Fragment>
            ) : (
              <Button color='primary' type='submit' disabled={isSubmitting}>
                {loading && (
                  <span>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{ marginLeft: '35px', marginRight: '35px' }}
                    />
                  </span>
                )}
                {!loading && <span>Guardar</span>}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.formCards.all,
    one: state.formCards.one,
    error: state.formCards.error,
    formCardLoading: state.formCards.loading,
    filters: state.formCards.filters,
    catalogs: state.formCards.catalogs,
    gtas: state.formCards.gtas,
    admin: state.admin,
    acl: state.acl.list
  }
}

const mapDispatchToProps = {
  formCardCreateRequest: formCardCreateRequest,
  formCardEditRequest: formCardEditRequest,
  formCardGetCatalogs: formCardGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCardForm)
