import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterBar from './CardsFilterBar'
import { Card } from './Card.js'
import {
  formCardloadMoreRequest,
  formCardCreateCarouselRequest
} from 'components/Modules/FormCard/formCard.redux'
import CardsSelectedCarousel from './CardsSelectedCarousel'

export const CardsList = ({
  filters,
  cardsList,
  loading,
  selection,
  carouselSelected,
  ...props
}) => {
  const handleScroll = event => {
    const { target } = event
    if (
      target.scrollHeight - target.scrollTop <= target.clientHeight &&
      cardsList.hasNextPage
    ) {
      filters.page = cardsList.nextPage
      props.cardsGetAllRequest(filters)
    }
  }

  return (
    <>
      <div
        className='container cards__row'
        style={{
          marginLeft: '0px',
          backgroundColor: 'white',
          maxWidth: '100%',
          overflow: 'hidden',
          height: '100%'
        }}
        onScroll={handleScroll}>
        {selection && <CardsSelectedCarousel data={carouselSelected} />}
        <FilterBar />
        <div className='row'>
          {cardsList &&
            cardsList.docs?.map(card => (
              <Card key={card._id} data={card} selection={selection} />
            ))}
        </div>
      </div>
    </>
  )
}

CardsList.propTypes = {
  selection: PropTypes.bool,
  carouselSelected: PropTypes.object
}

CardsList.defaultProps = {
  selection: false,
  carouselSelected: {}
}

const mapStateToProps = state => {
  return {
    loading: state.formCards.loading
  }
}

const mapDispatchToProps = {
  formCardloadMoreRequest,
  formCardCreateCarouselRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsList)
