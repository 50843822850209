import React from 'react'
import { mdy } from 'utils/dates'

function FormCardDetailEstat(props) {
  const data = { ...props.data }
  const messages = { ...props.messages }
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="wrapper wrapper-content animated fadeInUp">
          <div className="ibox">
            <div
              className="ibox-content"
              style={{ borderWidth: '0px 0', marginBottom: '-30px' }}
            >
              <div className="row" style={{ paddingLeft: '9%' }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-4">
                  <dl className="dl-horizontal">
                    <dt>Estatus:</dt>
                    <dd>
                      {(data.active && (
                        <span className="label label-success">Activa</span>
                      )) || <span className="label label-error">Inactiva</span>}
                    </dd>
                    <dt>Creada el:</dt> <dd> {mdy(data.createdAt)}</dd>
                    <dt>Mensajes:</dt>{' '}
                    <dd>{Object.keys(messages).length || 0}</dd>
                  </dl>
                </div>
                <div className="col-lg-4" id="cluster_info">
                  <dl className="dl-horizontal">
                    <dt>Creada por:</dt>{' '}
                    <dd>
                      {data.createdBy.name} {data.createdBy.lastName}
                    </dd>
                    <dt>Última actualización:</dt>{' '}
                    <dd> {mdy(data.updatedAt)} </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FormCardDetailEstat.propTypes = {}

export default FormCardDetailEstat
