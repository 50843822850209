// Type's
export const CLIMB_GET_ALL_REQUEST = 'CLIMB_GET_ALL_REQUEST'
export const CLIMB_GET_ALL_SUCCESS = 'CLIMB_GET_ALL_SUCCESS'
export const CLIMB_GET_ALL_FAILURE = 'CLIMB_GET_ALL_FAILURE'

export const CLIMB_CREATE_REQUEST = 'CLIMB_CREATE_REQUEST'
export const CLIMB_CREATE_SUCCESS = 'CLIMB_CREATE_SUCCESS'
export const CLIMB_CREATE_FAILURE = 'CLIMB_CREATE_FAILURE'

export const CLIMB_GET_ONE_REQUEST = 'CLIMB_GET_ONE_REQUEST'
export const CLIMB_GET_ONE_SUCCESS = 'CLIMB_GET_ONE_SUCCESS'
export const CLIMB_GET_ONE_FAILURE = 'CLIMB_GET_ONE_FAILURE'

export const CLIMB_EDIT_REQUEST = 'CLIMB_EDIT_REQUEST'
export const CLIMB_EDIT_SUCCESS = 'CLIMB_EDIT_SUCCESS'
export const CLIMB_EDIT_FAILURE = 'CLIMB_EDIT_FAILURE'

export const CLIMB_REACTIVATE_REQUEST = 'CLIMB_REACTIVATE_REQUEST'
export const CLIMB_REACTIVATE_SUCCESS = 'CLIMB_REACTIVATE_SUCCESS'
export const CLIMB_REACTIVATE_FAILURE = 'CLIMB_REACTIVATE_FAILURE'

export const CLIMB_DEACTIVATE_REQUEST = 'CLIMB_DEACTIVATE_REQUEST'
export const CLIMB_DEACTIVATE_SUCCESS = 'CLIMB_DEACTIVATE_SUCCESS'
export const CLIMB_DEACTIVATE_FAILURE = 'CLIMB_DEACTIVATE_FAILURE'

export const CLIMB_REMOVE_REQUEST = 'CLIMB_REMOVE_REQUEST'
export const CLIMB_REMOVE_SUCCESS = 'CLIMB_REMOVE_SUCCESS'
export const CLIMB_REMOVE_FAILURE = 'CLIMB_REMOVE_FAILURE'

export const CLIMB_FILTER_CHANGE_REQUEST = 'CLIMB_FILTER_CHANGE_REQUEST'
export const CLIMB_FILTER_CHANGE_SUCCESS = 'CLIMB_FILTER_CHANGE_SUCCESS'
export const CLIMB_FILTER_CHANGE_FAILURE = 'CLIMB_FILTER_CHANGE_FAILURE'

export const CLIMB_GET_TOTALS_REQUEST = 'CLIMB_GET_TOTALS_REQUEST'
export const CLIMB_GET_TOTALS_SUCCESS = 'CLIMB_GET_TOTALS_SUCCESS'
export const CLIMB_GET_TOTALS_FAILURE = 'CLIMB_GET_TOTALS_FAILURE'

// Action's

export const climbDeactivateRequest = payload => ({
  type: CLIMB_DEACTIVATE_REQUEST,
  payload
})
export const climbReactivateRequest = payload => ({
  type: CLIMB_REACTIVATE_REQUEST,
  payload
})

export const climbGetAllRequest = filters => ({ type: CLIMB_GET_ALL_REQUEST, filters })
export const climbGetAllSuccess = payload => ({
  type: CLIMB_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = error => ({
  type: CLIMB_GET_ALL_REQUEST,
  error
})
export const climbFiltersChangeRequest = filters => ({
  type: CLIMB_FILTER_CHANGE_REQUEST,
  filters
})
export const climbGetOneRequest = payload => ({ type: CLIMB_GET_ONE_REQUEST, payload })
export const climbGetOneSuccess = payload => ({ type: CLIMB_GET_ONE_SUCCESS, payload })
export const climbGetOneFailure = payload => ({ type: CLIMB_GET_ONE_FAILURE, payload })

export const climbCreateRequest = ({ values }) => ({
  type: CLIMB_CREATE_REQUEST,
  payload: values
})
export const climbCreateSuccess = payload => ({ type: CLIMB_CREATE_SUCCESS, payload })
export const climbCreateFailure = payload => ({ type: CLIMB_CREATE_FAILURE, payload })

export const climbEditRequest = ({ values }) => ({
  type: CLIMB_EDIT_REQUEST,
  payload: values
})

export const climbGetTotalsRequest = payload => ({ type: CLIMB_GET_TOTALS_REQUEST, payload })

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: { active: true },
  totals: {}
}

export function climbs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLIMB_GET_ONE_REQUEST:
    case CLIMB_REACTIVATE_REQUEST:
    case CLIMB_REMOVE_REQUEST:
    case CLIMB_GET_ALL_REQUEST:
    case CLIMB_CREATE_REQUEST:
    case CLIMB_EDIT_REQUEST:
    case CLIMB_DEACTIVATE_REQUEST:
    case CLIMB_GET_TOTALS_REQUEST:
      return { ...state, error: null, loading: true }

    case CLIMB_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case CLIMB_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case CLIMB_GET_ONE_SUCCESS:
    case CLIMB_EDIT_SUCCESS:
    case CLIMB_REMOVE_SUCCESS:
    case CLIMB_DEACTIVATE_SUCCESS:
    case CLIMB_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CLIMB_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }

    case CLIMB_DEACTIVATE_FAILURE:
    case CLIMB_GET_ONE_FAILURE:
    case CLIMB_GET_ALL_FAILURE:
    case CLIMB_EDIT_FAILURE:
    case CLIMB_REMOVE_FAILURE:
    case CLIMB_CREATE_FAILURE:
    case CLIMB_REACTIVATE_FAILURE:
    case CLIMB_GET_TOTALS_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}
