import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import NavigationSection from './NavigationSection'
import './Navigation.css'
import logo from 'assets/img/logos/logoPacto.svg'
import { connect } from 'react-redux'
import { logoutRequest } from 'components/Modules/Auth/auth.redux.js'

const Navigation = ({ items, ...props }) => {
  const handleLogout = () => {
    props.logoutRequest()
  }
  const sections = [
    {
      module: 'admins',
      action: 'read',
      link: '/admins',
      icon: 'fas fa-user-cog',
      title: 'Administradores',
      id: 'admins'
    },
    {
      module: 'users',
      action: 'read',
      link: '/users',
      icon: 'fas fa-user',
      title: 'Usuarios'
    },
    {
      module: 'formCards',
      action: 'read',
      link: '/formCards',
      icon: 'fas fa-question-circle',
      title: 'Panel de Administración Flashcards'
    },
    {
      module: 'flashcards',
      action: 'read',
      link: '/flashcards',
      icon: 'fas fa-file',
      title: 'Flashcards Creadas'
    },
    {
      module: 'priorizations',
      action: 'read',
      link: '/priorizations',
      icon: 'fas fa-star',
      title: 'Priorización'
    },
    // {
    //   module: 'carouselCards',
    //   action: 'read',
    //   link: '/carouselCards',
    //   icon: 'fas fa-list',
    //   title: 'Carrusel'
    // },
    {
      module: 'contacts',
      action: 'read',
      link: '/contacts',
      icon: 'fas fa-fw fa-users',
      title: 'Contactos'
    }
  ]

  const cataloguesJoin = [
    {
      module: 'goodPractices',
      action: 'read',
      link: '/goodPractices',
      icon: 'fas fa-fw fa-user-plus',
      title: 'Súmate'
    },
    {
      module: 'business-join-types',
      action: 'read',
      link: '/business-join-types',
      icon: 'fas fa-fw fa-briefcase',
      title: 'Ámbitos de implementación'
    },
    {
      module: 'business-joinsub-types',
      action: 'read',
      link: '/business-joinsub-types',
      icon: 'fas fa-fw fa-building',
      title: 'Tipos de Empresa'
    },
    {
      module: 'timeInitiatives',
      action: 'read',
      link: '/timeInitiatives',
      icon: 'fas fa-fw fa-clock',
      title: 'Duración iniciativas'
    },
    {
      module: 'axesGlobalPact',
      action: 'read',
      link: '/axesGlobalPact',
      icon: 'fas fa-fw fa-cog',
      title: 'Ejes del Pacto Global'
    }
  ]
  const catalogues = [
    {
      module: 'business-types',
      action: 'read',
      link: '/business-types',
      icon: 'fas fa-fw fa-building',
      title: 'Ámbito de implementación (Flashcards)'
    },
    {
      module: 'sectors',
      action: 'read',
      link: '/sectors',
      icon: 'fas fa-fw fa-puzzle-piece',
      title: 'Sectores (Registro principal)'
    },
    {
      module: 'companies',
      action: 'read',
      link: '/companies',
      icon: 'fas fa-fw fa-industry',
      title: 'Empresas'
    },
    {
      module: 'gtas',
      action: 'read',
      link: '/gtas',
      icon: 'fas fa-fw fa-globe',
      title: 'GTA2030'
    },
    {
      module: 'workAxes',
      action: 'read',
      link: '/workAxes',
      icon: 'fas fa-fw fa-briefcase',
      title: 'Ejes de trabajo'
    },
    {
      module: 'objectives',
      action: 'read',
      link: '/objectives',
      icon: 'fas fa-bullseye',
      title: 'ODS'
    },
    {
      module: 'climbs',
      action: 'read',
      link: '/climbs',
      icon: 'fas fa-fw fa-cubes',
      title: 'CLIMB'
    },
    {
      module: 'areas',
      action: 'read',
      link: '/areas',
      icon: 'fa fa-arrows-alt',
      title: 'Áreas Responsables'
    },

    {
      module: 'filters',
      action: 'read',
      link: '/filters',
      icon: 'fa fa-fw fa-ban',
      title: 'Filtros'
    }
  ]

  return (
    <Fragment>
      {/* <!-- Sidebar --> */}
      <ul
        className='navbar-nav bg-gradient-primary sidebar bg-sidebar-open sidebar-dark accordion'
        id='accordionSidebar'>
        <Link
          to='/home'
          className='sidebar-brand d-flex align-items-center justify-content-center'>
          <img src={logo} alt='logo' />
        </Link>
        <NavigationSection ItemList={sections} title={'Módulos'} />
        <NavigationSection ItemList={cataloguesJoin} title={'Súmate'} />

        <NavigationSection ItemList={catalogues} title={'Cátalogos'} />

        {/* <!-- Divider --> */}
        <hr className='sidebar-divider d-none d-md-block' />

        <li className='nav-item'>
          <a className='nav-link' href='javascript:void(0)' onClick={() => handleLogout()}>
            <i className='fa-sign-out'></i>
            <span>Salir</span>
          </a>
        </li>
        <small className='text-center mb-2' style={{ color: 'white' }}>
          {process.env.REACT_APP_VERSION}
        </small>
      </ul>
      {/* <!-- End of Sidebar --> */}
    </Fragment>
  )
}

const mapDispatchToProps = {
  logoutRequest: logoutRequest
}

export default connect(null, mapDispatchToProps)(Navigation)
