import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'reactstrap'
import ModalPriorizationContent from './PriorizationModal'
import DataTable from 'components/Theme/Table/Table'
import TableContainer from 'components/Theme/Container/TableContainer'
import {
  priorizationFilterByDateRequest,
  priorizationGetAllStatusRequest,
  priorizationGetListByDateRequest,
  priorizationShowModalRequest
} from './priorization.redux'
import { checkloginRequest } from 'components/Modules/Auth/auth.redux.js'
import PriorizationDatePicker from './PriorizationDatePicker'
import { columns } from './priorization.helpers'

const PriorizationList = ({
  list,
  filtered,
  admin,
  modal,
  priorizationGetAllStatusRequest,
  priorizationGetListByDateRequest,
  priorizationFilterByDateRequest,
  checkloginRequest,
  filters,
  ...props
}) => {
  const [modalInfo, setModalInfo] = useState([])
  const [show, setShow] = useState(false)
  useEffect(
    values => {
      priorizationGetAllStatusRequest({ page: 1, active: true })
      priorizationGetListByDateRequest(filters)
      checkloginRequest()
      if (!priorizationFilterByDateRequest({ values })) {
        return priorizationFilterByDateRequest()
      }
    },
    [
      checkloginRequest,
      priorizationGetAllStatusRequest,
      priorizationGetListByDateRequest,
      priorizationFilterByDateRequest,
      filters
    ]
  )

  const handleChange = values => {
    priorizationFilterByDateRequest({ values })
    setShow(true)
  }

  const cards = props.all?.docs || []
  const user = admin?._id
  const priorizationFiltered = filtered?.data?.data || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      setModalInfo(row)
      toggle()
    }
  }

  const data = cards.map(d => ({
    _id: d._id,
    number: d.gta.nomenclature + d.number,
    name: d.name,
    title: d.gta.title,
    businessType: d.businessType,
    workAxis: d.workAxis,
    gta: d.gta.number,
    ownership: d.area.name,
    logo: d.gta.logo,
    color: d.gta.color,
    priorizationLength: d.priorization.length,
    priorization: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(a => a._id),
    totalImpact: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(a =>
        parseFloat(((a.coverage + a.replicabilty + a.impact + a.benefit) / 4).toFixed(2))
      ),
    totalImplementation: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(t => parseFloat(((t.budget + t.humanResources + t.infrastructure) / 3).toFixed(2))),
    total: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(a =>
        parseFloat(
          (
            (a.coverage +
              a.replicabilty +
              a.impact +
              a.benefit +
              a.budget +
              a.humanResources +
              a.infrastructure) /
            7
          ).toFixed(2)
        )
      )
  }))
  const filteredData = priorizationFiltered.map(d => ({
    _id: d._id,
    number: d.gta.nomenclature + d.number,
    name: d.name,
    title: d.gta.title,
    businessType: d.businessType,
    workAxis: d.workAxis,
    gta: d.gta.logo,
    ownership: d.area.name,
    logo: d.gta.logo,
    color: d.gta.color,
    priorizationLength: d.priorization.length,
    priorization: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(a => a._id),
    totalImpact: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(a =>
        parseFloat(((a.coverage + a.replicabilty + a.impact + a.benefit) / 4).toFixed(2))
      ),
    totalImplementation: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(t => parseFloat(((t.budget + t.humanResources + t.infrastructure) / 3).toFixed(2))),
    total: d.priorization
      .map(a => a)
      .filter(e => e.createdBy === user)
      .map(a =>
        parseFloat(
          (
            (a.coverage +
              a.replicabilty +
              a.impact +
              a.benefit +
              a.budget +
              a.humanResources +
              a.infrastructure) /
            7
          ).toFixed(2)
        )
      )
  }))
  const toggle = () => props.priorizationShowModalRequest()

  const ModalContent = () => {
    return modalInfo.number ? (
      <Modal isOpen={modal} toggle={toggle} className='modal-priorization'>
        <ModalPriorizationContent
          gta={modalInfo.title}
          solution={modalInfo.name}
          sector={modalInfo.businessType}
          businessType={modalInfo.businessType}
          workAxis={modalInfo.workAxis}
          number={modalInfo.number + ''}
          gtaLogo={modalInfo.logo + ''}
          color={modalInfo.color}
          flashcards={modalInfo._id}
          ownership={modalInfo.ownership}
          priorization={modalInfo.priorization}
        />
      </Modal>
    ) : (
      <div />
    )
  }

  return (
    <>
      <div className='priorization_pagination'>
        <TableContainer
          title='Priorización'
          subtitle='Priorización - Mi Lista de Priorizaciones'
          buttonAdd={false}
          tooltip={true}
          tooltipText='Las priorizaciones en blanco son las que te faltan por priorizar'
          idTooltip='tooltip-priorization'
          >
          <PriorizationDatePicker handleChange={handleChange} />
          {show ? (
            <Button onClick={() => setShow(false)}>Mostrar todos los resultados</Button>
          ) : null}
          {filteredData === 0 ? (
            'No se encontraron resultados'
          ) : (
            <DataTable
              {...props}
              searchBar={show ? false : true}
              limit={limit}
              pageAct={pageAct}
              totalDocs={totalDocs}
              data={show ? filteredData : data}
              onTableChange={priorizationGetAllStatusRequest}
              rowEvents={rowEvents}
              structure={columns}
              filter={filters}
            />
          )}
        </TableContainer>
        {modal ? <ModalContent /> : null}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.priorizations.all,
    flashCardList: state.flashcards.flashCardList,
    one: state.flashcards.one,
    error: state.flashcards.error,
    userLoading: state.flashcards.loading,
    filters: state.priorizations.filters,
    list: state.priorizations.list,
    filtered: state.priorizations.filtered,
    admin: state.admin,
    modal: state.priorizations.modal
  }
}

const mapDispatchToProps = {
  checkloginRequest: checkloginRequest,
  priorizationGetListByDateRequest: priorizationGetListByDateRequest,
  priorizationFilterByDateRequest: priorizationFilterByDateRequest,
  priorizationGetAllStatusRequest: priorizationGetAllStatusRequest,
  priorizationShowModalRequest: priorizationShowModalRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorizationList)
