import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  COMPANY_GET_ALL_REQUEST,
  COMPANY_GET_ALL_FAILURE,
  COMPANY_GET_ALL_SUCCESS,
  COMPANY_CREATE_SUCCESS,
  COMPANY_CREATE_FAILURE,
  COMPANY_CREATE_REQUEST,
  COMPANY_GET_ONE_FAILURE,
  COMPANY_GET_ONE_SUCCESS,
  COMPANY_GET_ONE_REQUEST,
  COMPANY_REMOVE_SUCCESS,
  COMPANY_REMOVE_FAILURE,
  COMPANY_REMOVE_REQUEST,
  COMPANY_EDIT_FAILURE,
  COMPANY_EDIT_SUCCESS,
  COMPANY_EDIT_REQUEST,
  COMPANY_FILTER_CHANGE_SUCCESS,
  COMPANY_FILTER_CHANGE_FAILURE,
  COMPANY_FILTER_CHANGE_REQUEST,
  COMPANY_REACTIVATE_SUCCESS,
  COMPANY_REACTIVATE_FAILURE,
  COMPANY_REACTIVATE_REQUEST,
  COMPANY_GET_TOTALS_SUCCESS,
  COMPANY_GET_TOTALS_FAILURE,
  COMPANY_GET_TOTALS_REQUEST,
  COMPANY_GET_CATALOGS_SUCCESS,
  COMPANY_GET_CATALOGS_FAILURE,
  COMPANY_GET_CATALOGS_REQUEST,
  COMPANY_GET_STATES_SUCCESS,
  COMPANY_GET_STATES_FAILURE,
  COMPANY_GET_STATES_REQUEST,
  COMPANY_GET_CITIES_SUCCESS,
  COMPANY_GET_CITIES_FAILURE,
  COMPANY_GET_CITIES_REQUEST
} from './company.redux.js'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'
import { isEmpty } from 'utils/crud.js'

function* getAllCompanySaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/companies/?${filterString}`)
    yield delay(1000)
    yield put({ type: COMPANY_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: COMPANY_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneCompanySaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/companies/${_id}`)
    yield delay(1000)
    yield put({ type: COMPANY_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: COMPANY_GET_ONE_FAILURE, error: error.response })
  }
}
function* createCompanySaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('image', payload.imageFile)
    formData.append('name', payload.name)
    formData.append('description', payload.description)
    formData.append('sector', payload.sector)
    formData.append('businessType', payload.businessType)
    formData.append('country', payload.country)
    formData.append('state', payload.state)
    formData.append('city', payload.city)

    yield put(showLoading())
    const insert = yield call(axios.post, '/api/companies', formData)
    yield delay(1500)
    yield put({ type: COMPANY_CREATE_SUCCESS, payload: insert })
    yield put(push('/companies'))
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: COMPANY_CREATE_FAILURE, error: error.response })
  }
}

function* getCompanyFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: COMPANY_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: COMPANY_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: COMPANY_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editCompanySaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('name', payload.name)
    formData.append('description', payload.description)
    formData.append('sector', payload.sector)
    formData.append('businessType', payload.businessType)
    if (!isEmpty(payload.country)) {
      formData.append('country', payload.country)
    }
    if (!isEmpty(payload.state)) {
      formData.append('state', payload.state)
    }
    if (!isEmpty(payload.city)) {
      formData.append('city', payload.city)
    }
    if (payload.imageFile) {
      formData.append('image', payload.imageFile)
    }

    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())
    formData.append('historical', description)

    const insert = yield call(axios.put, '/api/companies/' + payload._id, formData)
    yield put({ type: COMPANY_EDIT_SUCCESS, payload: insert })
    yield put(push('/companies'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: COMPANY_CREATE_FAILURE, error: error.response })
  }
}

function* removeCompanySaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/companies/' + payload.id + '/deactivate',
      payload
    )
    yield put({ type: COMPANY_REMOVE_SUCCESS, payload: insert })
    yield put({ type: COMPANY_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/companies'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: COMPANY_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateCompanySaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/companies/' + payload.id + '/reactivate',
      payload
    )
    yield put({ type: COMPANY_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: COMPANY_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/companies'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: COMPANY_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/companies/totals`)
    yield delay(1000)
    yield put({ type: COMPANY_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: COMPANY_GET_TOTALS_FAILURE, error: error.response })
  }
}

function* getCatalogsSaga({ filters = { active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)

    const { data: businessTypes } = yield call(
      axios.get,
      `/api/business-types/all?${filterString}`
    )
    const { data: sectors } = yield call(axios.get, `/api/sectors/all?${filterString}`)
    const { data: countries } = yield call(
      axios.get,
      `https://countriesnow.space/api/v0.1/countries/iso`
    )
    const payload = { businessTypes, sectors, countries }
    yield put({ type: COMPANY_GET_CATALOGS_SUCCESS, payload: payload })
  } catch (error) {
    yield put({ type: COMPANY_GET_CATALOGS_FAILURE, error: error.response })
  }
}

function* getStatesSaga({ payload }) {
  try {
    const body = {
      country: payload
    }

    const { data } = yield call(
      axios.post,
      `https://countriesnow.space/api/v0.1/countries/states`,
      body
    )
    yield put({ type: COMPANY_GET_STATES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: COMPANY_GET_STATES_FAILURE, error: error.response })
  }
}

function* getCitiesSaga({ country, state }) {
  try {
    const body = {
      country: country,
      state: state
    }
    const { data } = yield call(
      axios.post,
      `https://countriesnow.space/api/v0.1/countries/state/cities`,
      body
    )

    yield put({ type: COMPANY_GET_CITIES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: COMPANY_GET_CITIES_FAILURE, error: error.response })
  }
}

export default function* companySagas() {
  yield takeLatest(COMPANY_GET_ALL_REQUEST, getAllCompanySaga)
  yield takeLatest(COMPANY_GET_ONE_REQUEST, getOneCompanySaga)
  yield takeLatest(COMPANY_CREATE_REQUEST, createCompanySaga)
  yield takeLatest(COMPANY_EDIT_REQUEST, editCompanySaga)
  yield takeLatest(COMPANY_GET_ALL_FAILURE, manageError)
  yield takeLatest(COMPANY_GET_ONE_FAILURE, manageError)
  yield takeLatest(COMPANY_EDIT_FAILURE, manageError)
  yield takeLatest(COMPANY_CREATE_FAILURE, manageError)
  yield takeLatest(COMPANY_REMOVE_REQUEST, removeCompanySaga)
  yield takeLatest(COMPANY_REMOVE_FAILURE, manageError)
  yield takeLatest(COMPANY_FILTER_CHANGE_REQUEST, getCompanyFiltersSaga)
  yield takeLatest(COMPANY_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(COMPANY_REACTIVATE_REQUEST, reactivateCompanySaga)
  yield takeLatest(COMPANY_REACTIVATE_FAILURE, manageError)
  yield takeLatest(COMPANY_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(COMPANY_GET_TOTALS_FAILURE, manageError)
  yield takeLatest(COMPANY_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(COMPANY_GET_CATALOGS_FAILURE, manageError)
  yield takeLatest(COMPANY_GET_STATES_REQUEST, getStatesSaga)
  yield takeLatest(COMPANY_GET_STATES_FAILURE, manageError)
  yield takeLatest(COMPANY_GET_CITIES_REQUEST, getCitiesSaga)
  yield takeLatest(COMPANY_GET_CITIES_FAILURE, manageError)
}
