import PageLogin from 'components/Pages/PageLogin'
import PageConfiguration from 'components/Pages/PageConfiguration'
import PageFlashcard from 'components/Pages/PageFlashcard'
import PageFlashcardCreate from 'components/Pages/PageFlashcardCreate'
import PageFlashcardEdit from 'components/Pages/PageFlashcardEdit'
import PageDashboard from 'components/Pages/PageDashboard'
import PageMainMenu from 'components/Pages/PageMainMenu'
import companyRoutes from 'components/Modules/Catalogues/Company/company.routes'
import sectorRoutes from 'components/Modules/Catalogues/Sector/sector.routes'
import adminRoutes from 'components/Modules/Admins/admin.routes'
import commentRoutes from 'components/Modules/Comments/cooments.routes'
import userRoutes from 'components/Modules/Users/user.routes'
import gtaRoutes from 'components/Modules/Catalogues/Gta/gta.routes'
import workAxisRoutes from 'components/Modules/Catalogues/WorkAxis/workAxis.routes'
import objectiveRoutes from 'components/Modules/Catalogues/Objective/objective.routes'
import climbRoutes from 'components/Modules/Catalogues/Climb/climb.routes'
import areaRoutes from 'components/Modules/Catalogues/Area/area.routes'
import timeInitiatives from 'components/Modules/Catalogues/TimeInitiatives/timeInitiatives.routes'
import contactRoutes from 'components/Modules/Contacts/contact.routes'
import businessTypeRoutes from 'components/Modules/Catalogues/BusinessType/businessType.routes'
import businessJoinTypeRoutes from 'components/Modules/Catalogues/BusinessTypeJoin/businessJoinType.routes'
import businessJoinSubTypeRoutes from 'components/Modules/Catalogues/BusinessJoinSubType/businessJoinSubType.routes'
import formCardRoutes from 'components/Modules/FormCard/formCard.routes'
import goodPracticesRoutes from 'components/Modules/GoodPractices/goodPractices.routes'
import priorizationRoutes from 'components/Modules/Priorization/priorization.routes'
import notificationRoutes from 'components/Theme/Layout/Header/notification.routes'
import filterRoutes from 'components/Modules/Catalogues/Filters/filter.routes'
import PageRecover from 'components/Pages/PageRecover'
import PageReset from 'components/Pages/PageReset'
import PriorizationGeneralList from 'components/Modules/Priorization/PriorizationGeneralList'
import axesGlobalPactRoutes from 'components/Modules/Catalogues/AxesGlobalPact/axesGlobalPact.routes'
// import PageFlashcardCarrusel from 'components/Pages/PageFlashcardCarrusel'

export const routes = [
  ...adminRoutes,
  ...businessTypeRoutes,
  ...businessJoinTypeRoutes,
  ...businessJoinSubTypeRoutes,
  ...companyRoutes,
  ...gtaRoutes,
  ...sectorRoutes,
  ...userRoutes,
  ...workAxisRoutes,
  ...objectiveRoutes,
  ...climbRoutes,
  ...areaRoutes,
  ...contactRoutes,
  ...formCardRoutes,
  ...priorizationRoutes,
  ...timeInitiatives,
  ...filterRoutes,
  ...notificationRoutes,
  ...commentRoutes,
  ...goodPracticesRoutes,
  ...axesGlobalPactRoutes,
  {
    path: '/home',
    component: PageMainMenu
  },
  {
    path: '/dashboard',
    component: PageDashboard
  },
  // {
  //   path: '/carouselCards',
  //   component: PageFlashcardCarrusel
  // },
  {
    path: '/configuration',
    component: PageConfiguration
  },
  {
    path: '/flashcards',
    component: PageFlashcard
  },
  {
    path: '/flashcard/create',
    component: PageFlashcardCreate
  },
  {
    path: '/flashcard/:id/edit',
    component: PageFlashcardEdit
  },
  {
    path: '/priorization',
    component: PriorizationGeneralList
  }
]

export const publicRoutes = [
  {
    path: '/recover',
    component: PageRecover
  },
  {
    path: '/',
    component: PageLogin
  },
  {
    path: '/user/updatepassword/:token',
    component: PageReset
  }
]
