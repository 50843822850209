import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { ConfigurationPrivacyForm } from 'components/Modules/Configuration/ConfigurationPrivacyForm'
import { ConfigurationTermsForm } from 'components/Modules/Configuration/ConfigurationTermsForm'
import ConfigurationContactForm from 'components/Modules/Configuration/ConfigurationContactForm'
import { ConfigurationMailForm } from 'components/Modules/Configuration/ConfigurationMailForm'
import Layout from 'components/Layout'
import CardCollapsable from 'components/Theme/Cards/CardCollapsable'

export const PageConfiguration = ({ one, loading }) => {
  const dispatch = useDispatch()
  useEffect(() => {}, [dispatch])
  return (
    <Layout icon="fas fa-fw fa-cog" title="Configuración">
      <br />
      <CardCollapsable title={'Correo de contacto'}>
        <ConfigurationContactForm conf={one} loading={loading} />
      </CardCollapsable>
      <CardCollapsable title={'Configuración de correo electrónico'}>
        <ConfigurationMailForm conf={one} loading={loading} />
      </CardCollapsable>
      <CardCollapsable title={'Aviso de privacidad'}>
        <ConfigurationPrivacyForm conf={one} loading={loading} />
      </CardCollapsable>
      <CardCollapsable title={'Términos y condiciones'}>
        <ConfigurationTermsForm conf={one} loading={loading} />
      </CardCollapsable>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.configurations?.one,
    error: state.configurations?.error,
    loading: state.configurations?.loading
  }
}

export default connect(mapStateToProps)(PageConfiguration)
