import React, { useEffect } from 'react'
import { columns } from './objective.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { objectiveGetAllRequest, objectiveGetTotals } from './objective.redux'

function WorkAxisList({
  objectiveGetAllRequest,
  objectiveGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    objectiveGetAllRequest(filters)
    objectiveGetTotals()
  }, [objectiveGetAllRequest, objectiveGetTotals, filters])

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      objectiveGetAllRequest()
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      objectiveGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      objectiveGetAllRequest({ active: false })
    }
  }

  const objective = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/objectives/${row._id}`)
    }
  }

  return (
    <Layout icon="fas fa-bullseye" title="Objetivos de Desarrollo Sostentable">
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title="Objetivos de Desarrollo Sostenible"
        subtitle="Lista"
        route="/objectives/create"
        buttonAdd={true}
        showAddbuton={true}
      >
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={filters}
          onTableChange={objectiveGetAllRequest}
          data={objective}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.objective.all,
    one: state.objective.one,
    error: state.objective.error,
    userLoading: state.objective.loading,
    filters: state.objective.filters,
    totals: state.objective.totals
  }
}

const mapDispatchToProps = {
  objectiveGetAllRequest: objectiveGetAllRequest,
  objectiveGetTotals: objectiveGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkAxisList)
