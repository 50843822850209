import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
// @ts-ignore
import profile from 'assets/media/profile.png'
import moment from 'moment'
import { causeData } from 'utils/flashcards'

function getCause(url) {
  let module =
    url === 'sectors'
      ? 'SECTOR'
      : url === 'companies'
      ? 'EMPRESA'
      : url === 'business-types'
      ? 'ÁMBITO DE IMPLEMENTACIÓN (FLASHCARDS)'
      : url === 'business-join-types'
      ? 'ÁMBITO DE IMPLEMENTACIÓN'
      : url === 'business-joinsub-types'
      ? 'TIPO DE EMPRESA'
      : url === 'workAxes'
      ? 'EJE DE TRABAJO'
      : url === 'filters'
      ? 'FILTRO'
      : url === 'areas'
      ? 'ÁREA'
      : url === 'admins'
      ? 'ADMINISTRADOR'
      : url === 'flashcards'
      ? 'FLASHCARD'
      : url === 'goodPractices'
      ? 'INICIATIVAS'
      : url === 'priorizations'
      ? 'PRIORIZATION'
      : url === 'contacts'
      ? 'CONTACTO'
      : url === 'gtas'
      ? 'GTA2030'
      : url === 'climbs'
      ? 'CLIMB'
      : url === 'axesGlobalPact'
      ? 'EJE DE PACTO GLOBAL'
      : url === 'objectives'
      ? 'OBJETIVO DE DESARROLLO SUSTENTABLE'
      : url.toUpperCase()
  return { label: module }
}

export default function CardHistoricalTime({ item, indexCause, seeMore }) {
  let location = useLocation().pathname.split('/')[1] || 'Register'
  const labelCard = getCause(location)

  return (
    <div className='card-margin'>
      <div className='card-body pt-0'>
        <div className='widget-49'>
          <div className='widget-49-title-wrapper'>
            <div className='widget-49-date-primary'>
              <img
                className='rounded-x'
                width='60px'
                src={item.adminId?.thumbnail || item.userId?.thumbnail || profile}
                alt='user img'
              />
            </div>
            <div className='widget-49-meeting-info'>
              <span className='widget-49-pro-title'>
                {(item.adminId && item.adminId?.name + ' ' + item.adminId?.lastName) ||
                  (item.userId && item.userId?.name + ' ' + item.userId?.lastName)}{' '}
              </span>
              <span className='widget-49-meeting-time'>
                {item.adminId?.company.name || item.userId?.company.name || 'Empresa'}
              </span>
              <span className='widget-49-meeting-time' style={{ fontSize: '15px' }}>
                {moment(item.createdAt).format('DD/MM/YYYY  HH:mm')}
              </span>
            </div>
          </div>
          <div className='widget-49-pro-title-cause'>
            <span
              className='widget-49-pro-title'
              style={{
                color: causeData[indexCause]?.color,
                marginTop: '0px',
                fontSize: '18px'
              }}>
              {labelCard.label + ` - ${causeData[indexCause]?.label}`}
            </span>
          </div>
          <p className='historical-parraf'>{item.description}</p>
          <span
            style={{
              float: 'right',
              cursor: 'pointer',
              color: '#4496f3',
              textDecoration: 'none'
            }}></span>
        </div>
      </div>
    </div>
  )
}
