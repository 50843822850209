import './config/axios.config'
import './config/yup.config'
import './config/swal.config'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from 'redux/configureStore'
const store = configureStore()

if (process.env.REACT_APP_ENV !== 'production') {
  console.log(process.env.REACT_APP_ENV)
  // @ts-ignore

  // @ts-ignore
  window.store = store
  // @ts-ignore
  window.state = store.getState()
}
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
