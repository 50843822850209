import React from 'react'
import { Formik, Form } from 'formik'
import FormInput from 'components/FormInput'
import CardBig from 'components/Theme/Cards/CardBig'
import { AreaValidationSchema } from './AreaValidation'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { areaCreateRequest, areaEditRequest } from './area.redux'
import { connect } from 'react-redux'

function AreaForm(props) {
  const { title, mode, area, loading } = props

  let initialValuesData = {
    name: '',
    description: ''
  }
  if (mode === 'edit') {
    initialValuesData = {
      name: area.name,
      description: area.description,
      active: area.active,
      _id: area._id
    }
  }

  const submit = values => {
    props.areaCreateRequest({ values })
  }

  const editArea = values => {
    props.areaEditRequest({ values })
  }

  return (
    <CardBig title={title}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        validationSchema={AreaValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              submit(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              editArea(values)
              setSubmitting(false)
            }
          }, 600)
        }}>
        {({ values }) => (
          <Form className='form'>
            <div className='row gutters'>
              <FormInput id='name' label='Nombre' value={values.name} />
              <FormInput
                id='description'
                type='textarea'
                label='Descripción'
                value={values.description}
              />
            </div>
            <Button color='primary' type='submit' disabled={loading}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </CardBig>
  )
}

const mapStateToProps = ({ areas }) => ({ areas })
const mapDispatchToProps = {
  areaCreateRequest,
  areaEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaForm)
