import React, { Fragment } from 'react'
import './button-filter.css'

const GoodPracticesFilter = ({ pending, all, active, rejected, handleClickAll }) => {
  return (
    <Fragment>
      <div className='row mx-3'>
        <div className='col-xl-3 col-md-3 mb-3'>
          <div className='card shadow' id='cardFilter'>
            <div className='card-blue'>
              <div className='card-body-filter' onClick={() => handleClickAll('all')}>
                <div className='button-all'>
                  <button>
                    <i className='fas fa-file' />
                  </button>
                </div>
                <div className='div-content' id='all'>
                  <h2>{all}</h2>
                  <p>Total registros</p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      <div className='col-xl-3 col-md-3 mb-3'>
          <div className='card shadow' id='cardFilter'>
            <div className='card-yellow'>
              <div className='card-body-filter' onClick={() => handleClickAll('pending')}>
                <div className='button-pending'>
                  <button>
                    <i className='fas fa-asterisk' />
                  </button>
                </div>
                <div className='div-content' id='pending'>
                  <h2>{pending}</h2>
                  <p>Registros Pendientes</p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-md-3 mb-3'>
          <div className='card shadow' id='cardFilter'>
            <div className='card-green'>
              <div className='card-body-filter' onClick={() => handleClickAll('active')}>
                <div className='button-active'>
                  <button>
                    <i className='fas fa-check' />
                  </button>
                </div>
                <div className='div-content' id='active'>
                  <h2>{active}</h2>
                  <p>Total aprobados</p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>{' '}        
        <div className='col-xl-3 col-md-3 mb-3'>
          <div className='card shadow' id='cardFilter'>
            <div className='card-red'>
              <div className='card-body-filter' onClick={() => handleClickAll('rejected')}>
                <div className='button-inactive'>
                  <button>
                    <i className='fas fa-times' />
                  </button>
                </div>
                <div className='div-content' id='inactive'>
                  <h2>{rejected}</h2>
                  <p>Total Rechazadas</p>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default GoodPracticesFilter
