import React from 'react'
import { mdy } from 'utils/dates'
import { generateActiveIcon } from 'components/Theme/Table/helpers'
import ImageBorder from 'components/Theme/Images/ImageBorder'

export const columns = [
  {
    dataField: 'gta',
    text: 'GTA2030',
    sort: true
  },
  {
    dataField: 'objective',
    text: 'Eje de Trabajo',
    sort: true
  },
  {
    dataField: 'position',
    text: 'Figura',
    style: () => ({
      padding: 0,
      background: '#184f7a'
    }),
    formatter: cell => (
      <div className='div-icon-workaxis-table'>
        <img
          className='icon-workaxis-table'
          width='100'
          alt='workAxis GTA2030'
          src={
            cell
              ? require(`assets/img/ejesDeTrabajo/workAxis${cell}.svg`)
              : require(`assets/img/ejesDeTrabajo/workAxis1.svg`)
          }
        />
      </div>
    )
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
