import FilterCreate from './FilterCreate'
import FilterEdit from './FilterEdit'
import FilterList from './FilterList'
import FilterView from './FilterView'

const filterRoutes = [
  { path: '/filters', component: FilterList },
  { path: '/filters/create', component: FilterCreate },
  { path: '/filters/:_id', component: FilterView },
  { path: '/filters/:id/edit', component: FilterEdit }
]

export default filterRoutes
