import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  GOODPRACTICES_GET_ALL_REQUEST,
  GOODPRACTICES_RELOAD,
  GOODPRACTICES_GET_LIST_REQUEST,
  GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_REQUEST,
  GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_SUCCESS,
  GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_FAILURE,
  GOODPRACTICES_GET_ALL_FAILURE,
  GOODPRACTICES_GET_ALL_SUCCESS,
  GOODPRACTICES_CREATE_SUCCESS,
  GOODPRACTICES_CREATE_FAILURE,
  GOODPRACTICES_CREATE_REQUEST,
  GOODPRACTICES_GET_ONE_FAILURE,
  GOODPRACTICES_GET_ONE_SUCCESS,
  GOODPRACTICES_GET_ONE_REQUEST,
  GOODPRACTICES_REMOVE_SUCCESS,
  GOODPRACTICES_REMOVE_FAILURE,
  GOODPRACTICES_REMOVE_REQUEST,
  GOODPRACTICES_EDIT_SUCCESS,
  GOODPRACTICES_EDIT_REQUEST,
  GOODPRACTICES_FILTER_CHANGE_SUCCESS,
  GOODPRACTICES_FILTER_CHANGE_FAILURE,
  GOODPRACTICES_FILTER_CHANGE_REQUEST,
  GOODPRACTICES_REACTIVATE_SUCCESS,
  GOODPRACTICES_REACTIVATE_FAILURE,
  GOODPRACTICES_REACTIVATE_REQUEST,
  GOODPRACTICES_GET_TOTALS_SUCCESS,
  GOODPRACTICES_GET_TOTALS_FAILURE,
  GOODPRACTICES_GET_TOTALS_REQUEST,
  GOODPRACTICES_GET_CATALOGS_SUCCESS,
  GOODPRACTICES_GET_CATALOGS_FAILURE,
  GOODPRACTICES_GET_CATALOGS_REQUEST,
  GOODPRACTICES_GET_COMMENTS_DETAIL_SUCCESS,
  GOODPRACTICES_GET_COMMENTS_DETAIL_FAILURE,
  GOODPRACTICES_GET_COMMENTS_DETAIL_REQUEST,
  GOODPRACTICES_CREATE_CAROUSEL_REQUEST,
  GOODPRACTICES_CREATE_CAROUSEL_SUCCESS,
  GOODPRACTICES_CREATE_CAROUSEL_FAILURE,
  GOODPRACTICES_LOAD_MORE_REQUEST,
  GOODPRACTICES_LOAD_MORE_SUCCESS,
  GOODPRACTICES_LOAD_MORE_FAILURE,
  GOODPRACTICES_SELECT_REQUEST,
  GOODPRACTICES_ACCEPT_SUCCESS,
  GOODPRACTICES_ACCEPT_FAILURE,
  GOODPRACTICES_REJECT_SUCCESS,
  GOODPRACTICES_REJECT_FAILURE,
  GOODPRACTICES_ACCEPT_REQUEST,
  GOODPRACTICES_REJECT_REQUEST,
  GOODPRACTICES_EDIT_FAILURE
} from './goodPractices.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { getUserId, encodeFiltersData } from 'redux/helpers/handlers.js'
import { showSuccessMessage } from 'components/Theme/Alerts/exceptionUtils'
import { CARDS_GET_WITHOUT_CARRUSEL_REQUEST } from 'components/Modules/Cards/cards.redux.js'
import { CAROUSEL_GET_ALL_REQUEST } from 'components/Modules/CarouselCards/carousel.redux'

function* getAllGoodPracticesSaga({ filters }) {
  try {
    yield put({ type: GOODPRACTICES_RELOAD })
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/flashcards/?${filterString}`)
    yield put({ type: GOODPRACTICES_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GOODPRACTICES_GET_ALL_FAILURE, error: error.response })
  }
}

function* getListGoodPracticesSaga({ filters }) {
  try {
    yield put({ type: GOODPRACTICES_RELOAD })
    const user = yield select(u => getUserId(u))
    filters.page = filters.page || 1
    filters.user = user
    yield put({ type: GOODPRACTICES_GET_TOTALS_REQUEST })
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/goodPractices/list?${filterString}`)
    yield put({ type: GOODPRACTICES_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GOODPRACTICES_GET_ALL_FAILURE, error: error.response })
  }
}

function* getAllGoodPracticesWithOutCarouselSaga({ filters }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(
      axios.get,
      `/api/getAllGoodPracticesWithOutCarousel/?${filterString}`
    )
    yield put({
      type: GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_SUCCESS,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_FAILURE,
      error: error.response
    })
  }
}

function* goodPracticesGetCommentsDetailRequestSaga({ filters, payload }) {
  try {
    yield put({ type: GOODPRACTICES_RELOAD })
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.post, `/api/comments-by-date?${filterString}`, payload)
    yield put({
      type: GOODPRACTICES_GET_COMMENTS_DETAIL_SUCCESS,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: GOODPRACTICES_GET_COMMENTS_DETAIL_FAILURE,
      error: error.response
    })
  }
}

function* getOneGoodPracticesSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/goodPractices/${_id}/admin`)
    yield delay(1000)
    yield put({ type: GOODPRACTICES_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GOODPRACTICES_GET_ONE_FAILURE, error: error.response })
  }
}

function* createGoodPracticesSaga({ payload }) {
  try {
    yield put(showLoading())

    const user = yield select(u => getUserId(u))
    payload.createdBy = user
    const insert = yield call(axios.post, '/api/flashcards', payload)
    yield delay(1500)
    yield put({ type: GOODPRACTICES_CREATE_SUCCESS, payload: insert })
    yield put(push('/goodPractices'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: GOODPRACTICES_CREATE_FAILURE, error: error.response })
  }
}

function* createGoodPracticesCarouselSaga({ values }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    const { cards } = values

    yield axios.post('/api/carouselCards/drop')
    yield cards.map(async card => {
      try {
        const carouselCard = {
          flashcard: card?.flashcard?._id || card._id,
          startDate: values.startDate,
          endDate: values.endDate,
          createdBy: user
        }
        const insert = await axios.post('/api/carouselCards', carouselCard)
        put({ type: GOODPRACTICES_CREATE_CAROUSEL_SUCCESS, payload: insert })
      } catch (error) {
        Swal.fire('Error', error, 'error')
        put({ type: GOODPRACTICES_CREATE_CAROUSEL_FAILURE, error: error.response })
      }
    })

    yield delay(1500)
    Swal.fire('Carussel Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: GOODPRACTICES_CREATE_CAROUSEL_FAILURE, error: error.response })
  }
}

function* getGoodPracticesFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield put({ type: GOODPRACTICES_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: GOODPRACTICES_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: GOODPRACTICES_FILTER_CHANGE_FAILURE, error: error.response })
  }
}

function* loadMoreGoodPracticessRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = filterString
      ? yield call(axios.get, `/api/flashcards?${filterString}`)
      : yield call(axios.get, `/api/flashcards`)
    yield delay(3000)
    yield put({ type: GOODPRACTICES_LOAD_MORE_SUCCESS, data: data.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: GOODPRACTICES_LOAD_MORE_FAILURE, error: error.response })
  }
}
export function* editGoodPracticesSaga({ payload }) {
  try {
    yield put(showLoading())
    let formData = new FormData()
    formData.append('_id', payload._id)
    formData.append('alliedInstitutions', payload.alliedInstitutions)
    formData.append('axisGlobalPact', payload.axisGlobalPact)
    formData.append('businessJoinSubType', payload.businessJoinSubType)
    formData.append('businessJoinType', payload.businessJoinType)
    formData.append('description', payload.description)
    formData.append('email', payload.email)
    formData.append('image', payload.imageFile)
    formData.append('logo', payload.logoFile)
    formData.append('implementationPeriod', payload.implementationPeriod)
    formData.append('implementationTime', payload.implementationTime)
    formData.append('information', payload.information)
    formData.append('indicator', payload.indicator)
    formData.append('lat', payload.lat)
    formData.append('lng', payload.lng)
    formData.append('name', payload.name)
    formData.append('objective', payload.objective)
    formData.append('ods', payload.ods)
    formData.append('aceptVolunteers', payload.aceptVolunteers)
    formData.append('numberOfVolunteers', payload.numberOfVolunteers)
    formData.append('otherAmbit', payload.otherAmbit)
    formData.append('promoterInstitution', payload.promoterInstitution)
    formData.append('ubication', payload.ubication)
    const insert = yield call(axios.post, '/api/goodPractices/edit', formData)
    yield put({ type: GOODPRACTICES_EDIT_SUCCESS, payload: insert })
    yield put(push('/goodPractices'))
    yield call(Swal.fire('Acción actualizada', '', 'success'))
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: GOODPRACTICES_EDIT_FAILURE, error: error.response })
  }
}

function* selectGoodPracticesSaga({ payload }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    const historical = {
      description: 'Se selecciona card para carrusel',
      adminId: user
    }
    payload.historical = historical
    yield call(axios.put, '/api/flashcards/' + payload._id, payload)
    yield put({
      type: CARDS_GET_WITHOUT_CARRUSEL_REQUEST,
      payload: { page: 1, selected: true }
    })
    yield put({ type: CAROUSEL_GET_ALL_REQUEST })
    payload.selected === true
      ? showSuccessMessage('Aviso', 'Tarjeta actualizada')
      : showSuccessMessage('Aviso', 'Tarjeta deseleccionada')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: GOODPRACTICES_CREATE_FAILURE, error: error.response })
  }
}

function* removeGoodPracticesSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(axios.put, `/api/goodPractices/${payload._id}`, {
      active: false
    })
    yield put({ type: GOODPRACTICES_REMOVE_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/goodPractices'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GOODPRACTICES_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateGoodPracticesSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, `/api/goodPractices/${payload._id}`, {
      active: true
    })
    yield put({ type: GOODPRACTICES_REACTIVATE_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/goodPractices'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GOODPRACTICES_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/goodPractices/totals`)
    yield delay(1000)
    yield put({ type: GOODPRACTICES_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GOODPRACTICES_GET_TOTALS_FAILURE, error: error.response })
  }
}

export function* getCatalogsSaga({ filters = { active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data: areas } = yield call(axios.get, `/api/areas/all?${filterString}`)
    const { data: workAxes } = yield call(axios.get, `/api/workAxes/all?${filterString}`)
    const { data: axesGlobalPact } = yield call(
      axios.get,
      `/api/axes-global-pact/all?${filterString}`
    )
    const { data: gtas } = yield call(axios.get, `/api/gtas/all?${filterString}`)
    const { data: timeInitiatives } = yield call(
      axios.get,
      `/api/timeInitiatives/all?${filterString}`
    )
    const { data: climbs } = yield call(axios.get, `/api/climbs/all?${filterString}`)
    const { data: businessTypes } = yield call(
      axios.get,
      `/api/business-types/all?${filterString}`
    )
    const { data: businessJoinTypes } = yield call(
      axios.get,
      `/api/business-join-types/all?${filterString}`
    )
    const { data: businessJoinSubtypes } = yield call(
      axios.get,
      `/api/business-joinsub-types/all?${filterString}`
    )
    const { data: objectives } = yield call(axios.get, `/api/objectives/all?${filterString}`)

    const payload = {
      workAxes,
      axesGlobalPact,
      gtas,
      climbs,
      businessTypes,
      businessJoinTypes,
      businessJoinSubtypes,
      objectives,
      areas,
      timeInitiatives
    }
    yield put({ type: GOODPRACTICES_GET_CATALOGS_SUCCESS, payload })
  } catch (error) {
    yield put({ type: GOODPRACTICES_GET_CATALOGS_FAILURE, error: error.response })
  }
}

export function* acceptGoodPracticeSaga({ newStatus }) {
  try {
    const description = 'Aceptar iniciativa'
    const user = yield select(u => getUserId(u))
    const historical = { description, adminId: user }
    newStatus.historical = historical

    const insert = yield call(
      axios.put,
      `/api/goodPractices/${newStatus._id}/accept`,
      newStatus
    )
    yield put(showLoading())
    yield put({ type: GOODPRACTICES_ACCEPT_SUCCESS, payload: insert })
    yield put(push('/goodPractices'))
    Swal.fire('Iniciativa aprobada', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: GOODPRACTICES_ACCEPT_FAILURE, error: error.response })
  }
}

export function* rejectGoodPracticeSaga({ newStatus }) {
  try {
    const user = yield select(u => getUserId(u))
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description, adminId: user }
    newStatus.historical = historical

    const insert = yield call(
      axios.put,
      `/api/goodPractices/${newStatus._id}/reject`,
      newStatus
    )
    yield put(showLoading())
    yield put({ type: GOODPRACTICES_REJECT_SUCCESS, payload: insert })
    yield put(push('/goodPractices'))
    Swal.fire('Iniciativa rechazada', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: GOODPRACTICES_REJECT_FAILURE, error: error.response })
  }
}

export default function* goodPracticesSagas() {
  yield takeLatest(GOODPRACTICES_GET_ALL_REQUEST, getAllGoodPracticesSaga)
  yield takeLatest(GOODPRACTICES_GET_LIST_REQUEST, getListGoodPracticesSaga)
  yield takeLatest(
    GOODPRACTICES_GET_ALL_WITHOUT_CAROUSEL_REQUEST,
    getAllGoodPracticesWithOutCarouselSaga
  )
  yield takeLatest(
    GOODPRACTICES_GET_COMMENTS_DETAIL_REQUEST,
    goodPracticesGetCommentsDetailRequestSaga
  )
  yield takeLatest(GOODPRACTICES_GET_ONE_REQUEST, getOneGoodPracticesSaga)
  yield takeLatest(GOODPRACTICES_CREATE_REQUEST, createGoodPracticesSaga)
  yield takeLatest(GOODPRACTICES_CREATE_CAROUSEL_REQUEST, createGoodPracticesCarouselSaga)
  yield takeLatest(GOODPRACTICES_EDIT_REQUEST, editGoodPracticesSaga)
  yield takeLatest(GOODPRACTICES_SELECT_REQUEST, selectGoodPracticesSaga)
  yield takeLatest(GOODPRACTICES_REMOVE_REQUEST, removeGoodPracticesSaga)
  yield takeLatest(GOODPRACTICES_FILTER_CHANGE_REQUEST, getGoodPracticesFiltersSaga)
  yield takeLatest(GOODPRACTICES_LOAD_MORE_REQUEST, loadMoreGoodPracticessRequest)
  yield takeLatest(GOODPRACTICES_REACTIVATE_REQUEST, reactivateGoodPracticesSaga)
  yield takeLatest(GOODPRACTICES_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(GOODPRACTICES_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(GOODPRACTICES_ACCEPT_REQUEST, acceptGoodPracticeSaga)
  yield takeLatest(GOODPRACTICES_REJECT_REQUEST, rejectGoodPracticeSaga)
}
