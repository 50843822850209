import ContactList from './ContactList'
import ContactView from './ContactView'
import ContactForm from './ContactForm'

const routes = [
  { path: '/contacts', component: ContactList },
  { path: '/contacts/create', component: ContactForm },
  { path: '/contacts/:_id', component: ContactView }
]

export default routes
