import React, { Fragment } from 'react'
import { Subtitle } from 'components/Theme/Typography'

function CardBig(props) {
  const { children, title } = props

  return (
    <Fragment>
      <div className="card shadow mb-4">
        <div className="card-header py-3 px-0">
          <Subtitle className="flex-row align-items-center d-flex justify-content-between">
            {title}
          </Subtitle>
        </div>
        <div className="card-body">
          {children}
          <ButtonsDeck {...props} />
        </div>
      </div>
    </Fragment>
  )
}

const ButtonsDeck = props => {
  const {
    pristine = false,
    submitting = false,
    valid = true
  } = props.status || {}

  return (
    <ul className="list-inline">
      {props.button === 'add' && (
        <li className="list-inline-item">
          <button
            className="btn btn-primary"
            onClick={props.onClick}
            disabled={pristine || submitting || !valid}
          >
            {!props.buttonAddLabel ? 'Guardar' : props.buttonAddLabel}
          </button>
        </li>
      )}

      {props.button === 'edit' && (
        <li className="list-inline-item">
          <button
            className="btn btn-danger"
            onClick={props.history.goBack}
            disabled={pristine || submitting}
          >
            Cancelar
          </button>
        </li>
      )}

      {(props.button === 'edit' || props.button === 'update') && (
        <li className="list-inline-item">
          <button
            className="btn btn-info"
            onClick={props.onClick}
            disabled={pristine || submitting}
          >
            Actualizar
          </button>
        </li>
      )}

      {/* Warning text next to the button */}
      {props.showWarning && (
        <span className="text-warning">{props.warning}</span>
      )}
    </ul>
  )
}

export default CardBig
