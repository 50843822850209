import SectorCreate from './SectorCreate'
import SectorEdit from './SectorEdit'
import SectorList from './SectorList'
import SectorView from './SectorView'

const sectorRoutes = [
  { path: '/sectors', component: SectorList },
  { path: '/sectors/create', component: SectorCreate },
  { path: '/sectors/:_id', component: SectorView },
  { path: '/sectors/:id/edit', component: SectorEdit }
]

export default sectorRoutes
