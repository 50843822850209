import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  COMMENTS_GET_BY_USER_REQUEST,
  COMMENTS_GET_BY_USER_FAILURE,
  COMMENTS_GET_BY_USER_SUCCESS,
  COMMENTS_GET_ONE_FAILURE,
  COMMENTS_GET_ONE_SUCCESS,
  COMMENTS_GET_ONE_REQUEST,
  COMMENTS_REACTIVATE_SUCCESS,
  COMMENTS_REACTIVATE_FAILURE,
  COMMENTS_REACTIVATE_REQUEST,
  COMMENTS_DEACTIVATE_SUCCESS,
  COMMENTS_DEACTIVATE_FAILURE,
  COMMENTS_DEACTIVATE_REQUEST
} from './comments.redux.js'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

function* getAllCommentsByUser({ payload = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(payload)
    const data = yield call(axios.get, `/api/comments/?${filterString}`)
    yield put({ type: COMMENTS_GET_BY_USER_SUCCESS, payload: data?.data.data })
  } catch (error) {
    yield put({ type: COMMENTS_GET_BY_USER_FAILURE, error: error.response })
  }
}

function* getOneCommentSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/comments/${_id}`)
    yield delay(1000)
    yield put({ type: COMMENTS_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: COMMENTS_GET_ONE_FAILURE, error: error.response })
  }
}

function* reactivateCommentSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/comments/' + payload.id + '/reactivate',
      payload
    )
    yield put({ type: COMMENTS_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: COMMENTS_GET_BY_USER_REQUEST })
    yield delay(1500)
    yield put(push(`/users/${payload.user}`))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: COMMENTS_REACTIVATE_FAILURE, error: error.response })
  }
}

function* deactivateCommentSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/comments/' + payload.id + '/deactivate',
      payload
    )
    yield put({ type: COMMENTS_DEACTIVATE_SUCCESS, payload: insert })
    yield put({ type: COMMENTS_GET_BY_USER_REQUEST })
    yield delay(1500)
    yield put(push(`/users/${payload.user}`))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: COMMENTS_DEACTIVATE_FAILURE, error: error.response })
  }
}

export default function* commentsSagas() {
  yield takeLatest(COMMENTS_GET_BY_USER_REQUEST, getAllCommentsByUser)
  yield takeLatest(COMMENTS_GET_BY_USER_FAILURE, manageError)
  yield takeLatest(COMMENTS_GET_ONE_REQUEST, getOneCommentSaga)
  yield takeLatest(COMMENTS_GET_ONE_FAILURE, manageError)

  yield takeLatest(COMMENTS_REACTIVATE_FAILURE, manageError)
  yield takeLatest(COMMENTS_REACTIVATE_REQUEST, reactivateCommentSaga)
  yield takeLatest(COMMENTS_DEACTIVATE_FAILURE, manageError)
  yield takeLatest(COMMENTS_DEACTIVATE_REQUEST, deactivateCommentSaga)
}
