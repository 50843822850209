import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  climbDeactivateRequest,
  climbGetOneRequest,
  climbReactivateRequest
} from './climb.redux'
import LoadingBar from 'react-redux-loading-bar'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function ClimbView({ climbGetOneRequest, historicalListRequest, ...props }) {
  const { historical } = props
  const { _id } = props.match.params

  useEffect(() => {
    climbGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [climbGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (climb.active) {
      props.climbDeactivateRequest({ id: _id })
    }
    if (!climb.active) {
      props.climbReactivateRequest({ id: _id })
    }
  }

  const climb = props.one || {}

  return (
    <Layout icon="fas fa-fw fa-cubes" title="CLIMB">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999,
          position: 'fixed'
        }}
      />
      <TableContainer
        title="Empresa"
        subtitle="Información del registro"
        buttonAdd={false}
        view={`/climbs/${_id}/edit`}
        actionButton={true}
        active={climb.active}
        onToggle={handleActiveToggle}
      >
        {!isEmpty(climb) && (
          <Row id="view-climb">
            <Col>
              <ul>
                <ListItem label="Nombre" value={climb.name} />
                <ListItem label="Descripción" value={climb.description} />
                <ListItem label="Activo" value={climb.active} />
                <ListItem label="Creado" value={mdy(climb.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className="overflow-box"
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}
      >
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.climbs.all,
    one: state.climbs.one,
    error: state.climbs.error,
    userLoading: state.climbs.loading,
    filters: state.climbs.filters,
    historical: state.historicals.list
  }
}

const mapDispatchToProps = {
  climbGetOneRequest: climbGetOneRequest,
  climbReactivateRequest: climbReactivateRequest,
  climbDeactivateRequest: climbDeactivateRequest,
  historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ClimbView)
