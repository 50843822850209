import React from 'react'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router-dom'
import FormInput from 'components/FormInput'
import Layout from 'components/Layout'
import FormContainer from 'components/Theme/Container/FormContainer'
import LoadingBar from 'react-redux-loading-bar'
import { contactCreateRequest } from './contact.redux'
import { connect, useDispatch } from 'react-redux'

function ContactForm({ mode, user }) {
  let history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = values => {
    dispatch(contactCreateRequest(values))
  }

  function onCancel() {
    history.goBack()
  }

  let initialValuesData = {
    name: '',
    lastName: '',
    email: '',
    nationality: '',
    contact: '',
    role: '',
    gender: '',
    gta: '',
    stateResidency: ''
  }
  if (mode === 'edit') {
    initialValuesData = {
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      nationality: user.nationality,
      contact: user.contact,
      role: user.role,
      gender: user.gender,
      gta: user.gta,
      stateResidency: user.stateResidency,
      _id: user._id
    }
  }

  return (
    <>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <Layout icon="fas fa-fw fa-industry" title="Empresas">
        <FormContainer
          label="Agregar"
          button="add"
          onClick={handleSubmit}
          onCancel={onCancel}
        >
          <Formik
            enableReinitialize={true}
            initialValues={initialValuesData}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                if (mode === 'create') {
                  setSubmitting(false)
                }
                if (mode === 'edit') {
                  setSubmitting(false)
                }
              }, 600)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row gutters">
                  <FormInput id="name" label="Nombre" />
                  <FormInput id="email" label="Email" />
                  <FormInput id="message" label="Mensaje" />
                </div>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.contacts.all,
    one: state.contacts.one,
    error: state.contacts.error,
    userLoading: state.contacts.loading,
    filters: state.contacts.filters
  }
}

export default connect(mapStateToProps, contactCreateRequest)(ContactForm)
