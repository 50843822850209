import React from 'react'

export function Subtitle({ children, className = '' }) {
	return (
		<div className="row mx-3">
			<div className="col-12">
				<h5 className={`m-0 font-weight-bold text-primary-tb d-inline ${className}`}>{children}</h5>
			</div>
		</div>
	);
}