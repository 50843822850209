import ClimbList from './ClimbList'
import ClimbView from './ClimbView'
import ClimbCreate from './ClimbCreate'
import ClimbEdit from './ClimbEdit'

const routes = [
  { path: '/climbs', component: ClimbList },
  { path: '/climbs/create', component: ClimbCreate },
  { path: '/climbs/:_id', component: ClimbView },
  { path: '/climbs/:id/edit', component: ClimbEdit }
]

export default routes
