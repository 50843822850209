import React, { useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import { objectiveCreateRequest, objectiveEditRequest } from './../Objective/objective.redux'
import FormInput from './../../../FormInput'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { showWarningMessage } from '../../../Theme/Alerts/exceptionUtils'

export const ObjectiveForm = ({ mode, objective, loading, ...props }) => {
  const handleCreateObjective = values => {
    let formData = new FormData()
    formData.append('file', values.logoFile)
    props.objectiveCreateRequest({ values })
  }
  const handleEditObjective = values => {
    props.objectiveEditRequest({ values })
  }
  const selectFile = (event, values) => {
    values.logoFile = event.target.files[0]
  }

  let initialValues = {
    title: '',
    objective: '',
    color: '',
    logo: '',
    logoFile: {}
  }
  if (mode === 'edit') {
    initialValues = {
      _id: objective._id,
      title: objective.title,
      objective: objective.objective,
      color: objective.color,
      logo: '',
      logoFile: {}
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          if (mode === 'create') {
            const requiredFields = ['title', 'objective', 'logo', 'color']
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.title.length >= 1 && values.title.length < 5) {
              errors.title = 'Debe ser mayor de 5 caracteres'
            }
            if (values.title.length > 50) {
              errors.title = 'Debe ser menor de 50 caracteres'
            }
            if (values.objective.length >= 1 && values.objective.length < 5) {
              errors.objective = 'Debe ser mayor de 5 caracteres'
            }
            if (values.objective.length > 50) {
              errors.objective = 'Debe ser menor de 50 caracteres'
            }
          } else {
            const requiredFields = ['title', 'objective', 'color']
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.title.length >= 1 && values.title.length < 5) {
              errors.title = 'Debe ser mayor de 5 caracteres'
            }
            if (values.title.length > 50) {
              errors.title = 'Debe ser menor de 50 caracteres'
            }
            if (values.objective.length >= 1 && values.objective.length < 5) {
              errors.objective = 'Debe ser mayor de 5 caracteres'
            }
            if (values.objective.length > 50) {
              errors.objective = 'Debe ser menor de 50 caracteres'
            }
          }
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          if (!values.logoFile.name && mode === 'create') {
            showWarningMessage(
              'Aviso',
              'Ingrese una imagen del Eje de Trabajo antes de guardar.'
            )
            setSubmitting(false)
            return
          }
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateObjective(values)
            }
            if (mode === 'edit') {
              handleEditObjective(values)
            }
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, handleSubmit, isSubmitting, handleChange }) => (
          <Form enctype='multipart/form-data' className='form ' onSubmit={handleSubmit}>
            <Row>
              <Col lg='6'>
                <FormInput id='title' label={'Titulo'} value={values.title} />
              </Col>
              <Col lg='6'>
                <FormInput
                  type='color'
                  id='color'
                  label={'Color de ODS'}
                  value={values.color}
                />
              </Col>
              <Col lg='12'>
                <FormInput
                  type='textarea'
                  id='objective'
                  label={'Descripción'}
                  value={values.objective}
                />
              </Col>
              <Col lg='12'>
                <div>
                  {mode === 'edit' && (
                    <img
                      src={objective.logo}
                      style={{ background: '#184f7a' }}
                      height={'80px'}
                      alt='logo'
                    />
                  )}
                  <div className='form-group'>
                    <label className='form-label auth__label'>Logo</label>
                    <Field
                      className='auth__input form-control'
                      type='file'
                      id='logo'
                      label='Logo'
                      value={values.logo}
                      onChange={e => {
                        selectFile(e, values)
                        handleChange(e)
                      }}
                    />
                  </div>
                  <ErrorMessage
                    name='logo'
                    component='div'
                    className='animate__animated animate__fadeIn animate__faster error-message'
                  />
                </div>
              </Col>
            </Row>
            <Button color='primary' type='submit' disabled={isSubmitting}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.objective.all,
    one: state.objective.one,
    error: state.objective.error,
    filters: state.objective.filters
  }
}

const mapDispatchToProps = {
  objectiveCreateRequest: objectiveCreateRequest,
  objectiveEditRequest: objectiveEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectiveForm)
