import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import {
  formCardGetOneRequest,
  formCardDeactivateRequest,
  formCardReactivateRequest,
  formCardGetCommentsDetailRequest
} from './formCard.redux'
import CardDetailFront from 'components/Modules/Cards/CardDetailFront.js'
import CardDetailBack from 'components/Modules/Cards/CardDetailBack.js'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import { FormCardComments } from './FormCardComments'
import { FormCardPriorizationDetail } from './FormCardPriorizationDetail'
import FormCardContentDetail from './FormCardContentDetail'
import FormCardDetailEstat from './FormCardDetailEstat'
import { priorizationGetDetailRequest } from 'components/Modules/Priorization/priorization.redux'
import { useParams } from 'react-router'

const FormCardDetail = (
  historicalListRequest,
  formCardGetOneRequest,
  formCardGetCommentsDetailRequest,
  priorizationGetDetailRequest,
  ...props
) => {
  const { card } = props
  const date = useParams()
  const params = date._id.split('&')
  const _id = params[0]
  const before = params[1]
  useEffect(() => {
    historicalListRequest({ _id })
    formCardGetOneRequest({ _id })
    formCardGetCommentsDetailRequest({ flashcard: _id }, { before })
    priorizationGetDetailRequest({ flashcard: card?._id })
  }, [
    historicalListRequest,
    formCardGetOneRequest,
    priorizationGetDetailRequest,
    formCardGetCommentsDetailRequest,
    _id,
    before,
    card
  ])

  return !card?.name ? (
    ''
  ) : (
    <Layout icon='fas fa-info-circle' title='Detalle de la flashcard'>
      <div className='container'>
        <div className='card'>
          {/* CARDS */}
          <div className='row justify-content-center'>
            <div className='m-b-md' style={{ margin: '35px', paddingLeft: '40px' }}>
              <h2>
                Flashcard #{card?.gta?.nomenclature}
                {card?.number}
              </h2>
            </div>
            <CardDetailFront data={card} />
            <CardDetailBack data={card} />
          </div>
          {/* ESTATUS */}
          <FormCardDetailEstat data={card} messages={props.comments} />
          {/* PRIORIZACIÓN */}
          {props.location?.search === '?cause=priorizationcreate' ? (
            <FormCardContentDetail title='Priorización'>
              <FormCardPriorizationDetail priorization={props.priorizations} />
            </FormCardContentDetail>
          ) : (
            ''
          )}

          {/* COMENTARIOS */}
          <FormCardContentDetail title='Comentarios'>
            <FormCardComments comments={props.comments} />
          </FormCardContentDetail>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.formCards.all,
    one: state.formCards.one,
    error: state.formCards.error,
    userLoading: state.formCards.loading,
    filters: state.formCards.filters,
    historical: state.historicals.list,
    comments: state.formCards.comments,
    card: state.formCards.one,
    priorizations: state.priorizations.all.data
  }
}

const mapDispatchToProps = {
  formCardGetOneRequest: formCardGetOneRequest,
  formCardReactivateRequest: formCardReactivateRequest,
  formCardDeactivateRequest: formCardDeactivateRequest,
  historicalListRequest: historicalListRequest,
  formCardGetCommentsDetailRequest: formCardGetCommentsDetailRequest,
  priorizationGetDetailRequest: priorizationGetDetailRequest
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(FormCardDetail)
