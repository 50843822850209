import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  faUserCog,
  faStar,
  faThumbtack,
  faBriefcase,
  faMountain,
  faBuilding,
  faAddressBook,
  faFilter,
  faSquare,
  faUsers,
  faBezierCurve,
  faNetworkWired,
  faUser
} from '@fortawesome/free-solid-svg-icons'

export const iconAdmin = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size="3x" icon={faUserCog} />
)
export const iconArea = (
  <FontAwesomeIcon
    style={{ marginLeft: '-21px' }}
    size="3x"
    icon={faThumbtack}
  />
)
export const iconBusiness = (
  <FontAwesomeIcon
    style={{ marginLeft: '-21px' }}
    size="3x"
    icon={faBriefcase}
  />
)
export const iconClimb = (
  <FontAwesomeIcon
    style={{ marginLeft: '-21px' }}
    size="3x"
    icon={faMountain}
  />
)
export const iconCompany = (
  <FontAwesomeIcon
    style={{ marginLeft: '-21px' }}
    size="3x"
    icon={faBuilding}
  />
)
export const iconContact = (
  <FontAwesomeIcon
    style={{ marginLeft: '-21px' }}
    size="3x"
    icon={faAddressBook}
  />
)
export const iconFilter = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size="3x" icon={faFilter} />
)
export const iconGta = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size="3x" icon={faUsers} />
)
export const iconSector = (
  <FontAwesomeIcon
    style={{ marginLeft: '-21px' }}
    size="3x"
    icon={faBezierCurve}
  />
)
export const iconWorkAxis = (
  <FontAwesomeIcon
    style={{ marginLeft: '-21px' }}
    size="3x"
    icon={faNetworkWired}
  />
)
export const iconUser = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size="3x" icon={faUser} />
)
export const iconFlashcard = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size="3x" icon={faSquare} />
)
export const iconStar = (
  <FontAwesomeIcon style={{ marginLeft: '-24px' }} size="3x" icon={faStar} />
)

export const moduleData = {
  admin: {
    color: '#386496',
    icon: iconAdmin
  },
  area: {
    color: '#828282',
    icon: iconArea
  },
  'business-type': {
    color: '#18b576',
    icon: iconBusiness
  },
  climb: {
    color: '#b2cc3d',
    icon: iconClimb
  },
  company: {
    color: '#2309b8',
    icon: iconCompany
  },
  contact: {
    color: '#e8e8e8',
    icon: iconContact
  },
  filter: {
    color: '#000000',
    icon: iconFilter
  },
  flashcard: {
    color: '#D84847',
    icon: iconFlashcard
  },
  gta: {
    color: '#674476',
    icon: iconGta
  },
  priorization: {
    color: '#E39C3B',
    icon: iconStar
  },
  sector: {
    color: 'blue',
    icon: iconSector
  },
  workAxis: {
    color: '#A68467',
    icon: iconWorkAxis
  },
  user: {
    color: '#449E52',
    icon: iconUser
  }
}
