import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { browserName } from 'react-device-detect'

const CheckBrowser = ({ handlerCheckBrowser, browsers }) => {
  const [validate, setValidate] = useState(true)
  useEffect(() => {
    if (![...browsers].includes(browserName)) {
      setValidate(false)
      handlerCheckBrowser(validate)
    }
  }, [browserName])
  return <></>
}

CheckBrowser.propTypes = {
  handlerCheckBrowser: PropTypes.func.isRequired,
  browsers: PropTypes.arrayOf(PropTypes.string).isRequired
}

CheckBrowser.defaultProps = {
  handlerCheckBrowser: noValidate => {
    {
      noValidate &&
        alert(
          'Algunas funcionalidades pueden no estár disponibles si usa el navegador actual. Se recomienda utilizar la versión más reciente del navegador Chrome, Firefox o Safari.'
        )
    }
  },
  browsers: ['Chrome', 'Firefox', 'Safari']
}

export default CheckBrowser
