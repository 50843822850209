import axios from 'axios'
import {
  showErrorMessage,
  showWarningMessage
} from 'components/Theme/Alerts/exceptionUtils'
import Swal from 'sweetalert2'


// Interceptamos todos los códigos 400 en llamadas de la API
const request = axios
request.interceptors.response.use(
  response => {
    if (response.status === 201) {
      response.data.message.error
        ? showWarningMessage(
            `${response.data.message?.success}`,
            response.data.message?.error
          )
        : response.data.message?.success &&
          Swal.fire(
            'Registro guardado',
            response.data.message.success,
            'success'
          )
    }
    if (response.status === 202) {
      response.data.message.error
        ? Swal.fire(
            `${response.data.message?.success}`,
            response.data.message?.error,
            'warning'
          )
        : response.data.message?.success &&
          Swal.fire(
            'Registro actualizado',
            response.data.message.success,
            'success'
          )
    }
    return response
  },
  error => {
    const { response } = error
    const { data, status } = response
    const { message, code, data: errors } = data
    // Error de validación en el backend
    code === 400 && showErrorMessage(message, errors)
    code === 401 && showErrorMessage(message, errors)

    // Sin conexión a la API
    status === 500 &&
      showErrorMessage(
        'Sin conexión con el servidor',
        'Servicio no disponible por el momento.'
      )
    status === 504 &&
      showErrorMessage(
        'Sin conexión con el servidor',
        'Por favor verifique que tiene conexión a internet, y vuelva a intentarlo.'
      )
    return Promise.reject(error)
  }
)
