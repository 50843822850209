import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

function ReportPdf({ label, className }) {
  return (
    <Button
      className={className || 'btn btn-dark float-right'}>
      <i className="mr-1 fas fa-file-pdf" /> {label || 'Descargar PDF'}
    </Button>
  )
}

ReportPdf.propTypes = {
  label: PropTypes.string,
  reportPdfUrl: PropTypes.string,
  className: PropTypes.string
}

export default ReportPdf
