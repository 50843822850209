import React from 'react'
import { Formik, Form } from 'formik'
import FormInput from 'components/FormInput'
import CardBig from 'components/Theme/Cards/CardBig'
import { TimeInitiativeValidationSchema } from './TimeInitiativeValidation'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  timeInitiativeCreateRequest,
  timeInitiativeEditRequest
} from './timeInitiatives.redux'
import { connect } from 'react-redux'

function TimeInitiativeForm(props) {
  const { title, mode, timeInitiative, loading } = props

  let initialValuesData = {
    name: '',
    description: ''
  }
  if (mode === 'edit') {
    initialValuesData = {
      name: timeInitiative.name,
      description: timeInitiative.description,
      active: timeInitiative.active,
      _id: timeInitiative._id
    }
  }

  const submit = values => {
    props.timeInitiativeCreateRequest({ values })
  }

  const editTimeInitiative = values => {
    props.timeInitiativeEditRequest({ values })
  }

  return (
    <CardBig title={title}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        validationSchema={TimeInitiativeValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              submit(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              editTimeInitiative(values)
              setSubmitting(false)
            }
          }, 600)
        }}>
        {({ values }) => (
          <Form className='form'>
            <div className='row gutters'>
              <FormInput id='name' label='Nombre' value={values.name} />
              <FormInput
                id='description'
                type='texttimeInitiative'
                label='Descripción'
                value={values.description}
              />
            </div>
            <Button color='primary' type='submit' disabled={loading}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </CardBig>
  )
}

const mapStateToProps = ({ timeInitiatives }) => ({ timeInitiatives })
const mapDispatchToProps = {
  timeInitiativeCreateRequest,
  timeInitiativeEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeInitiativeForm)
