import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ListItem } from 'components/Theme/Container/ListItem'
import { isEmpty } from 'utils/crud'
import Layout from 'components/Layout'
import TableContainer from 'components/Theme/Container/TableContainer'
import { priorizationGetOneDocRequest } from './priorization.redux'
import { getRating } from './priorization.helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight, faStar } from '@fortawesome/free-solid-svg-icons'
import ImageBorder from 'components/Theme/Images/ImageBorder'
import thumbnail from 'assets/media/profile.png'
import { Link } from 'react-router-dom'
import SplashScreen from 'components/SplashScreen/SplashScreen.js'
import CardDetailFront from '../Cards/CardDetailFront'

function PriorizationView({ loading, priorizationGetOneDocRequest, one, ...props }) {
  useEffect(() => {
    const { _id } = props.match.params
    priorizationGetOneDocRequest({ _id })
  }, [priorizationGetOneDocRequest, props.match.params])

  const icon_Star = <FontAwesomeIcon color={'#ffe200'} icon={faStar} />

  const customButton = (
    <Link
      to='/priorization'
      style={{
        marginTop: '0px',
        color: '#131a27',
        fontSize: '17px',
        textAlign: 'right',
        fontWeight: 'normal'
      }}>
      <FontAwesomeIcon
        style={{ marginRight: '2px', fontSize: '20px' }}
        color='#fcc417'
        icon={faArrowAltCircleRight}
      />{' '}
      Ver priorizaciones por usuario
    </Link>
  )

  const priorization = props.oneDoc || {}

  return (
    <Layout icon='fas fa-fw fa-star' title='Priorización'>
      {loading ? (
        <SplashScreen />
      ) : (
        <TableContainer
          title='Priorización'
          subtitle='Información del registro'
          buttonAdd={false}
          customButton={customButton}
          actionButton={false}>
          {!isEmpty(priorization) && (
            <Row id='view-priorización' className='priorization-view'>
              <Col>
                <CardDetailFront data={priorization.flashcard} />
              </Col>
              <Col>
                <h4>Impacto</h4>
                <ListItem
                  label='Cobertura'
                  value={getRating(priorization.coverage, icon_Star)}
                />
                <ListItem
                  label='Impacto Externo'
                  value={getRating(priorization.impact, icon_Star)}
                />
                <ListItem
                  label='Replicabilidad'
                  value={getRating(priorization.replicabilty, icon_Star)}
                />
                <ListItem
                  label='Beneficio Interno'
                  value={getRating(priorization.benefit, icon_Star)}
                />
                <div style={{ marginTop: '20%' }}></div>
                <h4> Facilidad de implementación</h4>
                <ListItem
                  label='Presupuesto'
                  value={getRating(priorization.budget, icon_Star)}
                />
                <ListItem
                  label='Recursos Humanos'
                  value={getRating(priorization.humanResources, icon_Star)}
                />
                <ListItem
                  label='Infraestructura'
                  value={getRating(priorization.infrastructure, icon_Star)}
                />
              </Col>
              <Col>
                <p>Creada por : </p>
                <ImageBorder
                  className='img-fluid'
                  src={
                    priorization.createdBy.thumbnail
                      ? priorization.createdBy.thumbnail
                      : thumbnail
                  }
                />
                <p>{priorization.createdBy.name + ' ' + priorization.createdBy.lastName}</p>
              </Col>
            </Row>
          )}
        </TableContainer>
      )}
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    oneDoc: state.priorizations.oneDoc,
    one: state.priorizations.one,
    loading: state.priorizations.loading
  }
}
const mapDispatchToProps = {
  priorizationGetOneDocRequest: priorizationGetOneDocRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorizationView)
