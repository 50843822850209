import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import { companyCreateRequest } from './company.redux.js'
import CompanyForm from './CompanyForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

function CompanyCreate(props) {
  return (
    <Layout icon='fas fa-fw fa-industry' title='Empresa'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <br />
      <CardBig title={'Agregar'}>
        <CompanyForm mode='create' loading={false} user={undefined} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = ({ companies }) => ({ companies })
const mapDispatchToProps = {
  createCompany: companyCreateRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate)
