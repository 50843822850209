import React, { useEffect } from 'react'
import { columns } from './timeInitiatives.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { connect } from 'react-redux'
import { timeInitiativeGetAllRequest, timeInitiativeGetTotals } from './timeInitiatives.redux'

function TimeInitiativeList({
  timeInitiativeGetAllRequest,
  timeInitiativeGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    timeInitiativeGetAllRequest(filters)
    timeInitiativeGetTotals()
  }, [timeInitiativeGetAllRequest, timeInitiativeGetTotals, filters])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/timeInitiatives/${row._id}`)
    }
  }
  const timeInitiative = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      timeInitiativeGetAllRequest(filters)
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      timeInitiativeGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      timeInitiativeGetAllRequest({ active: false })
    }
  }
  return (
    <Layout icon='far fa-fw fa-clock' title='Duración de iniciativas'>
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title='Duración de iniciativas'
        subtitle='Lista'
        route='/timeInitiatives/create'
        showAddbuton={true}>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={filters}
          onTableChange={timeInitiativeGetAllRequest}
          data={timeInitiative}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.timeInitiatives.all,
    one: state.timeInitiatives.one,
    error: state.timeInitiatives.error,
    userLoading: state.timeInitiatives.loading,
    filters: state.timeInitiatives.filters,
    totals: state.timeInitiatives.totals
  }
}
const mapDispatchToProps = {
  timeInitiativeGetAllRequest: timeInitiativeGetAllRequest,
  timeInitiativeGetTotals: timeInitiativeGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeInitiativeList)
