// Type's
export const WORK_AXIS_GET_ALL_REQUEST = 'WORK_AXIS_GET_ALL_REQUEST'
export const WORK_AXIS_GET_ALL_SUCCESS = 'WORK_AXIS_GET_ALL_SUCCESS'
export const WORK_AXIS_GET_ALL_FAILURE = 'WORK_AXIS_GET_ALL_FAILURE'

export const WORK_AXIS_CREATE_REQUEST = 'WORK_AXIS_CREATE_REQUEST'
export const WORK_AXIS_CREATE_SUCCESS = 'WORK_AXIS_CREATE_SUCCESS'
export const WORK_AXIS_CREATE_FAILURE = 'WORK_AXIS_CREATE_FAILURE'

export const WORK_AXIS_GET_ONE_REQUEST = 'WORK_AXIS_GET_ONE_REQUEST'
export const WORK_AXIS_GET_ONE_SUCCESS = 'WORK_AXIS_GET_ONE_SUCCESS'
export const WORK_AXIS_GET_ONE_FAILURE = 'WORK_AXIS_GET_ONE_FAILURE'

export const WORK_AXIS_EDIT_REQUEST = 'WORK_AXIS_EDIT_REQUEST'
export const WORK_AXIS_EDIT_SUCCESS = 'WORK_AXIS_EDIT_SUCCESS'
export const WORK_AXIS_EDIT_FAILURE = 'WORK_AXIS_EDIT_FAILURE'

export const WORK_AXIS_REMOVE_REQUEST = 'WORK_AXIS_REMOVE_REQUEST'
export const WORK_AXIS_REMOVE_SUCCESS = 'WORK_AXIS_REMOVE_SUCCESS'
export const WORK_AXIS_REMOVE_FAILURE = 'WORK_AXIS_REMOVE_FAILURE'

export const WORK_AXIS_REACTIVATE_REQUEST = 'WORK_AXIS_REACTIVATE_REQUEST'
export const WORK_AXIS_REACTIVATE_SUCCESS = 'WORK_AXIS_REACTIVATE_SUCCESS'
export const WORK_AXIS_REACTIVATE_FAILURE = 'WORK_AXIS_REACTIVATE_FAILURE'

export const WORK_AXIS_FILTER_CHANGE_REQUEST = 'WORK_AXIS_FILTER_CHANGE_REQUEST'
export const WORK_AXIS_FILTER_CHANGE_SUCCESS = 'WORK_AXIS_FILTER_CHANGE_SUCCESS'
export const WORK_AXIS_FILTER_CHANGE_FAILURE = 'WORK_AXIS_FILTER_CHANGE_FAILURE'

export const WORK_AXIS_GET_TOTALS_REQUEST = 'WORK_AXIS_GET_TOTALS_REQUEST'
export const WORK_AXIS_GET_TOTALS_SUCCESS = 'WORK_AXIS_GET_TOTALS_SUCCESS'
export const WORK_AXIS_GET_TOTALS_FAILURE = 'WORK_AXIS_GET_TOTALS_FAILURE'

export const WORK_AXIS_GET_CATALOGS_REQUEST = 'WORK_AXIS_GET_CATALOGS_REQUEST'
export const WORK_AXIS_GET_CATALOGS_SUCCESS = 'WORK_AXIS_GET_CATALOGS_SUCCESS'
export const WORK_AXIS_GET_CATALOGS_FAILURE = 'WORK_AXIS_GET_CATALOGS_FAILURE'

// Action's
export const workAxisGetAllRequest = (filters = {}) => ({
  type: WORK_AXIS_GET_ALL_REQUEST,
  filters
})
export const workAxisGetAllSuccess = (payload) => ({
  type: WORK_AXIS_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: WORK_AXIS_GET_ALL_REQUEST,
  error
})
export const workAxisFiltersChangeRequest = (filters) => ({
  type: WORK_AXIS_FILTER_CHANGE_REQUEST,
  filters
})

export const workAxisCreateRequest = ({ values }) => ({
  type: WORK_AXIS_CREATE_REQUEST,
  payload: values
})
export const workAxisEditRequest = ({ values }) => ({
  type: WORK_AXIS_EDIT_REQUEST,
  payload: values
})
export const workAxisGetOneRequest = (payload) => ({
  type: WORK_AXIS_GET_ONE_REQUEST,
  payload
})
export const workAxisGetOneSuccess = (payload) => ({
  type: WORK_AXIS_GET_ONE_SUCCESS,
  payload
})
export const workAxisGetOneFailure = (payload) => ({
  type: WORK_AXIS_GET_ONE_FAILURE,
  payload
})
export const workAxisDeactivateRequest = (payload) => ({
  type: WORK_AXIS_REMOVE_REQUEST,
  payload
})
export const workAxisReactivateRequest = (payload) => ({
  type: WORK_AXIS_REACTIVATE_REQUEST,
  payload
})
export const workAxisCreateSuccess = (payload) => ({ type: WORK_AXIS_CREATE_SUCCESS, payload })
export const workAxisCreateFailure = (payload) => ({ type: WORK_AXIS_CREATE_FAILURE, payload })
export const workAxisGetTotals = (payload) => ({ type: WORK_AXIS_GET_TOTALS_REQUEST, payload })
export const workAxisGetCatalogs = (payload) => ({
  type: WORK_AXIS_GET_CATALOGS_REQUEST,
  payload
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: {},
  totals: {},
  catalogs: {}
}

export function workAxis(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WORK_AXIS_GET_ALL_REQUEST:
    case WORK_AXIS_CREATE_REQUEST:
    case WORK_AXIS_GET_ONE_REQUEST:
    case WORK_AXIS_EDIT_REQUEST:
    case WORK_AXIS_REMOVE_REQUEST:
    case WORK_AXIS_REACTIVATE_REQUEST:
    case WORK_AXIS_GET_TOTALS_REQUEST:
    case WORK_AXIS_GET_CATALOGS_REQUEST:
      return { ...state, error: null, loading: true }

    case WORK_AXIS_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case WORK_AXIS_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case WORK_AXIS_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case WORK_AXIS_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case WORK_AXIS_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case WORK_AXIS_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case WORK_AXIS_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }
    case WORK_AXIS_GET_CATALOGS_SUCCESS:
      return { ...state, error: null, loading: false, catalogs: { ...action.payload } }

    case WORK_AXIS_GET_ALL_FAILURE:
    case WORK_AXIS_CREATE_FAILURE:
    case WORK_AXIS_GET_ONE_FAILURE:
    case WORK_AXIS_EDIT_FAILURE:
    case WORK_AXIS_REMOVE_FAILURE:
    case WORK_AXIS_REACTIVATE_FAILURE:
    case WORK_AXIS_GET_TOTALS_FAILURE:
    case WORK_AXIS_GET_CATALOGS_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}
