import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sendFlashcardRequest } from 'components/Modules/Cards/cards.redux.js'

function ButtonStatusReview(props) {
  const handleSendRevision = newStatus => {
    props.one.status = newStatus
    props.sendFlashcardRequest(props.one)
  }
  switch (props?.status) {
    case 0:
      return (
        <button
          className='btn btn-outline-info '
          onClick={() => handleSendRevision(1)}
          title='De clic para que un editor de visto bueno a su flashcard'
          style={{
            margin: '-9px 10px 12px 34px'
          }}>
          <i className='fas fa-share-square mr-1' />
          Enviar a revisión
        </button>
      )

    default:
      return <></>
  }
}
ButtonStatusReview.propTypes = {
  status: PropTypes.number,
  functionOnClick: PropTypes.func
}

ButtonStatusReview.defaultProps = {
  functionOnClick: null
}

const mapStateToProps = state => {
  return {
    one: state.formCards.one
  }
}

const mapDispatchToProps = {
  sendFlashcardRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonStatusReview)
