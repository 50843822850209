import React from 'react'

const getSafe = (fn, value = undefined) => {
  try {
    return fn()
  } catch (e) {
    return value
  }
}

function ImageBorder(props) {
  return (
    <img
      {...props}
      alt={props.alt}
      style={{
        borderRadius: '50%',
        padding: '.4rem',
        objectFit: 'cover',
        borderLeft: `${props.border}px solid ${getSafe(() => props.color, 'white')}`,
        ...props.style
      }}
    />
  )
}

ImageBorder.defaultProps = {
  width: 60,
  height: 60,
  className: 'd-block mx-auto',
  border: 5
}

export default ImageBorder
