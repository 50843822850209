import React from 'react'
import StarsRanking from 'components/Pages/StarsRanking'

export const FormCardPriorizationDetail = props => {
  const { priorization } = props
  const key = !priorization?.length ? 0 : 0
  return (
    <div className="feed-activity-list">
      {!priorization ? (
        <span style={{ color: '#797979', fontSize: '20px' }}>
          Sin registros
        </span>
      ) : (
        <div className="wrapper wrapper-content animated fadeInUp">
          <div className="ibox">
            <div
              className="ibox-content"
              style={{ borderWidth: '0px 0', marginBottom: '-65px' }}
            >
              <div className="row" style={{ paddingLeft: '41px' }}>
                <h5>Impacto</h5>
                <div className="col-lg-1"></div>
                <div className="col-lg-4">
                  <dl className="dl-horizontal" style={{ padding: '20px' }}>
                    <dt>Cobertura:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].coverage || 4} />
                    </dd>
                    <dt>Impacto Externo:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].impact || 5} />
                    </dd>
                  </dl>
                </div>
                <div className="col-lg-6" id="cluster_info">
                  <dl className="dl-horizontal" style={{ paddingTop: '20px' }}>
                    <dt>Replicabilidad:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].budget || 5} />
                    </dd>
                    <dt>Beneficio Interno:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].benefit || 4} />
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row" style={{ paddingLeft: '41px' }}>
                <h5>Implementación</h5>
                <div className="col-lg-1"></div>
                <div className="col-lg-4">
                  <dl
                    className="dl-horizontal"
                    style={{ paddingBottom: '20px' }}
                  >
                    <dt>Presupuesto:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].budget || 4} />
                    </dd>
                    <dt>Infraestructura:</dt>
                    <dd>
                      <StarsRanking
                        rank={Math.round(
                          priorization[key].infraestructure || 4
                        )}
                      />
                    </dd>
                  </dl>
                </div>
                <div className="col-lg-6" id="cluster_info">
                  <dl className="dl-horizontal">
                    <dt>Recursos humanos:</dt>
                    <dd>
                      <StarsRanking
                        rank={priorization[key].humanResources || 5}
                      />
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row" style={{ paddingLeft: '41px' }}>
                <h5>Totales</h5>
                <div className="col-lg-1"></div>
                <div className="col-lg-4">
                  <dl className="dl-horizontal">
                    <dt>Total Impacto:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].budget || 5} />
                    </dd>
                    <dt>Total:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].budget || 5} />
                    </dd>
                  </dl>
                </div>
                <div className="col-lg-6" id="cluster_info">
                  <dl className="dl-horizontal">
                    <dt>Total Implementación:</dt>
                    <dd>
                      <StarsRanking rank={priorization[key].budget || 5} />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

// @ts-ignore
export default FormCardPriorizationDetail
