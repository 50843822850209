import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import CompanyForm from './CompanyForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

export const CompanyEdit = ({ one }) => {
  return (
    <Layout icon="fas fa-fw fa-industry" title="Empresa">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />{' '}
      <CardBig title={'Editar'}>
        <CompanyForm mode="edit" company={one} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.companies.one
  }
}

export default connect(mapStateToProps)(CompanyEdit)
