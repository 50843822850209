import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect, useDispatch } from 'react-redux'
import { contactGetOneRequest } from './contact.redux'

function ContactView(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    const { _id } = props.match.params
    dispatch(contactGetOneRequest({ _id }))
  }, [dispatch, props.match.params])

  const contact = props.one || {}

  return (
    <Layout icon="fas fa-fw fa-industry" title="Contactos">
      <TableContainer
        title="Contacto"
        subtitle="Información del registro"
        buttonAdd={false}
        actionButton={false}
      >
        {!isEmpty(contact) && (
          <Row id="view-contact">
            <Col>
              <ul>
                <ListItem label="Nombre" value={contact.name} />
                <ListItem label="Email" value={contact.email} />
                <ListItem label="Mensaje" value={contact.message} />
                <ListItem label="Creado" value={mdy(contact.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.contacts.all,
    one: state.contacts.one,
    error: state.contacts.error,
    userLoading: state.contacts.loading,
    filters: state.contacts.filters
  }
}

export default connect(mapStateToProps, contactGetOneRequest)(ContactView)
