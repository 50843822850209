import React from 'react'

export const generateActiveIcon = value => {
  const icon = value
    ? 'fas fa-check-circle d-flex justify-content-center'
    : 'fas fa-exclamation-circle d-flex justify-content-center'
  const color = value ? '#14A352' : '#f0413E'
  return (
    <i
      className={`${icon} d-flex justify-content-center`}
      style={{ fontSize: '1.35rem', color }}
    />
  )
}

export const generateDocumentIcon = value => {
  return value ? (
    <a href={value} onClick='event.stopPropagation()' target='_blank'>
      <i
        className={`fas fa-file-pdf d-flex justify-content-center d-flex justify-content-center`}
        style={{ fontSize: '1.35rem', color: '#376496', marginRight: '47px' }}
      />
    </a>
  ) : (
    ''
  )
}

export const total = value => value.length

export const generateAmbit = value =>
  value === 0 ? 'En el interior de la empresa' : 'Dentro del territorio'

export const generateStatusIcon = value => {
  let icon = ''
  let color = ''
  let title = ''

  switch (value) {
    case 0:
      icon = 'fas fa-asterisk d-flex justify-content-center'
      color = '#ff9800'
      title = 'Nuevo'
      break

    case 1:
      icon = 'fas fa-share-square d-flex justify-content-center'
      color = 'blue'
      title = 'Enviado'
      break

    case 2:
      icon = 'fas fa-thumbs-up d-flex justify-content-center'
      color = '#14a352'
      title = 'Aprobado'
      break

    case 3:
      icon = 'fas fa-times-circle d-flex justify-content-center'
      color = 'red'
      title = 'Rechazado'
      break

    default:
      icon = 'fas fa-check-circle d-flex justify-content-center'
      color = '#14A352'
      break
  }
  return (
    <div title={title}>
      <i
        className={`${icon} d-flex justify-content-center`}
        style={{ fontSize: '1.35rem', color }}
        title={title}
      />
    </div>
  )
}
