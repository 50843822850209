import React, { useEffect } from 'react'
import { columns } from './area.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { connect } from 'react-redux'
import { areaGetAllRequest, areaGetTotals } from './area.redux'

function AreaList({ areaGetAllRequest, areaGetTotals, filters, ...props }) {
  useEffect(() => {
    areaGetAllRequest(filters)
    areaGetTotals()
  }, [areaGetAllRequest, areaGetTotals, filters])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/areas/${row._id}`)
    }
  }
  const area = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      areaGetAllRequest(filters)
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      areaGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      areaGetAllRequest({ active: false })
    }
  }
  return (
    <Layout icon="fa fa-fw fa-arrows" title="Áreas Responsables">
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title="Áreas Responsables"
        subtitle="Lista"
        route="/areas/create"
        showAddbuton={true}
      >
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={filters}
          onTableChange={areaGetAllRequest}
          data={area}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.areas.all,
    one: state.areas.one,
    error: state.areas.error,
    userLoading: state.areas.loading,
    filters: state.areas.filters,
    totals: state.areas.totals
  }
}
const mapDispatchToProps = {
  areaGetAllRequest: areaGetAllRequest,
  areaGetTotals: areaGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaList)
