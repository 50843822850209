import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  timeInitiativeDeactivateRequest,
  timeInitiativeGetOneRequest,
  timeInitiativeReactivateRequest
} from './timeInitiatives.redux'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function TimeInitiativeView({ timeInitiativeGetOneRequest, historicalListRequest, ...props }) {
  const { historical } = props
  const { _id } = props.match.params
  const timeInitiative = props.one || {}
  useEffect(() => {
    timeInitiativeGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [timeInitiativeGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (timeInitiative.active) {
      props.timeInitiativeDeactivateRequest({ id: _id })
    }
    if (!timeInitiative.active) {
      props.timeInitiativeReactivateRequest({ id: _id })
    }
  }
  return (
    <Layout icon='far fa-fw fa-clock' title='Duración de la iniciativa'>
      <TableContainer
        title='Duración de la iniciativa'
        subtitle='Información del registro'
        buttonAdd={false}
        view={`/timeInitiatives/${_id}/edit`}
        actionButton={true}
        active={timeInitiative.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(timeInitiative) && (
          <Row id='view-timeInitiative'>
            <Col>
              <ul>
                <ListItem label='Nombre' value={timeInitiative.name} />
                <ListItem label='Descripción' value={timeInitiative.description} />
                <ListItem label='Activo' value={timeInitiative.active} />
                <ListItem label='Creado' value={mdy(timeInitiative.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className='overflow-box'
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}>
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.timeInitiatives.all,
    one: state.timeInitiatives.one,
    error: state.timeInitiatives.error,
    userLoading: state.timeInitiatives.loading,
    filters: state.timeInitiatives.filters,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  timeInitiativeGetOneRequest,
  timeInitiativeDeactivateRequest,
  timeInitiativeReactivateRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(TimeInitiativeView)
