import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  SECTOR_GET_ALL_REQUEST,
  SECTOR_GET_ALL_FAILURE,
  SECTOR_GET_ALL_SUCCESS,
  SECTOR_CREATE_SUCCESS,
  SECTOR_CREATE_FAILURE,
  SECTOR_CREATE_REQUEST,
  SECTOR_GET_ONE_FAILURE,
  SECTOR_GET_ONE_SUCCESS,
  SECTOR_GET_ONE_REQUEST,
  SECTOR_REMOVE_SUCCESS,
  SECTOR_REMOVE_FAILURE,
  SECTOR_REMOVE_REQUEST,
  SECTOR_EDIT_FAILURE,
  SECTOR_EDIT_SUCCESS,
  SECTOR_EDIT_REQUEST,
  SECTOR_FILTER_CHANGE_SUCCESS,
  SECTOR_FILTER_CHANGE_FAILURE,
  SECTOR_FILTER_CHANGE_REQUEST,
  SECTOR_REACTIVATE_SUCCESS,
  SECTOR_REACTIVATE_FAILURE,
  SECTOR_REACTIVATE_REQUEST,
  SECTOR_GET_TOTALS_SUCCESS,
  SECTOR_GET_TOTALS_FAILURE,
  SECTOR_GET_TOTALS_REQUEST
} from './sector.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

function* getAllSectorSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/sectors?${filterString}`)
    yield delay(1000)
    yield put({ type: SECTOR_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: SECTOR_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneSectorSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/sectors/${_id}`)
    yield delay(1000)
    yield put({ type: SECTOR_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: SECTOR_GET_ONE_FAILURE, error: error.response })
  }
}

function* createSectorSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/sectors', payload)
    yield delay(1500)
    yield put({ type: SECTOR_CREATE_SUCCESS, payload: insert })
    yield put(push('/sectors'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: SECTOR_CREATE_FAILURE, error: error.response })
  }
}

function* getSectorFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: SECTOR_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: SECTOR_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: SECTOR_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editSectorSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())

    const historical = { description }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/sectors/' + payload._id, payload)
    yield put({ type: SECTOR_EDIT_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/sectors'))
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: SECTOR_CREATE_FAILURE, error: error.response })
  }
}

function* removeSectorSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(axios.put, '/api/sectors/' + payload.id + '/deactivate', payload)
    yield put({ type: SECTOR_REMOVE_SUCCESS, payload: insert })
    yield put({ type: SECTOR_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/sectors'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: SECTOR_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateSectorSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, '/api/sectors/' + payload.id + '/reactivate', payload)
    yield put({ type: SECTOR_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: SECTOR_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/sectors'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: SECTOR_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/sectors/totals`)
    yield delay(1000)
    yield put({ type: SECTOR_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: SECTOR_GET_TOTALS_FAILURE, error: error.response })
  }
}
export default function* sectorSagas() {
  yield takeLatest(SECTOR_GET_ALL_REQUEST, getAllSectorSaga)
  yield takeLatest(SECTOR_GET_ONE_REQUEST, getOneSectorSaga)
  yield takeLatest(SECTOR_CREATE_REQUEST, createSectorSaga)
  yield takeLatest(SECTOR_EDIT_REQUEST, editSectorSaga)
  yield takeLatest(SECTOR_GET_ALL_FAILURE, manageError)
  yield takeLatest(SECTOR_GET_ONE_FAILURE, manageError)
  yield takeLatest(SECTOR_EDIT_FAILURE, manageError)
  yield takeLatest(SECTOR_CREATE_FAILURE, manageError)
  yield takeLatest(SECTOR_REMOVE_REQUEST, removeSectorSaga)
  yield takeLatest(SECTOR_REMOVE_FAILURE, manageError)
  yield takeLatest(SECTOR_FILTER_CHANGE_REQUEST, getSectorFiltersSaga)
  yield takeLatest(SECTOR_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(SECTOR_REACTIVATE_REQUEST, reactivateSectorSaga)
  yield takeLatest(SECTOR_REACTIVATE_FAILURE, manageError)
  yield takeLatest(SECTOR_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(SECTOR_GET_TOTALS_FAILURE, manageError)
}
