import BusinessJoinTypeList from './BusinessJoinTypeList'
import BusinessJoinTypeView from './BusinessJoinTypeView'
import BusinessJoinTypeCreate from './BusinessJoinTypeCreate'
import BusinessJoinTypeEdit from './BusinessJoinTypeEdit'

const routes = [
  { path: '/business-join-types', component: BusinessJoinTypeList },
  { path: '/business-join-types/create', component: BusinessJoinTypeCreate },
  { path: '/business-join-types/:_id', component: BusinessJoinTypeView },
  { path: '/business-join-types/:_id/edit', component: BusinessJoinTypeEdit }
]

export default routes
