// Type's
export const AREA_GET_ALL_REQUEST = 'AREA_GET_ALL_REQUEST'
export const AREA_GET_ALL_SUCCESS = 'AREA_GET_ALL_SUCCESS'
export const AREA_GET_ALL_FAILURE = 'AREA_GET_ALL_FAILURE'

export const AREA_CREATE_REQUEST = 'AREA_CREATE_REQUEST'
export const AREA_CREATE_SUCCESS = 'AREA_CREATE_SUCCESS'
export const AREA_CREATE_FAILURE = 'AREA_CREATE_FAILURE'

export const AREA_GET_ONE_REQUEST = 'AREA_GET_ONE_REQUEST'
export const AREA_GET_ONE_SUCCESS = 'AREA_GET_ONE_SUCCESS'
export const AREA_GET_ONE_FAILURE = 'AREA_GET_ONE_FAILURE'

export const AREA_EDIT_REQUEST = 'AREA_EDIT_REQUEST'
export const AREA_EDIT_SUCCESS = 'AREA_EDIT_SUCCESS'
export const AREA_EDIT_FAILURE = 'AREA_EDIT_FAILURE'

export const AREA_REMOVE_REQUEST = 'AREA_REMOVE_REQUEST'
export const AREA_REMOVE_SUCCESS = 'AREA_REMOVE_SUCCESS'
export const AREA_REMOVE_FAILURE = 'AREA_REMOVE_FAILURE'

export const AREA_REACTIVATE_REQUEST = 'AREA_REACTIVATE_REQUEST'
export const AREA_REACTIVATE_SUCCESS = 'AREA_REACTIVATE_SUCCESS'
export const AREA_REACTIVATE_FAILURE = 'AREA_REACTIVATE_FAILURE'

export const AREA_FILTER_CHANGE_REQUEST = 'AREA_FILTER_CHANGE_REQUEST'
export const AREA_FILTER_CHANGE_SUCCESS = 'AREA_FILTER_CHANGE_SUCCESS'
export const AREA_FILTER_CHANGE_FAILURE = 'AREA_FILTER_CHANGE_FAILURE'

export const AREA_GET_TOTALS_REQUEST = 'AREA_GET_TOTALS_REQUEST'
export const AREA_GET_TOTALS_SUCCESS = 'AREA_GET_TOTALS_SUCCESS'
export const AREA_GET_TOTALS_FAILURE = 'AREA_GET_TOTALS_FAILURE'

// Action's
export const areaGetAllRequest = (filters) => ({ type: AREA_GET_ALL_REQUEST, filters })
export const areaGetAllSuccess = (payload) => ({
  type: AREA_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: AREA_GET_ALL_REQUEST,
  error
})
export const areaFiltersChangeRequest = (filters) => ({
  type: AREA_FILTER_CHANGE_REQUEST,
  filters
})
export const areaGetOneRequest = (payload) => ({ type: AREA_GET_ONE_REQUEST, payload })
export const areaGetOneSuccess = (payload) => ({ type: AREA_GET_ONE_SUCCESS, payload })
export const areaGetOneFailure = (payload) => ({ type: AREA_GET_ONE_FAILURE, payload })
export const areaDeactivateRequest = (payload) => ({ type: AREA_REMOVE_REQUEST, payload })
export const areaReactivateRequest = (payload) => ({ type: AREA_REACTIVATE_REQUEST, payload })
export const areaCreateRequest = ({ values }) => ({
  type: AREA_CREATE_REQUEST,
  payload: values
})
export const areaEditRequest = ({ values }) => ({
  type: AREA_EDIT_REQUEST,
  payload: values
})
export const areaCreateSuccess = (payload) => ({ type: AREA_CREATE_SUCCESS, payload })
export const areaCreateFailure = (payload) => ({ type: AREA_CREATE_FAILURE, payload })
export const areaGetTotals = (payload) => ({ type: AREA_GET_TOTALS_REQUEST, payload })

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: {},
  totals: {}
}

export function areas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AREA_GET_TOTALS_REQUEST:
    case AREA_CREATE_REQUEST:
    case AREA_GET_ALL_REQUEST:
    case AREA_GET_ONE_REQUEST:
    case AREA_REMOVE_REQUEST:
    case AREA_EDIT_REQUEST:
    case AREA_REACTIVATE_REQUEST:
      return { ...state, error: null, loading: true }

    case AREA_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case AREA_CREATE_SUCCESS:
    case AREA_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case AREA_GET_ONE_SUCCESS:
    case AREA_REMOVE_SUCCESS:
    case AREA_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case AREA_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }

    case AREA_REMOVE_FAILURE:
    case AREA_GET_TOTALS_FAILURE:
    case AREA_GET_ALL_FAILURE:
    case AREA_EDIT_FAILURE:
    case AREA_REACTIVATE_FAILURE:
    case AREA_GET_ONE_FAILURE:
    case AREA_CREATE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
