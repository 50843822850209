import React from 'react'
import CardAmbitList from './CardAmbitList'

function CardDetailFrontSmall(props) {
  const p = { ...props.data }
  const { gta, workAxis, name, area, number, businessType } = p

  return (
    <div className='card-detail-content-front-s' style={{ backgroundColor: gta?.color }}>
      <div className='row flashcard-detail-row-s'>
        <div className='flashcard-detail-front-header-s'>
          <img
            className='flashcard-detail-front-icon-s'
            width='40'
            alt='GTA2030 logo'
            src={gta?.logo ? gta?.logo : require(`./../../../assets/img/spinner.gif`)}
          />
        </div>

        <div className='flashcard-detail-front-title-s'>
          <span className='flashcard-detail-front-gta-s'>Grupo de Trabajo Agenda 2030</span>
          <span className='flashcard-detail-front-gta2-s'>{gta?.title}</span>
        </div>

        <div className='flashcard-detail-front-line'></div>
        {/* -- NOMBRE ------------------------------------------------- */}
        <div
          className='flashcard-detail-front-div-solution'
          style={{
            height: `${businessType === undefined ? 192 : 149 - businessType.length * 15}px`
          }}>
          <div className='flashcard-detail-front-solution-s'>{name}</div>
        </div>
        <div className='flashcard-detail-front-line' />

        {/* -- ÁMBITO DE IMPLEMENTACIÓN ------------------------------- */}
        <div className='flashcard-detail-div-image-eje-s'>
          <div className='flashcard-detail-div-ambito-front-title-s'>
            Ámbito de implementación
          </div>
          <CardAmbitList
            list={businessType}
            cssUl={'flashcard-list-ul-front-s'}
            css={'flashcard-list-li-front-s'}
          />
        </div>
        <div className='flashcard-detail-front-line' />

        {/* -- EJE DE TRABAJO ------------------------------- */}
        <div className='flashcard-detail-front-footer-s'>
          <div className='flashcard-front-div-eje-center-s'>
            <img
              className='flashcard-detail-img-eje-s'
              width='100'
              src={
                workAxis?.position
                  ? require(`assets/img/ejesDeTrabajo/workAxis${workAxis?.position}.svg`)
                  : require(`assets/img/ejesDeTrabajo/workAxis1.svg`)
              }
              alt='google button'
            />
            <div className='flashcard-detail-footer-front-grupo-s'>{workAxis?.objective}</div>
          </div>
        </div>
        <div className='flashcard-detail-front-line' />
        <div className='flashcard-detail-front-footer'>
          <span className='flashcard-detail-front-footer-left-s'>FLASHCARDS CLIMB</span>
          <span className='flashcard-detail-front-footer-number-right-s'>#{number}</span>
        </div>
      </div>
    </div>
  )
}

export default CardDetailFrontSmall
