import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import FormRatings from 'form-ratings'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  priorizationCreateRequest,
  priorizationEditRequest,
  priorizationGetOneRequest
} from './priorization.redux'
import { Button, ModalFooter, Row } from 'reactstrap'
import LoadingBar from 'react-redux-loading-bar'
import { isEmpty } from 'utils/crud'
import StarsRanking from 'components/Pages/StarsRanking'

function PriorizationEditRating({
  color,
  one,
  loading,
  flashcards,
  priorization,
  priorizationGetOneRequest,
  ...props
}) {
  const handleCreatePriorization = values => {
    props.priorizationCreateRequest({ values })
  }
  const [hover, setHover] = useState(false)
  useEffect(() => {
    priorizationGetOneRequest({ priorization })
  }, [priorizationGetOneRequest, priorization])

  let initialValues = {
    flashcard: flashcards,
    coverage: 0,
    impact: 0,
    budget: 0,
    benefit: 0,
    humanResources: 0,
    replicabilty: 0,
    infrastructure: 0
  }
  if (one) {
    initialValues = {
      flashcard: flashcards,
      coverage: one.coverage,
      impact: one.impact,
      budget: one.budget,
      benefit: one.benefit,
      humanResources: one.humanResources,
      replicabilty: one.replicabilty,
      infrastructure: one.infrastructure
    }
  }

  // Funcion para verificar si un campo es nulo, vacio o undefined.
  const handleIsRequired = value => isEmpty(value) && 'Campo Requerido'

  const customButton = one._id ? (
    <Row style={{ marginBottom: '45px' }}>
      <Link
        to={`/priorization/${one.flashcard?._id}`}
        style={{
          marginTop: '0px',
          color: '#131a27',
          fontSize: '17px',
          textAlign: 'right',
          fontWeight: 'normal'
        }}>
        <FontAwesomeIcon
          style={{ marginRight: '2px', fontSize: '20px' }}
          color='#fcc417'
          icon={faArrowAltCircleRight}
        />{' '}
        Ver priorizaciones por usuario
      </Link>
    </Row>
  ) : null

  return (
    <>
      {loading ? (
        <LoadingBar
          showFastActions
          maxProgress={100}
          progressIncrease={50}
          style={{
            backgroundColor: '#7a9ec6',
            height: '5px',
            top: 0,
            left: 0,
            zIndex: 999999,
            position: 'fixed'
          }}
        />
      ) : null}
      {customButton}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleCreatePriorization(values)
            setSubmitting(false)
          }, 600)
        }}>
        {({ values, handleSubmit, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <div className='priorization_form-rating'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                <div>
                  <p style={{ fontWeight: 'bolder' }} className='priorization_title-rating'>
                    Impacto
                  </p>
                </div>
                <div className='priorization_form-section'>
                  <p>Cobertura</p>
                  <div className='icon_stars-priorization'>
                    {!one._id ? (
                      <Field
                        name='coverage'
                        as={FormRatings}
                        value={values.coverage}
                        validate={handleIsRequired}
                      />
                    ) : (
                      <StarsRanking rank={values.coverage} />
                    )}
                    {errors.coverage && touched.coverage && (
                      <div style={{ color: 'red' }}>{errors.coverage}</div>
                    )}
                  </div>
                </div>
                <div className='priorization_form-section'>
                  <p>Impacto Externo</p>
                  <div className='icon_stars-priorization'>
                    {!one._id ? (
                      <Field
                        name='impact'
                        as={FormRatings}
                        value={values.impact}
                        validate={handleIsRequired}
                      />
                    ) : (
                      <StarsRanking rank={values.impact} />
                    )}
                    {errors.impact && touched.impact && (
                      <div style={{ color: 'red' }}>{errors.impact}</div>
                    )}
                  </div>
                </div>
                <hr style={{ width: '525px', height: '2px', marginTop: '13px' }} />
                <div>
                  <p
                    style={{ fontWeight: 'bolder' }}
                    className='priorization_title-rating priorization_rating-impl'>
                    Facilidad de implementación
                  </p>
                </div>
                <div className='priorization_form-section'>
                  <p>Presupuesto</p>
                  <div className='icon_stars-priorization'>
                    {!one._id ? (
                      <Field
                        name='budget'
                        as={FormRatings}
                        value={values.budget}
                        validate={handleIsRequired}
                      />
                    ) : (
                      <StarsRanking rank={values.budget} />
                    )}
                    {errors.budget && touched.budget && (
                      <div style={{ color: 'red' }}>{errors.budget}</div>
                    )}
                  </div>
                </div>
                <div className='priorization_form-section'>
                  <p>Infraestructura</p>
                  <div className='icon_stars-priorization'>
                    {!one._id ? (
                      <Field
                        name='infrastructure'
                        as={FormRatings}
                        value={values.infrastructure}
                        validate={handleIsRequired}
                      />
                    ) : (
                      <StarsRanking rank={values.infrastructure} />
                    )}
                    {errors.infrastructure && touched.infrastructure && (
                      <div style={{ color: 'red' }}>{errors.infrastructure}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                <div>
                  <p>
                    <br />
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: one?._id ? '30px' : '34px'
                  }}>
                  <p>Replicabilidad</p>
                  <div className='icon_stars-priorization'>
                    {!one._id ? (
                      <Field
                        name='replicabilty'
                        as={FormRatings}
                        value={values.replicabilty}
                        validate={handleIsRequired}
                      />
                    ) : (
                      <StarsRanking rank={values.replicabilty} />
                    )}
                    {errors.replicabilty && touched.replicabilty && (
                      <div style={{ color: 'red' }}>{errors.replicabilty}</div>
                    )}
                  </div>
                </div>
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '0px'
                  }}>
                  <p>Beneficio Interno</p>
                  <div className='icon_stars-priorization'>
                    {!one._id ? (
                      <Field
                        name='benefit'
                        as={FormRatings}
                        value={values.benefit}
                        validate={handleIsRequired}
                      />
                    ) : (
                      <StarsRanking rank={values.benefit} />
                    )}
                    {errors.benefit && touched.benefit && (
                      <div style={{ color: 'red' }}>{errors.benefit}</div>
                    )}
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      color: '#131a27',
                      fontSize: '18px',
                      fontWeight: 500,
                      marginTop: '40px'
                    }}>
                    <br />
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '60px'
                  }}>
                  <p>Recursos Humanos</p>
                  <div className='icon_stars-priorization'>
                    {!one._id ? (
                      <Field
                        name='humanResources'
                        as={FormRatings}
                        value={values.humanResources}
                        validate={handleIsRequired}
                      />
                    ) : (
                      <StarsRanking rank={values.humanResources} />
                    )}
                    {errors.humanResources && touched.humanResources && (
                      <div style={{ color: 'red' }}>{errors.humanResources}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='modal-footer_priorization'>
              <ModalFooter
                style={
                  hover
                    ? { backgroundColor: color, opacity: '0.9' }
                    : { backgroundColor: color }
                }>
                {one._id ? (
                  <Button
                    color={color}
                    size='lg'
                    disabled
                    style={
                      hover
                        ? { color: 'black' }
                        : { color: 'white', backgroundColor: 'transparent' }
                    }
                    type='submit'>
                    Evaluada
                  </Button>
                ) : (
                  <Button
                    color={color}
                    size='lg'
                    onMouseEnter={() => {
                      setHover(true)
                    }}
                    onMouseLeave={() => {
                      setHover(false)
                    }}
                    style={
                      hover
                        ? { color: 'black' }
                        : { color: 'white', backgroundColor: 'transparent' }
                    }
                    type='submit'>
                    Guardar
                  </Button>
                )}
              </ModalFooter>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
const mapStateToProps = state => {
  return {
    all: state.priorizations.all,
    one: state.priorizations.one,
    error: state.priorizations.error,
    list: state.priorizations.list,
    loading: state.priorizations.loading
  }
}

const mapDispatchToProps = {
  priorizationEditRequest: priorizationEditRequest,
  priorizationCreateRequest: priorizationCreateRequest,
  priorizationGetOneRequest: priorizationGetOneRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorizationEditRating)
