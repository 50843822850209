import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getRating } from './priorization.helpers'
import { priorizationAveragesRequest } from './priorization.redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'reactstrap'
import StarsRanking from 'components/Pages/StarsRanking'

const PriorizationGeneralAverage = ({
  idFlashcard,
  priorizationAverages,
  average,
  ...props
}) => {
  const icon_Star = <FontAwesomeIcon color='#ffe200' icon={faStar} />
  useEffect(() => {
    priorizationAverages({ filters: idFlashcard })
  }, [priorizationAverages])
  const { data } = average
  return (
    <div
      style={{
        backgroundColor: 'white',
        paddingTop: '20px',
        marginTop: '-43px',
        marginLeft: '-50px',
        width: '110%',
        marginBottom: '50px'
      }}>
      <div className='priorization_form-rating' style={{ marginLeft: '100px' }}>
        <div className='col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11'>
          <Row>
            <Col>
              <p style={{ fontWeight: 'bolder' }} className='priorization_title-rating'>
                Impacto
              </p>
              <div className='priorization_form-section'>
                <p>Cobertura</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.coverage} />
                </div>
              </div>
              <div className='priorization_form-section'>
                <p>Impacto Externo</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.impact} />
                </div>
              </div>
              <div className='priorization_form-section'>
                <p>Replicabilidad</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.replicabilty} />
                </div>
              </div>
              <div className='priorization_form-section'>
                <p>Beneficio Interno</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.benefit} />
                </div>
              </div>
            </Col>
            <Col>
              <p style={{ fontWeight: 'bolder' }} className='priorization_title-rating'>
                Facilidad de implementación
              </p>
              <div className='priorization_form-section'>
                <p>Presupuesto</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.budget} />
                </div>
              </div>
              <div className='priorization_form-section'>
                <p>Recursos Humanos</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.humanResources} />
                </div>
              </div>
              <div className='priorization_form-section'>
                <p>Infraestructura</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.infrastructure} />
                </div>
              </div>
            </Col>
          </Row>
          <hr style={{ width: '100%', height: '2px', marginTop: '13px' }} />
          <Row>
            <Col>
              <p style={{ fontWeight: 'bolder' }} className='priorization_title-rating'>
                Total
              </p>
              <div className='priorization_form-section'>
                <p style={{ fontWeight: 'bolder' }}>Impacto</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.impactTotal} />
                </div>
              </div>
              <div className='priorization_form-section'>
                <p style={{ fontWeight: 'bolder' }}>Implementación</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.implementationTotal} />
                </div>
              </div>
              <div className='priorization_form-section'>
                <p style={{ fontWeight: 'bolder' }}>Total</p>
                <div className='icon_stars-priorization'>
                  <StarsRanking rank={data?.total} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  average: state.priorizations.average
})

const mapDispatchToProps = {
  priorizationAverages: priorizationAveragesRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorizationGeneralAverage)
