import React, { Fragment } from 'react'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { recoverRequestRequest } from './auth.redux.js'
import FormInput from 'components/FormInput'

function RecoverForm({ loading, recoverRequestRequest }) {
  return (
    <Fragment>
      <h3 className='auth__title'>Recuperar contraseña</h3>
      <p className='recover__description'>
        Ingresa el correo electrónico asociado a la cuenta. <br />
        Te enviaremos las instrucciones para que puedas restablecer tu contraseña.
      </p>
      <Formik
        initialValues={{ email: '' }}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'Requerido'
          if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
            errors.email = 'Correo no válido'
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            recoverRequestRequest(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ handleSubmit, values, handleChange }) => (
          <Form onSubmit={handleSubmit} className='form recoverPwd__form'>
            <div className='row gutters recoverPwd__input'>
              <div className='recoverPwd__icon'>
                <FormInput
                  id='email'
                  type='email'
                  label='Correo electrónico'
                  onChange={handleChange}
                  value={values.email}
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='btn btn-primary btn-block mt-3'
                disabled={loading}>
                {/* {t('sign-up')} */}
                Enviar{' '}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    loading: state.auth.loading
  }
}

const mapDispatchToProps = {
  recoverRequestRequest: recoverRequestRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverForm)
