import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import WorkAxisForm from './WorkAxisForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

export const WorkAxisEdit = ({ one }) => {
  return (
    <Layout icon="fas fa-user-cog" title="Eje de trabajo">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />{' '}
      <CardBig title={'Editar'}>
        <WorkAxisForm mode="edit" workAxis={one} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.workAxis.one
  }
}

export default connect(mapStateToProps)(WorkAxisEdit)
