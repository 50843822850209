import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { mdy } from 'utils/dates'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  goodPracticesGetOneRequest,
  goodPracticesDeactivateRequest,
  goodPracticesReactivateRequest
} from './goodPractices.redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import ChangeStatus from 'components/Theme/Container/ChangeStatus'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'
import Acl from 'components/ACL/Acl'
import { useHistory } from 'react-router'
import DataTable from 'components/Theme/Table/Table'
import { columnsVolunteers } from './goodPractices.helpers'
import { GoogleMap, Marker } from '@react-google-maps/api'
import TableContainer from 'components/Theme/Container/TableContainer'
import { Link } from 'react-router-dom'
import { concatArray } from 'utils/arrays'
import GoodPracticeRowDetail from './GoodPracticeRowDetail'
import styled from 'styled-components'

function GoodPracticeView({
  historicalListRequest,
  goodPracticesGetOneRequest,
  goodPracticesReactivateRequest,
  goodPracticesDeactivateRequest,
  ...props
}) {
  const { historical } = props
  const { _id } = props.match.params
  let history = useHistory()
  useEffect(() => {
    historicalListRequest({ document: _id })
    goodPracticesGetOneRequest({ _id })
  }, [goodPracticesGetOneRequest, historicalListRequest, _id])

  const goodPractices = props.one || {}
  const volunteers = props?.volunteers || []
  const totalInfo =
    volunteers?.length + goodPractices.numberOfVolunteers || 'No hay voluntarios'
  const totalDocs = volunteers?.length

  const fullName = goodPractices.createdBy?.name + ' ' + goodPractices.createdBy?.lastName

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/users/${row._id}`)
    }
  }

  const mapContainerStyle = {
    height: '300px',
    width: '100%'
  }

  const ImgForm = styled.img`
    margin-right: 8px;
    height: 62px;
    width: auto;
    margin-left: 12px;
    @media screen and (max-width: 575px) {
      height: 40px;
    }
  `

  const { businessJoinType, businessJoinSubType, otherAmbit } = goodPractices
  const arrOther = [{ name: otherAmbit }]
  const businessTypes =
    otherAmbit && otherAmbit !== undefined && otherAmbit !== 'undefined'
      ? businessJoinType.concat(arrOther)
      : businessJoinType
  const sizeCompany = businessJoinSubType ? businessJoinSubType[0] : []

  const customButton = (
    <Link
      to={`/goodPractices/${_id}/edit`}
      style={{
        marginTop: '0px',
        color: '#131a27',
        fontSize: '17px',
        textAlign: 'right',
        fontWeight: 'normal'
      }}>
      <FontAwesomeIcon
        style={{ marginRight: '2px', fontSize: '20px' }}
        color='#fcc417'
        icon={faEdit}
      />{' '}
      Editar información de acción
    </Link>
  )
  const handleToggle = () => {
    !!goodPractices?.active
      ? goodPracticesDeactivateRequest({ _id })
      : goodPracticesReactivateRequest({ _id })
  }
  const activeButton = (
    <button
      onClick={handleToggle}
      style={{
        marginTop: '0px',
        color: '#131a27',
        fontSize: '17px',
        textAlign: 'right',
        fontWeight: 'normal',
        border: 'none',
        background: 'none',
        cursor: 'pointer'
      }}>
      <FontAwesomeIcon
        style={{ marginRight: '3px', fontSize: '20px' }}
        color={goodPractices?.active ? 'gray' : 'green'}
        icon={faPowerOff}
      />
      {goodPractices?.active ? 'Desactivar' : 'Activar'}
    </button>
  )
  return (
    <Layout icon='fas fa-fw fa-user-plus' title='Súmate'>
      <TableContainer
        title='Cuestionario'
        subtitle='Información del registro'
        buttonAdd={false}
        customButton={customButton}
        activeButton={activeButton}
        buttonDeactivate={false}>
        <Row id='view-goodPractices'>
          {/* Aprobar */}

          <Col style={{ paddingLeft: '0px', paddingTop: '5px' }}>
            <GoodPracticeRowDetail title='Nombre de la acción' text={goodPractices.name} />
            <GoodPracticeRowDetail
              title='Objetivos de la acción'
              text={goodPractices.objective}
            />
            <GoodPracticeRowDetail
              title='Principal ODS impactado'
              text={goodPractices.ods?.map(i => i.title)}
            />
            <GoodPracticeRowDetail
              title='Principal eje de Pacto Global de las Naciones Unidas'
              text={goodPractices.axisGlobalPact?.map(i => i?.name)}
            />
            <GoodPracticeRowDetail
              title='Descripción de la acción'
              text={goodPractices.description}
            />

            {businessTypes && (
              <GoodPracticeRowDetail
                title='Ámbito de implementación'
                text={concatArray(businessTypes?.map(i => i.name))}
              />
            )}

            <GoodPracticeRowDetail
              title='Ubicación donde se implementó la acción'
              text={goodPractices.ubication}
            />
            {goodPractices.lat && goodPractices.lng && (
              <GoodPracticeRowDetail
                title='Ubicación en el mapa'
                text={
                  <div>
                    <GoogleMap
                      id='searchbox-example'
                      mapContainerStyle={mapContainerStyle}
                      zoom={15}
                      center={{ lat: goodPractices.lat * 1, lng: goodPractices.lng * 1 }}>
                      <Marker
                        position={{ lat: goodPractices.lat * 1, lng: goodPractices.lng * 1 }}
                      />
                    </GoogleMap>
                  </div>
                }
              />
            )}

            <GoodPracticeRowDetail
              title='Nombre de la empresa que implementó la acción'
              text={goodPractices.promoterInstitution}
            />

            {sizeCompany?.name && (
              <GoodPracticeRowDetail
                title='Tamaño de empresa que implementa'
                text={sizeCompany.name}
              />
            )}

            <GoodPracticeRowDetail
              title='Otras organizaciones participantes'
              text={goodPractices.alliedInstitutions}
            />

            <GoodPracticeRowDetail
              title='Fecha de implementación de la acción'
              text={goodPractices.implementationPeriod}
            />
            <GoodPracticeRowDetail
              title='Duración de la iniciativa'
              text={goodPractices?.implementationTime?.name || 'No especificado'}
            />

            <GoodPracticeRowDetail
              title='¿Qué indicador utilizas para medir el impacto de esta acción?'
              text={
                goodPractices?.indicator && goodPractices?.indicator !== 'undefined'
                  ? goodPractices?.indicator
                  : 'No especificado'
              }
            />

            <GoodPracticeRowDetail
              title='¿Dónde se puede encontrar más información sobre esta acción?'
              text={goodPractices.information}
            />

            <GoodPracticeRowDetail
              title='Correo de contacto para compartir con las personas interesadas en esta iniciativa'
              text={goodPractices.email}
            />

            <GoodPracticeRowDetail
              title='¿Tu acción involucra a personas voluntarias?'
              text={
                goodPractices.numberOfVolunteers === '' ||
                goodPractices.numberOfVolunteers === 'undefined' ||
                goodPractices.numberOfVolunteers === undefined ||
                goodPractices.numberOfVolunteers === null ||
                (goodPractices.numberOfVolunteers === 0 &&
                  goodPractices.aceptVolunteers === false)
                  ? 'No'
                  : 'Sí'
              }
            />
            {goodPractices.numberOfVolunteers === '' ||
              goodPractices.numberOfVolunteers === 'undefined' ||
              goodPractices.numberOfVolunteers === undefined ||
              (goodPractices.numberOfVolunteers === null ||
              (goodPractices.numberOfVolunteers === 0 &&
                goodPractices.aceptVolunteers === false) ? (
                <></>
              ) : (
                <GoodPracticeRowDetail
                  title='¿Cuántos voluntarios participaron inicialmente en la acción?'
                  text={goodPractices.numberOfVolunteers}
                />
              ))}
            <GoodPracticeRowDetail
              title='¿Se Quieren sumar voluntarios?'
              text={goodPractices.aceptVolunteers ? 'Sí' : 'No'}
            />
            {goodPractices.aceptVolunteers && (
              <>
                <GoodPracticeRowDetail
                  title='Voluntarios inscritos en la plataforma LOGRA'
                  text={volunteers?.length}
                />
                <GoodPracticeRowDetail
                  title='Total de voluntarios en la acción'
                  text={totalInfo}
                />
              </>
            )}

            {/* ------------------------------------------------- */}
            <GoodPracticeRowDetail
              title='Logotipo que acompaña la buena práctica'
              text={
                goodPractices?.logo ? <ImgForm src={goodPractices?.logo} /> : 'No especificado'
              }
            />

            <GoodPracticeRowDetail
              title='Fotografía que acompaña la buena práctica'
              text={
                goodPractices?.image ? (
                  <ImgForm src={goodPractices?.image} />
                ) : (
                  'No especificado'
                )
              }
            />

            <GoodPracticeRowDetail
              title='¿Quieres que se comparta públicamente tu nombre como parte de esta acción?'
              text={goodPractices.shareName ? 'Sí' : 'No'}
            />

            {goodPractices.status === 2 && (
              <GoodPracticeRowDetail
                title='Enlace'
                text={
                  <a
                    className='btn btn-outline-success'
                    target='_blank'
                    href={`${process.env.REACT_APP_CLIENT_URL}/good-practice/${goodPractices._id}`}
                    style={{ margin: '0px 10px 0px 0px' }}>
                    Abrir iniciativa en sitio público
                  </a>
                }
              />
            )}
          </Col>
          <Col style={{ maxWidth: '310px', paddingLeft: '32px' }}>
            <ListItem label='Actualizado' value={mdy(goodPractices.updatedAt)} />
            <ListItem
              label='Creada por'
              value={
                goodPractices.shareName === false
                  ? `${fullName} - Anónimo en página pública`
                  : fullName
              }
            />

            <ListItem label='Creado' value={mdy(goodPractices.createdAt)} />
            {props.admin?.role === 2 ? (
              ''
            ) : (
              <div style={{ marginLeft: '-30px', marginTop: '5px' }}>
                <Acl action={'approve'} module={'goodPractices'}>
                  <ChangeStatus status={goodPractices.status} />
                </Acl>
              </div>
            )}
          </Col>
        </Row>
      </TableContainer>
      <TableContainer title='Voluntarios' subtitle='Lista de Voluntarios' buttonAdd={false}>
        <DataTable
          {...props}
          limit={10}
          searchBar={false}
          data={volunteers}
          structure={columnsVolunteers}
          totalDocs={totalDocs}
          rowEvents={rowEvents}
        />
      </TableContainer>
      <div
        className='overflow-box'
        style={{
          height: 'auto',
          overflowY: 'inherit',
          marginTop: '50px'
        }}>
        <HistoricalTimeline values={historical?.docs} seeMore={true} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.goodPractices.all,
    one: state.goodPractices.one,
    error: state.goodPractices.error,
    userLoading: state.goodPractices.loading,
    filters: state.goodPractices.filters,
    historical: state.historicals.list,
    admin: state.admin,
    volunteers: state.goodPractices.one.volunteers
  }
}

const mapDispatchToProps = {
  goodPracticesGetOneRequest: goodPracticesGetOneRequest,
  goodPracticesReactivateRequest: goodPracticesReactivateRequest,
  goodPracticesDeactivateRequest: goodPracticesDeactivateRequest,
  historicalListRequest: historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodPracticeView)
