import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import {
  AREA_GET_ALL_REQUEST,
  AREA_GET_ALL_FAILURE,
  AREA_GET_ALL_SUCCESS,
  AREA_CREATE_SUCCESS,
  AREA_CREATE_FAILURE,
  AREA_CREATE_REQUEST,
  AREA_GET_ONE_FAILURE,
  AREA_GET_ONE_SUCCESS,
  AREA_GET_ONE_REQUEST,
  AREA_REMOVE_SUCCESS,
  AREA_REMOVE_FAILURE,
  AREA_REMOVE_REQUEST,
  AREA_EDIT_SUCCESS,
  AREA_EDIT_REQUEST,
  AREA_FILTER_CHANGE_SUCCESS,
  AREA_FILTER_CHANGE_FAILURE,
  AREA_FILTER_CHANGE_REQUEST,
  AREA_REACTIVATE_SUCCESS,
  AREA_REACTIVATE_FAILURE,
  AREA_REACTIVATE_REQUEST,
  AREA_GET_TOTALS_SUCCESS,
  AREA_GET_TOTALS_FAILURE,
  AREA_GET_TOTALS_REQUEST
} from './area.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'
import { push } from 'connected-react-router'

function* getAllAreaSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/areas?${filterString}`)
    yield delay(1000)
    yield put({ type: AREA_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: AREA_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneAreaSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/areas/${_id}`)
    yield delay(1000)
    yield put({ type: AREA_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: AREA_GET_ONE_FAILURE, error: error.response })
  }
}
function* createAreaSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/areas', payload)
    yield delay(1500)
    yield put({ type: AREA_CREATE_SUCCESS, payload: insert })
    yield put(push('/areas'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: AREA_CREATE_FAILURE, error: error.response })
  }
}

function* getAreaFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: AREA_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: AREA_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: AREA_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editAreaSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())

    const historical = { description }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/areas/' + payload._id, payload)
    yield put({ type: AREA_EDIT_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/areas'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: AREA_CREATE_FAILURE, error: error.response })
  }
}

function* removeAreaSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(axios.put, '/api/areas/' + payload.id + '/deactivate', payload)
    yield put({ type: AREA_REMOVE_SUCCESS, payload: insert })
    yield put({ type: AREA_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/areas'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: AREA_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateAreaSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, '/api/areas/' + payload.id + '/reactivate', payload)
    yield put({ type: AREA_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: AREA_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/areas'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: AREA_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/areas/totals`)
    yield delay(1000)
    yield put({ type: AREA_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: AREA_GET_TOTALS_FAILURE, error: error.response })
  }
}
export default function* areaSagas() {
  yield takeLatest(AREA_GET_ALL_REQUEST, getAllAreaSaga)
  yield takeLatest(AREA_GET_ONE_REQUEST, getOneAreaSaga)
  yield takeLatest(AREA_CREATE_REQUEST, createAreaSaga)
  yield takeLatest(AREA_EDIT_REQUEST, editAreaSaga)
  yield takeLatest(AREA_GET_ALL_FAILURE, manageError)
  yield takeLatest(AREA_GET_ONE_FAILURE, manageError)
  yield takeLatest(AREA_REMOVE_REQUEST, removeAreaSaga)
  yield takeLatest(AREA_REMOVE_FAILURE, manageError)
  yield takeLatest(AREA_FILTER_CHANGE_REQUEST, getAreaFiltersSaga)
  yield takeLatest(AREA_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(AREA_REACTIVATE_REQUEST, reactivateAreaSaga)
  yield takeLatest(AREA_REACTIVATE_FAILURE, manageError)
  yield takeLatest(AREA_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(AREA_GET_TOTALS_FAILURE, manageError)
}
