import React from 'react'
import { Formik, Form } from 'formik'
import FormInput from 'components/FormInput'
import CardBig from 'components/Theme/Cards/CardBig'
import { BusinessTypeValidationSchema } from './BusinessTypeValidation'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  businessTypeCreateRequest,
  businessTypeEditRequest
} from './businessType.redux'
import { connect } from 'react-redux'

function BusinessTypeForm(props) {
  const { title, mode, businessType, loading } = props

  let initialValuesData = {
    name: '',
    description: ''
  }
  if (mode === 'edit') {
    initialValuesData = {
      name: businessType.name,
      description: businessType.description,
      _id: businessType._id
    }
  }

  const submit = values => {
    props.businessTypeCreateRequest({ values })
  }

  const editBusinessType = values => {
    props.businessTypeEditRequest({ values })
  }

  return (
    <CardBig title={title}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        validationSchema={BusinessTypeValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              submit(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              editBusinessType(values)
              setSubmitting(false)
            }
          }, 600)
        }}
      >
        {({ values }) => (
          <Form className="form">
            <div className="row gutters">
              <FormInput id="name" label="Nombre" value={values.name} />
              <FormInput
                type="textarea"
                id="description"
                label="Descripción"
                value={values.description}
              />
            </div>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </CardBig>
  )
}

const mapStateToProps = ({ businessTypes }) => ({ businessTypes })
const mapDispatchToProps = {
  businessTypeCreateRequest,
  businessTypeEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypeForm)
