import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import FilterForm from './FilterForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

export const FilterEdit = ({ one }) => {
  return (
    <Layout icon="fas fa-fw fa-ban" title="Filtro">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />{' '}
      <CardBig title={'Editar'}>
        <FilterForm mode="edit" filter={one} loading={false} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.filters.all,
    one: state.filters.one,
    error: state.filters.error,
    loading: state.filters.loading
  }
}

export default connect(mapStateToProps)(FilterEdit)
