import React from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import AuthForm from 'components/Modules/Auth/AuthForm'
import logo from 'assets/img/logos/submarcaAzul.svg'

export const PageLogin = ({ isLogin }) => {
  let history = useHistory()
  isLogin && history.push(`/home`)

  return (
    <div className='container-fluid login-admin-root '>
      <div className='row justify-content-center'>
        <div className='col-xl-5 col-lg-5 col-md-5 login-admin-content'>
          <div className='card o-hidden border-0 shadow-lg my-4'>
            <div className='card-body px-5'>
              <div className='d-flex flex-wrap justify-content-center align-items-center flex-column'>
                <img src={logo} width='250' className='logo' alt='logo' />
              </div>
              <div className='text-center'>
                <AuthForm />
              </div>
              <hr />
              <div className='text-center'>
                <Link className='small' to='/recover'>
                  Olvidé mi contraseña
                </Link>
              </div>
            </div>
          </div>
        </div>

        <small className='text-center text-login mb-2'>{process.env.REACT_APP_VERSION}</small>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLogin: state.auth.isLogin
  }
}

export default connect(mapStateToProps, null)(PageLogin)
