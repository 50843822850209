import React, { useEffect } from 'react'
import { columns } from './axesGlobalPact.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { axesGlobalPactGetAllRequest, axesGlobalPactGetTotals } from './axesGlobalPact.redux'

function AxesGlobalList({
  axesGlobalPactGetAllRequest,
  axesGlobalPactGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    axesGlobalPactGetAllRequest(filters)
    axesGlobalPactGetTotals()
  }, [axesGlobalPactGetAllRequest, axesGlobalPactGetTotals, filters])

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      axesGlobalPactGetAllRequest()
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      axesGlobalPactGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      axesGlobalPactGetAllRequest({ active: false })
    }
  }

  const axesGlobalPact = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/axesGlobalPact/${row._id}`)
    }
  }

  return (
    <Layout icon='fas fa-fw fa-cog' title='Ejes del Pacto Global'>
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title='Ejes del Pacto Global'
        subtitle='Lista'
        route='/axesGlobalPact/create'
        buttonAdd={true}
        showAddbuton={true}>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={filters}
          onTableChange={axesGlobalPactGetAllRequest}
          data={axesGlobalPact}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.axesGlobalPact.all,
    one: state.axesGlobalPact.one,
    error: state.axesGlobalPact.error,
    userLoading: state.axesGlobalPact.loading,
    filters: state.axesGlobalPact.filters,
    totals: state.axesGlobalPact.totals
  }
}

const mapDispatchToProps = {
  axesGlobalPactGetAllRequest: axesGlobalPactGetAllRequest,
  axesGlobalPactGetTotals: axesGlobalPactGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(AxesGlobalList)
