import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import ListItemLoader from 'components/Modules/Cards/CardsItemLoader.js'
import CardsList from 'components/Modules/Cards/CardsList.js'
import { cardsGetAllRequest } from 'components/Modules/Cards/cards.redux.js'

export const PageFlashcard = ({ loader, filters, cardsList, cardsGetAllRequest }) => {
  useEffect(() => {
    cardsGetAllRequest()
  }, [cardsGetAllRequest])
  return (
    <>
      <Layout icon='fas fa-file' title='Flashcards Creadas'>
        {/* Loader para la carga al llegar al final del scroll */}
        {loader && <ListItemLoader />}
        {/* Loading inicial */}
        {<CardsList cardsList={cardsList} filters={filters} />}
      </Layout>
    </>
  )
}

const mapStateToProps = ({ cards }) => ({
  cardsList: cards.cardsList,
  loading: cards.loading,
  error: cards.error,
  one: cards.one,
  filters: cards.filters,
  totals: cards.totals
})

const mapDispatchToProps = {
  cardsGetAllRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PageFlashcard)
