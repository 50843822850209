import React from 'react'
import { mdy } from 'utils/dates'
import { generateActiveIcon } from 'components/Theme/Table/helpers'
import thumbnail from 'assets/media/profile.png'
import ImageBorder from 'components/Theme/Images/ImageBorder'

export const columns = [
  {
    dataField: 'thumbnail',
    style: (cell, row) => ({
      padding: 0
    }),
    formatter: (cell, row) => (
      <ImageBorder src={cell ? cell : thumbnail} alt={row.name} height='70' width='70' />
    )
  },
  {
    dataField: '_id',
    text: 'ID',
    hidden: true
  },
  {
    dataField: 'name',
    text: 'Nombre',
    sort: true
  },
  {
    dataField: 'email',
    text: 'Correo',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'isActive',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]

export const columnsComments = [
  {
    dataField: '_id',
    text: 'id',
    hidden: true
  },
  {
    dataField: 'flashcard',
    text: 'Flashcard',
    sort: true
  },
  {
    dataField: 'comment',
    text: 'Comentario',
    sort: true
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]

export const columnsSavedCards = [
  {
    dataField: '_id',
    text: 'id',
    hidden: true
  },
  {
    dataField: 'number',
    text: 'Número',
    sort: true
  },
  {
    dataField: 'flashcard',
    text: 'Flashcard',
    sort: true
  },
  {
    dataField: 'nomenclature',
    text: 'Nomenclatura',
    sort: true
  },
  {
    dataField: 'gta',
    text: 'GTA2030',
    sort: true
  }
]
