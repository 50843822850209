// Type's
export const BUSINESS_JOIN_TYPE_GET_ALL_REQUEST = 'BUSINESS_JOIN_TYPE_GET_ALL_REQUEST'
export const BUSINESS_JOIN_TYPE_GET_ALL_SUCCESS = 'BUSINESS_JOIN_TYPE_GET_ALL_SUCCESS'
export const BUSINESS_JOIN_TYPE_GET_ALL_FAILURE = 'BUSINESS_JOIN_TYPE_GET_ALL_FAILURE'

export const BUSINESS_JOIN_TYPE_CREATE_REQUEST = 'BUSINESS_JOIN_TYPE_CREATE_REQUEST'
export const BUSINESS_JOIN_TYPE_CREATE_SUCCESS = 'BUSINESS_JOIN_TYPE_CREATE_SUCCESS'
export const BUSINESS_JOIN_TYPE_CREATE_FAILURE = 'BUSINESS_JOIN_TYPE_CREATE_FAILURE'

export const BUSINESS_JOIN_TYPE_GET_ONE_REQUEST = 'BUSINESS_JOIN_TYPE_GET_ONE_REQUEST'
export const BUSINESS_JOIN_TYPE_GET_ONE_SUCCESS = 'BUSINESS_JOIN_TYPE_GET_ONE_SUCCESS'
export const BUSINESS_JOIN_TYPE_GET_ONE_FAILURE = 'BUSINESS_JOIN_TYPE_GET_ONE_FAILURE'

export const BUSINESS_JOIN_TYPE_EDIT_REQUEST = 'BUSINESS_JOIN_TYPE_EDIT_REQUEST'
export const BUSINESS_JOIN_TYPE_EDIT_SUCCESS = 'BUSINESS_JOIN_TYPE_EDIT_SUCCESS'
export const BUSINESS_JOIN_TYPE_EDIT_FAILURE = 'BUSINESS_JOIN_TYPE_EDIT_FAILURE'

export const BUSINESS_JOIN_TYPE_REMOVE_REQUEST = 'BUSINESS_JOIN_TYPE_REMOVE_REQUEST'
export const BUSINESS_JOIN_TYPE_REMOVE_SUCCESS = 'BUSINESS_JOIN_TYPE_REMOVE_SUCCESS'
export const BUSINESS_JOIN_TYPE_REMOVE_FAILURE = 'BUSINESS_JOIN_TYPE_REMOVE_FAILURE'

export const BUSINESS_JOIN_TYPE_REACTIVATE_REQUEST = 'BUSINESS_JOIN_TYPE_REACTIVATE_REQUEST'
export const BUSINESS_JOIN_TYPE_REACTIVATE_SUCCESS = 'BUSINESS_JOIN_TYPE_REACTIVATE_SUCCESS'
export const BUSINESS_JOIN_TYPE_REACTIVATE_FAILURE = 'BUSINESS_JOIN_TYPE_REACTIVATE_FAILURE'

export const BUSINESS_JOIN_TYPE_FILTER_CHANGE_REQUEST = 'BUSINESS_JOIN_TYPE_FILTER_CHANGE_REQUEST'
export const BUSINESS_JOIN_TYPE_FILTER_CHANGE_SUCCESS = 'BUSINESS_JOIN_TYPE_FILTER_CHANGE_SUCCESS'
export const BUSINESS_JOIN_TYPE_FILTER_CHANGE_FAILURE = 'BUSINESS_JOIN_TYPE_FILTER_CHANGE_FAILURE'

export const BUSINESS_JOIN_TYPE_GET_TOTALS_REQUEST = 'BUSINESS_JOIN_TYPE_GET_TOTALS_REQUEST'
export const BUSINESS_JOIN_TYPE_GET_TOTALS_SUCCESS = 'BUSINESS_JOIN_TYPE_GET_TOTALS_SUCCESS'
export const BUSINESS_JOIN_TYPE_GET_TOTALS_FAILURE = 'BUSINESS_JOIN_TYPE_GET_TOTALS_FAILURE'

// Action's
export const businessJoinTypeGetAllRequest = (filters) => ({
  type: BUSINESS_JOIN_TYPE_GET_ALL_REQUEST,
  filters
})
export const businessJoinTypeGetAllSuccess = (payload) => ({
  type: BUSINESS_JOIN_TYPE_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: BUSINESS_JOIN_TYPE_GET_ALL_REQUEST,
  error
})
export const businessJoinTypeFiltersChangeRequest = (filters) => ({
  type: BUSINESS_JOIN_TYPE_FILTER_CHANGE_REQUEST,
  filters
})
export const businessJoinTypeGetOneRequest = (payload) => ({
  type: BUSINESS_JOIN_TYPE_GET_ONE_REQUEST,
  payload
})
export const businessJoinTypeGetOneSuccess = (payload) => ({
  type: BUSINESS_JOIN_TYPE_GET_ONE_SUCCESS,
  payload
})
export const businessJoinTypeGetOneFailure = (payload) => ({
  type: BUSINESS_JOIN_TYPE_GET_ONE_FAILURE,
  payload
})
export const businessJoinTypeDeactivateRequest = (payload) => ({
  type: BUSINESS_JOIN_TYPE_REMOVE_REQUEST,
  payload
})
export const businessJoinTypeReactivateRequest = (payload) => ({
  type: BUSINESS_JOIN_TYPE_REACTIVATE_REQUEST,
  payload
})
export const businessJoinTypeCreateRequest = ({ values }) => ({
  type: BUSINESS_JOIN_TYPE_CREATE_REQUEST,
  payload: values
})
export const businessJoinTypeEditRequest = ({ values }) => ({
  type: BUSINESS_JOIN_TYPE_EDIT_REQUEST,
  payload: values
})
export const businessJoinTypeCreateSuccess = (payload) => ({
  type: BUSINESS_JOIN_TYPE_CREATE_SUCCESS,
  payload
})
export const businessJoinTypeCreateFailure = (payload) => ({
  type: BUSINESS_JOIN_TYPE_CREATE_FAILURE,
  payload
})
export const businessJoinTypeGetTotals = (payload) => ({
  type: BUSINESS_JOIN_TYPE_GET_TOTALS_REQUEST,
  payload
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: {},
  totals: {}
}

export function businessJoinTypes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BUSINESS_JOIN_TYPE_GET_ALL_REQUEST:
    case BUSINESS_JOIN_TYPE_CREATE_REQUEST:
    case BUSINESS_JOIN_TYPE_GET_ONE_REQUEST:
    case BUSINESS_JOIN_TYPE_EDIT_REQUEST:
    case BUSINESS_JOIN_TYPE_REMOVE_REQUEST:
    case BUSINESS_JOIN_TYPE_REACTIVATE_REQUEST:
    case BUSINESS_JOIN_TYPE_GET_TOTALS_REQUEST:
      return { ...state, error: null, loading: true }

    case BUSINESS_JOIN_TYPE_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case BUSINESS_JOIN_TYPE_CREATE_SUCCESS:
    case BUSINESS_JOIN_TYPE_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case BUSINESS_JOIN_TYPE_GET_ONE_SUCCESS:
    case BUSINESS_JOIN_TYPE_REMOVE_SUCCESS:
    case BUSINESS_JOIN_TYPE_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case BUSINESS_JOIN_TYPE_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }

    case BUSINESS_JOIN_TYPE_REACTIVATE_FAILURE:
    case BUSINESS_JOIN_TYPE_REMOVE_FAILURE:
    case BUSINESS_JOIN_TYPE_EDIT_FAILURE:
    case BUSINESS_JOIN_TYPE_GET_ONE_FAILURE:
    case BUSINESS_JOIN_TYPE_CREATE_FAILURE:
    case BUSINESS_JOIN_TYPE_GET_ALL_FAILURE:
    case BUSINESS_JOIN_TYPE_GET_TOTALS_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
