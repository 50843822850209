import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import { formCardCreateRequest } from './formCard.redux'
import FromCardForm from './FormCardForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

function FromCardCreate(props) {
  return (
    <Layout icon="fas fa-question-circle" title="Flashcard Nueva">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <br />
      <CardBig title={'Agregar'}>
        <FromCardForm mode="create" loading={false} user={undefined} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = ({ formcards }) => ({ formcards })
const mapDispatchToProps = {
  createFormCard: formCardCreateRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(FromCardCreate)
