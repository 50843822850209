import TimeInitiativeList from './TimeInitiativeList'
import TimeInitiativeView from './TimeInitiativeView'
import TimeInitiativeCreate from './TimeInitiativeCreate'
import TimeInitiativeEdit from './TimeInitiativeEdit'

const routes = [
  { path: '/timeInitiatives', component: TimeInitiativeList },
  { path: '/timeInitiatives/create', component: TimeInitiativeCreate },
  { path: '/timeInitiatives/:_id', component: TimeInitiativeView },
  { path: '/timeInitiatives/:_id/edit', component: TimeInitiativeEdit }
]

export default routes
