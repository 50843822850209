import React, { useEffect } from 'react'
import { columns } from './notification.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import TableContainer from 'components/Theme/Container/TableContainer'
import {
  notificationsAllRequest,
  notificationsIsReadRequest
} from './notifications.redux'

function NotificationList({
  isLogin,
  notificationsIsReadRequest,
  notificationId,
  notificationsAllRequest,
  filters,
  ...props
}) {
  useEffect(() => {
    if (isLogin) {
      notificationsAllRequest(filters)
    }
  }, [notificationsAllRequest, filters, isLogin])

  const notification = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const data = notification?.map(d => {
    return {
      _id: d._id,
      description: d.description,
      thumbnail: d.createdBy?.thumbnail,
      createdBy: d.createdBy?.name + ' ' + d.createdBy?.lastName,
      createdAt: d.createdAt,
      updatedAt: d.updatedAt,
      document: d.document,
      module: d.module,
      recipients: d.recipients.map(d => d.isReading),
      isRead: d.recipients
    }
  })

  const rowEvents = {
    onClick: (e, row) => {
      notificationsIsReadRequest((notificationId = row._id))
      if (row.module === 'priorization') {
        props.history.push(`/${row.module}/${row.document}`)
      } else {
        props.history.push(`/${row.module}/${row.document}`)
      }
    }
  }

  const rowClasses = (row, rowIndex) => {
    let classes = null
    if (row.isRead[0].isReading === false) {
      classes = 'notifications_table'
    } else {
      classes = 'notifications_table_read'
    }

    return classes
  }

  return (
    <Layout icon="fas fa-fw fa-puzzle-piece" title="Notificaciones">
      <TableContainer
        title="Notificaciones"
        subtitle="Lista"
        buttonAdd={false}
        exportPDF={false}
      >
        <DataTable
          {...props}
          limit={limit}
          searchBar={false}
          pageAct={pageAct}
          filter={filters}
          totalDocs={totalDocs}
          onTableChange={notificationsAllRequest}
          data={data}
          rowEvents={rowEvents}
          structure={columns}
          hover={false}
          rowClasses={rowClasses}
        />
      </TableContainer>
    </Layout>
    // </div>
  )
}

const mapStateToProps = state => {
  return {
    all: state.notifications.all,
    loading: state.notifications.one,
    error: state.notifications.error,
    isLogin: state.auth.isLogin,
    filters: state.notifications.filters,
    isRead: state.notifications.isRead
  }
}

const mapDispatchToProps = {
  notificationsAllRequest: notificationsAllRequest,
  notificationsIsReadRequest: notificationsIsReadRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)
