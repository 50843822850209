import React from 'react'
import ResetForm from 'components/Modules/Auth/ResetForm'
import logo from 'assets/img/logos/LogoGrande01.svg'

export const PageReset = () => {
  return (
    <div className='container-fluid login-admin-root '>
      <div className='row justify-content-center'>
        <div className='col-xl-5 col-lg-5 col-md-5 login-admin-content'>
          <div className='card o-hidden border-0 shadow-lg my-4'>
            <div className='card-body px-5'>
              <div className='d-flex flex-wrap justify-content-center align-items-center flex-column'>
                <img src={logo} width='250' className='logo' alt='logo' />
              </div>
              <div className='text-center'>
                <ResetForm />
              </div>
            </div>
          </div>
        </div>

        <small className='text-center text-login mb-2'>
          © Valores Corporativos Softtek S.A. de C.V. 2022.
        </small>
      </div>
    </div>
  )
}

export default PageReset
