import React from 'react'
import { connect } from 'react-redux'

const PriorizationBanner = ({ gta, gtaId, gtaTitle }) => {
  return (
    <div className='priorization-banner_container'>
      <div className='priorization-banner' key={gtaId}>
        <div className='row' style={{ backgroundColor: gta === undefined ? ' #38506c' : gta }}>
          <div>
            <h3>{gtaTitle}</h3>
          </div>
          <div>
            <p>Reporte de priorización</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    gta: state.gta.color,
    gtaId: state.gta._id,
    gtaTitle: state.gta.title
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PriorizationBanner)
