import React from 'react'
import Layout from 'components/Layout'
import BusinessTypeForm from './BusinessTypeForm'
import LoadingBar from 'react-redux-loading-bar'

function BusinessTypeCreate(props) {
  return (
    <Layout icon="fa fa-fw fa-building" title="Ámbito de implementación (Flashcards)">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <BusinessTypeForm
        {...props}
        title="Agregar"
        mode="create"
        loading={false}
        user={undefined}
      />
    </Layout>
  )
}

export default BusinessTypeCreate
