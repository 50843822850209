import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  CLIMB_GET_ALL_REQUEST,
  CLIMB_GET_ALL_FAILURE,
  CLIMB_GET_ALL_SUCCESS,
  CLIMB_CREATE_SUCCESS,
  CLIMB_CREATE_FAILURE,
  CLIMB_CREATE_REQUEST,
  CLIMB_GET_ONE_FAILURE,
  CLIMB_GET_ONE_SUCCESS,
  CLIMB_GET_ONE_REQUEST,
  CLIMB_REMOVE_SUCCESS,
  CLIMB_REMOVE_FAILURE,
  CLIMB_REMOVE_REQUEST,
  CLIMB_EDIT_FAILURE,
  CLIMB_EDIT_REQUEST,
  CLIMB_FILTER_CHANGE_SUCCESS,
  CLIMB_FILTER_CHANGE_FAILURE,
  CLIMB_FILTER_CHANGE_REQUEST,
  CLIMB_DEACTIVATE_SUCCESS,
  CLIMB_DEACTIVATE_FAILURE,
  CLIMB_REACTIVATE_SUCCESS,
  CLIMB_DEACTIVATE_REQUEST,
  CLIMB_REACTIVATE_REQUEST,
  CLIMB_GET_TOTALS_SUCCESS,
  CLIMB_GET_TOTALS_FAILURE,
  CLIMB_GET_TOTALS_REQUEST
} from './climb.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

export function* getAllClimbSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/climbs?${filterString}`)
    yield delay(1000)
    yield put({ type: CLIMB_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CLIMB_GET_ALL_FAILURE, error: error.response })
  }
}

export function* getOneClimbSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/climbs/${_id}`)
    yield delay(1000)
    yield put({ type: CLIMB_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CLIMB_GET_ONE_FAILURE, error: error.response })
  }
}

export function* createClimbSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/climbs', payload)
    yield delay(1500)
    yield put({ type: CLIMB_CREATE_SUCCESS, payload: insert })
    yield put(push('/climbs'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CLIMB_CREATE_FAILURE, error: error.response })
  }
}

export function* getClimbFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: CLIMB_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: CLIMB_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: CLIMB_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
export function* editClimbSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())

    const historical = { description }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/climbs/' + payload._id, payload)
    yield put({ type: CLIMB_CREATE_SUCCESS, payload: insert })
    yield put(showLoading())
    yield delay(1500)
    yield put(push('/climbs'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CLIMB_CREATE_FAILURE, error: error.response })
  }
}

export function* removeClimbSaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/climbs/' + id)
    yield put({ type: CLIMB_REMOVE_SUCCESS, payload: insert })
    yield put({ type: CLIMB_GET_ALL_REQUEST })
    yield put(showLoading())
    yield delay(3000)
    yield put(push('/climbs'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CLIMB_REMOVE_FAILURE, error: error.response })
  }
}

function* deactivateClimbSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(axios.put, '/api/climbs/' + payload.id + '/deactivate', payload)
    yield put({ type: CLIMB_DEACTIVATE_SUCCESS, payload: insert })
    yield put({ type: CLIMB_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/climbs'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CLIMB_DEACTIVATE_FAILURE, error: error.response })
  }
}

function* reactivateClimbSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, '/api/climbs/' + payload.id + '/reactivate', payload)
    yield put({ type: CLIMB_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: CLIMB_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/climbs'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CLIMB_DEACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/climbs/totals`)
    yield delay(1000)
    yield put({ type: CLIMB_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CLIMB_GET_TOTALS_FAILURE, error: error.response })
  }
}
export default function* climbSagas() {
  yield takeLatest(CLIMB_GET_ALL_REQUEST, getAllClimbSaga)
  yield takeLatest(CLIMB_GET_ONE_REQUEST, getOneClimbSaga)
  yield takeLatest(CLIMB_CREATE_REQUEST, createClimbSaga)
  yield takeLatest(CLIMB_EDIT_REQUEST, editClimbSaga)
  yield takeLatest(CLIMB_REMOVE_REQUEST, removeClimbSaga)
  yield takeLatest(CLIMB_GET_ALL_FAILURE, manageError)
  yield takeLatest(CLIMB_GET_ONE_FAILURE, manageError)
  yield takeLatest(CLIMB_EDIT_FAILURE, manageError)
  yield takeLatest(CLIMB_CREATE_FAILURE, manageError)
  yield takeLatest(CLIMB_REMOVE_FAILURE, manageError)
  yield takeLatest(CLIMB_FILTER_CHANGE_REQUEST, getClimbFiltersSaga)
  yield takeLatest(CLIMB_DEACTIVATE_REQUEST, deactivateClimbSaga)
  yield takeLatest(CLIMB_REACTIVATE_REQUEST, reactivateClimbSaga)
  yield takeLatest(CLIMB_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(CLIMB_FILTER_CHANGE_FAILURE, manageError)
}
