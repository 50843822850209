import React, { Fragment, useEffect } from 'react'
import Breadcrumb from 'components/Theme/Layout/Breadcrumb/Breadcrumb'
import Navigation from 'components/Theme/Layout/Navigation/Navigation'
import Header from 'components/Theme/Layout/Header/Header'
import SplashScreen from './SplashScreen/SplashScreen.js'
import { connect } from 'react-redux'
import { notificationsAllRequest } from 'components/Theme/Layout/Header/notifications.redux'

function Layout({ ...props }) {
  useEffect(() => {}, [])
  return (
    <Fragment>
      <Wrapper>
        <Navigation items={{}} />
        <ContentWrapper>
          <Header props={{}} />
          <Content title={props.title} icon={props.icon}>
            {props.children}
          </Content>
        </ContentWrapper>
      </Wrapper>
      <ScrollTopBtn />
    </Fragment>
  )
}

function Content(props) {
  return (
    <div className='container-fluid'>
      <SplashScreen />
      <Breadcrumb {...props} />
      {props.children}
    </div>
  )
}

function ContentWrapper(props) {
  return (
    <div id='content-wrapper' className='d-flex flex-column'>
      <div id='content'>{props.children}</div>
    </div>
  )
}

function Wrapper(props) {
  return <div id='wrapper'>{props.children}</div>
}

function ScrollTopBtn() {
  return (
    <div className='scroll-to-top rounded' style={{ cursor: 'pointer' }}>
      <i className='fas fa-angle-up' />
    </div>
  )
}

const mapDispatchToProps = {
  getNotifications: notificationsAllRequest
}
export default connect(null, mapDispatchToProps)(Layout)
