import React, { useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { connect } from 'react-redux'
import { CompanyValidationSchema } from './CompanyValidation'
import FormInput from 'components/FormInput'
import {
  companyCreateRequest,
  companyEditRequest,
  companyGetCatalogs,
  companyGetCities,
  companyGetStates
} from './company.redux.js'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { showWarningMessage } from 'components/Theme/Alerts/exceptionUtils'
import { isEmpty } from 'utils/crud'

function CompanyForm({
  mode,
  company,
  loading,
  catalogs,
  states,
  cities,
  companyGetCatalogs,
  companyGetStates,
  companyGetCities,
  ...props
}) {
  useEffect(() => {
    companyGetCatalogs()

    if (mode === 'edit') {
      if (!isEmpty(company.country) && company.country !== 'undefined') companyGetStates(company.country)
      if (!isEmpty(company.state)&& company.state !== 'undefined') companyGetCities(company.country, company.state)
    }
  }, [companyGetCatalogs, companyGetStates, companyGetCities, company, mode])

  const selectFile = (event, values) => {
    values.imageFile = event.target.files[0]
  }

  const handleCreateCompany = values => {
    props.companyCreateRequest({ values })
  }
  const handleEditCompany = values => {
    console.log(values)
   props.companyEditRequest({ values })
  }

  const getState = event => {
    companyGetStates(event.value)
  }
  const getCities = (country, event) => {
    companyGetCities(country, event.value)
  }
  const changeCountry = (e, values) => {
    values.country = e.value
  }
  const changeState = (e, values) => {
    values.state = e.value
  }

  const bussinesTypes = catalogs.businessTypes?.data?.map(({ name, _id }) => ({
    value: _id,
    text: name,
    id: _id,
    key: _id
  }))

  const sectors = catalogs.sectors?.data?.map(({ name, _id }) => ({
    value: _id,
    text: name,
    id: _id,
    key: _id
  }))

  const countries = catalogs.countries?.data?.map(({ name, Iso3 }) => ({
    value: name,
    label: name,
    id: name,
    key: Iso3
  }))

  const statesList = states?.data?.states?.map(({ name }) => ({
    value: name,
    label: name,
    id: name,
    key: name
  }))

  const citiesList = cities.data?.map(city => ({
    value: city,
    label: city,
    id: city,
    key: city
  }))

  let initialValuesData = {
    name: '',
    description: '',
    businessType: '',
    sector: '',
    country: '',
    state: '',
    city: '',
    image: '',
    imageFile: {}
  }

  if (mode === 'edit') {
    initialValuesData = {
      name: company?.name,
      description: company?.description,
      businessType: company.businessType?._id,
      sector: company.sector?._id,
      country: company.country,
      state: company.state,
      city: company.city,
      _id: company?._id,
      image: '',
      imageFile: {}
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={CompanyValidationSchema}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          if (!values.imageFile.name && mode === 'create') {
            showWarningMessage('Aviso', 'Ingrese una imagen de la empresa antes de guardar.')
            setSubmitting(false)
            return
          }
          if (!values.country && mode === 'create') {
            showWarningMessage('Aviso', 'Selecciona un país.')
            setSubmitting(false)
            return
          }
          if (!values.state && mode === 'create') {
            showWarningMessage('Aviso', 'Selecciona un estado.')
            setSubmitting(false)
            return
          }
          if (!values.city && mode === 'create') {
            showWarningMessage('Aviso', 'Selecciona una ciudad.')
            setSubmitting(false)
            return
          }
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateCompany(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleEditCompany(values)
              setSubmitting(false)
            }
          }, 600)
        }}>
        {({ values, isSubmitting, handleChange }) => (
          <Form enctype='multipart/form-data' className='form'>
            <Row>
              <FormInput id='name' label='Nombre' value={values.name} />
              <FormInput
                type='textarea'
                id='description'
                label={'Descripción'}
                value={values.description}
              />
              <div>
                {mode === 'edit' && (
                  <img src={company.thumbnail} height={'80px'} alt='logoCompany' />
                )}

                <div className='form-group'>
                  <label className='form-label auth__label'>Imagen</label>

                  <Field
                    className='auth__input form-control'
                    type='file'
                    id='image'
                    label='Imagen'
                    value={values.image}
                    onChange={e => {
                      selectFile(e, values)
                      handleChange(e)
                    }}
                  />
                </div>
              </div>
              <Col lg='6'>
                <FormInput
                  id='businessType'
                  label='Tipo de Empresa'
                  type='select'
                  onChange={handleChange}
                  dataOptions={bussinesTypes}
                  value={values.businessType}
                />
              </Col>
              <Col lg='6'>
                <FormInput
                  id='sector'
                  label='Sector'
                  type='select'
                  onChange={handleChange}
                  dataOptions={sectors && sectors}
                  value={values.sector}
                />
              </Col>
              <div className='col-lg-4'>
                <div className='form-group'>
                  <label className='form-label auth__label'>País</label>
                  <Select
                    {...props}
                    id='country'
                    name='country'
                    onChange={e => {
                      changeCountry(e, values)
                      getState(e)
                    }}
                    options={countries && countries}
                    value={countries?.filter(({ value }) => value === values.country)}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='form-group'>
                  <label className='form-label auth__label'>Estado</label>
                  <Select
                    {...props}
                    id='state'
                    name='state'
                    onChange={e => {
                      changeState(e, values)
                      getCities(values.country, e)
                    }}
                    options={states && statesList}
                    value={statesList?.filter(({ value }) => value === values.state)}
                  />
                </div>
              </div>
              <Col lg='4'>
                <FormInput
                  id='city'
                  label='Ciudad'
                  type='select'
                  onChange={handleChange}
                  dataOptions={
                    citiesList &&
                    citiesList.map(({ value }) => ({
                      value: value,
                      text: value,
                      id: value,
                      key: value
                    }))
                  }
                  value={values.city}
                />
              </Col>
            </Row>

            <Button color='primary' type='submit' disabled={isSubmitting}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.companies.all,
    one: state.companies.one,
    error: state.companies.error,
    companyLoading: state.companies.loading,
    filters: state.companies.filters,
    catalogs: state.companies.catalogs,
    states: state.companies.states,
    cities: state.companies.cities
  }
}

const mapDispatchToProps = {
  companyCreateRequest: companyCreateRequest,
  companyEditRequest: companyEditRequest,
  companyGetCatalogs: companyGetCatalogs,
  companyGetStates,
  companyGetCities
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm)
