import PageAdminEdit from './Pages/PageAdminEdit'
import PageAdmin from './Pages/PageAdmin'
import AdminView from './AdminView'
import PageAdminCreate from './Pages/PageAdminCreate'
import PageAdminEditPermission from './Pages/PageAdminEditPermission'

const adminRoutes = [
  { path: '/admins', component: PageAdmin },
  { path: '/admins/create', component: PageAdminCreate },
  { path: '/admins/:_id', component: AdminView },
  { path: '/admins/:id/edit', component: PageAdminEdit },
  { path: '/admins/:id/permission', component: PageAdminEditPermission }
]

export default adminRoutes
