import React from 'react'

function FormCardContentDetail({ children, title }) {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="wrapper wrapper-content animated fadeInUp">
          <div className="ibox">
            <div className="ibox-content">
              <div className="row">
                <div
                  className="m-b-md"
                  style={{ margin: '15px 0px', paddingLeft: '10%' }}
                >
                  <h3>{title}</h3>
                </div>
                <div className="col-lg-12" style={{ paddingLeft: '10%' }}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FormCardContentDetail.propTypes = {}

export default FormCardContentDetail
