import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  acceptFlashcardRequest,
  rejectFlashcardListRequest
} from 'components/Modules/Cards/cards.redux.js'

function ButtonStatus(props) {
  const handleAcceptFlashcard = newStatus => {
    props.one.status = newStatus
    props.acceptFlashcardRequest(props.one)
  }
  const handleRejectFlashcard = newStatus => {
    props.one.status = newStatus
    props.rejectFlashcardListRequest(props.one)
  }
  switch (props?.status) {
    case 1:
      return (
        <>
          <button
            className='btn btn-outline-success '
            onClick={() => handleAcceptFlashcard(2)}
            style={{
              margin: '-9px 10px 17px 34px'
            }}>
            <i className='fas fa-thumbs-up mr-1' />
            Aprobar
          </button>
          <button
            className='btn btn-outline-danger  '
            onClick={() => handleRejectFlashcard(3)}
            style={{
              margin: '-9px 0px 17px 34px'
            }}>
            <i className='fas fa-times mr-1' />
            Rechazar
          </button>
        </>
      )

    case 2:
      return (
        <>
          <i
            className='fas fa-clipboard-check mr-2'
            aria-hidden='true'
            style={{
              margin: '0px 10px 12px 34px',
              fontSize: '1.35rem',
              color: 'rgb(67, 208, 135)'
            }}></i>
          <span
            style={{
              color: 'green',
              fontWeight: 'bold',
              marginRight: '15px',
              fontSize: '16px'
            }}>
            Aprobado
          </span>
        </>
      )
    case 3:
      return (
        <>
          <i
            className='fas fa-times-circle mr-2'
            aria-hidden='true'
            style={{
              margin: '0px 10px 12px 34px',
              fontSize: '1.35rem',
              color: '#ff6b7d'
            }}></i>
          <span
            style={{
              color: 'red',
              fontWeight: 'bold',
              marginRight: '12px',
              fontSize: '16px'
            }}>
            Rechazado
          </span>
        </>
      )
    default:
      return <></>
  }
}
ButtonStatus.propTypes = {
  status: PropTypes.number,
  functionOnClick: PropTypes.func
}

ButtonStatus.defaultProps = {
  functionOnClick: null
}

const mapStateToProps = state => {
  return {
    one: state.formCards.one
  }
}

const mapDispatchToProps = {
  acceptFlashcardRequest,
  rejectFlashcardListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonStatus)
