import Acl from 'components/ACL/Acl'
import React from 'react'
import { Link } from 'react-router-dom'

function ButtonCreate(props) {
  return (
    <Acl action={props.action}>
      {!!props.action && (
        <Link className='float-right' style={props.style} to={props.route}>
          <button className='btn btn-primary'>
            <i className={props.icon} /> {props.label}
          </button>
        </Link>
      )}
    </Acl>
  )
}

ButtonCreate.defaultProps = {
  route: './create',
  icon: 'fas fa-plus-circle',
  label: 'Agregar',
  style: { margin: '0px 0 2rem .5rem' }
}

export default ButtonCreate
