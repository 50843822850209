import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function CardDetailClimb({ letter, color, type = 'big' }) {
  const iconCircle =
    type === 'small' ? (
      <FontAwesomeIcon style={{ fontSize: '21px' }} color={color} icon={faCircle} />
    ) : (
      <FontAwesomeIcon size='2x' color={color} icon={faCircle} />
    )

  const fontSize = type === 'small' ? 'small-' : ''

  return (
    <>
      {iconCircle}
      <span className={`flashcard-climb-text-${fontSize}${letter}`}>{letter}</span>
    </>
  )
}
