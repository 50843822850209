import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  businessTypeDeactivateRequest,
  businessTypeGetOneRequest,
  businessTypeReactivateRequest
} from './businessType.redux'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function BusinessTypeView({
  businessTypeGetOneRequest,
  historicalListRequest,
  ...props
}) {
  const { _id } = props.match.params
  const { historical } = props

  const businessType = props.one || {}
  useEffect(() => {
    businessTypeGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [businessTypeGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (businessType.active) {
      props.businessTypeDeactivateRequest({ id: _id })
    }
    if (!businessType.active) {
      props.businessTypeReactivateRequest({ id: _id })
    }
  }
  return (
    <Layout icon="fa fa-fw fa-building" title="Ámbito de implementación (Flashcards)">
      <TableContainer
        title="Área Responsable"
        subtitle="Información del registro"
        buttonAdd={false}
        view={`/business-types/${_id}/edit`}
        actionButton={true}
        active={businessType.active}
        onToggle={handleActiveToggle}
      >
        {!isEmpty(businessType) && (
          <Row id="view-businessType">
            <Col>
              <ul>
                <ListItem label="Nombre" value={businessType.name} />
                <ListItem
                  label="Descripción"
                  value={businessType.description}
                />
                <ListItem label="Activo" value={businessType.active} />
                <ListItem label="Creado" value={mdy(businessType.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className="overflow-box"
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}
      >
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.businessTypes.all,
    one: state.businessTypes.one,
    error: state.businessTypes.error,
    userLoading: state.businessTypes.loading,
    filters: state.businessTypes.filters,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  businessTypeGetOneRequest,
  businessTypeDeactivateRequest,
  businessTypeReactivateRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypeView)
