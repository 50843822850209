// Type's
export const FORMCARD_GET_ALL_REQUEST = 'FORMCARD_GET_ALL_REQUEST'
export const FORMCARD_GET_ALL_SUCCESS = 'FORMCARD_GET_ALL_SUCCESS'
export const FORMCARD_GET_ALL_FAILURE = 'FORMCARD_GET_ALL_FAILURE'
export const FORMCARD_RELOAD = '[RELOADs] FORMCARD_RELOAD'

export const FORMCARD_GET_LIST_REQUEST = 'FORMCARD_GET_LIST_REQUEST'
export const FORMCARD_GET_LIST_SUCCESS = 'FORMCARD_GET_LIST_SUCCESS'
export const FORMCARD_GET_LIST_FAILURE = 'FORMCARD_GET_LIST_FAILURE'

export const FORMCARD_GET_ALL_WITHOUT_CAROUSEL_REQUEST =
  'FORMCARD_GET_ALL_WITHOUT_CAROUSEL_REQUEST'
export const FORMCARD_GET_ALL_WITHOUT_CAROUSEL_SUCCESS =
  'FORMCARD_GET_ALL_WITHOUT_CAROUSEL_SUCCESS'
export const FORMCARD_GET_ALL_WITHOUT_CAROUSEL_FAILURE =
  'FORMCARD_GET_ALL_WITHOUT_CAROUSEL_FAILURE'
export const FORMCARD_GET_ALL_WITHOUT_CAROUSEL_DROP = 'FORMCARD_GET_ALL_WITHOUT_CAROUSEL_DROP'

export const FORMCARD_CREATE_REQUEST = 'FORMCARD_CREATE_REQUEST'
export const FORMCARD_CREATE_SUCCESS = 'FORMCARD_CREATE_SUCCESS'
export const FORMCARD_CREATE_FAILURE = 'FORMCARD_CREATE_FAILURE'

export const FORMCARD_CREATE_CAROUSEL_REQUEST = 'FORMCARD_CREATE_CAROUSEL_REQUEST'
export const FORMCARD_CREATE_CAROUSEL_SUCCESS = 'FORMCARD_CREATE_CAROUSEL_SUCCESS'
export const FORMCARD_CREATE_CAROUSEL_FAILURE = 'FORMCARD_CREATE_CAROUSEL_FAILURE'

export const FORMCARD_GET_ONE_REQUEST = 'FORMCARD_GET_ONE_REQUEST'
export const FORMCARD_GET_ONE_SUCCESS = 'FORMCARD_GET_ONE_SUCCESS'
export const FORMCARD_GET_ONE_FAILURE = 'FORMCARD_GET_ONE_FAILURE'

export const FORMCARD_EDIT_REQUEST = 'FORMCARD_EDIT_REQUEST'
export const FORMCARD_SELECT_REQUEST = 'FORMCARD_SELECT_REQUEST'
export const FORMCARD_EDIT_SUCCESS = 'FORMCARD_EDIT_SUCCESS'
export const FORMCARD_EDIT_FAILURE = 'FORMCARD_EDIT_FAILURE'

export const FORMCARD_REMOVE_REQUEST = 'FORMCARD_REMOVE_REQUEST'
export const FORMCARD_REMOVE_SUCCESS = 'FORMCARD_REMOVE_SUCCESS'
export const FORMCARD_REMOVE_FAILURE = 'FORMCARD_REMOVE_FAILURE'

export const FORMCARD_REACTIVATE_REQUEST = 'FORMCARD_REACTIVATE_REQUEST'
export const FORMCARD_REACTIVATE_SUCCESS = 'FORMCARD_REACTIVATE_SUCCESS'
export const FORMCARD_REACTIVATE_FAILURE = 'FORMCARD_REACTIVATE_FAILURE'

export const FORMCARD_FILTER_CHANGE_REQUEST = 'FORMCARD_FILTER_CHANGE_REQUEST'
export const FORMCARD_FILTER_CHANGE_SUCCESS = 'FORMCARD_FILTER_CHANGE_SUCCESS'
export const FORMCARD_FILTER_CHANGE_FAILURE = 'FORMCARD_FILTER_CHANGE_FAILURE'

export const FORMCARD_LOAD_MORE_REQUEST = 'FORMCARD_LOAD_MORE_REQUEST'
export const FORMCARD_LOAD_MORE_SUCCESS = 'FORMCARD_LOAD_MORE_SUCCESS'
export const FORMCARD_LOAD_MORE_FAILURE = 'FORMCARD_LOAD_MORE_FAILURE'

export const FORMCARD_GET_TOTALS_REQUEST = 'FORMCARD_GET_TOTALS_REQUEST'
export const FORMCARD_GET_TOTALS_SUCCESS = 'FORMCARD_GET_TOTALS_SUCCESS'
export const FORMCARD_GET_TOTALS_FAILURE = 'FORMCARD_GET_TOTALS_FAILURE'

export const FORMCARD_GET_CATALOGS_REQUEST = 'FORMCARD_GET_CATALOGS_REQUEST'
export const FORMCARD_GET_CATALOGS_SUCCESS = 'FORMCARD_GET_CATALOGS_SUCCESS'
export const FORMCARD_GET_CATALOGS_FAILURE = 'FORMCARD_GET_CATALOGS_FAILURE'

export const FORMCARD_GET_COMMENTS_DETAIL_REQUEST = 'FORMCARD_GET_COMMENTS_DETAIL_REQUEST'
export const FORMCARD_GET_COMMENTS_DETAIL_SUCCESS = 'FORMCARD_GET_COMMENTS_DETAIL_SUCCESS'
export const FORMCARD_GET_COMMENTS_DETAIL_FAILURE = 'FORMCARD_GET_COMMENTS_DETAIL_FAILURE'

// Action's
export const formCardGetCommentsDetailRequest = (filters, payload) => ({
  type: FORMCARD_GET_COMMENTS_DETAIL_REQUEST,
  filters,
  payload
})
export const formCardGetCommentsDetailSuccess = (payload) => ({
  type: FORMCARD_GET_COMMENTS_DETAIL_SUCCESS,
  payload
})
export const formCardReload = () => ({
  type: FORMCARD_RELOAD
})
export const formCardGetCommentsDetailFailure = (error) => ({
  type: FORMCARD_GET_COMMENTS_DETAIL_FAILURE,
  error
})
export const formCardGetAllWithoutCarouselDrop = () => ({
  type: FORMCARD_GET_ALL_WITHOUT_CAROUSEL_DROP
})

export const formCardGetAllRequest = (filters = {}) => ({
  type: FORMCARD_GET_ALL_REQUEST,
  filters
})

export const formCardGetListRequest = (filters = {}) => ({
  type: FORMCARD_GET_LIST_REQUEST,
  filters
})

export const formCardGetAllWithOutCarouselRequest = (filters = {}) => ({
  type: FORMCARD_GET_ALL_WITHOUT_CAROUSEL_REQUEST,
  filters
})
export const formCardGetAllSuccess = (payload) => ({
  type: FORMCARD_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: FORMCARD_GET_ALL_REQUEST,
  error
})

export const formCardFiltersChangeRequest = (filters) => ({
  type: FORMCARD_FILTER_CHANGE_REQUEST,
  filters
})

export const formCardloadMoreRequest = (payload) => ({
  type: FORMCARD_LOAD_MORE_REQUEST,
  payload
})

export const formCardCreateRequest = ({ values }) => ({
  type: FORMCARD_CREATE_REQUEST,
  payload: values
})

export const formCardCreateCarouselRequest = ({ values }) => ({
  type: FORMCARD_CREATE_CAROUSEL_REQUEST,
  values
})

export const formCardEditRequest = ({ values }) => ({
  type: FORMCARD_EDIT_REQUEST,
  payload: values
})
export const formCardSelectRequest = (payload) => ({
  type: FORMCARD_SELECT_REQUEST,
  payload: payload
})
export const formCardGetOneRequest = (payload) => ({ type: FORMCARD_GET_ONE_REQUEST, payload })
export const formCardGetOneSuccess = (payload) => ({ type: FORMCARD_GET_ONE_SUCCESS, payload })
export const formCardGetOneFailure = (payload) => ({ type: FORMCARD_GET_ONE_FAILURE, payload })
export const formCardDeactivateRequest = (payload) => ({
  type: FORMCARD_REMOVE_REQUEST,
  payload
})
export const formCardReactivateRequest = (payload) => ({
  type: FORMCARD_REACTIVATE_REQUEST,
  payload
})
export const formCardCreateSuccess = (payload) => ({ type: FORMCARD_CREATE_SUCCESS, payload })
export const formCardCreateFailure = (payload) => ({ type: FORMCARD_CREATE_FAILURE, payload })
export const formCardGetTotals = (payload) => ({ type: FORMCARD_GET_TOTALS_REQUEST, payload })
export const formCardGetCatalogs = (filters) => ({
  type: FORMCARD_GET_CATALOGS_REQUEST,
  filters
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  listSelected: [],
  comments: {},
  loading: false,
  error: null,
  filters: {},
  totals: {},
  catalogs: {}
}

export function formCards(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FORMCARD_GET_ALL_REQUEST:
    case FORMCARD_GET_LIST_REQUEST:
    case FORMCARD_GET_ALL_WITHOUT_CAROUSEL_REQUEST:
    case FORMCARD_CREATE_REQUEST:
    case FORMCARD_CREATE_CAROUSEL_REQUEST:
    case FORMCARD_GET_ONE_REQUEST:
    case FORMCARD_EDIT_REQUEST:
    case FORMCARD_SELECT_REQUEST:
    case FORMCARD_REMOVE_REQUEST:
    case FORMCARD_REACTIVATE_REQUEST:
    case FORMCARD_GET_TOTALS_REQUEST:
    case FORMCARD_GET_COMMENTS_DETAIL_REQUEST:
      return { ...state, error: null, loading: true }

    case FORMCARD_GET_ONE_FAILURE:
    case FORMCARD_CREATE_FAILURE:
    case FORMCARD_CREATE_CAROUSEL_FAILURE:
    case FORMCARD_GET_ALL_FAILURE:
    case FORMCARD_GET_LIST_FAILURE:
    case FORMCARD_GET_ALL_WITHOUT_CAROUSEL_FAILURE:
    case FORMCARD_EDIT_FAILURE:
    case FORMCARD_REMOVE_FAILURE:
    case FORMCARD_REACTIVATE_FAILURE:
    case FORMCARD_GET_TOTALS_FAILURE:
    case FORMCARD_GET_CATALOGS_FAILURE:
    case FORMCARD_GET_COMMENTS_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.error }

    case FORMCARD_GET_ONE_SUCCESS:
    case FORMCARD_EDIT_SUCCESS:
    case FORMCARD_REMOVE_SUCCESS:
    case FORMCARD_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }

    case FORMCARD_GET_COMMENTS_DETAIL_SUCCESS:
    case FORMCARD_CREATE_CAROUSEL_SUCCESS:
      return { ...state, error: null, loading: false, comments: { ...action.payload } }
    case FORMCARD_GET_ALL_SUCCESS:
    case FORMCARD_GET_LIST_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case FORMCARD_GET_ALL_WITHOUT_CAROUSEL_SUCCESS:
      return { ...state, error: null, loading: false, listSelected: { ...action.payload } }
    case FORMCARD_GET_ALL_WITHOUT_CAROUSEL_DROP:
      return { ...state, error: null, loading: false, all: {} }
    case FORMCARD_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case FORMCARD_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }
    case FORMCARD_GET_CATALOGS_SUCCESS:
      return { ...state, error: null, loading: false, catalogs: { ...action.payload } }
    case FORMCARD_LOAD_MORE_REQUEST:
      return { ...state, loading: true, count: action.payload }
    case FORMCARD_RELOAD:
      return { ...INITIAL_STATE, loading: true }

    case FORMCARD_LOAD_MORE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action
      // eslint-disable-next-line no-case-declarations
      const { cardsList } = state
      payload.data.docs = [...cardsList.docs, ...payload.data.docs]
      return {
        ...state,
        loading: false,
        cardsList: payload.data
      }
    default:
      return state
  }
}
