import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import { typesNotifications } from './notifications.redux'
import { aclGetConfigRequest } from 'components/ACL/acl.redux'
import { logoutRequest } from 'components/Modules/Auth/auth.saga'
import { getUserId } from 'redux/helpers/handlers'

function* getAllNotificationsSaga({ filters }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/notifications/?${filterString}`)
    yield delay(1000)
    yield put({
      type: typesNotifications.notificationsAllSuccess,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: typesNotifications.notificationsAllFailure,
      error: error.response
    })
  }
}

function* getAllUnreadNotificationsSaga({ filters = { page: 1 } }) {
  try {
    filters.page = filters.page || 1
    const user = yield select(u => getUserId(u))
    const acl = yield select(s => s.acl)
    const { list } = acl
    if (user) {
      const { data: permissionsList } = yield call(
        axios.get,
        `/api/permissions/all?admin=${user}`
      )
      if (list.length && JSON.stringify(list) !== JSON.stringify(permissionsList?.data)) {
        yield Swal.fire(
          'Se realizaron cambios en sus permisos',
          'Reinicie sesión nuevamente',
          'info'
        )
        yield call(logoutRequest)
        yield put(push('/'))
        yield window.location.reload()
      }
    }
    const { permissions, ...filtersNew } = filters
    const filterString = encodeFiltersData(filtersNew)
    const { data } = yield call(
      axios.get,
      `/api/notifications/unread?${filterString}?type=notification`
    )
    yield delay(1000)
    yield put({
      type: typesNotifications.notificationsListSuccess,
      payload: data?.data
    })
  } catch (error) {
    if (error.response.data.data.errors[0].msg === 'Usuario desactivado') {
      yield call(logoutRequest)
    }
    yield put({
      type: typesNotifications.notificationsAllFailure,
      error: error.response
    })
  }
}

function* updateNotificationSaga(action) {
  try {
    const notificationId = action.notificationId

    if (notificationId === 'all') {
      const user = yield select(u => getUserId(u))
      yield call(axios.put, `/api/notifications/set-read-all?user=${user}`)
      yield put(push('/notifications'))
    } else {
      yield call(axios.put, `/api/notifications/${notificationId}/set-read`)
    }
    yield put({
      type: typesNotifications.notificationsIsReadSuccess,
      notificationId
    })
  } catch (error) {
    yield put({
      type: typesNotifications.notificationsIsReadFailure,
      error: error.response
    })
  }
}

function encodeFiltersData(data) {
  return Object.keys(data)
    .filter(f => data[f] !== '' && data[f] !== null)
    .map(f => encodeURIComponent(f) + '=' + encodeURIComponent(data[f]))
    .join('&')
}

function* manageError(error) {
  try {
    if (error?.error?.status === 401) {
      yield put(push('/'))
    } else {
      if (error?.error?.status !== 400) {
        Swal.fire('Alerta', error?.error?.data.message, 'info')
      }
    }
  } catch (error) {
    throw error
  }
}

export default function* notificationsSagas() {
  yield takeLatest(typesNotifications.notificationsAllRequest, getAllNotificationsSaga)
  yield takeLatest(typesNotifications.notificationsIsReadRequest, updateNotificationSaga)
  yield takeLatest(typesNotifications.notificationsListRequest, getAllUnreadNotificationsSaga)
  yield takeLatest(typesNotifications.notificationsAllFailure, manageError)
}
