import React from 'react'
import Layout from 'components/Layout'

export const PageFlashcardEdit = () => {
  return (
    <Layout>
      <h1 className="h3 mb-0 text-gray-800">PageFlashcardEdit</h1>
    </Layout>
  )
}

export default PageFlashcardEdit
