import React, { useEffect } from 'react'
import { columns } from './user.helpers'
import DataTable from 'components/Theme/Table/Table'
import TableContainer from 'components/Theme/Container/TableContainer'
import { useHistory } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import { userGetAllRequest } from './user.redux'

function UsersTable(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userGetAllRequest(props.filters))
  }, [dispatch, props.filters])

  let history = useHistory()
  const users = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/users/${row._id}`)
    }
  }

  return (
    <TableContainer subtitle="Lista" action="list" buttonAdd={false} route="#">
      <DataTable
        {...props}
        limit={limit}
        pageAct={pageAct}
        totalDocs={totalDocs}
        onTableChange={props.userGetAllRequest}
        data={users}
        structure={columns}
        rowEvents={rowEvents}
      />
    </TableContainer>
  )
}

const mapDispatchToProps = {
  userGetAllRequest
}

const mapStateToProps = state => {
  return {
    all: state.users.all,
    one: state.users.one,
    error: state.users.error,
    userLoading: state.users.loading,
    filters: state.users.filters
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
