import React from 'react'
import { connect } from 'react-redux'

const PriorizationUserBanner = ({ nameGta, nameFlashcard, colorGta }) => {
  return (
    <div className='priorization-user-banner_container'>
      <div className='priorization-banner' key={'PriorizationUserBanner'}>
        <div
          className='row'
          style={{ backgroundColor: colorGta === undefined ? ' #38506c' : colorGta }}>
          <div>
            <h3>{nameFlashcard}</h3>
          </div>
          <div className='priorization-user-banner-right'>
            <h3>{nameGta}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    gta: state.gta.color,
    gtaId: state.gta._id,
    gtaTitle: state.gta.title
  }
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(PriorizationUserBanner)
