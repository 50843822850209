/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { Row, Col, Input } from 'reactstrap'
import { ListItem } from 'components/Theme/Container/ListItem'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import ImageBorder from 'components/Theme/Images/ImageBorder'
// @ts-ignore
import thumbnailAxis from 'assets/img/ejesDeTrabajo/workAxis1.svg'
import { connect, useDispatch } from 'react-redux'
import {
  objectiveDeactivateRequest,
  objectiveGetOneRequest,
  objectiveReactivateRequest
} from './objective.redux'
import { isEmpty } from 'utils/crud'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

export const ObjectiveView = ({ objectiveGetOneRequest, historicalListRequest, ...props }) => {
  const { historical } = props
  const dispatch = useDispatch()
  const { _id } = props.match.params
  useEffect(() => {
    objectiveGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [objectiveGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (objective.active) {
      dispatch(objectiveDeactivateRequest({ id: _id }))
    }
    if (!objective.active) {
      dispatch(objectiveReactivateRequest({ id: _id }))
    }
  }
  const objective = props.one || {}

  return (
    <Layout icon='fas fa-bullseye' title='Objetivo de Desarrollo Sustentable'>
      <TableContainer
        title='Detalle'
        subtitle='Información del registro'
        buttonAdd={false}
        view={`/objectives/${_id}/edit`}
        actionButton={true}
        active={objective.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(objective) && (
          <Row id='view-objective'>
            <Col
              md='4'
              className='flex justify-content-end'
              >
              <img
                src={objective.logo ? objective.logo : thumbnailAxis.path}
                height='150'
                width='150'
              />
            </Col>
            <Col md='8'>
              <ul>
                <ListItem label='Titulo' value={objective.title} />
                <ListItem label='Descripcion' value={objective.objective} />
                <ListItem label='Creado' value={mdy(objective.createdAt)} />
                <ListItem label='Actualizado' value={mdy(objective.updatedAt)} />
                <Input
                  type='color'
                  id='color'
                  value={objective.color}
                  label='Color'
                  disabled={true}
                />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className='overflow-box'
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}>
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.objective.one,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  objectiveGetOneRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ObjectiveView)
