import React from 'react'
import { mdy } from 'utils/dates'
import ImageBorder from 'components/Theme/Images/ImageBorder'
// @ts-ignore
import thumbnail from 'assets/media/profile.png'
import StarsRanking from 'components/Pages/StarsRanking'
import { Link } from 'react-router-dom'

export const columns = [
  { dataField: 'number', text: '#' },
  { dataField: 'name', text: 'Solución', style: { width: '10px' } },
  {
    dataField: 'businessType',
    style: { width: '360px' },
    text: 'Ámbito de implementación',
    formatter: (cell, row) => cell.map(item => item.name).join(', ')
  },
  {
    dataField: 'totalImpact',
    text: 'Impacto',
    formatter: cell => <StarsRanking rank={cell} /* Rating Props */ />
  },
  {
    dataField: 'totalImplementation',
    text: 'Facilidad de Implementación',
    formatter: cell => <StarsRanking rank={cell} /* Rating Props */ />
  },
  {
    dataField: 'total',
    text: 'Resultado',
    formatter: cell => <StarsRanking rank={cell} /* Rating Props */ />
  },
  {
    dataField: 'priorizationLength',
    text: 'Votaciones',
    formatter: (cell, row) => <Link to={`/priorization/${row._id}`}> {cell} </Link>
  }
  // ,  { dataField: 'updatedAt', text: 'Última actualización', sort: true, formatter: mdy }
]

export const allColumns = [
  {
    dataField: 'thumbnail',
    style: () => ({
      padding: 0
    }),
    formatter: (cell, row) => (
      <ImageBorder src={cell ? cell : thumbnail} alt={row.name} height='70' width='70' />
    )
  },
  { dataField: 'fullName', text: 'Creado por:' },
  {
    dataField: 'totalImpact',
    text: 'Impacto',
    formatter: cell => <StarsRanking rank={cell} /* Rating Props */ />
  },
  {
    dataField: 'totalImplementation',
    text: 'Facilidad de implementación',
    formatter: cell => <StarsRanking rank={cell} /* Rating Props */ />
  },
  {
    dataField: 'total',
    text: 'Resultado',
    formatter: cell => <StarsRanking rank={cell} /* Rating Props */ />
  },
  { dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdy }
]

export const curr = new Date()
curr.setDate(curr.getDate() + 3)
export const date = curr.toISOString().substr(0, 10)

export const t = new Date()
export const firstDay = new Date(t.getFullYear(), t.getMonth() + 0, 0, 23, 59, 59)
  .toISOString()
  .substr(0, 10)
export const lastDay = new Date(t.getFullYear(), t.getMonth() + 0, 30, 23, 59, 59)
  .toISOString()
  .substr(0, 10)

export const getRating = (number, icon) => {
  let starsCount = []
  for (let i = 1; i <= number; i++) {
    starsCount.push(icon)
  }
  return starsCount
}
