import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import TableContainer from 'components/Theme/Container/TableContainer'
import DataTable from 'components/Theme/Table/Table'
import { priorizationGetAllRequest } from './priorization.redux'
import { allColumns } from './priorization.helpers'
import PriorizationUserBanner from './PriorizationUserBanner'
import { useLocation } from 'react-router-dom'
import PriorizationGeneralAverage from './PriorizationGeneralAverage'

function PriorizationGeneralList({ priorizationGetAllRequest, one, filters, ...props }) {
  const idFlashcard = useLocation().pathname.split('/')[2] || ''

  const priorization = props.allPriorizations?.docs || []
  const totalDocs = props.allPriorizations?.totalDocs
  const pageAct = props.allPriorizations?.page
  const limit = props.allPriorizations?.limit

  useEffect(() => {
    priorizationGetAllRequest({
      page: 1,
      limit: 10,
      active: true,
      flashcard: idFlashcard
    })
  }, [priorizationGetAllRequest])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/priorizations/${row._id}`)
    }
  }

  const data = priorization.map(d => ({
    _id: d._id,
    fullName: d.createdBy.name + ' ' + d.createdBy.lastName,
    thumbnail: d.createdBy.thumbnail,
    createdAt: d.createdAt,
    totalImpact: parseFloat(
      ((d.coverage + d.replicabilty + d.impact + d.benefit) / 4).toFixed(2)
    ),
    totalImplementation: parseFloat(
      ((d.budget + d.humanResources + d.infrastructure) / 3).toFixed(2)
    ),
    total: parseFloat(
      (
        (d.coverage +
          d.replicabilty +
          d.impact +
          d.benefit +
          d.budget +
          d.humanResources +
          d.infrastructure) /
        7
      ).toFixed(2)
    )
  }))

  return (
    <Layout icon='fas fa-fw fa-star' title='Lista de priorizaciones por usuario'>
      <PriorizationUserBanner
        nameFlashcard={priorization[0]?.flashcard?.name}
        nameGta={priorization[0]?.gtaTitle}
        colorGta={priorization[0]?.gtaColor}
      />
      <PriorizationGeneralAverage idFlashcard={idFlashcard} />
      <TableContainer
        title='Priorizaciones'
        subtitle='Priorizaciones - Lista de votaciones por usuario'
        buttonAdd={false}>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          onTableChange={priorizationGetAllRequest}
          data={data}
          rowEvents={rowEvents}
          structure={allColumns}
          filter={filters}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    allPriorizations: state.priorizations.allPriorizations,
    oneDoc: state.flashcards.oneDoc,
    one: state.priorizations.one,
    filters: state.priorizations.filters
  }
}

const mapDispatchToProps = {
  priorizationGetAllRequest: priorizationGetAllRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorizationGeneralList)
