import { push } from 'connected-react-router'
import { all, takeLatest, put } from 'redux-saga/effects'
import cardsSagas from 'components/Modules/Cards/cards.saga'
import historicalSagas from 'components/Historicals/historical.saga'
import areaSagas from 'components/Modules/Catalogues/Area/area.sagas'
import climbSagas from 'components/Modules/Catalogues/Climb/climb.sagas'
import gtaSagas from 'components/Modules/Catalogues/Gta/gta.sagas'
import sectorSagas from 'components/Modules/Catalogues/Sector/sector.sagas'
import workAxisSagas from 'components/Modules/Catalogues/WorkAxis/workAxis.saga'
import objectiveSagas from 'components/Modules/Catalogues/Objective/objective.saga'
import userSagas from 'components/Modules/Users/user.saga'
import adminSagas from 'components/Modules/Admins/admin.saga'
import companiesagas from 'components/Modules/Catalogues/Company/company.sagas'
import authSagas from 'components/Modules/Auth/auth.saga'
import contactSagas from 'components/Modules/Contacts/contact.sagas'
import businessTypeSagas from 'components/Modules/Catalogues/BusinessType/businessType.sagas'
import businessJoinTypeSagas from 'components/Modules/Catalogues/BusinessTypeJoin/businessJoinType.sagas'
import businessJoinSubTypeSagas from 'components/Modules/Catalogues/BusinessJoinSubType/businessJoinSubType.sagas'
import formCardSagas from 'components/Modules/FormCard/formCard.sagas'
import priorizationSagas from 'components/Modules/Priorization/priorization.saga'
// import carouselSagas from 'components/Modules/CarouselCards/carousel.saga'
import filterSagas from 'components/Modules/Catalogues/Filters/filter.sagas'
import permissionsSagas from 'components/Modules/Catalogues/Permissions/permissions.sagas'
import notificationsSagas from 'components/Theme/Layout/Header/notifications.saga'
import aclSagas from 'components/ACL/acl.sagas'
import commentsSagas from 'components/Modules/Comments/comments.saga'
import cardSavedSagas from 'components/Modules/SavedCards/savedCards.saga'
// import 'config'
import modulesSagas from 'components/Modules/Catalogues/Modules/modules.sagas'
import goodPracticesSagas from 'components/Modules/GoodPractices/goodPractices.sagas'
import timeInitiativeSagas from 'components/Modules/Catalogues/TimeInitiatives/timeInitiatives.sagas'
import AxesGlobalPactSagas from 'components/Modules/Catalogues/AxesGlobalPact/axesGlobalPact.saga'

export function* initAppSaga(action) {
  const lang = navigator.language

  yield console.log('initAppSaga')
 
  yield alert('SAGAS RUNING')
}

export function* checkRouterSaga(action) {
  yield alert('YOU WILL BE REDIRECT')
  yield put(push('/about'))
}

export function* appSagas() {
  yield all([
    goodPracticesSagas(),
    adminSagas(),
    aclSagas(),
    areaSagas(),
    authSagas(),
    businessTypeSagas(),
    businessJoinTypeSagas(),
    businessJoinSubTypeSagas(),
    AxesGlobalPactSagas(),
    cardsSagas(),
    // carouselSagas(),
    climbSagas(),
    companiesagas(),
    contactSagas(),
    filterSagas(),
    modulesSagas(),
    formCardSagas(),
    gtaSagas(),
    historicalSagas(),
    timeInitiativeSagas(),
    priorizationSagas(),
    permissionsSagas(),
    sectorSagas(),
    takeLatest('INIT_APP', initAppSaga),
    takeLatest('CHEK_ROUTER', checkRouterSaga),
    userSagas(),
    workAxisSagas(),
    objectiveSagas(),
    notificationsSagas(),
    commentsSagas(),
    cardSavedSagas()
  ])
}
