import React, { useEffect } from 'react'
import { Row, Col, Input } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import { gtaGetOneRequest, gtaReactivateRequest, gtaDeactivateRequest } from './gta.redux.js'
import LoadingBar from 'react-redux-loading-bar'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'
import GoodPracticeRowDetail from 'components/Modules/GoodPractices/GoodPracticeRowDetail.js'

function GtaView({ gtaGetOneRequest, historicalListRequest, ...props }) {
  const { historical } = props
  const { _id } = props.match.params

  useEffect(() => {
    gtaGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [gtaGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (gta.active) {
      props.gtaDeactivateRequest({ id: _id })
    }
    if (!gta.active) {
      props.gtaReactivateRequest({ id: _id })
    }
  }

  const gta = props.one || {}

  return (
    <Layout icon='fas fa-fw fa-globe' title='GTA2030'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999,
          position: 'fixed'
        }}
      />
      <TableContainer
        title='Gta'
        subtitle='Información del registro'
        buttonAdd={false}
        view={`/gtas/${_id}/edit`}
        actionButton={true}
        active={gta.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(gta) && (
          <Row id='view-goodPractices'>
            <Col>
              <ul>
                <GoodPracticeRowDetail title='Título' text={gta.title} />
                <GoodPracticeRowDetail title='Objetivo' text={gta.goal} />
                <GoodPracticeRowDetail title='Líneas de actuación' text={gta.actionLines} />
              </ul>
            </Col>
            <Col>
              <ul>
                <GoodPracticeRowDetail title='Nomenclatura' text={gta.nomenclature} />
                <GoodPracticeRowDetail title='Autor' text={gta.author} />
                <GoodPracticeRowDetail title='Posición' text={gta.position} />
                <GoodPracticeRowDetail
                  title='Activo'
                  text={<ListItem label=' ' value={gta.active} />}
                />
                <GoodPracticeRowDetail title='Fecha de creación' text={mdy(gta.createdAt)} />
                <GoodPracticeRowDetail title='Número' text={gta.number} />
                <GoodPracticeRowDetail
                  title='Color'
                  text={
                    <Input
                      type='color'
                      id='color'
                      value={gta.color}
                      label='Color'
                      disabled={true}
                    />
                  }
                />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className='overflow-box'
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}>
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.gtas.all,
    one: state.gtas.one,
    error: state.gtas.error,
    userLoading: state.gtas.loading,
    filters: state.gtas.filters,
    historical: state.historicals.list
  }
}

const mapDispatchToProps = {
  gtaGetOneRequest: gtaGetOneRequest,
  gtaReactivateRequest: gtaReactivateRequest,
  gtaDeactivateRequest: gtaDeactivateRequest,
  historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(GtaView)
