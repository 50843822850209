import React from 'react'
import { withRouter } from 'react-router-dom'

function FormContainer(props) {
  const { title, children, label, actionButton, layout = true } = props
  return (
    <div className='col-12 mt-5 '>
      <div className='header-h2'>
        <h2 className='mb-3 text-left'>{title}</h2>
      </div>
      {layout ? (
        <div className='card shadow mb-4'>
          <div className='card-header py-3 d-flex justify-content-between'>
            <h6 className='m-0 font-weight-bold text-primary-tb'>{label}</h6>
            {actionButton}
          </div>
          <div className='card-body'>
            {children}
            <ButtonsDeck {...props} />
          </div>
        </div>
      ) : (
        <div>
          {children}
          <ButtonsDeck {...props} />
        </div>
      )}
    </div>
  )
}

const ButtonsDeck = props => {
  const { pristine = false, submitting = false, valid = true } = props.status || {}

  return (
    <ul className='list-inline'>
      {props.button === 'add' && (
        <li className='list-inline-item'>
          <button
            className='btn btn-info'
            onClick={props.onClick}
            disabled={pristine || submitting || !valid}>
            {!props.buttonAddLabel ? 'Guardar' : props.buttonAddLabel}
          </button>
        </li>
      )}

      {props.button === 'edit' && (
        <li className='list-inline-item'>
          <button
            className='btn btn-danger'
            onClick={props.history.goBack}
            disabled={pristine || submitting}>
            Cancelar
          </button>
        </li>
      )}

      {(props.button === 'edit' || props.button === 'update') && (
        <li className='list-inline-item'>
          <button
            className='btn btn-info'
            onClick={props.onClick}
            disabled={pristine || submitting}>
            Actualizar
          </button>
        </li>
      )}

      {/* Warning text next to the button */}
      {props.showWarning && <span className='text-warning'>{props.warning}</span>}
    </ul>
  )
}
export default withRouter(FormContainer)
