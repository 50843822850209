import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  PRIORIZATION_GET_ALL_REQUEST,
  PRIORIZATION_GET_ALL_FAILURE,
  PRIORIZATION_GET_ALL_SUCCESS,
  PRIORIZATION_CREATE_SUCCESS,
  PRIORIZATION_CREATE_FAILURE,
  PRIORIZATION_CREATE_REQUEST,
  PRIORIZATION_GET_ONE_FAILURE,
  PRIORIZATION_GET_ONE_SUCCESS,
  PRIORIZATION_GET_ONE_REQUEST,
  PRIORIZATION_REMOVE_SUCCESS,
  PRIORIZATION_REMOVE_FAILURE,
  PRIORIZATION_REMOVE_REQUEST,
  PRIORIZATION_EDIT_FAILURE,
  PRIORIZATION_EDIT_SUCCESS,
  PRIORIZATION_EDIT_REQUEST,
  PRIORIZATION_FILTER_CHANGE_SUCCESS,
  PRIORIZATION_FILTER_CHANGE_FAILURE,
  PRIORIZATION_FILTER_CHANGE_REQUEST,
  PRIORIZATION_DEACTIVATE_SUCCESS,
  PRIORIZATION_DEACTIVATE_FAILURE,
  PRIORIZATION_REACTIVATE_SUCCESS,
  PRIORIZATION_GET_TOTALS_SUCCESS,
  PRIORIZATION_GET_TOTALS_FAILURE,
  PRIORIZATION_DEACTIVATE_REQUEST,
  PRIORIZATION_REACTIVATE_REQUEST,
  PRIORIZATION_GET_TOTALS_REQUEST,
  PRIORIZATION_GET_LIST_SUCCESS,
  PRIORIZATION_GET_LIST_FAILURE,
  PRIORIZATION_GET_LIST_REQUEST,
  PRIORIZATION_FILTER_BY_DATE_REQUEST,
  PRIORIZATION_FILTER_BY_DATE_SUCCESS,
  PRIORIZATION_FILTER_BY_DATE_FAILURE,
  PRIORIZATION_GET_LIST_BY_DATE_SUCCESS,
  PRIORIZATION_GET_LIST_BY_DATE_FAILURE,
  PRIORIZATION_GET_LIST_BY_DATE_REQUEST,
  PRIORIZATION_GET_DETAIL_REQUEST,
  PRIORIZATION_GET_DETAIL_SUCCESS,
  PRIORIZATION_GET_DETAIL_FAILURE,
  PRIORIZATION_GET_ALL_STATUS_SUCCESS,
  PRIORIZATION_GET_ALL_STATUS_FAILURE,
  PRIORIZATION_GET_ALL_STATUS_REQUEST,
  PRIORIZATION_GET_ONE_DOC_SUCCESS,
  PRIORIZATION_GET_ONE_DOC_FAILURE,
  PRIORIZATION_GET_ONE_DOC_REQUEST,
  PRIORIZATION_SHOW_MODAL_REQUEST,
  PRIORIZATION_AVERAGES_REQUEST,
  PRIORIZATION_AVERAGES_SUCCESS,
  PRIORIZATION_AVERAGES_FAILURE
} from './priorization.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import {
  encodeFiltersData,
  getUserGta,
  getUserId,
  manageError
} from 'redux/helpers/handlers.js'

export function* getAllPriorizationSaga({ filters = { page: 1, active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/priorizations/?${filterString}`)
    yield delay(1000)
    yield put({ type: PRIORIZATION_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_ALL_FAILURE, error: error.response })
  }
}

export function* getAllPriorizationByStatusSaga({ filters = { page: 1, active: true } }) {
  try {
    const user = yield select(u => getUserId(u))
    filters.user = user
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/priorizationsstatus/?${filterString}`)
    yield delay(1000)
    yield put({
      type: PRIORIZATION_GET_ALL_STATUS_SUCCESS,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: PRIORIZATION_GET_ALL_STATUS_FAILURE,
      error: error
    })
  }
}

export function* priorizationGetDetailRequestSaga2({ filters = { page: 1, active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/priorizations/?${filterString}`)
    yield delay(1000)
    yield put({ type: PRIORIZATION_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_ALL_FAILURE, error: error.response })
  }
}

function* priorizationGetDetailRequestSaga({ payload }) {
  try {
    const body = {
      firsDate: '2022-01-01',
      secondDate: '2022-10-21'
    }
    const { data } = yield call(axios.post, `/api/priorizations/filteraveragebydate`, body)
    Object.keys(data)
      .filter(f => data[f] !== '' && data[f] !== null)
      .map(f => data[f])
    yield put({ type: PRIORIZATION_GET_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_DETAIL_FAILURE, error: error.response })
  }
}

export function* getPriorizationListSaga({ filters = { page: 1, active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(
      axios.get,
      `/api/priorizations/getListCardPriorization/?${filterString}`
    )
    yield delay(1000)
    yield put({ type: PRIORIZATION_GET_LIST_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_LIST_FAILURE, error: error.response })
  }
}

export function* getPriorizationListByDateSaga({ filters = { page: 1, active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/priorizations/date/?${filterString}`)
    yield delay(1000)
    yield put({
      type: PRIORIZATION_GET_LIST_BY_DATE_SUCCESS,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: PRIORIZATION_GET_LIST_BY_DATE_FAILURE,
      error: error.response
    })
  }
}

export function* getOnePriorizationSaga({ payload }) {
  const priorization = payload.priorization
  try {
    yield put(showLoading())
    yield delay(3000)
    if (payload.priorization.length > 0) {
      const { data } = yield call(axios.get, `/api/priorizations/${priorization}`)
      yield put({ type: PRIORIZATION_GET_ONE_SUCCESS, payload: data?.data })
      yield put(hideLoading())
    } else {
      yield put({
        type: PRIORIZATION_GET_ONE_SUCCESS,
        payload: {
          coverage: null,
          impact: null,
          budget: null,
          benefit: null,
          humanResources: null,
          replicabilty: null,
          infrastructure: null
        }
      })
      yield put(hideLoading())
    }
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_ONE_FAILURE, error: error.response })
  }
}

export function* getOneDocPriorizationSaga({ payload }) {
  const _id = payload._id
  try {
    yield put(showLoading())
    const { data } = yield call(axios.get, `/api/priorizations/${_id}`)
    yield put({ type: PRIORIZATION_GET_ONE_DOC_SUCCESS, payload: data?.data })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_ONE_DOC_FAILURE, error: error.response })
  }
}

export function* createPriorizationSaga({ payload }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    const description = 'Priorización realizada'
    const historical = { description, adminId: user }
    payload.createdBy = user
    payload.historical = historical
    const insert = yield call(axios.post, '/api/priorizations', payload)
    yield delay(1500)
    yield put({ type: PRIORIZATION_CREATE_SUCCESS, payload: insert })
    yield put({ type: PRIORIZATION_SHOW_MODAL_REQUEST })
    yield put({ type: PRIORIZATION_GET_ALL_STATUS_REQUEST })
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: PRIORIZATION_CREATE_FAILURE, error: error.response })
  }
}

export function* filterPriorizationByDateSaga({ payload }) {
  try {
    if (!payload) {
      return
    }
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    payload.user = user
    const insert = yield call(axios.post, '/api/priorizations/filterdates', payload)
    yield delay(1500)
    yield put({ type: PRIORIZATION_FILTER_BY_DATE_SUCCESS, payload: insert })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: PRIORIZATION_FILTER_BY_DATE_FAILURE,
      error: error.response
    })
  }
}

export function* getPriorizationFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: PRIORIZATION_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: PRIORIZATION_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({
      type: PRIORIZATION_FILTER_CHANGE_FAILURE,
      error: error.response
    })
  }
}
export function* editPriorizationSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.put, '/api/priorizations/' + payload._id, payload)
    yield delay(1500)
    yield put({ type: PRIORIZATION_EDIT_SUCCESS, payload: insert })
    yield put(push('/priorizations'))
    Swal.fire('Rating actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: PRIORIZATION_CREATE_FAILURE, error: error.response })
  }
}

export function* removePriorizationSaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/priorizations/' + id)
    yield put({ type: PRIORIZATION_REMOVE_SUCCESS, payload: insert })
    yield put({ type: PRIORIZATION_GET_ALL_REQUEST })
    yield put(showLoading())
    yield delay(3000)
    yield put(push('/priorizations'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: PRIORIZATION_REMOVE_FAILURE, error: error.response })
  }
}

function* deactivatePriorizationSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/priorizations/' + payload.id + '/deactivate',
      payload
    )
    yield put({ type: PRIORIZATION_DEACTIVATE_SUCCESS, payload: insert })
    yield put({ type: PRIORIZATION_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/priorizations'))
    Swal.fire('Mensaje', 'PRIORIZATION desactivado con éxito', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: PRIORIZATION_DEACTIVATE_FAILURE, error: error.response })
  }
}

function* reactivatePriorizationSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/priorizations/' + payload.id + '/reactivate',
      payload
    )
    yield put({ type: PRIORIZATION_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: PRIORIZATION_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/priorizations'))
    Swal.fire('Mensaje', 'PRIORIZATION reactivado con éxito', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: PRIORIZATION_DEACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/priorizations/totals`)
    yield delay(1000)
    yield put({ type: PRIORIZATION_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_TOTALS_FAILURE, error: error.response })
  }
}

export function* priorizationAverages({ filters }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(
      axios.get,
      `/api/priorizations/getPriorizationAverageAdmin/?${filterString}`
    )
    yield delay(1000)
    yield put({
      type: PRIORIZATION_AVERAGES_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({ type: PRIORIZATION_AVERAGES_FAILURE, error: error.response })
  }
}

export default function* priorizationSagas() {
  yield takeLatest(PRIORIZATION_GET_DETAIL_REQUEST, priorizationGetDetailRequestSaga)
  yield takeLatest(PRIORIZATION_GET_ALL_REQUEST, getAllPriorizationSaga)
  yield takeLatest(PRIORIZATION_GET_ALL_STATUS_REQUEST, getAllPriorizationByStatusSaga)
  yield takeLatest(PRIORIZATION_GET_LIST_REQUEST, getPriorizationListSaga)
  yield takeLatest(PRIORIZATION_GET_LIST_BY_DATE_REQUEST, getPriorizationListByDateSaga)
  yield takeLatest(PRIORIZATION_GET_ONE_REQUEST, getOnePriorizationSaga)
  yield takeLatest(PRIORIZATION_GET_ONE_DOC_REQUEST, getOneDocPriorizationSaga)
  yield takeLatest(PRIORIZATION_CREATE_REQUEST, createPriorizationSaga)
  yield takeLatest(PRIORIZATION_FILTER_BY_DATE_REQUEST, filterPriorizationByDateSaga)
  yield takeLatest(PRIORIZATION_EDIT_REQUEST, editPriorizationSaga)
  yield takeLatest(PRIORIZATION_REMOVE_REQUEST, removePriorizationSaga)
  yield takeLatest(PRIORIZATION_GET_ALL_FAILURE, manageError)
  yield takeLatest(PRIORIZATION_EDIT_FAILURE, manageError)
  yield takeLatest(PRIORIZATION_CREATE_FAILURE, manageError)
  yield takeLatest(PRIORIZATION_REMOVE_FAILURE, manageError)
  yield takeLatest(PRIORIZATION_GET_ONE_FAILURE, manageError)
  yield takeLatest(PRIORIZATION_FILTER_CHANGE_REQUEST, getPriorizationFiltersSaga)
  yield takeLatest(PRIORIZATION_DEACTIVATE_REQUEST, deactivatePriorizationSaga)
  yield takeLatest(PRIORIZATION_REACTIVATE_REQUEST, reactivatePriorizationSaga)
  yield takeLatest(PRIORIZATION_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(PRIORIZATION_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(PRIORIZATION_AVERAGES_REQUEST, priorizationAverages)
  yield takeLatest(PRIORIZATION_GET_TOTALS_FAILURE, manageError)
}
