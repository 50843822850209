import PriorizationGeneralList from './PriorizationGeneralList'
import PagePrioritization from 'components/Pages/PagePrioritization'
import PriorizationView from './PriorizationView'

const priorizationRoutes = [
  { path: '/priorizations', component: PagePrioritization },
  { path: '/priorization/:id', component: PriorizationGeneralList },
  { path: '/priorizations/:_id', component: PriorizationView }
]

export default priorizationRoutes
