// Type's
export const SECTOR_GET_ALL_REQUEST = 'SECTOR_GET_ALL_REQUEST'
export const SECTOR_GET_ALL_SUCCESS = 'SECTOR_GET_ALL_SUCCESS'
export const SECTOR_GET_ALL_FAILURE = 'SECTOR_GET_ALL_FAILURE'

export const SECTOR_CREATE_REQUEST = 'SECTOR_CREATE_REQUEST'
export const SECTOR_CREATE_SUCCESS = 'SECTOR_CREATE_SUCCESS'
export const SECTOR_CREATE_FAILURE = 'SECTOR_CREATE_FAILURE'

export const SECTOR_GET_ONE_REQUEST = 'SECTOR_GET_ONE_REQUEST'
export const SECTOR_GET_ONE_SUCCESS = 'SECTOR_GET_ONE_SUCCESS'
export const SECTOR_GET_ONE_FAILURE = 'SECTOR_GET_ONE_FAILURE'

export const SECTOR_EDIT_REQUEST = 'SECTOR_EDIT_REQUEST'
export const SECTOR_EDIT_SUCCESS = 'SECTOR_EDIT_SUCCESS'
export const SECTOR_EDIT_FAILURE = 'SECTOR_EDIT_FAILURE'

export const SECTOR_REMOVE_REQUEST = 'SECTOR_REMOVE_REQUEST'
export const SECTOR_REMOVE_SUCCESS = 'SECTOR_REMOVE_SUCCESS'
export const SECTOR_REMOVE_FAILURE = 'SECTOR_REMOVE_FAILURE'

export const SECTOR_REACTIVATE_REQUEST = 'SECTOR_REACTIVATE_REQUEST'
export const SECTOR_REACTIVATE_SUCCESS = 'SECTOR_REACTIVATE_SUCCESS'
export const SECTOR_REACTIVATE_FAILURE = 'SECTOR_REACTIVATE_FAILURE'

export const SECTOR_FILTER_CHANGE_REQUEST = 'SECTOR_FILTER_CHANGE_REQUEST'
export const SECTOR_FILTER_CHANGE_SUCCESS = 'SECTOR_FILTER_CHANGE_SUCCESS'
export const SECTOR_FILTER_CHANGE_FAILURE = 'SECTOR_FILTER_CHANGE_FAILURE'

export const SECTOR_GET_TOTALS_REQUEST = 'SECTOR_GET_TOTALS_REQUEST'
export const SECTOR_GET_TOTALS_SUCCESS = 'SECTOR_GET_TOTALS_SUCCESS'
export const SECTOR_GET_TOTALS_FAILURE = 'SECTOR_GET_TOTALS_FAILURE'

// Action's
export const sectorGetAllRequest = (filters) => ({ type: SECTOR_GET_ALL_REQUEST, filters })
export const sectorGetAllSuccess = (payload) => ({
  type: SECTOR_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: SECTOR_GET_ALL_REQUEST,
  error
})
export const sectorFiltersChangeRequest = (filters) => ({
  type: SECTOR_FILTER_CHANGE_REQUEST,
  filters
})
export const sectorGetOneRequest = (payload) => ({ type: SECTOR_GET_ONE_REQUEST, payload })
export const sectorGetOneSuccess = (payload) => ({ type: SECTOR_GET_ONE_SUCCESS, payload })
export const sectorGetOneFailure = (payload) => ({ type: SECTOR_GET_ONE_FAILURE, payload })
export const sectorDeactivateRequest = (payload) => ({ type: SECTOR_REMOVE_REQUEST, payload })
export const sectorReactivateRequest = (payload) => ({
  type: SECTOR_REACTIVATE_REQUEST,
  payload
})
export const sectorCreateRequest = ({ values }) => ({
  type: SECTOR_CREATE_REQUEST,
  payload: values
})
export const sectorEditRequest = ({ values }) => ({
  type: SECTOR_EDIT_REQUEST,
  payload: values
})
export const sectorCreateSuccess = (payload) => ({ type: SECTOR_CREATE_SUCCESS, payload })
export const sectorCreateFailure = (payload) => ({ type: SECTOR_CREATE_FAILURE, payload })
export const sectorGetTotals = (payload) => ({ type: SECTOR_GET_TOTALS_REQUEST, payload })

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: {},
  totals: {}
}

export function sectors(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SECTOR_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case SECTOR_GET_ALL_REQUEST:
    case SECTOR_CREATE_REQUEST:
    case SECTOR_GET_ONE_REQUEST:
    case SECTOR_EDIT_REQUEST:
    case SECTOR_REMOVE_REQUEST:
    case SECTOR_REACTIVATE_REQUEST:
    case SECTOR_GET_TOTALS_REQUEST:
      return { ...state, error: null, loading: true }

    case SECTOR_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case SECTOR_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case SECTOR_REACTIVATE_SUCCESS:
    case SECTOR_REMOVE_SUCCESS:
    case SECTOR_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case SECTOR_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }

    case SECTOR_GET_ALL_FAILURE:
    case SECTOR_CREATE_FAILURE:
    case SECTOR_GET_ONE_FAILURE:
    case SECTOR_EDIT_FAILURE:
    case SECTOR_REMOVE_FAILURE:
    case SECTOR_REACTIVATE_FAILURE:
    case SECTOR_GET_TOTALS_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
