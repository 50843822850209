import React from 'react'
import CardBig from 'components/Theme/Cards/CardBig'
import Layout from 'components/Layout'
import LoadingBar from 'react-redux-loading-bar'
import AdminFormCreate from 'components/Modules/Admins/AdminFormCreate'

export const PageAdminCreate = () => {
  return (
    <Layout icon="fas fa-user-cog" title="Agregar Administrador">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <br />
      <CardBig title={'Agregar'}>
        <AdminFormCreate mode="create" loading={false} user={undefined} />
      </CardBig>
    </Layout>
  )
}

export default PageAdminCreate
