import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { Subtitle } from 'components/Theme/Typography'
import ButtonCreate from 'components/Theme/Buttons/ButtonCreate'
import { Link } from 'react-router-dom'
import ButtonStatus from './ButtonStatus'
import Acl from 'components/ACL/Acl'
import { Tooltip } from 'reactstrap'
function TableContainer(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const {
    subtitle,
    children,
    customButton = null,
    activeButton = null,
    className = '',
    exportPDF = false,
    buttonAdd = false,
    actionButton = false,
    buttonDeactivate = false,
    buttonEdit = 'none',
    idTooltip = '',
    tooltipText = '',
    tooltip = false,
    ...others
  } = props

  return (
    <div {...others} className={`row mx-3 ${className}`}>
      <div className='col-12'>
        <div className='card shadow mb-2'>
          <div className='card-header py-3 px-0'>
            <Subtitle className='flex-row align-items-center d-flex justify-content-between'>
              {subtitle}{' '}
              {tooltip && (
                <i class='fa fa-question-circle' aria-hidden='true' id={idTooltip}>
                  {tooltip && (
                    <Tooltip
                      placement='top'
                      isOpen={tooltipOpen}
                      target={idTooltip}
                      toggle={toggle}>
                      {tooltipText}
                    </Tooltip>
                  )}
                </i>
              )}
              {actionButton && <ActionButtons action='update' action2='destroy' {...props} />}
              {buttonDeactivate && <ButtonDeactivate action='destroy' {...props} />}
              {buttonEdit !== 'none' && (
                <ButtonEdit action='update' buttons={buttonEdit} {...props} />
              )}
              {exportPDF && (
                <Button className='btn btn-dark float-right'>
                  <i className='mr-1 fas fa-file-pdf' /> Descargar PDF
                </Button>
              )}
              {activeButton}
              {customButton}
            </Subtitle>
          </div>
          <div className='card-body table-responsive pb-2' id='export-area'>
            {buttonAdd && <ButtonCreate action='create' {...props} />}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const ActionButtons = props => {
  const { data } = props
  return (
    <div>
      <Acl action={props.action}>
        {!!props.action && (
          <>
            <Link className='no-style mr-2' to={props.view}>
              <button className='btn btn-info'>
                <i className='fas fa-edit mr-1' />
                Editar
              </button>
            </Link>
          </>
        )}
      </Acl>

      <Acl action={props.action2}>
        {!!props.action2 && (
          <>
            <button
              className={`mr-2 btn btn-${props.active ? 'danger' : 'success'}`}
              onClick={() => props.onToggle()}>
              <i className='fas fa-power-off' /> {props.active ? 'Desactivar' : 'Activar'}
            </button>
          </>
        )}
      </Acl>

      {props?.type === 'flashcard' && <ButtonStatus status={data?.status} />}
    </div>
  )
}

const ButtonDeactivate = props => {
  const { data } = props
  return (
    <div>
      <Acl action={props.action}>
        {!!props.action && (
          <button
            className={`mr-2 btn btn-${props.active ? 'danger' : 'success'}`}
            onClick={() => props.onToggle()}>
            <i className='fas fa-power-off' /> {props.active ? 'Desactivar' : 'Activar'}
          </button>
        )}
      </Acl>
      {props?.type === 'flashcard' && <ButtonStatus status={data?.status} />}
    </div>
  )
}
const ButtonEdit = props => {
  const { data } = props
  return (
    <div>
      <Acl action={props.action}>
        {!!props.action &&
          (props.buttons ? (
            <>
              <Link className='no-style mr-2' to={props.view}>
                <button className='btn btn-info'>
                  <i className='fas fa-edit mr-1' />
                  Editar
                </button>
              </Link>
              <button
                className={`mr-2 btn btn-${props.active ? 'danger' : 'success'}`}
                onClick={() => props.onToggle()}>
                <i className='fas fa-power-off' /> {props.active ? 'Desactivar' : 'Activar'}
              </button>
            </>
          ) : (
            <>
              <Link className='no-style mr-2' to={props.view}>
                <button className='btn btn-info'>
                  <i className='fas fa-edit mr-1' />
                  Editar
                </button>
              </Link>
            </>
          ))}
      </Acl>
      {props?.type === 'flashcard' && <ButtonStatus status={data?.status} />}
    </div>
  )
}
TableContainer.defaultProps = {
  buttonAdd: true,
  buttonDeactivate: false
}

export default TableContainer
