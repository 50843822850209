import React from 'react'
import { connect } from 'react-redux'
import LoadingBar from 'react-redux-loading-bar'
import Layout from 'components/Layout'
import CardBig from 'components/Theme/Cards/CardBig'
import GtaForm from './GtaForm'

export const GtaEdit = ({ one }) => {
  return (
    <Layout icon='fas fa-fw fa-globe' title='GTA2030'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999,
          position: 'fixed'
        }}
      />
      <CardBig title={'Editar'}>
        <GtaForm mode='edit' gta={one} />
      </CardBig>
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    all: state.gtas.all,
    one: state.gtas.one,
    error: state.gtas.error,
    loading: state.gtas.loading
  }
}

export default connect(mapStateToProps)(GtaEdit)
