import ObjectiveList from './ObjectiveList'
import ObjectiveView from './ObjectiveView'
import ObjectiveCreate from './ObjectiveCreate'
import ObjectiveEdit from './ObjectiveEdit'

const objectiveRoutes = [
  { path: '/objectives', component: ObjectiveList },
  { path: '/objectives/create', component: ObjectiveCreate },
  { path: '/objectives/:_id', component: ObjectiveView },
  { path: '/objectives/:id/edit', component: ObjectiveEdit }
]
export default objectiveRoutes
