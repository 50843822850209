import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

function Acl({ acl, role, action, children, module, prueba }) {
  const { pathname } = useLocation()
  const moduleName = module || pathname.split('/')[1]
  let can =
    acl && acl.find(p => p.module && p.module.name === moduleName && p.permissions[action])
  if(role === 0) can = true
  return can === undefined ? <></> : <>{children}</>
}

Acl.defaultProps = {
  action: 'read'
}

const mapStateToProps = ({ acl, admin }) => ({ acl: acl.list, role: admin.role })
export default connect(mapStateToProps)(Acl)
