import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
// import './Breadcrumb.css'
import styles from './breadcrumb.module.css'

class Breadcrumb extends Component {
  render() {
    const { title, icon } = this.props
    const location = window.location

    return (
      <div>
        <nav aria-label='breadcrumb'>
          <ol className={`breadcrumb ${styles['breadcrumb']}`}>
            <span>
              <h3>
                <i className={icon} style={{ margin: '10px' }} />
                <span>{title}</span>
              </h3>
            </span>
            <li className={`breadcrumb-item ml-auto ${styles['breadcrumb-item']}`}>
              <Link to='/home'>
                <i className='fas fa-home mr-1' />
              </Link>
            </li>
            {location.pathname.split('/')[2] && (
              <li className={`breadcrumb-item  ${styles['breadcrumb-item']}`}>
                {location.pathname.split('/')[2] === 'loan' ? (
                  <Link to={`/${location.pathname.split('/')[1]}/loan`}>{title}</Link>
                ) : (
                  <Link to={`/${location.pathname.split('/')[1]}`}>{title}</Link>
                )}
              </li>
            )}
            {location.pathname.split('/')[2] === 'create' && (
              <li className={`breadcrumb-item ${styles['breadcrumb-item']}`}>
                <Link to={`/${location.pathname.split('/')[1]}`}>Agregar</Link>
              </li>
            )}
            {location.pathname.split('/')[2] === 'view' && (
              <li className={`breadcrumb-item ${styles['breadcrumb-item']}`}>
                <Link to={`/${location.pathname.split('/')[1]}`}>Detalle</Link>
              </li>
            )}
            {location.pathname.split('/')[3] === 'view' && (
              <li className={`breadcrumb-item ${styles['breadcrumb-item']}`}>
                <Link
                  to={`/${location.pathname.split('/')[1]}/${
                    location.pathname.split('/')[2]
                  }`}>
                  Detalle
                </Link>
              </li>
            )}
            {location.pathname.split('/')[2] === 'edit' && (
              <>
                <li className={`breadcrumb-item ${styles['breadcrumb-item']}`}>
                  <Link
                    to={`/${location.pathname.split('/')[1]}/${
                      location.pathname.split('/')[3]
                    }`}>
                    Detalle
                  </Link>
                </li>
                <li className={`breadcrumb-item ${styles['breadcrumb-item']}`}>
                  <Link
                    to={`/${location.pathname.split('/')[1]}/${
                      location.pathname.split('/')[3]
                    }`}>
                    Editar
                  </Link>
                </li>
              </>
            )}
          </ol>
        </nav>
      </div>
    )
  }
}

export default withRouter(Breadcrumb)
