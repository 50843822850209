import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { mdyhm } from 'utils/dates'
import { moduleData } from './notificationIcons'
import { Link } from 'react-router-dom'

import profile from 'assets/media/profile.png'
import { notificationsIsReadRequest, notificationsListRequest } from './notifications.redux'

function NotificationList({
  getUnreadNotifications,
  notificationsIsReadRequest,
  isLogin,
  notificationId,
  isRead,
  ...props
}) {
  useEffect(() => {
    if (isLogin) {
      getUnreadNotifications()
    }
  }, [getUnreadNotifications, isLogin])

  const unread = props.unread?.docs || []
  const unreadLength = props.unread?.totalDocs

  return (
    <>
      <li className='nav-item dropdown no-arrow mx-1'>
        <a
          className='nav-link dropdown-toggle'
          href='javascript:void(0)'
          id='alertsDropdown'
          role='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'>
          <i className='fas fa-bell fa-fw' style={{ fontSize: '1.7rem' }}></i>
          <span className='badge badge-danger badge-counter'>
            {unreadLength > 5 ? '+' + unreadLength : unreadLength}
          </span>
        </a>
        <div
          className='dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in'
          aria-labelledby='alertsDropdown'>
          <h6 className='dropdown-header'>Notificaciones</h6>
          {isRead
            ? unread?.map(i => (
                <ItemNotificaction
                  onclick={e =>
                    notificationsIsReadRequest((notificationId = e.currentTarget.id))
                  }
                  id={i._id}
                  icon={i.createdBy?.thumbnail}
                  color={moduleData[i?.module]?.color}
                  key={i._id}
                  style={{
                    background: i?.isReading ? 'white' : '#e3e3e3'
                  }}
                  url={
                    i.module === 'priorization'
                      ? `/${i.module}/${i.document}`
                      : `/${i.module}/${i.document}`
                  }
                  className='icon-circle notifications-circle bg-primary'
                  date={mdyhm(i.createdAt)}
                  description={i.description}
                  fullName={i.createdBy?.name + ' ' + i.createdBy?.lastName}
                />
              ))
            : unread?.map(i => (
                <ItemNotificaction
                  onclick={e =>
                    notificationsIsReadRequest((notificationId = e.currentTarget.id))
                  }
                  id={i._id}
                  icon={i.createdBy?.thumbnail}
                  color={moduleData[i?.module]?.color}
                  key={i._id}
                  style={{
                    background: i?.isReading ? 'white' : '#e3e3e3'
                  }}
                  url={
                    i.module === 'priorization'
                      ? `/${i.module}/${i.document}`
                      : `/${i.module}/${i.document}`
                  }
                  className='icon-circle notifications-circle bg-primary'
                  date={mdyhm(i.createdAt)}
                  description={i.description}
                  fullName={i.createdBy?.name + ' ' + i.createdBy?.lastName}
                />
              ))}
          {/* {unreadLength < 5 ? null : ( */}
          <a
            className='dropdown-item text-center small text-gray-500 notification-text-viewall'
            onClick={() => notificationsIsReadRequest((notificationId = 'all'))}
            href='#'>
            MOSTRAR TODAS LAS NOTIFICACIONES
          </a>
          {/* )} */}
        </div>
      </li>
    </>
  )
}

const ItemNotificaction = ({
  url,
  className,
  icon,
  date,
  description,
  style,
  color,
  fullName,
  onclick,
  id
}) => {
  return (
    <Link
      key={id}
      id={id}
      type='submit'
      onClick={onclick}
      className='dropdown-item d-flex align-items-center'
      to={url}
      style={style}>
      <div className='mr-3'>
        <div className={className}>
          <img style={{ color: color }} src={icon || profile} alt='notification' />
        </div>
      </div>
      <div>
        <div className='small text-gray-500'>{date}</div>
        <span>{description}</span>
        <br />
        <span className='small text-gray-500'>Creado por: {fullName}</span>
      </div>
    </Link>
  )
}

const mapStateToProps = state => {
  return {
    unread: state.notifications.notRead,
    one: state.notifications.one,
    isRead: state.notifications.isRead,
    isLogin: state.auth.isLogin
  }
}

const mapDispatchToProps = {
  getUnreadNotifications: notificationsListRequest,
  notificationsIsReadRequest: notificationsIsReadRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)
