import { LOCALES } from 'i18n/locales'

export default {
  [LOCALES.SPANISH]: {
    //DASHBOARD CREATE USERS
    'dashboard-create-user': 'Crear usuario',
    'dashboard-user-module-name': 'Usuarios',
    'dashboard-user-registered-users': 'Usuarios registrados',

    //DASHBOARD EDIT USERS
    'dashboard-edit-user': 'Editar usuario',

    //DASHBOARD MENU
    'db-m-home': 'Dashboard',
    'db-m-users': 'Usuarios',
    'db-m-users-public': 'Usuarios público general',
    'db-m-users-dashboard': 'Administradores',
    'db-m-work-area': 'Ejes de trabajo',
    'db-m-questionnaires-sent': 'Cuestionarios enviados',
    'db-m-questionnaires': 'Cuestionarios',
    'db-m-flashcards': 'Flashcards',
    'db-m-evaluations': 'Evaluaciones',
    'db-m-traffic-light': 'Semáforo de implementación',
    'db-m-catalogues': 'Catálogos',
    'db-m-catalogues-area': 'Áreas',
    'db-m-catalogues-climb': 'CLIMB',
    'db-m-catalogues-companies': 'Empresa',
    'db-m-catalogues-gta': 'GTA2030',
    'db-m-catalogues-sector': 'Sectores',
    'db-m-reports': 'Reportes',
    'db-m-config': 'Configuración',

    //DASHBOARD CREATE CARD
    'db-cc-title': 'Crear Flashcard',
    'db-cc-back': 'Regresar',

    //DASHBOARD CREATE USERS
    'db-cu-add': 'Enviar usuario',
    'db-cu-back': 'Regresar',
    'db-cu-company': 'Empresa donde laboras',
    'db-cu-gender': 'Género',
    'db-cu-gta': 'GTA2030',
    'db-cu-lastname': 'Apellido',
    'db-cu-email': 'Correo electronico',
    'db-cu-name': 'Nombre',
    'db-cu-nationality': 'Nacionalidad',
    'db-cu-pass': 'Contraseña',
    'db-cu-pass-val': 'Validar contraseña',
    'db-cu-role': 'Rol',
    'db-cu-state': 'Estado de residencia',

    'db-cu-send': 'Enviar',

    'db-cu-mp': 'Matriz de permisos'
  }
}
