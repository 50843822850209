/* eslint-disable react/prop-types */
import Acl from 'components/ACL/Acl'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import './card-menu.css'
const CardMenu = ({ module, action, title, icon, link }) => {
  return (
    <Acl module={module} action={action}>
      <Fragment>
        <div className={`col-md-3 col-sm-6 col-xs-12`}>
          <Link to={link} style={{ textDecoration: 'none' }}>
            <div className={`panel panel-${icon.replaceAll(' ', '')} panel-colorful`}>
              <div className='panel-body text-center'>
                <p className='text-uppercase mar-btm text-sm'></p>
                <i className={`fa fa-5x ${icon} panel-margin`}></i>
                <hr />
                <p className='h2 text-thin text-label-thin-p'>{title}</p>
              </div>
            </div>
          </Link>
        </div>
      </Fragment>
    </Acl>
  )
}
export default CardMenu
