/* eslint-disable react/prop-types */
import React from 'react'
import { columns } from './admin.helper'
import DataTable from 'components/Theme/Table/Table'
import TableContainer from 'components/Theme/Container/TableContainer'
import { useHistory } from 'react-router'
import { adminGetAllRequest } from './admin.redux'
import { connect } from 'react-redux'

function AdminTable(props) {
  let history = useHistory()
  const admin = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/admins/${row._id}`)
    }
  }
  return (
    admin && (
      <TableContainer subtitle='Lista' route='/admins/create' module='admins'>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          totalDocs={totalDocs}
          filter={props.filters}
          onTableChange={props.adminGetAllRequest}
          data={admin}
          structure={columns}
          rowEvents={rowEvents}
        />
      </TableContainer>
    )
  )
}
const mapDispatchToProps = {
  adminGetAllRequest
}

const mapStateToProps = state => {
  return {
    all: state.admins.all,
    one: state.admins.one,
    error: state.admins.error,
    userLoading: state.admins.loading,
    filters: state.admins.filters,
    totals: state.admins.totals
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable)
