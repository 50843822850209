import React from 'react'
import CardDetailClimb from './CardDetailClimb.js'

function CardDetailBackTwo(props) {
  const p = { ...props?.data }
  const { gta, implementationTime, indicator, area, climb } = p

  return (
    <div className='card-detail-content-back'>
      <div className='flashcard-detail-back-div2 '>
        {/* --- CLIMB ---------------------------------------------------------------- */}
        {indicator !== undefined && (
          <>
            <span className='flashcard-detail-back-colortext' style={{ color: gta?.color }}>
              Indicador
            </span>
            <p className='flashcard-detail-back-text flashcard-detail-back-text-ownership'>
              {indicator !== '' ? indicator : 'N/A'}
            </p>
            <br />
          </>
        )}
        {/* --- CLIMB ---------------------------------------------------------------- */}
        {area !== undefined && (
          <>
            <span className='flashcard-detail-back-colortext' style={{ color: gta?.color }}>
              Área responsable
            </span>
            {area !== null && area.length > 0 ? (
              <ul>
                {area.map((a, i) => (
                  <li className='flashcard-detail-back-text' key={i}>
                    {a?.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className='flashcard-detail-back-text '>N/A</p>
            )}
            <br />
          </>
        )}

        {/* --- Tiempo de implementación ---------------------------------------------------------------- */}
        {implementationTime !== undefined && (
          <>
            <span className='flashcard-detail-back-colortext' style={{ color: gta?.color }}>
              Tiempo de implementación
            </span>
            <p className='flashcard-detail-back-text '>
              {implementationTime !== '' ? implementationTime : 'N/A'}
            </p>
          </>
        )}
      </div>

      {/* --- CLIMB ---------------------------------------------------------------- */}
      <div
        className='flashcard-footer-back justify-content-center'
        style={{ marginTop: '7px' }}>
        <div className='flashcard-footer-back-climb2 justify-content-center '>
          {climb?.map(c => (
            <CardDetailClimb key={c._id} letter={c.nomenclature} color={gta?.color} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CardDetailBackTwo
