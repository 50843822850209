import React from 'react'
import Layout from 'components/Layout'
import CardMenu from 'components/Theme/Cards/CardMenu/CardMenu'

export const PageMainMenu = ({ menu }) => {
  menu = [
    {
      module: 'admins',
      action: 'read',
      link: '/admins',
      icon: 'fas fa-user-cog',
      title: 'Administradores'
    },
    {
      module: 'users',
      action: 'read',
      link: '/users',
      icon: 'fas fa-user',
      title: 'Usuarios'
    },
    {
      module: 'formCards',
      action: 'read',
      link: '/formCards',
      icon: 'fas fa-question-circle',
      title: 'Panel de Administración Flashcards'
    },
    {
      module: 'flashcards',
      action: 'read',
      link: '/flashcards',
      icon: 'fas fa-file',
      title: 'Flashcards Creadas'
    },
    {
      module: 'priorizations',
      action: 'read',
      link: '/priorizations',
      icon: 'fas fa-star',
      title: 'Priorización'
    },
    // {
    //   module: 'carouselCards',
    //   action: 'read',
    //   link: '/carouselCards',
    //   icon: 'fas fa-list',
    //   title: 'Carrusel'
    // },
    {
      module: 'goodPractices',
      action: 'read',
      link: '/goodPractices',
      icon: 'fas fa-user-plus',
      title: 'Súmate'
    }
  ]
  return (
    <Layout>
      <div>
        <div className=' row' style={{ padding: '2%' }}>
          {menu.map((prop, key) => {
            return (
              <CardMenu
                module={prop.module}
                action={prop.action}
                key={key}
                icon={prop.icon}
                link={prop.link}
                title={prop.title}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default PageMainMenu
