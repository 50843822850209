import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  faPen,
  faStarOfLife,
  faThumbsUp,
  faTimes,
  faUserEdit,
  faPowerOff,
  faBookmark,
  faDoorClosed,
  faStar,
  faShareSquare,
  faCommentDots,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons'

export const iconPen = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size='3x' icon={faPen} />
)
export const iconUserPlus = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size='3x' icon={faUserPlus} />
)
export const iconCommentDots = (
  <FontAwesomeIcon style={{ marginLeft: '-20px' }} size='3x' icon={faCommentDots} />
)
export const iconStarOfLife = (
  <FontAwesomeIcon style={{ marginLeft: '-19px' }} size='3x' icon={faStarOfLife} />
)
export const iconClipboardCheck = (
  <FontAwesomeIcon style={{ marginLeft: '-20px' }} size='3x' icon={faThumbsUp} />
)
export const iconTimesCircle = (
  <FontAwesomeIcon style={{ marginLeft: '-15px' }} size='3x' icon={faTimes} />
)
export const iconUserEdit = (
  <FontAwesomeIcon style={{ marginLeft: '-26px' }} size='3x' icon={faUserEdit} />
)
export const iconPowerOff = (
  <FontAwesomeIcon style={{ marginLeft: '-21px' }} size='3x' icon={faPowerOff} />
)
export const iconBookmark = (
  <FontAwesomeIcon style={{ marginLeft: '-16px' }} size='3x' icon={faBookmark} />
)
export const iconDoorClosed = (
  <FontAwesomeIcon style={{ marginLeft: '-26px' }} size='3x' icon={faDoorClosed} />
)
export const iconStar = (
  <FontAwesomeIcon style={{ marginLeft: '-24px' }} size='3x' icon={faStar} />
)
export const iconStarYellow = (
  <FontAwesomeIcon style={{ color: '#f1a545' }} size='1x' icon={faStar} />
)
const iconShareSquare = (
  <FontAwesomeIcon style={{ marginLeft: '-25px' }} size='3x' icon={faShareSquare} />
)

export const causeData = {
  edit: {
    label: 'EDICIÓN',
    color: '#4496f3',
    icon: iconPen
  },
  create: {
    label: ' CREACIÓN',
    color: '#4496f3',
    icon: iconStarOfLife
  },
  acepted: {
    label: 'ACEPTADA',
    color: '#14a352',
    icon: iconClipboardCheck
  },
  rejected: {
    label: 'RECHAZADA',
    color: 'red',
    icon: iconTimesCircle
  },
  comment: {
    label: 'COMENTARIO',
    color: 'brown',
    icon: iconCommentDots
  },
  activate: {
    label: 'ACTIVADA',
    color: 'green',
    icon: iconPowerOff
  },
  deactivate: {
    label: 'DESACTIVADA',
    color: 'red',
    icon: iconPowerOff
  },
  join: {
    label: 'VOLUNTARIO',
    color: '#376496',
    icon: iconUserPlus
  },
  saved: {
    label: 'GUARDADA POR EL USUARIO',
    color: '#674476',
    icon: iconBookmark
  },
  periodEnd: {
    label: 'PERIODO FINALIZADO',
    color: '#ea1000',
    icon: iconDoorClosed
  },
  send: {
    label: 'ENVIADO A EVALUACIÓN',
    color: 'blue',
    icon: iconShareSquare
  }
}

export const moduleData = {
  formCard: {
    label: 'PANEL DE ADMIONITRACION FLASHCARDS'
  },
  area: {
    label: 'ÁREA'
  },
  'business-type': {
    label: 'TIPO EMPRESA'
  },
  climb: {
    label: 'CLIMB'
  },
  contact: {
    label: 'CONTACTO'
  },
  filter: {
    label: 'FILTRO'
  },
  gta: {
    label: 'GTA2030'
  },
  priorization: {
    label: 'PRIORIZACIÓN'
  },
  sector: {
    label: 'SECTOR'
  },
  workAxis: {
    label: 'EJE DE TRABAJO'
  },
  user: {
    label: 'USUARIO'
  },
  notifications: {
    label: 'NOTIFICACIONES'
  },
  flashcards: {
    label: 'FLASHCARD'
  },
  flashcard: {
    label: 'FLASHCARD'
  },
  admin: {
    label: 'ADMIN'
  },
  company: {
    label: 'EMPRESA'
  },
  goodPractices: {
    label: 'INICIATIVAS'
  },
  axesGlobalPact: {
    label: 'EJE DE PACTO GLOBAL'
  }
}
