import * as Yup from 'yup'

export const FormCardValidationSchema = Yup.object().shape({
  gta: Yup.string().required(),
  workAxis: Yup.string().required(),
  businessType: Yup.array()
    .min(1, 'Seleccione por lo menos un ámbito de implementación')
    .required(),
  name: Yup.string().min(2).max(120).required(),
  what: Yup.string().min(2).max(400).required(),
  how1: Yup.string().min(2).max(300),
  how2: Yup.string().min(2).max(300),
  how3: Yup.string().min(2).max(300),
  indicator: Yup.string().min(2).max(200)
  // area: Yup.array().min(1,'Seleccione por lo menos un área').required(),
  // implementationTime: Yup.string().required()
})
