import React from 'react'
import LoadingBar from 'react-redux-loading-bar'
import Layout from 'components/Layout'
import GtaForm from './GtaForm'
import CardBig from 'components/Theme/Cards/CardBig'
import { connect } from 'react-redux'

function GtaCreate(props) {
  return (
    <Layout icon='fas fa-fw fa-globe' title='GTA2030'>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999,
          position: 'fixed'
        }}
      />
      <br />
      <CardBig title={'Agregar nuevo GTA2030'}>
        <GtaForm mode='create' loading={false} user={undefined} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = ({ gtas }) => ({ gtas })

export default connect(mapStateToProps, null)(GtaCreate)
