import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import TableContainer from 'components/Theme/Container/TableContainer'
import {
  goodPracticesGetListRequest,
  goodPracticesGetTotals,
  goodPracticesReload
} from './goodPractices.redux'
import { columns } from './goodPractices.helpers'
import GoodPracticesFilter from 'components/Theme/Buttons/ButtonFilter/GoodPracticesFilter'

function GoodPracticesList({
  goodPracticesReload,
  goodPracticesGetListRequest,
  goodPracticesGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    goodPracticesReload()
    goodPracticesGetListRequest(filters)
    goodPracticesGetTotals()
  }, [goodPracticesReload, goodPracticesGetListRequest, goodPracticesGetTotals, filters])

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      filters.status = null
      goodPracticesGetListRequest()
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      filters.status = 2
      goodPracticesGetListRequest(filters)
    }
    if (type === 'pending') {
      filters.page = 1
      filters.active = true
      filters.status = 0
      goodPracticesGetListRequest(filters)
    }
    if (type === 'rejected') {
      filters.page = 1
      filters.active = null
      filters.status = 3
      goodPracticesGetListRequest(filters)
    }
  }

  const goodPractices = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/goodPractices/${row._id}`)
    }
  }

  return (
    <Layout icon='fas fa-fw fa-user-plus' title='Súmate'>
      <GoodPracticesFilter
        pending={props.totals.totalPending}
        all={props.totals.total}
        active={props.totals.totalAction}
        inactive={props.totals.innactive}
        rejected={props.totals.rejected}
        handleClickAll={handleClick}
      />
      <TableContainer
        title='Inciativas'
        type='flashcard'
        subtitle='Lista'
        buttonAdd={false}>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          filter={filters}
          totalDocs={totalDocs}
          onTableChange={goodPracticesGetListRequest}
          data={goodPractices}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = ({ goodPractices }) => {
  const { all, one, error, loading, filters, totals } = goodPractices
  return {
    all,
    one,
    error,
    loading,
    filters,
    totals
  }
}

const mapDispatchToProps = {
  goodPracticesGetListRequest,
  goodPracticesGetTotals,
  goodPracticesReload
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodPracticesList)
