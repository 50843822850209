import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import {
  TIMEINITIATIVE_GET_ALL_REQUEST,
  TIMEINITIATIVE_GET_ALL_FAILURE,
  TIMEINITIATIVE_GET_ALL_SUCCESS,
  TIMEINITIATIVE_CREATE_SUCCESS,
  TIMEINITIATIVE_CREATE_FAILURE,
  TIMEINITIATIVE_CREATE_REQUEST,
  TIMEINITIATIVE_GET_ONE_FAILURE,
  TIMEINITIATIVE_GET_ONE_SUCCESS,
  TIMEINITIATIVE_GET_ONE_REQUEST,
  TIMEINITIATIVE_REMOVE_SUCCESS,
  TIMEINITIATIVE_REMOVE_FAILURE,
  TIMEINITIATIVE_REMOVE_REQUEST,
  TIMEINITIATIVE_EDIT_SUCCESS,
  TIMEINITIATIVE_EDIT_REQUEST,
  TIMEINITIATIVE_FILTER_CHANGE_SUCCESS,
  TIMEINITIATIVE_FILTER_CHANGE_FAILURE,
  TIMEINITIATIVE_FILTER_CHANGE_REQUEST,
  TIMEINITIATIVE_REACTIVATE_SUCCESS,
  TIMEINITIATIVE_REACTIVATE_FAILURE,
  TIMEINITIATIVE_REACTIVATE_REQUEST,
  TIMEINITIATIVE_GET_TOTALS_SUCCESS,
  TIMEINITIATIVE_GET_TOTALS_FAILURE,
  TIMEINITIATIVE_GET_TOTALS_REQUEST
} from './timeInitiatives.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'
import { push } from 'connected-react-router'

function* getAllTimeInitiativeSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/timeInitiatives?${filterString}`)
    yield delay(1000)
    yield put({ type: TIMEINITIATIVE_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: TIMEINITIATIVE_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneTimeInitiativeSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/timeInitiatives/${_id}`)
    yield delay(1000)
    yield put({ type: TIMEINITIATIVE_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: TIMEINITIATIVE_GET_ONE_FAILURE, error: error.response })
  }
}
function* createTimeInitiativeSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/timeInitiatives', payload)
    yield delay(1500)
    yield put({ type: TIMEINITIATIVE_CREATE_SUCCESS, payload: insert })
    yield put(push('/timeInitiatives'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: TIMEINITIATIVE_CREATE_FAILURE, error: error.response })
  }
}

function* getTimeInitiativeFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: TIMEINITIATIVE_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: TIMEINITIATIVE_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: TIMEINITIATIVE_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editTimeInitiativeSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())

    const historical = { description }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/timeInitiatives/' + payload._id, payload)
    yield put({ type: TIMEINITIATIVE_EDIT_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/timeInitiatives'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: TIMEINITIATIVE_CREATE_FAILURE, error: error.response })
  }
}

function* removeTimeInitiativeSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/timeInitiatives/' + payload.id + '/deactivate',
      payload
    )
    yield put({ type: TIMEINITIATIVE_REMOVE_SUCCESS, payload: insert })
    yield put({ type: TIMEINITIATIVE_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/timeInitiatives'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: TIMEINITIATIVE_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateTimeInitiativeSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/timeInitiatives/' + payload.id + '/reactivate',
      payload
    )
    yield put({ type: TIMEINITIATIVE_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: TIMEINITIATIVE_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/timeInitiatives'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: TIMEINITIATIVE_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/timeInitiatives/totals`)
    yield delay(1000)
    yield put({ type: TIMEINITIATIVE_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: TIMEINITIATIVE_GET_TOTALS_FAILURE, error: error.response })
  }
}
export default function* timeInitiativeSagas() {
  yield takeLatest(TIMEINITIATIVE_GET_ALL_REQUEST, getAllTimeInitiativeSaga)
  yield takeLatest(TIMEINITIATIVE_GET_ONE_REQUEST, getOneTimeInitiativeSaga)
  yield takeLatest(TIMEINITIATIVE_CREATE_REQUEST, createTimeInitiativeSaga)
  yield takeLatest(TIMEINITIATIVE_EDIT_REQUEST, editTimeInitiativeSaga)
  yield takeLatest(TIMEINITIATIVE_GET_ALL_FAILURE, manageError)
  yield takeLatest(TIMEINITIATIVE_GET_ONE_FAILURE, manageError)
  yield takeLatest(TIMEINITIATIVE_REMOVE_REQUEST, removeTimeInitiativeSaga)
  yield takeLatest(TIMEINITIATIVE_REMOVE_FAILURE, manageError)
  yield takeLatest(TIMEINITIATIVE_FILTER_CHANGE_REQUEST, getTimeInitiativeFiltersSaga)
  yield takeLatest(TIMEINITIATIVE_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(TIMEINITIATIVE_REACTIVATE_REQUEST, reactivateTimeInitiativeSaga)
  yield takeLatest(TIMEINITIATIVE_REACTIVATE_FAILURE, manageError)
  yield takeLatest(TIMEINITIATIVE_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(TIMEINITIATIVE_GET_TOTALS_FAILURE, manageError)
}
