import React from 'react'
import PropTypes from 'prop-types'

const CardAmbitList = ({ list, css, cssUl }) => {
  return (
    <ul className={cssUl}>
      {list.map(item => (
        <li className={css} key={item._id}>
          {item.name}
        </li>
      ))}
    </ul>
  )
}
CardAmbitList.propTypes = {
  list: PropTypes.array,
  css: PropTypes.string,
  cssUl: PropTypes.string
}
CardAmbitList.defaultProps = {
  list: [],
  css: 'flashcard-list-li-front',
  cssUl: ''
}
export default CardAmbitList
