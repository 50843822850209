/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { Row, Col } from 'reactstrap'
import { ListItem } from 'components/Theme/Container/ListItem'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import ImageBorder from 'components/Theme/Images/ImageBorder'
import thumbnail from 'assets/media/profile.png'
import { connect } from 'react-redux'
import {
  adminDeactivateRequest,
  adminReactivateRequest,
  adminGetOneRequest
} from './admin.redux'
import { isEmpty } from 'utils/crud'
import { permissionsGetConfigRequest } from 'components/Modules/Catalogues/Permissions/permissions.redux'
import CardBig from 'components/Theme/Cards/CardBig'
import AdminPermissions from './AdminPermissions'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

export const AdminView = ({
  adminGetOneRequest,
  permissionsGetConfigRequest,
  historicalListRequest,
  userId,
  ...props
}) => {
  const { historical } = props
  const { _id } = props.match.params
  const showButton = _id !== userId
  useEffect(() => {
    adminGetOneRequest({ _id })
    permissionsGetConfigRequest({ _id })
    historicalListRequest({ document: _id })
  }, [adminGetOneRequest, permissionsGetConfigRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (admin.active) {
      props.adminDeactivateRequest({ id: _id })
    }
    if (!admin.active) {
      props.adminReactivateRequest({ id: _id })
    }
  }
  const admin = props.one || {}

  const roles = ['Super Usuario', 'Comité editorial', 'Participante']
  return (
    <Layout icon='fas fa-user-cog' title='Administrador'>
      <TableContainer
        title='Administrador'
        subtitle='Información del registro'
        buttonAdd={false}
        view={`/admins/${_id}/edit`}
        buttonEdit={showButton}
        active={admin.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(admin) && (
          <Row id='view-admin'>
            <Col md='4' className='flex justify-content-end'>
              <ImageBorder
                src={admin.thumbnail ? admin.thumbnail : thumbnail}
                height='190'
                width='190'
              />
            </Col>
            <Col md='4'>
              <ul>
                <ListItem label='Nombre(s)' value={admin.name} />
                <ListItem label='Apellido(s)' value={admin.lastName} />
                <ListItem label='Rol' value={roles[admin.role]} />
                <ListItem label='Creado' value={mdy(admin.createdAt)} />
                <ListItem label='Actualizado' value={mdy(admin.updatedAt)} />
              </ul>
            </Col>
            <Col md='4'>
              <ul>
                <ListItem
                  label='GTA'
                  value={admin.gta === null ? 'Todos' : admin.gta?.title}
                />
                <ListItem label='Correo' value={admin.email} />
                <ListItem
                  label='País'
                  value={admin.country === 'Mexico' ? 'México' : admin.country}
                />
                <ListItem label='Estado' value={admin.state} />
                <ListItem label='Ciudad' value={admin.city} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      {admin.role !== 0 && (
        <div style={{ margin: '20px 27px' }}>
          <CardBig title={'Permisos'}>
            <AdminPermissions
              admin={props.match?.params}
              user={props.one}
              mode='view'
              fields={props.permissions?.list}
            />
          </CardBig>
        </div>
      )}

      {admin.role === 0 && (
        <div style={{ margin: '20px 27px' }}>
          <CardBig title={'Permisos'}>
            <p>El Super usuario cuenta con todos los permisos</p>
          </CardBig>
        </div>
      )}
      <div
        className='overflow-box'
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}>
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.admins,
    one: state.admins.one,
    permissions: state.permissions,
    error: state.admins.error,
    userLoading: state.admins.loading,
    filters: state.admins.filters,
    historical: state.historicals.list,
    userId: state.admin._id
  }
}

const mapDispatchToProps = {
  adminGetOneRequest,
  adminDeactivateRequest,
  adminReactivateRequest,
  permissionsGetConfigRequest,
  historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminView)
