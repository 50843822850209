import GoodPracticesEdit from './GoodPracticesEdit'
import GoodPracticesList from './GoodPracticesList'
import GoodPracticeView from './GoodPracticeView'

const goodPracticesRoutes = [
  { path: '/goodPractices', component: GoodPracticesList },
  { path: '/goodPractices/:_id', component: GoodPracticeView },
  { path: '/goodPractices/:id/edit', component: GoodPracticesEdit }
]

export default goodPracticesRoutes
