import React from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'
import CardDetailFront from '../Cards/CardDetailFront'
import PriorizationEditRating from './PriorizationEditRating'

function ModalPriorizationContent({
  gta,
  gtaLogo,
  flashcards,
  solution,
  sector,
  businessType,
  image,
  workAxis,
  ownership,
  number,
  color,
  imageGta,
  coverage,
  impact,
  budget,
  benefit,
  infrastructure,
  humanResources,
  replicabilty,
  id,
  priorization
}) {
  // const splitBussinesType = businessType.toString().split(',')
  const businessTypeList = businessType.map(e => {
    return { _id: e._id, name: e.name }
  })
  const data = {
    gta,
    gtaLogo,
    flashcards,
    sector,
    businessType: businessTypeList,
    image,
    workAxis,
    area: { name: ownership },
    gta: { logo: gtaLogo, color, title: gta },
    workAxis,
    name: solution,
    number
  }
  return (
    <>
      <ModalHeader style={{ color: color }}>
        <p>{gta}</p>
        <p>Flashcards #{number}</p>
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12'>
            <CardDetailFront data={data} />
          </div>
          <div className='col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12'>
            <PriorizationEditRating
              flashcards={flashcards}
              gta={gta}
              number={number + ''}
              solution={solution}
              image={image}
              imageGta={imageGta}
              sector={sector}
              ownership={ownership}
              coverage={coverage}
              impact={impact}
              budget={budget}
              benefit={benefit}
              infrastructure={infrastructure}
              replicabilty={replicabilty}
              color={color}
              id={id}
              humanResources={humanResources}
              priorization={priorization}
            />
          </div>
        </div>
      </ModalBody>
    </>
  )
}

export default ModalPriorizationContent
