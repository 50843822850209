import AreaList from './AreaList'
import AreaView from './AreaView'
import AreaCreate from './AreaCreate'
import AreaEdit from './AreaEdit'

const routes = [
  { path: '/areas', component: AreaList },
  { path: '/areas/create', component: AreaCreate },
  { path: '/areas/:_id', component: AreaView },
  { path: '/areas/:_id/edit', component: AreaEdit }
]

export default routes
