import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CardDetailClimb from './CardDetailClimb.js'
import CardDetailFrontSmall from './CardDetailFrontSmall.js'

export const Card = ({ data }) => {
  const { _id, gta, workAxis, name, businessType, what, number, climb } = data
  return (
    <div
      className='col-md-3 cards__card-dimension animate__animated animate__fadeIn animate__faster'
      style={{ maxWidth: '298px', minWidth: '290px' }}>
      <Link to={`/flashcards/${_id}`}>
        <div className='flashcard-container'>
          <div className='flashcard'>
            {/* ----- FRONT ---- */}
            <CardDetailFrontSmall data={data} />
            {/* ----- BACK ---- */}
            <div className='flashcard-back'>
              <div className='flashcard-main-back'>
                <div className='flashcard-div-border'>
                  <h5 className='flashcard-text-center' style={{ color: gta?.color }}>
                    ¿Qué?
                  </h5>
                  <p className='flashcard-text-solution'>{what}</p>
                  <div
                    className='flashcard-footer-back-indicador'
                    style={{ color: gta?.color }}>
                    Ver más →
                  </div>
                </div>
                <div className='flashcard-footer-back justify-content-center'>
                  <div className='flashcard-footer-back-climb justify-content-center '>
                    {climb?.map(c => (
                      <CardDetailClimb
                        key={c._id}
                        letter={c.nomenclature}
                        color={gta?.color}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    data: state.cardsList
  }
}
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Card)
