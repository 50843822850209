import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  commentDeactivateRequest,
  commentGetOneRequest,
  commentReactivateRequest
} from './comments.redux'

function CommentView({ commentGetOneRequest, ...props }) {
  const { _id } = props.match.params
  const comment = props.one || {}
  useEffect(() => {
    commentGetOneRequest({ _id })
  }, [commentGetOneRequest, _id])

  const handleActiveToggle = () => {
    if (comment.active) {
      props.commentDeactivateRequest({ id: _id, user: comment.createdBy._id })
    }
    if (!comment.active) {
      props.commentReactivateRequest({ id: _id, user: comment.createdBy._id })
    }
  }
  return (
    <Layout icon='fas fa-comments'>
      <TableContainer
        title='Comentario'
        subtitle='Información de comentario'
        buttonAdd={false}
        view={`/comments/${_id}/edit`}
        buttonDeactivate={true}
        active={comment.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(comment) && (
          <Row id='view-comment'>
            <Col>
              <ul>
                <ListItem label='Nombre' value={comment.createdBy.name} />
                <ListItem label='Comentario' value={comment.comment} />
                <ListItem label='Flashcard' value={comment.flashcard.name} />
                <ListItem label='Activo' value={comment.active} />
                <ListItem label='Creado' value={mdy(comment.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.comments.all,
    one: state.comments.one,
    error: state.comments.error,
    userLoading: state.comments.loading,
    filters: state.comments.filters,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  commentGetOneRequest,
  commentDeactivateRequest,
  commentReactivateRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(CommentView)
