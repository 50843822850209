import { generateActiveIcon, generateStatusIcon } from 'components/Theme/Table/helpers'
import { total } from 'components/Theme/Table/helpers/index'
import { mdy } from 'utils/dates'

export const columns = [
  {
    dataField: 'numberOfVolunteers',
    text: 'Voluntarios capturados',
    sort: true,
    width: '30%',
    align: 'center',
    formatter: (cell, row) => cell || '0'
  },
  {
    dataField: 'volunteers',
    text: 'Voluntarios inscritos',
    sort: true,
    width: '30%',
    align: 'center',
    formatter: (cell, row) => {
      const numberVolunteers = total(cell)
      return row.aceptVolunteers ? numberVolunteers : '0'
    }
  },
  {
    dataField: 'name',
    text: 'Nombre de iniciativa',
    sort: true
  },
  {
    dataField: 'ods',
    text: 'Objetivo de Desarrollo Sostenible',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'active',
    align: 'center',
    width: '210',
    text: 'Activa',
    formatter: cell => (!!cell ? 'Sí' : 'No'),
    sort: true
  },
  {
    dataField: 'status',
    align: 'center',
    width: '210',
    text: 'Status',
    formatter: cell => generateStatusIcon(cell),
    sort: true
  }
]

export const columnsVolunteers = [
  {
    dataField: 'name',
    text: 'Nombres',
    sort: true
  },
  {
    dataField: 'lastName',
    text: 'Apellidos',
    sort: true
  },
  {
    dataField: 'email',
    text: 'Correo de Usuario',
    sort: true
  },
  {
    dataField: 'country',
    text: 'País',
    sort: true,
    formatter: (cell, row) => (row.country === 'Mexico' ? 'México' : cell)
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
