// Type's
export const OBJECTIVE_GET_ALL_REQUEST = 'OBJECTIVE_GET_ALL_REQUEST'
export const OBJECTIVE_GET_ALL_SUCCESS = 'OBJECTIVE_GET_ALL_SUCCESS'
export const OBJECTIVE_GET_ALL_FAILURE = 'OBJECTIVE_GET_ALL_FAILURE'

export const OBJECTIVE_CREATE_REQUEST = 'OBJECTIVE_CREATE_REQUEST'
export const OBJECTIVE_CREATE_SUCCESS = 'OBJECTIVE_CREATE_SUCCESS'
export const OBJECTIVE_CREATE_FAILURE = 'OBJECTIVE_CREATE_FAILURE'

export const OBJECTIVE_GET_ONE_REQUEST = 'OBJECTIVE_GET_ONE_REQUEST'
export const OBJECTIVE_GET_ONE_SUCCESS = 'OBJECTIVE_GET_ONE_SUCCESS'
export const OBJECTIVE_GET_ONE_FAILURE = 'OBJECTIVE_GET_ONE_FAILURE'

export const OBJECTIVE_EDIT_REQUEST = 'OBJECTIVE_EDIT_REQUEST'
export const OBJECTIVE_EDIT_SUCCESS = 'OBJECTIVE_EDIT_SUCCESS'
export const OBJECTIVE_EDIT_FAILURE = 'OBJECTIVE_EDIT_FAILURE'

export const OBJECTIVE_REMOVE_REQUEST = 'OBJECTIVE_REMOVE_REQUEST'
export const OBJECTIVE_REMOVE_SUCCESS = 'OBJECTIVE_REMOVE_SUCCESS'
export const OBJECTIVE_REMOVE_FAILURE = 'OBJECTIVE_REMOVE_FAILURE'

export const OBJECTIVE_REACTIVATE_REQUEST = 'OBJECTIVE_REACTIVATE_REQUEST'
export const OBJECTIVE_REACTIVATE_SUCCESS = 'OBJECTIVE_REACTIVATE_SUCCESS'
export const OBJECTIVE_REACTIVATE_FAILURE = 'OBJECTIVE_REACTIVATE_FAILURE'

export const OBJECTIVE_FILTER_CHANGE_REQUEST = 'OBJECTIVE_FILTER_CHANGE_REQUEST'
export const OBJECTIVE_FILTER_CHANGE_SUCCESS = 'OBJECTIVE_FILTER_CHANGE_SUCCESS'
export const OBJECTIVE_FILTER_CHANGE_FAILURE = 'OBJECTIVE_FILTER_CHANGE_FAILURE'

export const OBJECTIVE_GET_TOTALS_REQUEST = 'OBJECTIVE_GET_TOTALS_REQUEST'
export const OBJECTIVE_GET_TOTALS_SUCCESS = 'OBJECTIVE_GET_TOTALS_SUCCESS'
export const OBJECTIVE_GET_TOTALS_FAILURE = 'OBJECTIVE_GET_TOTALS_FAILURE'

export const OBJECTIVE_GET_CATALOGS_REQUEST = 'OBJECTIVE_GET_CATALOGS_REQUEST'
export const OBJECTIVE_GET_CATALOGS_SUCCESS = 'OBJECTIVE_GET_CATALOGS_SUCCESS'
export const OBJECTIVE_GET_CATALOGS_FAILURE = 'OBJECTIVE_GET_CATALOGS_FAILURE'

// Action's
export const objectiveGetAllRequest = (filters = {}) => ({
  type: OBJECTIVE_GET_ALL_REQUEST,
  filters
})
export const objectiveGetAllSuccess = (payload) => ({
  type: OBJECTIVE_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: OBJECTIVE_GET_ALL_REQUEST,
  error
})
export const objectiveFiltersChangeRequest = (filters) => ({
  type: OBJECTIVE_FILTER_CHANGE_REQUEST,
  filters
})

export const objectiveCreateRequest = ({ values }) => ({
  type: OBJECTIVE_CREATE_REQUEST,
  payload: values
})
export const objectiveEditRequest = ({ values }) => ({
  type: OBJECTIVE_EDIT_REQUEST,
  payload: values
})
export const objectiveGetOneRequest = (payload) => ({
  type: OBJECTIVE_GET_ONE_REQUEST,
  payload
})
export const objectiveGetOneSuccess = (payload) => ({
  type: OBJECTIVE_GET_ONE_SUCCESS,
  payload
})
export const objectiveGetOneFailure = (payload) => ({
  type: OBJECTIVE_GET_ONE_FAILURE,
  payload
})
export const objectiveDeactivateRequest = (payload) => ({
  type: OBJECTIVE_REMOVE_REQUEST,
  payload
})
export const objectiveReactivateRequest = (payload) => ({
  type: OBJECTIVE_REACTIVATE_REQUEST,
  payload
})
export const objectiveCreateSuccess = (payload) => ({ type: OBJECTIVE_CREATE_SUCCESS, payload })
export const objectiveCreateFailure = (payload) => ({ type: OBJECTIVE_CREATE_FAILURE, payload })
export const objectiveGetTotals = (payload) => ({ type: OBJECTIVE_GET_TOTALS_REQUEST, payload })


// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  filters: {},
  totals: {}
}

export function objective(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OBJECTIVE_GET_ALL_REQUEST:
    case OBJECTIVE_CREATE_REQUEST:
    case OBJECTIVE_GET_ONE_REQUEST:
    case OBJECTIVE_EDIT_REQUEST:
    case OBJECTIVE_REMOVE_REQUEST:
    case OBJECTIVE_REACTIVATE_REQUEST:
    case OBJECTIVE_GET_TOTALS_REQUEST:
      return { ...state, error: null, loading: true }

    case OBJECTIVE_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case OBJECTIVE_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case OBJECTIVE_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case OBJECTIVE_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case OBJECTIVE_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case OBJECTIVE_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case OBJECTIVE_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }

    case OBJECTIVE_GET_ALL_FAILURE:
    case OBJECTIVE_CREATE_FAILURE:
    case OBJECTIVE_GET_ONE_FAILURE:
    case OBJECTIVE_EDIT_FAILURE:
    case OBJECTIVE_REMOVE_FAILURE:
    case OBJECTIVE_REACTIVATE_FAILURE:
    case OBJECTIVE_GET_TOTALS_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}
