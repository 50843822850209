import React from 'react'
import { Formik, Form } from 'formik'
import { recoverAdminRequest } from './auth.redux.js'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import FormInput from 'components/FormInput'

const ResetForm = ({ loading, recoverAdminRequest }) => {
  const { token } = useParams()
  const params = token.split('&')
  const recoverPasswordToken = params[0]
  const email = params[1]

  return (
    <div className='recover__password'>
      <h3 className='auth__title'>Cambiar contraseña</h3>
      <Formik
        initialValues={{
          email: email,
          recoverPasswordToken: recoverPasswordToken,
          password: '',
          cfrmPassword: ''
        }}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'Requerido'
          if (
            values.password &&
            values.cfrmPassword &&
            values.password !== values.cfrmPassword
          )
            errors.password = 'Las contraseñas no coinciden'
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            recoverAdminRequest(values, recoverPasswordToken, email)
            setSubmitting(false)
          }, 400)
        }}>
        {({ handleSubmit, values, handleChange }) => (
          <Form onSubmit={handleSubmit} className='form recover__form'>
            <FormInput
              id='password'
              type='password'
              label='Contraseña nueva'
              onChange={handleChange}
              value={values.password}
            />
            <FormInput
              id='cfrmPassword'
              type='password'
              label='Confirmar contraseña'
              onChange={handleChange}
              value={values.cfrmPassword}
            />
            <div>
              <button
                type='submit'
                className='btn btn-primary btn-block mt-3'
                disabled={loading}>
                Cambiar contraseña
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    loading: state.auth.loading
  }
}

const mapDispatchToProps = {
  recoverAdminRequest: recoverAdminRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetForm)
