// Type's
export const MODULES_GET_ALL_REQUEST = 'MODULES_GET_ALL_REQUEST'
export const MODULES_GET_ALL_SUCCESS = 'MODULES_GET_ALL_SUCCESS'
export const MODULES_GET_ALL_FAILURE = 'MODULES_GET_ALL_FAILURE'

export const MODULES_CREATE_REQUEST = 'MODULES_CREATE_REQUEST'
export const MODULES_CREATE_SUCCESS = 'MODULES_CREATE_SUCCESS'
export const MODULES_CREATE_FAILURE = 'MODULES_CREATE_FAILURE'

// Action's
export const modulesGetAllRequest = () => ({
  type: MODULES_GET_ALL_REQUEST
})
export const modulesGetAllSuccess = payload => ({
  type: MODULES_GET_ALL_SUCCESS,
  payload
})
export const modulesGetAllFailure = error => ({
  type: MODULES_GET_ALL_FAILURE,
  error
})
export const modulesCreateRequest = ({ values }) => ({
  type: MODULES_CREATE_REQUEST,
  payload: values
})
export const modulesCreateSuccess = payload => ({
  type: MODULES_CREATE_SUCCESS,
  payload
})
export const modulesCreateFailure = payload => ({
  type: MODULES_CREATE_FAILURE,
  payload
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null
}

export function modules(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MODULES_GET_ALL_REQUEST:
    case MODULES_CREATE_REQUEST:
      return { ...state, error: null, loading: true }
    case MODULES_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: [...action.payload] }
    case MODULES_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case MODULES_GET_ALL_FAILURE:
    case MODULES_CREATE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
