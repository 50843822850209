import React from 'react'
import NotificationList from './Notifications'
import profile from 'assets/media/profile.png'
import { connect } from 'react-redux'
import { checkloginRequest, logoutRequest } from 'components/Modules/Auth/auth.redux.js'
import { useHistory } from 'react-router'

function Header({ admin, search = false, ...props }) {
  let history = useHistory()
  const handleLogout = () => {
    props.logoutRequest()
  }
  return (
    <nav className='navbar navbar-expand navbar-dark  topbar mb-4 static-top shadow'>
      <button id='sidebarToggleTop' className='btn btn-link d-md-none rounded-circle mr-3'>
        <i className='fa fa-bars' />
      </button>
      {search && (
        <form className='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
          <div className='input-group'>
            <input
              type='text'
              className='form-control bg-dark border-0 small'
              placeholder='Search for...'
              aria-label='Search'
              aria-describedby='basic-addon2'
            />
            <div className='input-group-append'>
              <button className='btn btn-primary' type='button'>
                <i className='fas fa-search fa-sm'></i>
              </button>
            </div>
          </div>
        </form>
      )}
      <ul className='navbar-nav ml-auto'>
        <NotificationList />
        {/* <Message /> */}

        <div className='topbar-divider d-none d-sm-block' />
        <li className='nav-item dropdown no-arrow'>
          <a
            className='nav-link dropdown-toggle'
            href='javascript:void(0)'
            id='userDropdown'
            role='button'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'>
            <span className='mr-2 d-none d-lg-inline text-white-600 small'>
              {admin?.name} {admin?.lastName}
            </span>
            <img
              className='img-profile rounded-circle'
              src={admin?.thumbnail || profile}
              alt='profile'
            />
          </a>
          <div
            className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
            aria-labelledby='userDropdown'>
            <ItemDropdown
              label='Mi perfil'
              className='fas fa-user fa-sm fa-fw mr-2 text-white-400'
              onClick={() => history.push(`/admins/${admin?._id}`)}
            />
            <ItemDropdown
              label='Cerrar Sesión'
              className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-white-400'
              onClick={() => handleLogout()}
            />
          </div>
        </li>
      </ul>
    </nav>
  )
}

const ItemDropdown = ({ label, url, className, onClick }) => {
  return (
    <a style={{ cursor: 'pointer' }} className='dropdown-item' href={url} onClick={onClick}>
      <i className={className}></i>
      {label}
    </a>
  )
}

const mapStateToProps = state => {
  return {
    admin: state.admin
  }
}

const mapDispatchToProps = {
  checkloginRequest: checkloginRequest,
  logoutRequest: logoutRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
