import axios from 'axios'
import * as Yup from 'yup'

//Homologacion de validaciones
export const AdminValidationSchema = Yup.object().shape({
  name: Yup.string().min(2).max(50).required(),
  lastName: Yup.string().min(2).max(50).required(),
  email: Yup.string()
    .email('Ingrese un correo válido')
    .test('Unique Email', 'Este correo ya existe', function (value) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/admins?email=${value}`).then(res => {
          if (res.data.data.docs.map(admin => admin.email)[0] === value) {
            resolve(false)
          }
          resolve(true)
        })
      })
    }),
  company: Yup.string().required(),
  role: Yup.string().required(),
  gta: Yup.string().required(),
  state: Yup.string().required(),
  country: Yup.string().required(),
  gender: Yup.string().required()
})

export const AdminValidationSchemaUpdate = Yup.object().shape({
  name: Yup.string().min(2).max(50).required(),
  lastName: Yup.string().min(2).max(50).required(),
  email: Yup.string().email('Formato invalido').required(),
  company: Yup.string().required(),
  role: Yup.string().required(),
  gta: Yup.string().required(),
  state: Yup.string().required(),
  country: Yup.string().required(),
  gender: Yup.string().required()
})
