import axios from 'axios'

/**
 * @desc brief description
 * @param token ...
 * @return bool - success or failure
 */
export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

export const labelsModules = {
  admins: 'Administradores',
  areas: 'Áreas Responsables',
  'business-types': 'Tipos de empresas',
  carouselCards: 'Carrusel',
  climbs: 'CLIMB',
  comments: 'Comentarios',
  companies: 'Empresas',
  goodPractices: 'Súmate',
  contacts: 'Contactos',
  filters: 'Filtros',
  flashcards: 'Flashcards Creadas',
  gtas: 'GTA2030',
  workAxes: 'Ejes de trabajo',
  objectives: 'Objetivos de Desarrollo Sostenible',
  'business-join-types': 'Ámbitos de implementación',
  'business-joinsub-types': 'Súmate - Tipos de Empresa',
  users: 'Usuarios',
  timeInitiatives: 'Duración iniciativas',
  axesGlobalPact: 'Ejes del Pacto Global',
  sectors: 'Sectores',
  priorizations: 'Priorizaciones',
  formCards: 'Panel de Administración Flashcards'
}

// check if is better const currentTime = Date.now() / 1000
export const isExpired = exp => exp < Date.now() / 1000

export const removeCredentials = () => {
  // Remove token from localStorage
  localStorage.removeItem('token')
  // Remove the auth header for future requests
  setAuthToken(false)
}
