import React, { Fragment } from 'react'
import { Button, Table } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { adminEditPermitsRequest } from './admin.redux'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { labelsModules } from 'utils/auth.js'
import Acl from 'components/ACL/Acl'
import { showWarningMessage } from 'components/Theme/Alerts/exceptionUtils'

export const AdminPermissions = ({ fields, mode, admin }) => {
  const dispatch = useDispatch()
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={fields}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'edit') {
              return dispatch(adminEditPermitsRequest(values))
            }
            if (mode === 'view') {
              setSubmitting(false)
            }
            setSubmitting(false)
          }, 600)
        }}>
        {({ values, handleChange }) => (
          <Form>
            <Table className='matrix-permissions'>
              <thead>
                <tr className='text-center'>
                  <th>Módulo</th>
                  <th>Consultar</th>
                  <th>Crear</th>
                  <th>Actualizar</th>
                  <th>Eliminar</th>
                  <th>Aprobar</th>
                </tr>
              </thead>
              <tbody>
                {fields &&
                  fields.map((p, index) => {
                    return (
                      p.module?.name !== 'comments' && (
                        <tr key={p?._id}>
                          <td className='text-center'>{labelsModules[p.module?.name]}</td>
                          <td
                            style={{
                              paddingLeft: '10.5%',
                              paddingBottom: '28px'
                            }}>
                            <Field
                              checked={p?.permissions?.read}
                              className='form-check-input'
                              onClick={e =>
                                p.permissions.create ||
                                p.permissions.update ||
                                p.permissions.destroy ||
                                p.permissions.approve
                                  ? showWarningMessage(
                                      'Aviso',
                                      'Si tiene otros permisos activados el permiso de consultar no puede ser deshabilitado'
                                    )
                                  : (p.permissions.read = e.target.checked)
                              }
                              type='checkbox'
                              name={`consult${index}`}
                              disabled={mode === 'view' ? true : false}
                              id={`consult${index}`}
                              value='One'
                            />
                          </td>
                          <td style={{ paddingLeft: '7.4%', paddingBottom: '28px' }}>
                            <Field
                              checked={p?.permissions?.create}
                              className='form-check-input'
                              onClick={e => {
                                p.permissions.read = p.permissions.read || e.target.checked
                                p.permissions.create = e.target.checked
                              }}
                              type='checkbox'
                              name={`create${index}`}
                              disabled={
                                mode === 'view'
                                  ? true
                                  : [
                                      'comments',
                                      'contacts',
                                      'flashcards',
                                      'priorizations',
                                      'users'
                                    ].includes(p.module?.name)
                              }
                              id={`create${index}`}
                              value='One'
                            />
                          </td>
                          <td
                            style={{
                              paddingLeft: '10.8%',
                              paddingBottom: '28px'
                            }}>
                            <Field
                              checked={p?.permissions?.update}
                              className='form-check-input'
                              onClick={e => {
                                p.permissions.read = p.permissions.read || e.target.checked
                                p.permissions.update = e.target.checked
                              }}
                              type='checkbox'
                              name={`update${index}`}
                              disabled={
                                mode === 'view'
                                  ? true
                                  : [
                                      'carouselCards',
                                      'comments',
                                      'contacts',
                                      'flashcards',
                                      'users'
                                    ].includes(p.module?.name)
                              }
                              id={`update${index}`}
                              value='One'
                            />
                          </td>
                          <td style={{ paddingLeft: '9.1%', paddingBottom: '28px' }}>
                            <Field
                              checked={p?.permissions?.destroy}
                              className='form-check-input'
                              onClick={e => {
                                p.permissions.read = p.permissions.read || e.target.checked
                                p.permissions.destroy = e.target.checked
                              }}
                              type='checkbox'
                              name={`delete${index}`}
                              disabled={
                                mode === 'view'
                                  ? true
                                  : [
                                      'admins',
                                      'carouselCards',
                                      'comments',
                                      'contacts',
                                      'flashcards',
                                      'priorizations',
                                      'formCards'
                                    ].includes(p.module?.name)
                              }
                              id={`delete${index}`}
                              value='One'
                            />
                          </td>
                          <td
                            style={{
                              paddingLeft: '10.2%',
                              paddingBottom: '28px'
                            }}>
                            <Field
                              checked={p?.permissions?.approve}
                              className='form-check-input'
                              onClick={e => {
                                p.permissions.read = p.permissions.read || e.target.checked
                                p.permissions.approve = e.target.checked
                              }}
                              type='checkbox'
                              name={`approve${index}`}
                              disabled={
                                mode === 'view'
                                  ? true
                                  : p.module?.name !== 'formCards' &&
                                    p.module?.name !== 'goodPractices'
                              }
                              id={`approve${index}`}
                              value='One'
                            />
                          </td>
                        </tr>
                      )
                    )
                  })}
              </tbody>
            </Table>
            {mode === 'edit' ? (
              <Fragment style={{ justifyContent: 'flex-end' }}>
                <Button
                  color='primary'
                  type='submit'
                  style={{ display: 'table', marginTop: '7px' }}
                  disabled={false}>
                  <i className='fas fa-save mr-1' /> Guardar
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Link to={`/admins/${admin?._id}/permission`}>
                  <Acl action='update'>
                    <Button
                      color='primary'
                      style={{ margin: '17px 5px 14px 0' }}
                      disabled={false}>
                      <i className='fas fa-edit mr-1' />
                      Editar permisos{' '}
                    </Button>
                  </Acl>
                </Link>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AdminPermissions
