import React, { useEffect } from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { Formik, Form, Field } from 'formik'
import { connect } from 'react-redux'
import { AdminValidationSchema, AdminValidationSchemaUpdate } from './AdminValidation'
import FormInput from 'components/FormInput'
import {
  adminCreateRequest,
  adminEditRequest,
  adminGetCatalogs,
  adminGetCities,
  adminGetStates
} from './admin.redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Row } from 'reactstrap'
import { Col } from 'reactstrap'

function AdminForm({
  mode,
  user,
  loading,
  catalogs,
  adminGetCatalogs,
  adminGetStates,
  adminGetCities,
  ...props
}) {
  useEffect(() => {
    adminGetCatalogs()
    if (mode === 'edit') {
      if (user.country) adminGetStates(user.country)
      if (user.state) adminGetCities(user.country, user.state)
    }
  }, [adminGetCatalogs, adminGetStates, adminGetCities, mode, user])

  const handleCreateAdmin = values => {
    props.adminCreateRequest({ values })
  }
  const handleEditAdmin = values => {
    props.adminEditRequest({ values })
  }

  const roles = [
    { id: 0, text: 'Super Usuario', value: 0 },
    { id: 1, text: 'Comité editorial', value: 1 },
    { id: 2, text: 'Participante', value: 2 }
  ]

  const countryOnChange = (values, e) => {
    values.country = e.target.value
    user.country = e.target.value
    user.city = ''
    adminGetStates(e.target.value)
  }

  const stateOnChange = (values, e) => {
    values.state = e.target.value
    user.state = e.target.value
    user.city = ''
    adminGetCities(user.country, e.target.value)
  }

  const countries = catalogs.countries?.data?.map(({ name, Iso3 }) => ({
    value: name,
    label: name === 'Mexico' ? 'México' : name,
    text: name === 'Mexico' ? 'México' : name,
    id: name,
    key: Iso3
  }))

  const statesList = catalogs.states?.data?.states?.map(({ name }) => ({
    value: name,
    label: name,
    text: name,
    id: name,
    key: name
  }))

  const citiesList = catalogs.cities.data?.map(city => ({
    value: city,
    label: city,
    id: city,
    key: city
  }))

  const genders = [
    { id: '1', value: 'Femenino', text: 'Femenino' },
    { id: '2', value: 'Masculino', text: 'Masculino' },
    { id: '3', value: 'Otro', text: 'Otro' }
  ]

  let initialValuesData = {
    name: '',
    lastName: '',
    email: '',
    country: '',
    company: '',
    role: '',
    gender: '',
    gta: '',
    state: '',
    city: '',
    thumbnail: '',
    imageFile: {}
  }
  if (mode === 'edit') {
    initialValuesData = {
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      country: user.country,
      company: user.company.name,
      role: user.role,
      gender: user.gender,
      gta: user.gta === null ? '61bd3f1ae2e0b394e27faa33' : user.gta?._id,
      state: user.state,
      _id: user._id,
      city: user.city,
      thumbnail: '',
      imageFile: {}
    }
  }
  const changeCompany = (e, values) => {
    values.company = e.value
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={
          mode === 'create' ? AdminValidationSchema : AdminValidationSchemaUpdate
        }
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateAdmin(values)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleEditAdmin(values)
              setSubmitting(false)
            }
          }, 600)
        }}>
        {({ values, handleChange, setFieldValue }) => (
          <Form className='form' encType='multipart/form-data'>
            <Row>
              <Col md={6}>
                <ImgCrop rotate grid>
                  <Field
                    component={Upload}
                    error={false}
                    method={false}
                    listType='picture-card'
                    fileList={values.avatars}
                    value={values.thumbnail}
                    onChange={({ fileList: newFileList }) => {
                      if (!newFileList[0]) {
                        // @ts-ignore
                        setFieldValue('imageFile', '', values)
                        // @ts-ignore
                        setFieldValue('thumbnail', '', values)
                      } else {
                        // @ts-ignore
                        setFieldValue('imageFile', newFileList[0].originFileObj, values)
                        // @ts-ignore
                        setFieldValue('thumbnail', newFileList[0].name, values)
                      }
                    }}>
                    {values.thumbnail.length < 1 && '+ Agregar imagen'}
                  </Field>
                </ImgCrop>
              </Col>
            </Row>
            <Row>
              <Col lg='6'>
                <FormInput id='name' label='Nombre' value={values.name} />
              </Col>
              <Col lg='6'>
                <FormInput id='lastName' label={'Apellido'} value={values.lastName} />
              </Col>
            </Row>
            <Row>
              <Col lg='6'>
                <FormInput
                  id='role'
                  label={'Rol'}
                  type='select'
                  onChange={handleChange}
                  dataOptions={roles}
                  value={values.role}
                />
              </Col>
              <Col lg='6'>
                <FormInput
                  id='gta'
                  label='GTA2030'
                  type='select'
                  onChange={handleChange}
                  allGtas={'Todos'}
                  dataOptions={
                    catalogs.gta &&
                    catalogs.gta.data?.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.gta}
                />
              </Col>
            </Row>
            <Row>
              <Col lg='4'>
                <FormInput
                  id='country'
                  label='País'
                  type='select'
                  onChange={e => countryOnChange(values, e)}
                  dataOptions={countries && countries}
                  value={values.country}
                />
              </Col>
              <Col lg='4'>
                <FormInput
                  id='state'
                  label='Estado'
                  type='select'
                  onChange={e => {
                    stateOnChange(values, e)
                  }}
                  dataOptions={catalogs.states && statesList}
                  value={values.state}
                />
              </Col>
              <Col lg='4'>
                <FormInput
                  id='city'
                  label='Ciudad'
                  type='select'
                  onChange={handleChange}
                  dataOptions={
                    citiesList &&
                    citiesList.map(({ value }) => ({
                      value: value,
                      text: value,
                      id: value,
                      key: value
                    }))
                  }
                  value={values.city}
                />
              </Col>
            </Row>

            <Row>
              <Col lg='4'>
                <FormInput
                  label='Empresa u organización'
                  type='multiselectsmall'
                  css={'form-inputs-sector-company'}
                  tooltip='Si no encuentra la empresa que busca, puede escribirla manualmente dentro del campo de Empresa u organización y dar clic en "Agregar nueva empresa..."'
                  placeholder={user.company.name}
                  defaultValue={user.company.name}
                  name='company'
                  id='company'
                  onChange={e => {
                    changeCompany(e, values)
                  }}
                  options={
                    catalogs.companies &&
                    catalogs.companies.data.map(({ _id, name }) => ({
                      value: name,
                      label: name,
                      _id
                    }))
                  }
                />
              </Col>
              <Col lg='4'>
                <FormInput id='email' label='Correo' value={values.email} />
              </Col>
              <Col lg='4'>
                <FormInput
                  id='gender'
                  label='Género'
                  type='radioButton'
                  radioButton={genders}
                  placeholder=''
                />
              </Col>
            </Row>
            <Button color='primary' type='submit' disabled={loading}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.admins.all,
    one: state.admin.one,
    error: state.admins.error,
    userLoading: state.admins.loading,
    filters: state.admins.filters,
    catalogs: state.catalogs
  }
}
const mapDispatchToProps = {
  adminCreateRequest,
  adminEditRequest,
  adminGetCatalogs,
  adminGetStates,
  adminGetCities
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm)
