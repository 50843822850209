import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'
import AdminPermissions from 'components/Modules/Admins/AdminPermissions'

export const PageAdminEditPermission = ({ one, permissions }) => {
  return (
    <Layout icon="fas fa-user-cog" title="Administrador">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        className="loadingbar-style"
      />{' '}
      <CardBig title={'Editar permisos'}>
        <AdminPermissions mode="edit" admin={one} fields={permissions?.list} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.admins.one,
    permissions: state.permissions
  }
}

export default connect(mapStateToProps)(PageAdminEditPermission)
