import React, { useState } from 'react'
import { Subtitle } from 'components/Theme/Typography'

const CardCollapsable = ({ children, title }) => {
  const [isPreview, setIsPreview] = useState(false)

  return (
    <div className='card shadow mb-4'>
      <a
        className='card-header py-3 px-0'
        href='javascript:void(0)'
        data-toggle='collapse'
        role='button'
        onClick={() => setIsPreview(!isPreview)}>
        <Subtitle className='flex-row align-items-center d-flex justify-content-between'>
          {title}
        </Subtitle>{' '}
      </a>
      {isPreview ? <div className='card-body'>{children}</div> : null}
    </div>
  )
}

export default CardCollapsable
