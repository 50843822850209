/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { Row, Col, Input } from 'reactstrap'
import { ListItem } from 'components/Theme/Container/ListItem'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import ImageBorder from 'components/Theme/Images/ImageBorder'
// @ts-ignore
import thumbnailAxis from 'assets/img/ejesDeTrabajo/workAxis1.svg'
import { connect, useDispatch } from 'react-redux'
import {
  axesGlobalPactDeactivateRequest,
  axesGlobalPactGetOneRequest,
  axesGlobalPactReactivateRequest
} from './axesGlobalPact.redux'
import { isEmpty } from 'utils/crud'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

export const AxesGlobalPactView = ({
  axesGlobalPactGetOneRequest,
  historicalListRequest,
  ...props
}) => {
  const { historical } = props
  const dispatch = useDispatch()
  const { _id } = props.match.params
  useEffect(() => {
    axesGlobalPactGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [axesGlobalPactGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (axesGlobalPact.active) {
      dispatch(axesGlobalPactDeactivateRequest({ id: _id }))
    }
    if (!axesGlobalPact.active) {
      dispatch(axesGlobalPactReactivateRequest({ id: _id }))
    }
  }
  const axesGlobalPact = props.one || {}

  return (
    <Layout icon='fas fa-fw fa-cog' title='Ejes del Pacto Global'>
      <TableContainer
        title='Detalle'
        subtitle='Información del registro'
        buttonAdd={false}
        view={`/axesGlobalPact/${_id}/edit`}
        actionButton={true}
        active={axesGlobalPact.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(axesGlobalPact) && (
          <Row id='view-axesGlobalPact'>
            <Col md='4' className='flex justify-content-end'>
              <img
                src={axesGlobalPact.logo ? axesGlobalPact.logo : thumbnailAxis.path}
                height='150'
                width='150'
              />
            </Col>
            <Col md='8'>
              <ul>
                <ListItem label='Titulo' value={axesGlobalPact.name} />
                <ListItem label='Descripcion' value={axesGlobalPact.description} />
                <ListItem label='Creado' value={mdy(axesGlobalPact.createdAt)} />
                <ListItem label='Actualizado' value={mdy(axesGlobalPact.updatedAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className='overflow-box'
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}>
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.axesGlobalPact.one,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  axesGlobalPactGetOneRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(AxesGlobalPactView)
