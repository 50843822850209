/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { Row, Col } from 'reactstrap'
import { ListItem } from 'components/Theme/Container/ListItem'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import ImageBorder from 'components/Theme/Images/ImageBorder'
// @ts-ignore
import thumbnailAxis from 'assets/img/ejesDeTrabajo/workAxis1.svg'
import { connect, useDispatch } from 'react-redux'
import {
  workAxisDeactivateRequest,
  workAxisGetOneRequest,
  workAxisReactivateRequest
} from './workAxis.redux'
import { isEmpty } from 'utils/crud'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

export const WorkAxisView = ({ workAxisGetOneRequest, historicalListRequest, ...props }) => {
  const { historical } = props
  const dispatch = useDispatch()
  const { _id } = props.match.params
  useEffect(() => {
    workAxisGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [workAxisGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (workAxis.active) {
      dispatch(workAxisDeactivateRequest({ id: _id }))
    }
    if (!workAxis.active) {
      dispatch(workAxisReactivateRequest({ id: _id }))
    }
  }
  const workAxis = props.one || {}

  return (
    <Layout icon='fas fa-user-cog' title='Eje de trabajo'>
      <TableContainer
        title='Detalle'
        subtitle='Información del registro'
        buttonAdd={false}
        view={`/workAxes/${_id}/edit`}
        actionButton={true}
        active={workAxis.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(workAxis) && (
          <Row id='view-workAxis'>
            <Col
              md='4'
              className='flex justify-content-end'
              style={{ background: '#a2a8af42' }}>
              <ImageBorder
                src={
                  workAxis.position
                    ? require(`assets/img/ejesDeTrabajo/workAxis${workAxis.position}.svg`)
                    : require(`assets/img/ejesDeTrabajo/workAxis1.svg`)
                }
                height='150'
                width='150'
              />
            </Col>
            <Col md='8'>
              <ul>
                <ListItem label='Grupo de Trabajo Agenda 2030' value={workAxis.gta?.title} />
                <ListItem label='Objetivo' value={workAxis.objective} />
                <ListItem label='Creado' value={mdy(workAxis.createdAt)} />
                <ListItem label='Actualizado' value={mdy(workAxis.updatedAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className='overflow-box'
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}>
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    one: state.workAxis.one,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  workAxisGetOneRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkAxisView)
