import React, { useEffect } from 'react'
import { columns } from './businessType.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import TableContainer from 'components/Theme/Container/TableContainer'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import { connect } from 'react-redux'
import { businessTypeGetAllRequest, businessTypeGetTotals } from './businessType.redux'

function BusinessTypeList({
  businessTypeGetAllRequest,
  businessTypeGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    businessTypeGetAllRequest(filters)
    businessTypeGetTotals()
  }, [businessTypeGetAllRequest, businessTypeGetTotals, filters])

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/business-types/${row._id}`)
    }
  }

  const businessType = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      businessTypeGetAllRequest(filters)
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      businessTypeGetAllRequest(filters)
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      businessTypeGetAllRequest(filters)
    }
  }
  return (
    <Layout icon='fa fa-fw fa-building' title='Ámbito de implementación (Flashcards)'>
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <TableContainer
        title='Ámbito de implementación (Flashcards)'
        subtitle='Lista'
        route='/business-types/create'
        showAddbuton={true}>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          filter={filters}
          totalDocs={totalDocs}
          onTableChange={businessTypeGetAllRequest}
          data={businessType}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.businessTypes.all,
    one: state.businessTypes.one,
    error: state.businessTypes.error,
    userLoading: state.businessTypes.loading,
    filters: state.businessTypes.filters,
    totals: state.businessTypes.totals
  }
}
const mapDispatchToProps = {
  businessTypeGetAllRequest: businessTypeGetAllRequest,
  businessTypeGetTotals: businessTypeGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypeList)
