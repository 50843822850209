import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import { filterCreateRequest } from './filter.redux'
import FilterForm from './FilterForm'
import LoadingBar from 'react-redux-loading-bar'
import CardBig from 'components/Theme/Cards/CardBig'

function FilterCreate() {
  return (
    <Layout icon="fas fa-fw fa-ban" title="Filtro">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <br />
      <CardBig title={'Agregar'}>
        <FilterForm mode="create" loading={false} user={undefined} />
      </CardBig>
    </Layout>
  )
}

const mapStateToProps = ({ companies }) => ({ companies })
const mapDispatchToProps = {
  createFilter: filterCreateRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterCreate)
