export const CONFIGURATION_EDIT_REQUEST = 'CONFIGURATION_EDIT_REQUEST'
export const CONFIGURATION_EDIT_SUCCESS = 'CONFIGURATION_EDIT_SUCCESS'
export const CONFIGURATION_EDIT_FAILURE = 'CONFIGURATION_EDIT_FAILURE'

export const CONFIGURATION_GET_ONE_REQUEST = 'CONFIGURATION_GET_ONE_REQUEST'
export const CONFIGURATION_GET_ONE_SUCCESS = 'CONFIGURATION_GET_ONE_SUCCESS'
export const CONFIGURATION_GET_ONE_FAILURE = 'CONFIGURATION_GET_ONE_FAILURE'

// Reducer
const INITIAL_STATE = {
  one: {},
  loading: false,
  error: null
}

export function configurations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIGURATION_GET_ONE_REQUEST:
    case CONFIGURATION_EDIT_REQUEST:
      return { ...state, error: null, loading: true }
    case CONFIGURATION_EDIT_SUCCESS:
    case CONFIGURATION_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CONFIGURATION_GET_ONE_FAILURE:
    case CONFIGURATION_EDIT_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
