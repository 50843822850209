import React from 'react'
import moment from 'moment'
import thumbnail from 'assets/media/profile.png'

export const FormCardComments = ({ comments }) => {
  return (
    <div className="feed-activity-list">
      {!comments[0] ? (
        <span style={{ color: '#797979', fontSize: '20px' }}>
          Sin registros
        </span>
      ) : (
        Object.keys(comments).map(key => (
          <div key={key} className="feed-element">
            <div className="card-body pt-0">
              <div className="widget-49">
                <div className="widget-49-title-wrapper">
                  <div className="widget-49-date-primary">
                    {/* FOTO */}
                    <img
                      className="rounded-x"
                      width="60px"
                      src={
                        comments[key].createdBy.thumbnail
                          ? comments[key].createdBy.thumbnail
                          : thumbnail
                      }
                      alt="user img"
                    />
                  </div>
                  <div className="widget-49-meeting-info">
                    {/* NOMBRE */}
                    <span style={{ fontWeight: 'bold' }}>
                      {comments[key].createdBy.name}
                    </span>
                    {/* <span style={{ fontWeight: 'bold' }}>{comments[key].name}</span> */}
                    {/* COMPAÑÍA */}
                    <span
                      style={{ fontWeight: 'bold', margin: '-4px 0 -3px 0' }}
                    >
                      {comments[key].createdBy.company.name}
                    </span>
                    {/* FECHA */}
                    <span
                      className="widget-49-meeting-time"
                      style={{ fontSize: '15px' }}
                    >
                      {moment(comments[key].createdAt).format(
                        'DD/MM/YYYY  HH:mm'
                      )}
                    </span>
                  </div>
                </div>
                <div className="media-body ">
                  {/* MENSAJE */}
                  <div
                    className="well"
                    style={{ paddingRight: '10%', maxWidth: '949px' }}
                  >
                    {comments[key].comment}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

FormCardComments.defaultProps = {
  // buttonAdd: true
}

// @ts-ignore
export default FormCardComments
