import { generateActiveIcon } from 'components/Theme/Table/helpers'
import { mdy } from 'utils/dates'

export const columns = [
  {
    dataField: 'value',
    text: 'Valor',
    sort: true
  },
  {
    dataField: 'filterType',
    text: 'Tipo filtro',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
