// Type's
export const PRIORIZATION_GET_ALL_REQUEST = 'PRIORIZATION_GET_ALL_REQUEST'
export const PRIORIZATION_GET_ALL_SUCCESS = 'PRIORIZATION_GET_ALL_SUCCESS'
export const PRIORIZATION_GET_ALL_FAILURE = 'PRIORIZATION_GET_ALL_FAILURE'

export const PRIORIZATION_GET_ALL_STATUS_REQUEST = 'PRIORIZATION_GET_ALL_STATUS_REQUEST'
export const PRIORIZATION_GET_ALL_STATUS_SUCCESS = 'PRIORIZATION_GET_ALL_STATUS_SUCCESS'
export const PRIORIZATION_GET_ALL_STATUS_FAILURE = 'PRIORIZATION_GET_ALL_STATUS_FAILURE'

export const PRIORIZATION_GET_LIST_REQUEST = 'PRIORIZATION_GET_LIST_REQUEST'
export const PRIORIZATION_GET_LIST_SUCCESS = 'PRIORIZATION_GET_LIST_SUCCESS'
export const PRIORIZATION_GET_LIST_FAILURE = 'PRIORIZATION_GET_LIST_FAILURE'

export const PRIORIZATION_GET_LIST_BY_DATE_REQUEST = 'PRIORIZATION_GET_LIST_BY_DATE_REQUEST'
export const PRIORIZATION_GET_LIST_BY_DATE_SUCCESS = 'PRIORIZATION_GET_LIST_BY_DATE_SUCCESS'
export const PRIORIZATION_GET_LIST_BY_DATE_FAILURE = 'PRIORIZATION_GET_LIST_BY_DATE_FAILURE'

export const PRIORIZATION_FILTER_BY_DATE_REQUEST = 'PRIORIZATION_FILTER_BY_DATE_REQUEST'
export const PRIORIZATION_FILTER_BY_DATE_SUCCESS = 'PRIORIZATION_FILTER_BY_DATE_SUCCESS'
export const PRIORIZATION_FILTER_BY_DATE_FAILURE = 'PRIORIZATION_FILTER_BY_DATE_FAILURE'

export const PRIORIZATION_CREATE_REQUEST = 'PRIORIZATION_CREATE_REQUEST'
export const PRIORIZATION_CREATE_SUCCESS = 'PRIORIZATION_CREATE_SUCCESS'
export const PRIORIZATION_CREATE_FAILURE = 'PRIORIZATION_CREATE_FAILURE'

export const PRIORIZATION_GET_ONE_REQUEST = 'PRIORIZATION_GET_ONE_REQUEST'
export const PRIORIZATION_GET_ONE_SUCCESS = 'PRIORIZATION_GET_ONE_SUCCESS'
export const PRIORIZATION_GET_ONE_FAILURE = 'PRIORIZATION_GET_ONE_FAILURE'

export const PRIORIZATION_GET_ONE_DOC_REQUEST = 'PRIORIZATION_GET_ONE_DOC_REQUEST'
export const PRIORIZATION_GET_ONE_DOC_SUCCESS = 'PRIORIZATION_GET_ONE_DOC_SUCCESS'
export const PRIORIZATION_GET_ONE_DOC_FAILURE = 'PRIORIZATION_GET_ONE_DOC_FAILURE'

export const PRIORIZATION_EDIT_REQUEST = 'PRIORIZATION_EDIT_REQUEST'
export const PRIORIZATION_EDIT_SUCCESS = 'PRIORIZATION_EDIT_SUCCESS'
export const PRIORIZATION_EDIT_FAILURE = 'PRIORIZATION_EDIT_FAILURE'

export const PRIORIZATION_REMOVE_REQUEST = 'PRIORIZATION_REMOVE_REQUEST'
export const PRIORIZATION_REMOVE_SUCCESS = 'PRIORIZATION_REMOVE_SUCCESS'
export const PRIORIZATION_REMOVE_FAILURE = 'PRIORIZATION_REMOVE_FAILURE'

export const PRIORIZATION_FILTER_CHANGE_REQUEST = 'PRIORIZATION_FILTER_CHANGE_REQUEST'
export const PRIORIZATION_FILTER_CHANGE_SUCCESS = 'PRIORIZATION_FILTER_CHANGE_SUCCESS'
export const PRIORIZATION_FILTER_CHANGE_FAILURE = 'PRIORIZATION_FILTER_CHANGE_FAILURE'

export const PRIORIZATION_REACTIVATE_REQUEST = 'PRIORIZATION_REACTIVATE_REQUEST'
export const PRIORIZATION_REACTIVATE_SUCCESS = 'PRIORIZATION_REACTIVATE_SUCCESS'
export const PRIORIZATION_REACTIVATE_FAILURE = 'PRIORIZATION_REACTIVATE_FAILURE'

export const PRIORIZATION_DEACTIVATE_REQUEST = 'PRIORIZATION_DEACTIVATE_REQUEST'
export const PRIORIZATION_DEACTIVATE_SUCCESS = 'PRIORIZATION_DEACTIVATE_SUCCESS'
export const PRIORIZATION_DEACTIVATE_FAILURE = 'PRIORIZATION_DEACTIVATE_FAILURE'

export const PRIORIZATION_GET_TOTALS_REQUEST = 'PRIORIZATION_GET_TOTALS_REQUEST'
export const PRIORIZATION_GET_TOTALS_SUCCESS = 'PRIORIZATION_GET_TOTALS_SUCCESS'
export const PRIORIZATION_GET_TOTALS_FAILURE = 'PRIORIZATION_GET_TOTALS_FAILURE'

export const PRIORIZATION_GET_DETAIL_REQUEST = 'PRIORIZATION_GET_DETAIL_REQUEST'
export const PRIORIZATION_GET_DETAIL_SUCCESS = 'PRIORIZATION_GET_DETAIL_SUCCESS'
export const PRIORIZATION_GET_DETAIL_FAILURE = 'PRIORIZATION_GET_DETAIL_FAILURE'

export const PRIORIZATION_SHOW_MODAL_REQUEST = 'PRIORIZATION_SHOW_MODAL_REQUEST'
export const PRIORIZATION_SHOW_MODAL_SUCCESS = 'PRIORIZATION_SHOW_MODAL_SUCCESS'
export const PRIORIZATION_SHOW_MODAL_FAILURE = 'PRIORIZATION_SHOW_MODAL_FAILURE'

export const PRIORIZATION_AVERAGES_REQUEST = 'PRIORIZATION_AVERAGES_REQUEST'
export const PRIORIZATION_AVERAGES_SUCCESS = 'PRIORIZATION_AVERAGES_SUCCESS'
export const PRIORIZATION_AVERAGES_FAILURE = 'PRIORIZATION_AVERAGES_FAILURE'

// Action's

//Priorization Detail

export const priorizationShowModalRequest = payload => ({
  type: PRIORIZATION_SHOW_MODAL_REQUEST,
  payload
})

export const priorizationGetDetailRequest = payload => ({
  type: PRIORIZATION_GET_DETAIL_REQUEST,
  payload
})
export const priorizationGetDetailSuccess = payload => ({
  type: PRIORIZATION_GET_DETAIL_SUCCESS,
  payload
})
export const priorizationGetDetailFailure = error => ({
  type: PRIORIZATION_GET_DETAIL_FAILURE,
  error
})

export const priorizationDeactivateRequest = payload => ({
  type: PRIORIZATION_DEACTIVATE_REQUEST,
  payload
})
export const priorizationReactivateRequest = payload => ({
  type: PRIORIZATION_REACTIVATE_REQUEST,
  payload
})

export const priorizationGetTotalsRequest = payload => ({
  type: PRIORIZATION_GET_TOTALS_REQUEST,
  payload
})

export const priorizationGetListRequest = page => ({
  type: PRIORIZATION_GET_LIST_REQUEST,
  page
})
export const priorizationGetListSuccess = payload => ({
  type: PRIORIZATION_GET_LIST_SUCCESS,
  payload
})

export const priorizationGetListByDateRequest = page => ({
  type: PRIORIZATION_GET_LIST_BY_DATE_REQUEST,
  page
})
export const priorizationGetListByDateSuccess = payload => ({
  type: PRIORIZATION_GET_LIST_BY_DATE_SUCCESS,
  payload
})

export const priorizationGetAllRequest = filters => ({
  type: PRIORIZATION_GET_ALL_REQUEST,
  filters
})
export const priorizationGetAllSuccess = payload => ({
  type: PRIORIZATION_GET_ALL_SUCCESS,
  payload
})
export const priorizationGetAllFailure = error => ({
  type: PRIORIZATION_GET_ALL_REQUEST,
  error
})

export const priorizationGetAllStatusRequest = filters => ({
  type: PRIORIZATION_GET_ALL_STATUS_REQUEST,
  filters
})
export const priorizationGetAllStatusSuccess = payload => ({
  type: PRIORIZATION_GET_ALL_STATUS_SUCCESS,
  payload
})
export const priorizationGetAllStatusFailure = error => ({
  type: PRIORIZATION_GET_ALL_STATUS_REQUEST,
  error
})
export const priorizationFiltersChangeRequest = filters => ({
  type: PRIORIZATION_FILTER_CHANGE_REQUEST,
  filters
})
export const priorizationGetOneRequest = payload => ({
  type: PRIORIZATION_GET_ONE_REQUEST,
  payload
})
export const priorizationGetOneSuccess = payload => ({
  type: PRIORIZATION_GET_ONE_SUCCESS,
  payload
})
export const priorizationGetOneFailure = payload => ({
  type: PRIORIZATION_GET_ONE_FAILURE,
  payload
})
export const priorizationGetOneDocRequest = payload => ({
  type: PRIORIZATION_GET_ONE_DOC_REQUEST,
  payload
})
export const priorizationGetOneDocSuccess = payload => ({
  type: PRIORIZATION_GET_ONE_DOC_SUCCESS,
  payload
})
export const priorizationGetOneDocFailure = payload => ({
  type: PRIORIZATION_GET_ONE_DOC_FAILURE,
  payload
})

export const priorizationCreateRequest = ({ values }) => ({
  type: PRIORIZATION_CREATE_REQUEST,
  payload: values
})

export const priorizationFilterByDateRequest = ({ values }) => ({
  type: PRIORIZATION_FILTER_BY_DATE_REQUEST,
  payload: values
})
export const priorizationCreateSuccess = payload => ({
  type: PRIORIZATION_CREATE_SUCCESS,
  payload
})
export const priorizationCreateFailure = payload => ({
  type: PRIORIZATION_CREATE_FAILURE,
  payload
})

export const priorizationEditRequest = ({ values }) => ({
  type: PRIORIZATION_EDIT_REQUEST,
  payload: values
})

export const priorizationAveragesRequest = filters => ({
  type: PRIORIZATION_AVERAGES_REQUEST,
  filters
})
export const priorizationAveragesSuccess = payload => ({
  type: PRIORIZATION_AVERAGES_SUCCESS,
  payload
})
export const priorizationAveragesFailure = error => ({
  type: PRIORIZATION_AVERAGES_FAILURE,
  error
})
// Reducer
const INITIAL_STATE = {
  one: {},
  oneDoc: {},
  all: [],
  allPriorizations: [],
  average: {},
  loading: false,
  error: null,
  filters: {},
  totals: {},
  list: [],
  filtered: {},
  modal: false
}

export function priorizations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRIORIZATION_REMOVE_REQUEST:
    case PRIORIZATION_GET_ALL_REQUEST:
    case PRIORIZATION_GET_ALL_STATUS_REQUEST:
    case PRIORIZATION_GET_LIST_REQUEST:
    case PRIORIZATION_GET_LIST_BY_DATE_REQUEST:
    case PRIORIZATION_CREATE_REQUEST:
    case PRIORIZATION_FILTER_BY_DATE_REQUEST:
    case PRIORIZATION_EDIT_REQUEST:
    case PRIORIZATION_GET_ONE_REQUEST:
    case PRIORIZATION_DEACTIVATE_REQUEST:
    case PRIORIZATION_REACTIVATE_REQUEST:
    case PRIORIZATION_GET_TOTALS_REQUEST:
    case PRIORIZATION_GET_ONE_DOC_REQUEST:
    case PRIORIZATION_AVERAGES_REQUEST:
      return { ...state, error: null, loading: true }

    case PRIORIZATION_SHOW_MODAL_REQUEST:
      return { ...state, modal: !state.modal }

    case PRIORIZATION_GET_ALL_FAILURE:
    case PRIORIZATION_GET_ALL_STATUS_FAILURE:
    case PRIORIZATION_GET_LIST_FAILURE:
    case PRIORIZATION_GET_LIST_BY_DATE_FAILURE:
    case PRIORIZATION_CREATE_FAILURE:
    case PRIORIZATION_GET_ONE_FAILURE:
    case PRIORIZATION_EDIT_FAILURE:
    case PRIORIZATION_REMOVE_FAILURE:
    case PRIORIZATION_DEACTIVATE_FAILURE:
    case PRIORIZATION_REACTIVATE_FAILURE:
    case PRIORIZATION_GET_TOTALS_FAILURE:
    case PRIORIZATION_FILTER_BY_DATE_FAILURE:
    case PRIORIZATION_GET_ONE_DOC_FAILURE:
    case PRIORIZATION_AVERAGES_FAILURE:
      return { ...state, loading: false, error: action.error }

    case PRIORIZATION_CREATE_SUCCESS:
    case PRIORIZATION_DEACTIVATE_SUCCESS:
    case PRIORIZATION_REACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case PRIORIZATION_GET_ONE_DOC_SUCCESS:
      return { ...state, error: null, loading: false, oneDoc: { ...action.payload } }
    case PRIORIZATION_EDIT_SUCCESS:
    case PRIORIZATION_GET_ONE_SUCCESS:
    case PRIORIZATION_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case PRIORIZATION_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, allPriorizations: { ...action.payload } }
    case PRIORIZATION_GET_ALL_STATUS_SUCCESS:
    case PRIORIZATION_GET_DETAIL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case PRIORIZATION_GET_LIST_BY_DATE_SUCCESS:
    case PRIORIZATION_GET_LIST_SUCCESS:
      return { ...state, error: null, loading: false, list: { ...action.payload } }
    case PRIORIZATION_FILTER_BY_DATE_SUCCESS:
      return { ...state, error: null, loading: false, filtered: { ...action.payload } }
    case PRIORIZATION_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }
    case PRIORIZATION_AVERAGES_SUCCESS:
      return { ...state, error: null, loading: false, average: { ...action.payload } }

    default:
      return state
  }
}
