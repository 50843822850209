import React from 'react'
import { FlashcardForm } from 'components/Modules/Flashcards/FlashcardForm'
import Layout from 'components/Layout'
import CardBig from 'components/Theme/Cards/CardBig'

export const PageFlashcardCreate = () => {
  return (
    <Layout>
      <br />
      <CardBig title={'Datos'}>
        <FlashcardForm mode="create" />
      </CardBig>
    </Layout>
  )
}

export default PageFlashcardCreate
