import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  ADMIN_GET_ALL_REQUEST,
  ADMIN_GET_ALL_FAILURE,
  ADMIN_GET_ALL_SUCCESS,
  ADMIN_CREATE_SUCCESS,
  ADMIN_CREATE_FAILURE,
  ADMIN_CREATE_REQUEST,
  ADMIN_GET_ONE_FAILURE,
  ADMIN_GET_ONE_SUCCESS,
  ADMIN_GET_ONE_REQUEST,
  ADMIN_REMOVE_SUCCESS,
  ADMIN_REMOVE_FAILURE,
  ADMIN_REMOVE_REQUEST,
  ADMIN_EDIT_SUCCESS,
  ADMIN_EDIT_REQUEST,
  ADMIN_FILTER_CHANGE_SUCCESS,
  ADMIN_FILTER_CHANGE_FAILURE,
  ADMIN_FILTER_CHANGE_REQUEST,
  ADMIN_REACTIVATE_SUCCESS,
  ADMIN_REACTIVATE_FAILURE,
  ADMIN_REACTIVATE_REQUEST,
  ADMIN_GET_TOTALS_SUCCESS,
  ADMIN_GET_TOTALS_FAILURE,
  ADMIN_GET_TOTALS_REQUEST,
  ADMIN_GET_CATALOGS_SUCCESS,
  ADMIN_GET_CATALOGS_FAILURE,
  ADMIN_GET_CATALOGS_REQUEST,
  ADMIN_GET_STATES_SUCCESS,
  ADMIN_GET_STATES_FAILURE,
  ADMIN_GET_STATES_REQUEST,
  ADMIN_GET_CITIES_SUCCESS,
  ADMIN_GET_CITIES_FAILURE,
  ADMIN_GET_CITIES_REQUEST,
  ADMIN_EDIT_PERMITS_REQUEST,
  ADMIN_EDIT_PERMITS_SUCCESS,
  ADMIN_EDIT_PERMITS_FAILURE
} from './admin.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from '../../Justification'
import { encodeFiltersData, getUserId, manageError } from '../../../redux/helpers/handlers.js'
import { logoutRequest } from '../Auth/auth.saga'
import { modulesGetAllRequest } from '../Catalogues/Modules/modules.redux'

function* getAllAdminSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/admins?${filterString}`)
    yield delay(1000)
    yield put({ type: ADMIN_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ADMIN_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneAdminSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/admins/${_id}`)
    const { data: permits } = yield call(axios.get, `/api/permissions?admin=${_id}`)
    data.data.permits = permits.data?.docs
    yield put({ type: ADMIN_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ADMIN_GET_ONE_FAILURE, error: error.response })
  }
}

function* createAdminSaga({ payload }) {
  try {
    yield put(showLoading())
    let formData = new FormData()
    formData.append('name', payload.name)
    formData.append('lastName', payload.lastName)
    formData.append('email', payload.email)
    formData.append('country', payload.country)
    formData.append('company', payload.company)
    formData.append('role', payload.role)
    formData.append('gender', payload.gender)
    formData.append('gta', payload.gta)
    formData.append('state', payload.state)
    formData.append('city', payload.city)
    if (payload.imageFile) {
      formData.append('thumbnail', payload.imageFile)
    }
    // Crea nuevo administrador
    const insert = yield call(axios.post, '/api/admins', formData)
    // Si fue creado el administrador, obtiene su id y se utiliza para agregar sus permisos
    const listOfPermissions = payload.permissions
      .map(permission => ({
        admin: insert.data.data._id,
        module: permission._id,
        read: permission.read || false,
        create: permission.create || false,
        update: permission.update || false,
        destroy: permission.destroy || false,
        approve: permission.approve || false
      }))
      .filter(
        permission =>
          permission.create ||
          permission.update ||
          permission.destroy ||
          permission.approve ||
          permission.read
      )
    yield call(axios.post, '/api/permissions', listOfPermissions)
    yield delay(1000)
    yield put({ type: ADMIN_CREATE_SUCCESS, payload: insert })
    yield put(modulesGetAllRequest())
    yield put(push('/admins'))
    return yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: ADMIN_CREATE_FAILURE, error: error.response })
  }
}

function* getAdminFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: ADMIN_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: ADMIN_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: ADMIN_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editAdminSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('name', payload.name)
    formData.append('lastName', payload.lastName)
    formData.append('email', payload.email)
    formData.append('country', payload.country)
    formData.append('company', payload.company)
    formData.append('role', payload.role)
    formData.append('gender', payload.gender)
    formData.append('gta', payload.gta)
    formData.append('state', payload.state)
    formData.append('city', payload.city)
    if (payload.imageFile) {
      formData.append('thumbnail', payload.imageFile)
    }

    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())

    const user = yield select(u => getUserId(u))
    const historical = { description, adminId: user }
    payload.historical = historical
    formData.append('description', description)
    const insert = yield call(axios.put, '/api/admins/' + payload._id, formData)

    if (payload._id === user) {
      yield call(logoutRequest)
      yield put(push('/'))
    } else {
      yield put({ type: ADMIN_EDIT_SUCCESS, payload: insert?.data?.data })
      yield delay(1500)
      yield put(push('/admins'))
    }

    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: ADMIN_CREATE_FAILURE, error: error.response })
  }
}

function* editAdminPermitsSaga({ payload }) {
  try {
    const user = yield select(u => getUserId(u))
    const historical = { description: 'Cambios en permisos de administrador', adminId: user }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/permissions/' + payload._id, payload)
    yield put({ type: ADMIN_EDIT_PERMITS_SUCCESS, payload: insert })
    if (payload.admin === user) {
      yield call(logoutRequest)
      yield put(push('/'))
    } else {
      yield put(push('/admins'))
      Swal.fire('Información de contacto actualizado', '', 'success')
    }
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: ADMIN_EDIT_PERMITS_FAILURE, error: error.response })
  }
}

function* removeAdminSaga({ payload }) {
  try {
    const user = yield select(u => getUserId(u))
    
    if (payload.id === user) {
      Swal.fire('Alerta', 'No puedes desactivar tu propio usuario.', 'info')
      return

    }
    const description = yield justificationComponent()
    .then(detail => detail.value)
    .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical


    yield put(showLoading())
    if (payload.id === user) {
      yield call(logoutRequest)
      yield call(axios.put, '/api/admins/' + payload.id + '/deactivate', payload)
      yield put(push('/'))
    } else {
      const insert = yield call(
        axios.put,
        '/api/admins/' + payload.id + '/deactivate',
        payload
      )

      yield put(push('/admins'))
      yield put(hideLoading())
      yield put({ type: ADMIN_REMOVE_SUCCESS, payload: insert })
      yield put({ type: ADMIN_GET_ALL_REQUEST })
      Swal.fire('Usuario desactivado', '', 'success')
      yield delay(1500)
    }
  } catch (error) {
    yield put({ type: ADMIN_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateAdminSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(axios.put, '/api/admins/' + payload.id + '/reactivate', payload)
    yield put({ type: ADMIN_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: ADMIN_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/admins'))
    Swal.fire('Usuario activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: ADMIN_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/admins/totals`)
    yield delay(1000)
    yield put({ type: ADMIN_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ADMIN_GET_TOTALS_FAILURE, error: error.response })
  }
}
function* getCatalogsSaga({ filters = { active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data: companies } = yield call(axios.get, `/api/companies/all?${filterString}`)
    const { data: gta } = yield call(axios.get, `/api/gtas/all?${filterString}`)
    const { data: countries } = yield call(
      axios.get,
      `https://countriesnow.space/api/v0.1/countries/iso`
    )

    const payload = { companies, gta, countries }
    yield put({ type: ADMIN_GET_CATALOGS_SUCCESS, payload: payload })
  } catch (error) {
    yield put({ type: ADMIN_GET_CATALOGS_FAILURE, error: error.response })
  }
}
function* getStatesSaga({ payload }) {
  try {
    const body = {
      country: payload
    }
    const { data } = yield call(
      axios.post,
      `https://countriesnow.space/api/v0.1/countries/states`,
      body
    )
    yield put({ type: ADMIN_GET_STATES_SUCCESS, payload: data })
  } catch (error) {
    const data = yield require('assets/countries.json')
    yield put({ type: ADMIN_GET_STATES_SUCCESS, payload: data })
    // yield put({ type: ADMIN_GET_STATES_FAILURE, error: error.response })
  }
}

function* getCitiesSaga({ country, state }) {
  try {
    const body = {
      country: country,
      state: state
    }
    const { data } = yield call(
      axios.post,
      `https://countriesnow.space/api/v0.1/countries/state/cities`,
      body
    )

    yield put({ type: ADMIN_GET_CITIES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: ADMIN_GET_CITIES_FAILURE, error: error.response })
  }
}

export default function* adminSagas() {
  yield takeLatest(ADMIN_GET_ALL_REQUEST, getAllAdminSaga)
  yield takeLatest(ADMIN_GET_ONE_REQUEST, getOneAdminSaga)
  yield takeLatest(ADMIN_CREATE_REQUEST, createAdminSaga)
  yield takeLatest(ADMIN_EDIT_REQUEST, editAdminSaga)
  yield takeLatest(ADMIN_EDIT_PERMITS_REQUEST, editAdminPermitsSaga)
  yield takeLatest(ADMIN_GET_ALL_FAILURE, manageError)
  yield takeLatest(ADMIN_GET_ONE_FAILURE, manageError)
  yield takeLatest(ADMIN_REMOVE_REQUEST, removeAdminSaga)
  yield takeLatest(ADMIN_REMOVE_FAILURE, manageError)
  yield takeLatest(ADMIN_FILTER_CHANGE_REQUEST, getAdminFiltersSaga)
  yield takeLatest(ADMIN_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(ADMIN_REACTIVATE_REQUEST, reactivateAdminSaga)
  yield takeLatest(ADMIN_REACTIVATE_FAILURE, manageError)
  yield takeLatest(ADMIN_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(ADMIN_GET_TOTALS_FAILURE, manageError)
  yield takeLatest(ADMIN_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(ADMIN_GET_CATALOGS_FAILURE, manageError)
  yield takeLatest(ADMIN_GET_STATES_REQUEST, getStatesSaga)
  yield takeLatest(ADMIN_GET_STATES_FAILURE, manageError)
  yield takeLatest(ADMIN_GET_CITIES_REQUEST, getCitiesSaga)
  yield takeLatest(ADMIN_GET_CITIES_FAILURE, manageError)
}
