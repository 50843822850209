// TYPES
export const typesNotifications = {
  notificationsAllRequest: '[ NOTIFICATIONS ] NOTIFICATIONS_ALL_REQUEST',
  notificationsAllSuccess: '[ NOTIFICATIONS ] NOTIFICATIONS_ALL_SUCCESS',
  notificationsAllFailure: '[ NOTIFICATIONS ] NOTIFICATIONS_ALL_FAILURE',

  notificationsListRequest: '[ NOTIFICATIONS ] NOTIFICATIONS_LIST_REQUEST',
  notificationsListSuccess: '[ NOTIFICATIONS ] NOTIFICATIONS_LIST_SUCCESS',
  notificationsListFailure: '[ NOTIFICATIONS ] NOTIFICATIONS_LIST_FAILURE',

  notificationsSocketRequest: '[ NOTIFICATIONS ] NOTIFICATIONS_SOCKET_REQUEST',
  notificationsSocketSuccess: '[ NOTIFICATIONS ] NOTIFICATIONS_SOCKET_SUCCESS',
  notificationsSocketFailure: '[ NOTIFICATIONS ] NOTIFICATIONS_SOCKET_FAILURE',

  notificationsIsReadRequest: '[ NOTIFICATIONS ] NOTIFICATIONS_IS_READ_REQUEST',
  notificationsIsReadSuccess: '[ NOTIFICATIONS ] NOTIFICATIONS_IS_READ_SUCCESS',
  notificationsIsReadFailure: '[ NOTIFICATIONS ] NOTIFICATIONS_IS_READ_FAILURE'
}

// ACTIONS
export const notificationsAllRequest = filters => ({
  type: typesNotifications.notificationsAllRequest,
  filters
})

export const notificationsAllSuccess = payload => ({
  type: typesNotifications.notificationsAllSuccess,
  payload
})

export const notificationsAllFailure = error => ({
  type: typesNotifications.notificationsAllFailure,
  error
})

export const notificationsListRequest = filters => ({
  type: typesNotifications.notificationsListRequest,
  filters
})

export const notificationsListSuccess = payload => ({
  type: typesNotifications.notificationsListSuccess,
  payload
})

export const notificationsListFailure = error => ({
  type: typesNotifications.notificationsListFailure,
  error
})

export const notificationsIsReadRequest = notificationId => ({
  type: typesNotifications.notificationsIsReadRequest,
  notificationId
})
export const notificationsIsReadSuccess = notification => ({
  type: typesNotifications.notificationsIsReadSuccess,
  notification
})

export const notificationSocket = notification => ({
  type: typesNotifications.notificationsSocketRequest,
  notification
})

export const notificationsIsReadFailure = error => ({
  type: typesNotifications.notificationsIsReadFailure,
  error
})

// REDUCER
const initialState = {
  all: [],
  notRead: {},
  isRead: [],
  filters: {}
}

const getNotification = (notificationList, notificationId) => {
  const check = notificationList.docs?.find(item => item._id === notificationId)
  if (check) {
    const updateNotification = {
      ...check,
      notificationId,
      active: check.active,
      recipients: check.recipients?.map(item => {
        return { isReading: (item.isReading = true), _id: notificationId }
      }),
      type: check.type,
      module: check.module,
      description: check.description,
      document: check.document,
      isReading: true
    }
    const list = notificationList.docs?.filter(
      i => i._id !== updateNotification.notificationId
    )
    return [...list, updateNotification]
  }
}

export function notifications(state = initialState, action) {
  switch (action.type) {
    case typesNotifications.notificationsAllRequest:
    case typesNotifications.notificationsListRequest:
      return { ...state, loading: true }
    case typesNotifications.notificationsIsReadRequest:
      return {
        ...state,
        loading: true,
        isRead: getNotification(state.notRead, action.notificationId)
      }

    case typesNotifications.notificationsSocketRequest:
      return {
        ...state,
        notRead: [state.notRead, action.notification]
      }

    case typesNotifications.notificationsAllSuccess:
      return { ...state, loading: false, all: { ...action.payload } }
    case typesNotifications.notificationsListSuccess:
      return { ...state, loading: false, notRead: { ...action.payload } }
    // case typesNotifications.notificationsIsReadSuccess:
    //   return {
    //     loading: false,
    //     isRead: (state.isRead, action.notification)
    //   }

    case typesNotifications.notificationsAllFailure:
    case typesNotifications.notificationsListFailure:
    case typesNotifications.notificationsIsReadFailure:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
