import React from 'react'
import 'animate.css'
import { Link } from 'react-router-dom'
import Acl from 'components/ACL/Acl'

function DetailContainer(props) {
  const { subtitle, children, actionButton = false, ...others } = props

  return (
    <div {...others} className='col-lg-12 animate__animated animate__fadeIn'>
      <div className='card card-white grid-margin'>
        <div className='card-heading clearfix' style={{ marginBottom: '-7px' }}>
          <h4 className='card-title' style={{ float: 'left' }}>
            {subtitle}
          </h4>
          <div
            className='card-heading clearfix  '
            style={{ float: 'right', marginTop: '5px !important' }}>
            {true && <ActionButtons action='update' action2='destroy' {...props} />}
          </div>
        </div>

        <hr />

        {children}
      </div>
    </div>
  )
}

const ActionButtons = props => {
  return (
    <div>
      <Acl action={props.action}>
        {!!props.action && (
          <>
            <Link className='no-style mr-2' to={props.view}>
              <button className='btn btn-info'>
                <i className='fas fa-edit mr-1' />
                Editar
              </button>
            </Link>
          </>
        )}
      </Acl>

      <Acl action={props.action2}>
        {!!props.action2 && (
          <>
            <button
              className={`mr-2 btn btn-${props.active ? 'danger' : 'success'}`}
              onClick={() => props.onToggle()}>
              <i className='fas fa-power-off' /> {props.active ? 'Desactivar' : 'Activar'}
            </button>
          </>
        )}
      </Acl>
    </div>
  )
}

DetailContainer.defaultProps = {
  buttonAdd: true
}

export default DetailContainer
