import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import { GoodPracticesValidationSchema } from './GoodPracticesValidation'
import FormInput from 'components/FormInput'
import {
  goodPracticesEditRequest,
  goodPracticesGetCatalogs,
  goodPracticesGetOneRequest
} from './goodPractices.redux'
import { Row, Col } from 'reactstrap'
import { RowForm } from 'components/DragAndDropUploadFile/DragAndDropUploadFile'
import styled from 'styled-components'

function GoodPracticesForm({
  mode,
  admin,
  one,
  formCard,
  loading,
  catalogs,
  goodPracticesEditRequest,
  goodPracticesGetCatalogs,
  goodPracticesGetOneRequest,
  acl,
  match,
  ...props
}) {
  const getUrl = window.location.href
  const _id = getUrl.split('/')[getUrl.split('/').length - 2]
  const businessJoinTypes = catalogs.businessJoinTypes?.data
  const [ambitState, setAmbitState] = useState(false)
  const LabelImg = styled.label.attrs({
    className: 'form-label auth__label'
  })`
    display: block;
    font-size: 1.2rem;
    margin-top: 15px;
  `
  const LabelImgSmall = styled.label.attrs({
    className: 'form-label auth__label'
  })`
    display: block;
    font-size: 13px;
    margin-top: -6px;
    color: #184f7a;
    margin-bottom: 7px;
  `
  const selectFileImage = (event, values) => {
    values.imageFile = event.target.files[0]
  }
  const selectFileLogo = (event, values) => {
    values.logoFile = event.target.files[0]
  }
  useEffect(() => {
    goodPracticesGetOneRequest({ _id })
    goodPracticesGetCatalogs()
  }, [goodPracticesGetCatalogs])

  const [lat, setLat] = useState(one.lat)
  const [lng, setLng] = useState(one.lng)
  const [street, setStreet] = useState(one.ubication)

  const handleEditFormCard = values => {
    goodPracticesEditRequest({ values })
  }

  const initialValuesData = {
    _id,
    name: one.name,
    aceptVolunteers: one.aceptVolunteers,
    aceptVolunteersVal:
      one.numberOfVolunteers === '' ||
      one.numberOfVolunteers === 'undefined' ||
      one.numberOfVolunteers === undefined ||
      one.numberOfVolunteers === null ||
      (one.numberOfVolunteers === 0 && one.aceptVolunteers === false)
        ? false
        : true,
    objective: one.objective,
    ods: one.ods?.map(i => i._id),
    axisGlobalPact: one.axisGlobalPact?.map(i => i?._id),
    description: one.description,
    otherAmbit: one.otherAmbit === 'undefined' ? '' : one.otherAmbit,
    ubication: one.ubication,
    promoterInstitution: one.promoterInstitution,
    businessJoinSubType: one.businessJoinSubType?.map(i => i._id),
    alliedInstitutions: one.alliedInstitutions,
    implementationPeriod: one.implementationPeriod,
    indicator: one.indicator,
    information: one.information,
    implementationTime: one.implementationTime?._id,
    email: one.email,
    businessJoinType: one.businessJoinType ? one?.businessJoinType?.map(i => i?._id) : [],
    image: '',
    imageFile: {},
    logo: '',
    logoFile: {},
    lat: one.lat,
    lng: one.lng,
    numberOfVolunteers: one.numberOfVolunteers || ''
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        validationSchema={GoodPracticesValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (['Sí', 'No'].includes(values.aceptVolunteers)) {
            values.aceptVolunteers = values.aceptVolunteers === 'Sí' ? true : false
          }
          if (lat !== undefined) {
            values.lat = lat
            values.lng = lng
            values.ubication = street
          }
          setTimeout(() => {
            handleEditFormCard(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, isSubmitting, handleChange }) => {
          const handleChangeUbication = e => {
            setStreet(e.street)
            setLat(e.lat)
            setLng(e.lng)
          }
          if (values.aceptVolunteersVal === 'No' || values.aceptVolunteersVal === false) {
            values.numberOfVolunteers = ''
          }
          if (
            (values.aceptVolunteersVal === 'Sí' || values.aceptVolunteersVal === true) &&
            values.numberOfVolunteers === ''
          ) {
            values.numberOfVolunteers = 0
          }
          return (
            <Form className='form join-action_form'>
              <div className='row '>
                <FormInput
                  id='name'
                  label='Nombre de la acción'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.name}
                />
                <FormInput
                  id='objective'
                  label='Objetivos de la acción'
                  css='col-12 mb-3'
                  onChange={handleChange}
                  type='textarea'
                  value={values.objective}
                />
                <RowForm className='impacted-ods_row'>
                  <Col className='impacted-ods_join'>
                    <FormInput
                      id='ods'
                      label={'Principal ODS impactado '}
                      type='selectForm'
                      css='join-label-select-register join-label_responsive'
                      onChange={handleChange}
                      dataOptions={
                        catalogs.objectives &&
                        catalogs.objectives.data.map(({ title, _id }) => ({
                          value: _id,
                          text: title,
                          id: _id,
                          key: _id
                        }))
                      }
                      value={values.ods}
                    />
                  </Col>
                  <Col className='impacted-ods_join'>
                    <FormInput
                      id='axisGlobalPact'
                      label={'Principal eje de Pacto Global de las Naciones Unidas'}
                      type='selectForm'
                      css='join-label-select-register'
                      onChange={handleChange}
                      dataOptions={
                        catalogs.axesGlobalPact &&
                        catalogs.axesGlobalPact.data.map(({ name, _id }) => ({
                          value: _id,
                          text: name,
                          id: _id,
                          key: _id
                        }))
                      }
                      value={values.axisGlobalPact}
                    />
                  </Col>
                </RowForm>
                <FormInput
                  id='description'
                  label='Describe aquí tu acción'
                  value={values.description}
                  onChange={handleChange}
                  css='col-12 mb-4'
                  type='textarea'
                />
                <div style={{ width: '100%', height: '23px' }} />
                <FormInput
                  id='businessJoinType'
                  label='Ámbito de implementación'
                  value={values.businessJoinType}
                  type='multiSelect'
                  child={
                    <div
                      style={{
                        width: '208px',
                        margin: '9px 0px 39px 15px'
                      }}>
                      <FormInput
                        id='otherAmbit'
                        css='join-other-input'
                        placeholder='Otro tipo de ámbito'
                        type='inputForm'
                        onChange={e => {
                          setAmbitState(false)
                          handleChange(e)
                        }}
                        value={values.otherAmbit}
                      />
                    </div>
                  }
                  multiOptions={
                    businessJoinTypes &&
                    businessJoinTypes.map(({ name, _id }) => ({
                      value: _id,
                      text: name,
                      id: _id,
                      key: _id,
                      name: _id
                    }))
                  }
                />

                {values.ubication && (
                  <FormInput
                    id='ubication'
                    onChange={handleChange}
                    label='Ubicación donde se implementó la acción'
                    css='col-12 mb-3'
                    lat={one.lat}
                    lng={one.lng}
                    type='autocomplete'
                    onChanges={handleChangeUbication}
                    value={values.ubication}
                  />
                )}
                <FormInput
                  id='promoterInstitution'
                  label='Nombre de la empresa que implementó la acción'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.promoterInstitution}
                />
                <FormInput
                  id='businessJoinSubType'
                  label='Tamaño de empresa que implementa'
                  type='selectForm'
                  css='join-label-select-register'
                  onChange={handleChange}
                  dataOptions={
                    catalogs.businessJoinSubtypes &&
                    catalogs.businessJoinSubtypes.data.map(({ name, _id }) => ({
                      value: _id,
                      text: name,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.businessJoinSubType}
                />
                <FormInput
                  id='alliedInstitutions'
                  label='Otras organizaciones participantes'
                  css='col-12 mb-3'
                  type='textarea'
                  onChange={handleChange}
                  value={values.alliedInstitutions}
                />
                <FormInput
                  id='implementationPeriod'
                  label='Fecha de implementación de la acción'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.implementationPeriod}
                />
                <FormInput
                  id='implementationTime'
                  label='Duración de la iniciativa'
                  css='join-label-select-register'
                  type='selectForm'
                  onChange={handleChange}
                  dataOptions={
                    catalogs.timeInitiatives &&
                    catalogs.timeInitiatives.data.map(({ name, _id }) => ({
                      value: _id,
                      text: name,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.implementationTime}
                />
                <FormInput
                  id='indicator'
                  label='¿Qué indicador utilizas para medir el impacto de esta acción?'
                  css='col-12 mb-5'
                  type='textarea'
                  onChange={handleChange}
                  value={values.indicator}
                />
                <div style={{ width: '100%', height: '23px' }} />

                <FormInput
                  id='information'
                  label='¿Dónde se puede encontrar más información sobre esta acción?'
                  css='col-12 mb-5'
                  type='textarea'
                  onChange={handleChange}
                  value={values.information}
                />
                <FormInput
                  id='email'
                  label='Correo de contacto para compartir con las personas interesadas en esta iniciativa (al compartirlo, será visible públicamente)'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.email}
                />
                <FormInput
                  id='aceptVolunteersVal'
                  label='¿Tu acción involucra a personas voluntarias?'
                  css='col-12 mb-3'
                  type='yesNo'
                  onChange={handleChange}
                  value={values.aceptVolunteersVal}
                />
                {(values.aceptVolunteersVal === true ||
                  values.aceptVolunteersVal === 'Sí') && (
                  <FormInput
                    id='numberOfVolunteers'
                    label='¿Cuántos voluntarios participan actualmente en la acción?'
                    css='col-12 mb-6'
                    type='number'
                    onChange={handleChange}
                    value={values.numberOfVolunteers}
                  />
                )}
                <FormInput
                  id='aceptVolunteers'
                  label='¿Quieres sumar voluntarios? (Si aceptas, se activará un registro de voluntarios)'
                  css='col-12 mb-3'
                  type='yesNo'
                  onChange={handleChange}
                  value={values.aceptVolunteers}
                />
                {/* Imputs de carga */}
                <Col lg='12' style={{ margin: '16px 0 33px 0' }}>
                  <div>
                    <div className='form-group'>
                      <LabelImg>Logo de la empresa</LabelImg>
                      <LabelImgSmall>
                        De clic en Seleccionar archivo para cambiar la imagen y de clic en el
                        botón guardar cambios.
                      </LabelImgSmall>
                      {one.image && (
                        <img
                          src={one.logo}
                          style={{ margin: '6px 0 11px 0' }}
                          height={'80px'}
                          alt='logo'
                        />
                      )}
                      <Field
                        className='auth__input form-control'
                        type='file'
                        id='logo'
                        label='Logo'
                        value={values.logo}
                        onChange={e => {
                          selectFileLogo(e, values)
                          handleChange(e)
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name='logo'
                      component='div'
                      className='animate__animated animate__fadeIn animate__faster error-message'
                    />
                  </div>
                </Col>
                <Col lg='12'>
                  <div>
                    <div className='form-group'>
                      <LabelImg>Imágen de la acción</LabelImg>
                      <LabelImgSmall>
                        De clic en Seleccionar archivo para cambiar la imagen y de clic en el
                        botón guardar cambios.
                      </LabelImgSmall>
                      {one.image && (
                        <img
                          src={one.image}
                          style={{ margin: '6px 0 11px 0' }}
                          height={'80px'}
                          alt='logo'
                        />
                      )}
                      <Field
                        className='auth__input form-control'
                        type='file'
                        id='image'
                        label='image'
                        value={values.image}
                        onChange={e => {
                          selectFileImage(e, values)
                          handleChange(e)
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name='logo'
                      component='div'
                      className='animate__animated animate__fadeIn animate__faster error-message'
                    />
                  </div>
                </Col>
              </div>
              <Row>
                <button
                  type='submit'
                  className='btn btn-primary btn-block mt-3'
                  disabled={isSubmitting}>
                  {'Guardar cambios'}
                </button>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const mapStateToProps = ({ acl, admin, goodPractices }) => {
  return {
    all: goodPractices.all,
    one: goodPractices.one,
    error: goodPractices.error,
    formCardLoading: goodPractices.loading,
    filters: goodPractices.filters,
    catalogs: goodPractices.catalogs,
    gtas: goodPractices.gtas,
    admin,
    acl: acl.list
  }
}

const mapDispatchToProps = {
  goodPracticesEditRequest,
  goodPracticesGetOneRequest,
  goodPracticesGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodPracticesForm)
