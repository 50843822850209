// TYPES
export const CARDS_CREATE_REQUEST = 'CARDS_CREATE_REQUEST'
export const CARDS_CREATE_SUCCESS = 'CARDS_CREATE_SUCCESS'
export const CARDS_CREATE_FAILURE = 'CARDS_CREATE_FAILURE'

export const CARDS_SEND_REQUEST = 'CARDS_SEND_REQUEST'
export const CARDS_SEND_SUCCESS = 'CARDS_SEND_SUCCESS'
export const CARDS_SEND_FAILURE = 'CARDS_SEND_FAILURE'

export const CARDS_ACCEPT_REQUEST = 'CARDS_ACCEPT_REQUEST'
export const CARDS_ACCEPT_SUCCESS = 'CARDS_ACCEPT_SUCCESS'
export const CARDS_ACCEPT_FAILURE = 'CARDS_ACCEPT_FAILURE'

export const CARDS_REJECT_REQUEST = 'CARDS_REJECT_REQUEST'
export const CARDS_REJECT_SUCCESS = 'CARDS_REJECT_SUCCESS'
export const CARDS_REJECT_FAILURE = 'CARDS_REJECT_FAILURE'

export const CARDS_GET_ALL_REQUEST = 'CARDS_GET_ALL_REQUEST'
export const CARDS_GET_ALL_SUCCESS = 'CARDS_GET_ALL_SUCCESS'
export const CARDS_GET_ALL_FAILURE = 'CARDS_GET_ALL_FAILURE'

export const CARDS_GET_WITHOUT_CARRUSEL_REQUEST = 'CARDS_GET_WITHOUT_CARRUSEL_REQUEST'
export const CARDS_GET_WITHOUT_CARRUSEL_SUCCESS = 'CARDS_GET_WITHOUT_CARRUSEL_SUCCESS'
export const CARDS_GET_WITHOUT_CARRUSEL_FAILURE = 'CARDS_GET_WITHOUT_CARRUSEL_FAILURE'

export const CARDS_REMOVE_REQUEST = 'CARDS_REMOVE_REQUEST'
export const CARDS_REMOVE_SUCCESS = 'CARDS_REMOVE_SUCCESS'
export const CARDS_REMOVE_FAILURE = 'CARDS_REMOVE_FAILURE'

export const CARDS_EDIT_REQUEST = 'CARDS_EDIT_REQUEST'
export const CARDS_EDIT_SUCCESS = 'CARDS_EDIT_SUCCESS'
export const CARDS_EDIT_FAILURE = 'CARDS_EDIT_FAILURE'

export const CARDS_GET_ONE_REQUEST = 'CARDS_GET_ONE_REQUEST'
export const CARDS_GET_ONE_SUCCESS = 'CARDS_GET_ONE_SUCCESS'
export const CARDS_GET_ONE_FAILURE = 'CARDS_GET_ONE_FAILURE'

export const CARDS_FILTER_CHANGE_REQUEST = 'CARDS_FILTER_CHANGE_REQUEST'
export const CARDS_FILTER_CHANGE_SUCCESS = 'CARDS_FILTER_CHANGE_SUCCESS'
export const CARDS_FILTER_CHANGE_FAILURE = 'CARDS_FILTER_CHANGE_FAILURE'

export const typesCards = {
  cardsListRequest: '[ CARDS ] CARDS_LIST_REQUEST',
  cardsListSuccess: '[ CARDS ] CARDS_LIST_SUCESS',
  cardsListError: '[ CARDS ] CARDS_LIST_ERROR',

  loadMoreCardsRequest: '[ CARDS ] CARDS_LOAD_MORE_REQUEST',
  loadMoreCardsSuccess: '[ CARDS ] CARDS_LOAD_MORE_SUCESS',
  loadMoreCardsError: '[ CARDS ] CARDS_LOAD_MORE_ERROR',

  cardDetailRequest: '[ CARDS ] CARD_DETAIL_REQUEST',
  cardDetailSuccess: '[ CARDS ] CARD_DETAIL_SUCESS',
  cardDetailError: '[ CARDS ] CARD_DETAIL_ERROR',

  workAxisRequest: '[ WORKAXIS ] WORK_AXIS_REQUEST',
  workAxisSuccess: '[ WORKAXIS ] WORK_AXIS_SUCESS',
  workAxisError: '[ WORKAXIS ] WORK_AXIS_ERROR'
}

// ACTIONS
export const cardEditRequest = (payload) => ({
  type: CARDS_EDIT_REQUEST,
  payload: payload.values
})
export const cardsGetAllRequest = (page) => ({ type: CARDS_GET_ALL_REQUEST, page })
export const cardsGetWithOutCarruselRequest = (page) => ({
  type: CARDS_GET_WITHOUT_CARRUSEL_REQUEST,
  page
})

export const cardsFiltersChangeRequest = (filters) => ({
  type: CARDS_FILTER_CHANGE_REQUEST,
  filters
})

export const cardsListRequest = (filters) => ({ type: typesCards.cardsListRequest, filters })
export const cardsListSuccess = (payload) => ({ type: typesCards.cardsListSuccess, payload })
export const cardsListError = (payload) => ({ type: typesCards.cardsListError, payload })

export const cardDetailRequest = (card) => ({ type: typesCards.cardDetailRequest, card })
export const cardDetailSuccess = (payload) => ({ type: typesCards.cardDetailSuccess, payload })
export const cardDetailError = (payload) => ({ type: typesCards.cardDetailError, payload })

export const workAxisRequest = () => ({ type: typesCards.workAxisRequest })
export const workAxisSuccess = (payload) => ({ type: typesCards.workAxisSuccess, payload })
export const workAxisError = (payload) => ({ type: typesCards.workAxisError, payload })

export const sendFlashcardRequest = (newStatus) => ({ type: CARDS_SEND_REQUEST, newStatus })
export const acceptFlashcardRequest = (newStatus) => ({
  type: CARDS_ACCEPT_REQUEST,
  newStatus
})
export const rejectFlashcardListRequest = (newStatus) => ({
  type: CARDS_REJECT_REQUEST,
  newStatus
})

export const loadMoreCardsRequest = (filters) => ({
  type: typesCards.loadMoreCardsRequest,
  filters
})
export const loadMoreCardsSuccess = (payload) => ({
  type: typesCards.loadMoreCardsSuccess,
  payload
})
export const loadMoreCardsError = (payload) => ({
  type: typesCards.loadMoreCardsError,
  payload
})

// REDUCER
const initialState = {
  cardsList: {},
  loading: false,
  loader: false,
  error: '',
  all: [],
  one: {},
  cardSelected: '',
  filters: {}
}

export function cards(state = initialState, action) {
  switch (action.type) {
    case typesCards.cardsListRequest:
    case typesCards.cardDetailRequest:
    case typesCards.workAxisRequest:
    case typesCards.cardsListSuccess:
      return { ...state, loading: false, cardsList: action.payload }
    case typesCards.workAxisSuccess:
      return { ...state, loading: false, workAxisList: action.payload }
    case typesCards.cardsListError:
    case typesCards.loadMoreCardsError:
    case typesCards.workAxisError:
      return { ...state, loading: false, error: action.payload }
    // Detalle
    case typesCards.cardDetailError:
      return { ...state, loading: false, error: action.payload, one: {} }
    case typesCards.cardDetailSuccess:
      return { ...state, loading: false, one: action.payload }
    case typesCards.loadMoreCardsRequest:
      return { ...state, loader: true, count: action.payload }
    case typesCards.loadMoreCardsSuccess:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action
      // eslint-disable-next-line no-case-declarations
      const { cardsList } = state
      payload.data.docs = [...cardsList.docs, ...payload.data.docs]
      return {
        ...state,
        loader: false,
        cardsList: payload.data
      }
    case CARDS_REJECT_REQUEST:
    case CARDS_CREATE_REQUEST:
    case CARDS_GET_ALL_REQUEST:
    case CARDS_REMOVE_REQUEST:
    case CARDS_EDIT_REQUEST:
    case CARDS_GET_ONE_REQUEST:
    case CARDS_FILTER_CHANGE_REQUEST:
    case CARDS_SEND_REQUEST:
    case CARDS_ACCEPT_REQUEST:
      return { ...state, error: null, loading: true }

    case CARDS_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case CARDS_GET_ALL_SUCCESS:
    case CARDS_GET_WITHOUT_CARRUSEL_SUCCESS:
      return { ...state, error: null, loading: false, cardsList: { ...action.payload } }
    case CARDS_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CARDS_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CARDS_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CARDS_FILTER_CHANGE_SUCCESS:
      return { ...state, error: null, loading: false, filters: { ...action.payload } }
    case CARDS_SEND_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CARDS_ACCEPT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CARDS_REJECT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }

    case CARDS_ACCEPT_FAILURE:
    case CARDS_SEND_FAILURE:
    case CARDS_FILTER_CHANGE_FAILURE:
    case CARDS_GET_ONE_FAILURE:
    case CARDS_GET_ALL_FAILURE:
    case CARDS_GET_WITHOUT_CARRUSEL_FAILURE:
    case CARDS_EDIT_FAILURE:
    case CARDS_CREATE_FAILURE:
    case CARDS_REJECT_FAILURE:
    case CARDS_REMOVE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
