import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { Row, Col } from 'reactstrap'
import { ListItem } from 'components/Theme/Container/ListItem'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import CardCollapsable from 'components/Theme/Cards/CardCollapsable'
import ImageBorder from 'components/Theme/Images/ImageBorder'
// @ts-ignore
import thumbnail from 'assets/media/profile.png'
import DataTable from 'components/Theme/Table/Table'
import { connect } from 'react-redux'
import { userDeactivateRequest, userGetOneRequest, userReactivateRequest } from './user.redux'
import { commentsGetByUserRequest } from 'components/Modules/Comments/comments.redux.js'
import { columnsComments, columnsSavedCards } from './user.helpers'
import { savedCardsByUserRequest } from 'components/Modules/SavedCards/savedCards.redux'
import { useHistory } from 'react-router'
import Acl from 'components/ACL/Acl'

function UserView(props) {
  let history = useHistory()

  const { _id } = props.match.params
  const { userGetOneRequest, commentsGetByUserRequest, savedCardsByUserRequest } = props

  useEffect(() => {
    userGetOneRequest({ _id })
    commentsGetByUserRequest({ createdBy: _id, page: 1 })
    savedCardsByUserRequest({ user: _id, page: 1 })
  }, [userGetOneRequest, commentsGetByUserRequest, savedCardsByUserRequest, _id])

  const user = props.one || {}
  const { docs: comments, totalDocs, limit, page: pageAct } = props.comments
  const {
    docs: savedCards,
    totalDocs: totalDocsSaved,
    limit: limitSaved,
    page: pageActCards
  } = props.savedCards

  const handleActiveToggle = () => {
    if (user.active) {
      props.userDeactivateRequest({ id: _id })
    }
    if (!user.active) {
      props.userReactivateRequest({ id: _id })
    }
  }
  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/comments/${row._id}`)
    }
  }

  return (
    <Layout icon='fas fa-user' title='Usuarios'>
      <TableContainer
        title='Usuario'
        subtitle='Información del registro'
        buttonAdd={false}
        buttonDeactivate={true}
        active={user.active}
        onToggle={handleActiveToggle}>
        <Row id='view-user'>
          <Col md='4' className='flex justify-content-end'>
            <ImageBorder
              src={user.thumbnail ? user.thumbnail : thumbnail}
              height='190'
              width='190'
            />
          </Col>
          <Col md='4'>
            <ul>
              <ListItem label='Nombre(s)' value={user.name} />
              <ListItem label='Apellido(s)' value={user.lastName} />
              <ListItem label='Teléfono' value={user.profileUser?.telephone} />
              <ListItem label='Creado' value={mdy(user.createdAt)} />
              <ListItem label='Actualizado' value={mdy(user.updatedAt)} />
            </ul>
          </Col>
          <Col md='4'>
            <ul>
              <ListItem label='Correo' value={user.email} />
              <ListItem label='País' value={user.country} />
              <ListItem label='Estado' value={user.state} />
              <ListItem label='Ciudad' value={user.city} />
              <ListItem label='Compañia' value={user.company?.name} />
            </ul>
          </Col>
        </Row>
      </TableContainer>
      <div className='row mx-3'>
        <div className='col-12'>
          <Acl action={'read'} module={'comments'}>
            <CardCollapsable title='Comentarios'>
              <DataTable
                {...props}
                limit={limit}
                onTableChange={filters =>
                  commentsGetByUserRequest({ createdBy: _id, ...filters })
                }
                data={comments}
                structure={columnsComments}
                totalDocs={totalDocs}
                pageAct={pageAct}
                filter={props.filterComment}
                rowEvents={rowEvents}
              />
            </CardCollapsable>
          </Acl>
          <CardCollapsable title='Flashcards guardadas'>
            <DataTable
              {...props}
              limit={limitSaved}
              onTableChange={filters => savedCardsByUserRequest({ user: _id, ...filters })}
              data={savedCards}
              structure={columnsSavedCards}
              totalDocs={totalDocsSaved}
              pageAct={pageActCards}
              filter={props.filterSavedCards}
            />
          </CardCollapsable>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.users.all,
    one: state.users.one,
    error: state.users.error,
    userLoading: state.users.loading,
    filters: state.users.filters,
    filterComment: state.comments.filters,
    filterSavedCards: state.savedCards.filters,
    comments: state.comments.all,
    savedCards: state.savedCards.all
  }
}

const mapDispatchToProps = {
  userGetOneRequest: userGetOneRequest,
  commentsGetByUserRequest: commentsGetByUserRequest,
  userDeactivateRequest: userDeactivateRequest,
  userReactivateRequest: userReactivateRequest,
  savedCardsByUserRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(UserView)
