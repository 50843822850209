import { put, call, takeLatest } from 'redux-saga/effects'
import { typesSavedCards } from './savedCards.redux'
import axios from 'axios'
import { encodeFiltersData } from 'redux/helpers/handlers.js'

function* savedCardsByUserRequest({ payload = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(payload)
    const { data } = yield call(axios.get, `/api/savedCards/list?${filterString}`)
    yield put({
      type: typesSavedCards.savedCardsByUserSuccess,
      payload: data?.data
    })
  } catch (error) {
    yield put({
      type: typesSavedCards.savedCardsByUserFailure,
      error: error.response
    })
  }
}

export default function* cardSavedSagas() {
  yield takeLatest(typesSavedCards.savedCardsByUserRequest, savedCardsByUserRequest)
}
