import React from 'react'
import { mdy } from 'utils/dates'
import { generateActiveIcon } from 'components/Theme/Table/helpers'

export const columns = [
  {
    dataField: 'logo',
    text: 'Logo',
    formatter: (cell, row) => (
      <img src={cell ? cell : null} alt={row.title} height='70' width='70' />
    )
  },
  {
    dataField: 'title',
    text: 'Título de Eje de Trabajo',
    sort: true
  },
  {
    dataField: 'objective',
    text: 'Descripción',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'updatedAt',
    text: 'Última modificación',
    sort: true,
    formatter: mdy
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]
