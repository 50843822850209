import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  companyGetOneRequest,
  companyDeactivateRequest,
  companyReactivateRequest
} from './company.redux.js'
import thumbnail from 'assets/media/profile.png'
import ImageBorder from 'components/Theme/Images/ImageBorder'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'
import { historicalListRequest } from 'components/Historicals/historical.redux'

function CompanyView({ companyGetOneRequest, historicalListRequest, ...props }) {
  const { historical } = props
  const { _id } = props.match.params

  useEffect(() => {
    companyGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [companyGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (company.active) {
      props.companyDeactivateRequest({ id: _id })
    }
    if (!company.active) {
      props.companyReactivateRequest({ id: _id })
    }
  }

  const company = props.one || {}

  return (
    <Layout icon='fas fa-fw fa-industry' title='Empresa'>
      <TableContainer
        title='Empresa'
        subtitle='Información del registro'
        buttonAdd={false}
        view={`/companies/${_id}/edit`}
        actionButton={true}
        active={company.active}
        onToggle={handleActiveToggle}>
        {!isEmpty(company) && (
          <Row id='view-company'>
            <Col md='4' className='flex justify-content-end'>
              <ImageBorder
                src={company.thumbnail ? company.thumbnail : thumbnail}
                height='190'
                width='190'
              />
            </Col>
            <Col md='8'>
              <ul>
                <ListItem label='Nombre' value={company.name} />
                <ListItem label='Descripción' value={company.description} />
                <ListItem label='Tipo de Empresa' value={company.businessType?.name} />
                <ListItem label='Sector' value={company.sector?.name} />
                <ListItem label='Activo' value={company.active} />
                <ListItem label='Creado' value={mdy(company.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className='overflow-box'
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}>
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.companies.all,
    one: state.companies.one,
    error: state.companies.error,
    userLoading: state.companies.loading,
    filters: state.companies.filters,
    historical: state.historicals.list
  }
}

const mapDispatchToProps = {
  companyGetOneRequest: companyGetOneRequest,
  companyReactivateRequest: companyReactivateRequest,
  companyDeactivateRequest: companyDeactivateRequest,
  historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyView)
