import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, Field } from 'formik'
import { Tooltip } from 'reactstrap'
import CreatableSelect from 'react-select/creatable'
import { FormField, FormLabel } from './DragAndDropUploadFile/DragAndDropUploadFile'
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api'
import img1 from 'assets/img/mark1.svg'
import styled from 'styled-components'

function FormInput(props, field) {
  const { tooltip } = props
  const [autocomplete, setAutocomplete] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [lat, setLatitude] = useState(null)
  const [lng, setLongitude] = useState(null)
  const [street, setStreet] = useState(props.value)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  useEffect(() => {
    if (props?.lat !== undefined) {
      setLatitude(props.lat * 1)
      setLongitude(props.lng * 1)
      setStreet(props.value)
    }
  }, [props?.lat])

  switch (props.type) {
    //-- RADIO BUTTON ------------------------------------------------------------//
    case 'radioButton': {
      let optionsForm = []
      props.radioButton.map(e =>
        optionsForm.push(
          <label key={e.id}>
            <Field
              type='radio'
              className='form-radio'
              name={props.id}
              value={e.value}
              validate={props.validate}
            />
            {e.value}
          </label>
        )
      )
      return (
        <div>
          <div className='form-group'>
            <div id={props.id}>{props.label}</div>
            <div role='group' aria-labelledby={props.label}>
              {optionsForm}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- Google Maps Autocomplete ---------------------------------------------------------------//
    case 'autocomplete':
      const mapContainerStyle = {
        height: '300px',
        width: '100%'
      }
      const onLoad = autocompleted => setAutocomplete(autocompleted)
      const onPlaceChanged = () => {
        if (autocomplete !== null) {
          const { lat, lng } = autocomplete.getPlace().geometry.location
          const street = autocomplete.getPlace().formatted_address
          setLatitude(lat)
          setLongitude(lng)
          setStreet(street)
          props.onChanges({ street, lat, lng })
        }
      }
      const ImgMark = styled.img.attrs({
        alt: 'search'
      })`
        position: absolute;
        margin: 19px 0 0 10px;
        height: 21px;
        display: block;
        width: auto;
      `
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            <FormLabel style={{ marginTop: '0px' }}>{props.label}</FormLabel>
            <ImgMark src={img1} />
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <FormField
                type={props.type}
                style={{ border: '1px solid #d1d4e2', paddingLeft: '36px' }}
                name={props.id}
                id={props.id}
                placeholder={props.placeholder}
                autoComplete='off'
                onChange={e => {
                  setStreet(e.target.value)
                  props.onChanges({ street, lat, lng })
                }}
                value={street}
              />
            </Autocomplete>
            {lat && lng && (
              <GoogleMap
                id='searchbox-example'
                mapContainerStyle={mapContainerStyle}
                zoom={15}
                center={{ lat, lng }}>
                <Marker position={{ lat, lng }} />
              </GoogleMap>
            )}
          </div>
        </div>
      )
    //-- SMALL ---------------------------------------------------------------//
    case 'inputForm':
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            {props.label && <FormLabel>{props.label}</FormLabel>}
            <FormField
              type={props.type}
              name={props.id}
              placeholder={props.placeholder}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '5px',
                padding: '8px'
              }}
              autoComplete='off'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )
    //-- SELECTION rado Button------------------------------------------------------------//
    case 'checkbox': {
      // eslint-disable-next-line no-case-declarations
      let optionsForm = []
      props.radioButton.map(e => {
        return optionsForm.push(
          <div>
            <label key={e.id || e._id} className='radio-button-gender'>
              <Field
                type='checkbox'
                className='form-radio'
                style={{ marginRight: '5px' }}
                name={props.id}
                onClick={() => props.onChange(e.value || e.objective || e.name)}
                value={e.value || e._id}
              />
              {e.value || e.objective || e.name}
            </label>
          </div>
        )
      })
      return (
        <div className={props.css || 'col-12 mb-5 mt-1'}>
          <div className='form-group '>
            <FormLabel style={{ margin: '11px 0 10px 0' }}>{props.label}</FormLabel>
            <div role='group' aria-labelledby={props.label}>
              {optionsForm}
              {props?.child}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- SMALL ---------------------------------------------------------------//
    case 'number':
      return (
        <div
          className={
            props.css ||
            `col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 animate__animated animate__fadeIn animate__faster `
          }>
          <div className='form-group'>
            <FormLabel style={{ marginBottom: '5px' }}>{props.label}</FormLabel>
            <FormField
              type={props.type}
              name={props.id}
              value={props.value}
              placeholder={props.placeholder}
              onChange={e => {
                e.preventDefault()
                const { value } = e.target
                const regex = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
                if (regex.test(value.toString())) {
                  props.onChange(e)
                }
              }}
              style={{
                width: '100px',
                border: '1px solid #ced4da',
                borderRadius: '5px',
                padding: '8px'
              }}
              autoComplete='off'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )
    //-- RADIO BUTTON SMALL ------------------------------------------------------------//
    case 'yesNo': {
      const yesNo = [
        { id: true, value: 'Sí' },
        { id: false, value: 'No' }
      ]
      let optionsForm = []
      yesNo.map(e =>
        optionsForm.push(
          <label key={e.id} className='radio-button-gender'>
            <Field
              type='radio'
              style={{ marginRight: '5px' }}
              className='form-radio'
              name={props.id}
              checked={props.value === e.id || props.value === e.value}
              value={e.value}
            />
            {e.value}
          </label>
        )
      )
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            <FormLabel id={props.id} style={{ marginBottom: '0px' }}>
              {props.label}
            </FormLabel>
            <div role='group' onChange={props.onChange} aria-labelledby={props.label}>
              {optionsForm}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- SELECT FORM ------------------------------------------------------------------//
    case 'selectForm':
      return (
        <div className={props.css}>
          <div className={`form-group`} style={{ marginBottom: '10px' }}>
            <FormLabel>{props.label}</FormLabel>
            <select
              name={props.id}
              className={'form-select'}
              onChange={props.onChange}
              value={props.value}>
              <option key={'default'} value={''}>
                {'Seleccione'}
              </option>
              {props.dataOptions.map(({ value, text }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
            </select>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    //-- COMPANY ------------------------------------------------------------------//
    case 'multiselectsmall':
      return (
        <div id='TooltipIdMessageDiv'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            {tooltip && (
              <Tooltip
                placement='top'
                isOpen={tooltipOpen}
                target='TooltipIdMessageDiv'
                toggle={toggle}>
                {tooltip}
              </Tooltip>
            )}
            <CreatableSelect
              className={props.className}
              id={props.id}
              name={props.name}
              formatCreateLabel={() => 'Agregar nueva empresa...'}
              placeholder={props.placeholder}
              onChange={props.onChange}
              options={props.options}
              value={props.value}
            />
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )

    //-- RADIO BUTTON SMALL ------------------------------------------------------------//
    case 'multiSelect': {
      // eslint-disable-next-line no-case-declarations
      let optionsForm = []
      props.multiOptions.map(e =>
        optionsForm.push(
          <label className='form-label auth__label' key={e.id}>
            <Field type='checkbox' className='form-radio' name={props.id} value={e.value} />
            {e.text}
          </label>
        )
      )
      return (
        <div>
          <div className='form-group'>
            <div id={props.id}>{props.label}</div>
            <div role='group' aria-labelledby={props.label}>
              {optionsForm}
              {props?.child}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- SELECT ------------------------------------------------------------------//
    case 'select':
      return (
        <div>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <select
              id={props.id}
              name={props.id}
              className={'form-select'}
              onChange={props.onChange}
              value={props.value}
              // onBlur={handleBlur}
              style={{ display: 'block' }}>
              <option key={'default'} value={''}>
                {'Seleccione'}
              </option>
              {props.allGtas ? (
                <option key={'all'} value={'61bd3f1ae2e0b394e27faa33'}>
                  {props.allGtas}
                </option>
              ) : (
                ''
              )}
              {props.dataOptions.map(({ id, value, text }) => (
                <option key={value} value={id}>
                  {text}
                </option>
              ))}
            </select>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )

    //-- TEXTAREA ---------------------------------------------------------------//
    case 'textarea':
      return (
        <div>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Field
              as={props.type}
              name={props.id}
              placeholder={props.placeholder}
              //value        = { props.value }
              autoComplete='off'
              className='auth__input mt-4 form-control'
              validate={props.validate}
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )

    //-- DEFAULT ---------------------------------------------------------------//
    case 'fullwidth':
      return (
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Field
              type={props.type}
              name={props.id}
              placeholder={props.placeholder}
              //value        = { props.value }
              autoComplete='off'
              className='auth__input mt-4 form-control'
              validate={props.validate}
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )

    //-- DEFAULT ---------------------------------------------------------------//
    default:
      return (
        <div>
          <div className='form-group'>
            <label for={props.id} className='form-label auth__label'>
              {props.label}
            </label>
            <Field
              id={props.id}
              type={props.type}
              name={props.id}
              placeholder={props.placeholder}
              value={props.value}
              disabled={props.disabled}
              autoComplete='off'
              validate={props.validate}
              className='auth__input form-control'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )
  }
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  radioButton: PropTypes.array,
  dataOptions: PropTypes.array,
  multiOptions: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string
}

FormInput.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  radioButton: [],
  dataOptions: [],
  multiOptions: []
}

export default FormInput
