import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import ButtonFilter from 'components/Theme/Buttons/ButtonFilter/ButtonFilter'
import AdminTable from 'components/Modules/Admins/AdminTable'
import {
  adminGetAllRequest,
  adminGetTotals
} from 'components/Modules/Admins/admin.redux'

function PageAdmin({ adminGetAllRequest, adminGetTotals, filters, ...props }) {
  useEffect(() => {
    adminGetAllRequest(filters)
    adminGetTotals()
  }, [adminGetAllRequest, adminGetTotals, filters])

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      adminGetAllRequest()
    }
    if (type === 'active') {
      filters.page = 1
      adminGetAllRequest({ active: true })
    }
    if (type === 'inactive') {
      filters.page = 1
      adminGetAllRequest({ active: false })
    }
  }
  return (
    <Layout icon="fas fa-user-cog" title="Administradores">
      <ButtonFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        handleClickAll={handleClick}
      />
      <AdminTable all={props.all} />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.admins.all,
    one: state.admins.one,
    error: state.admins.error,
    userLoading: state.admins.loading,
    filters: state.admins.filters,
    totals: state.admins.totals
  }
}
const mapDispatchToProps = {
  adminGetAllRequest: adminGetAllRequest,
  adminGetTotals: adminGetTotals
}
export default connect(mapStateToProps, mapDispatchToProps)(PageAdmin)
