import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  BUSINESS_TYPE_GET_ALL_REQUEST,
  BUSINESS_TYPE_GET_ALL_FAILURE,
  BUSINESS_TYPE_GET_ALL_SUCCESS,
  BUSINESS_TYPE_CREATE_SUCCESS,
  BUSINESS_TYPE_CREATE_FAILURE,
  BUSINESS_TYPE_CREATE_REQUEST,
  BUSINESS_TYPE_GET_ONE_FAILURE,
  BUSINESS_TYPE_GET_ONE_SUCCESS,
  BUSINESS_TYPE_GET_ONE_REQUEST,
  BUSINESS_TYPE_REMOVE_SUCCESS,
  BUSINESS_TYPE_REMOVE_FAILURE,
  BUSINESS_TYPE_REMOVE_REQUEST,
  BUSINESS_TYPE_EDIT_FAILURE,
  BUSINESS_TYPE_EDIT_SUCCESS,
  BUSINESS_TYPE_EDIT_REQUEST,
  BUSINESS_TYPE_FILTER_CHANGE_SUCCESS,
  BUSINESS_TYPE_FILTER_CHANGE_FAILURE,
  BUSINESS_TYPE_FILTER_CHANGE_REQUEST,
  BUSINESS_TYPE_REACTIVATE_SUCCESS,
  BUSINESS_TYPE_REACTIVATE_FAILURE,
  BUSINESS_TYPE_REACTIVATE_REQUEST,
  BUSINESS_TYPE_GET_TOTALS_SUCCESS,
  BUSINESS_TYPE_GET_TOTALS_FAILURE,
  BUSINESS_TYPE_GET_TOTALS_REQUEST
} from './businessType.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { manageError, encodeFiltersData } from 'redux/helpers/handlers.js'

function* getAllBusinessTypeSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/business-types/?${filterString}`)
    yield delay(1000)
    yield put({ type: BUSINESS_TYPE_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: BUSINESS_TYPE_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneBusinessTypeSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/business-types/${_id}`)
    yield delay(1000)
    yield put({ type: BUSINESS_TYPE_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: BUSINESS_TYPE_GET_ONE_FAILURE, error: error.response })
  }
}
function* createBusinessTypeSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/business-types/', payload)
    yield delay(1500)
    yield put({ type: BUSINESS_TYPE_CREATE_SUCCESS, payload: insert })
    yield put(push('/business-types'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: BUSINESS_TYPE_CREATE_FAILURE, error: error.response })
  }
}

function* getBusinessTypeFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: BUSINESS_TYPE_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: BUSINESS_TYPE_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({
      type: BUSINESS_TYPE_FILTER_CHANGE_FAILURE,
      error: error.response
    })
  }
}
function* editBusinessTypeSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())

    const historical = { description }
    payload.historical = historical
    const insert = yield call(axios.put, '/api/business-types/' + payload._id, payload)
    yield put({ type: BUSINESS_TYPE_EDIT_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/business-types'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: BUSINESS_TYPE_CREATE_FAILURE, error: error.response })
  }
}

function* removeBusinessTypeSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/business-types/' + payload.id + '/deactivate',
      payload
    )
    yield put({ type: BUSINESS_TYPE_REMOVE_SUCCESS, payload: insert })
    yield put({ type: BUSINESS_TYPE_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/business-types'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: BUSINESS_TYPE_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateBusinessTypeSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/business-types/' + payload.id + '/reactivate',
      payload
    )
    yield put({ type: BUSINESS_TYPE_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: BUSINESS_TYPE_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/business-types'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: BUSINESS_TYPE_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/business-types/totals`)
    yield delay(1000)
    yield put({ type: BUSINESS_TYPE_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: BUSINESS_TYPE_GET_TOTALS_FAILURE, error: error.response })
  }
}
export default function* businessTypeSagas() {
  yield takeLatest(BUSINESS_TYPE_GET_ALL_REQUEST, getAllBusinessTypeSaga)
  yield takeLatest(BUSINESS_TYPE_GET_ONE_REQUEST, getOneBusinessTypeSaga)
  yield takeLatest(BUSINESS_TYPE_CREATE_REQUEST, createBusinessTypeSaga)
  yield takeLatest(BUSINESS_TYPE_EDIT_REQUEST, editBusinessTypeSaga)
  yield takeLatest(BUSINESS_TYPE_GET_ALL_FAILURE, manageError)
  yield takeLatest(BUSINESS_TYPE_GET_ONE_FAILURE, manageError)
  yield takeLatest(BUSINESS_TYPE_EDIT_FAILURE, manageError)
  // yield takeLatest(BUSINESS_TYPE_CREATE_FAILURE, manageError)
  yield takeLatest(BUSINESS_TYPE_REMOVE_REQUEST, removeBusinessTypeSaga)
  yield takeLatest(BUSINESS_TYPE_REMOVE_FAILURE, manageError)
  yield takeLatest(BUSINESS_TYPE_FILTER_CHANGE_REQUEST, getBusinessTypeFiltersSaga)
  yield takeLatest(BUSINESS_TYPE_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(BUSINESS_TYPE_REACTIVATE_REQUEST, reactivateBusinessTypeSaga)
  yield takeLatest(BUSINESS_TYPE_REACTIVATE_FAILURE, manageError)
  yield takeLatest(BUSINESS_TYPE_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(BUSINESS_TYPE_GET_TOTALS_FAILURE, manageError)
}
