import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  businessJoinSubTypeDeactivateRequest,
  businessJoinSubTypeGetOneRequest,
  businessJoinSubTypeReactivateRequest
} from './businessJoinSubType.redux'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function BusinessJoinTypeView({
  businessJoinSubTypeGetOneRequest,
  historicalListRequest,
  ...props
}) {
  const { _id } = props.match.params
  const { historical } = props

  const businessJoinSubType = props.one || {}
  useEffect(() => {
    businessJoinSubTypeGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [businessJoinSubTypeGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (businessJoinSubType.active) {
      props.businessJoinSubTypeDeactivateRequest({ id: _id })
    }
    if (!businessJoinSubType.active) {
      props.businessJoinSubTypeReactivateRequest({ id: _id })
    }
  }
  return (
    <Layout icon="fa fa-fw fa-building" title="Tipo de empresa">
      <TableContainer
        title="Área Responsable"
        subtitle="Información del registro"
        buttonAdd={false}
        view={`/business-joinsub-types/${_id}/edit`}
        actionButton={true}
        active={businessJoinSubType.active}
        onToggle={handleActiveToggle}
      >
        {!isEmpty(businessJoinSubType) && (
          <Row id="view-businessJoinSubType">
            <Col>
              <ul>
                <ListItem label="Nombre" value={businessJoinSubType.name} />
                <ListItem
                  label="Descripción"
                  value={businessJoinSubType.description}
                />
                <ListItem label="Activo" value={businessJoinSubType.active} />
                <ListItem label="Creado" value={mdy(businessJoinSubType.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className="overflow-box"
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}
      >
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.businessJoinSubTypes.all,
    one: state.businessJoinSubTypes.one,
    error: state.businessJoinSubTypes.error,
    userLoading: state.businessJoinSubTypes.loading,
    filters: state.businessJoinSubTypes.filters,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  businessJoinSubTypeGetOneRequest,
  businessJoinSubTypeDeactivateRequest,
  businessJoinSubTypeReactivateRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(BusinessJoinTypeView)
