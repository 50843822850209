import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  filterGetOneRequest,
  filterDeactivateRequest,
  filterReactivateRequest
} from './filter.redux'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function FilterView({ filterGetOneRequest, historicalListRequest, ...props }) {
  const { _id } = props.match.params
  const { historical } = props

  useEffect(() => {
    filterGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [filterGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (filter.active) {
      props.filterDeactivateRequest({ id: _id })
    }
    if (!filter.active) {
      props.filterReactivateRequest({ id: _id })
    }
  }

  const filter = props.one || {}

  return (
    <Layout icon="fas fa-fw fa-ban" title="Filtro">
      <TableContainer
        title="Filtro"
        subtitle="Información del registro"
        buttonAdd={false}
        view={`/filters/${_id}/edit`}
        actionButton={true}
        active={filter.active}
        onToggle={handleActiveToggle}
      >
        {!isEmpty(filter) && (
          <Row id="view-filter">
            <Col>
              <ul>
                <ListItem label="Valor" value={filter.value} />
                <ListItem label="Tipo Filtro" value={filter.filterType} />
                <ListItem label="Activo" value={filter.active} />
                <ListItem label="Creado" value={mdy(filter.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className="overflow-box"
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}
      >
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.filters.all,
    one: state.filters.one,
    error: state.filters.error,
    userLoading: state.filters.loading,
    filters: state.filters.filters,
    historical: state.historicals.list
  }
}

const mapDispatchToProps = {
  filterGetOneRequest,
  filterReactivateRequest,
  filterDeactivateRequest,
  historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterView)
