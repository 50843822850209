import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  AXES_GLOBAL_PACT_GET_ALL_REQUEST,
  AXES_GLOBAL_PACT_GET_ALL_FAILURE,
  AXES_GLOBAL_PACT_GET_ALL_SUCCESS,
  AXES_GLOBAL_PACT_CREATE_SUCCESS,
  AXES_GLOBAL_PACT_CREATE_FAILURE,
  AXES_GLOBAL_PACT_CREATE_REQUEST,
  AXES_GLOBAL_PACT_GET_ONE_FAILURE,
  AXES_GLOBAL_PACT_GET_ONE_SUCCESS,
  AXES_GLOBAL_PACT_GET_ONE_REQUEST,
  AXES_GLOBAL_PACT_REMOVE_SUCCESS,
  AXES_GLOBAL_PACT_REMOVE_FAILURE,
  AXES_GLOBAL_PACT_REMOVE_REQUEST,
  AXES_GLOBAL_PACT_EDIT_SUCCESS,
  AXES_GLOBAL_PACT_EDIT_REQUEST,
  AXES_GLOBAL_PACT_FILTER_CHANGE_SUCCESS,
  AXES_GLOBAL_PACT_FILTER_CHANGE_FAILURE,
  AXES_GLOBAL_PACT_FILTER_CHANGE_REQUEST,
  AXES_GLOBAL_PACT_REACTIVATE_SUCCESS,
  AXES_GLOBAL_PACT_REACTIVATE_FAILURE,
  AXES_GLOBAL_PACT_REACTIVATE_REQUEST,
  AXES_GLOBAL_PACT_GET_TOTALS_SUCCESS,
  AXES_GLOBAL_PACT_GET_TOTALS_FAILURE,
  AXES_GLOBAL_PACT_GET_TOTALS_REQUEST
} from './axesGlobalPact.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { justificationComponent } from 'components/Justification'
import { encodeFiltersData, manageError } from 'redux/helpers/handlers.js'

function* getAllAxesGlobalPactSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/axes-global-pact/?${filterString}`)
    yield delay(1000)
    yield put({ type: AXES_GLOBAL_PACT_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: AXES_GLOBAL_PACT_GET_ALL_FAILURE, error: error.response })
  }
}

function* getOneAxesGlobalPactSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/axes-global-pact/${_id}`)
    yield delay(1000)
    yield put({ type: AXES_GLOBAL_PACT_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: AXES_GLOBAL_PACT_GET_ONE_FAILURE, error: error.response })
  }
}
function* createAxesGlobalPactSaga({ payload }) {
  try {
    yield put(showLoading())
    let formData = new FormData()
    formData.append('name', payload.name)
    formData.append('description', payload.description)
    formData.append('logo', payload.logoFile)

    const insert = yield call(axios.post, '/api/axes-global-pact', formData)
    yield delay(1500)
    yield put({ type: AXES_GLOBAL_PACT_CREATE_SUCCESS, payload: insert })
    yield put(push('/axesGlobalPact'))
    Swal.fire('Registro Guardado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: AXES_GLOBAL_PACT_CREATE_FAILURE, error: error.response })
  }
}

function* getAxesGlobalPactFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield delay(1000)
    yield put({ type: AXES_GLOBAL_PACT_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: AXES_GLOBAL_PACT_GET_ALL_REQUEST, filters })
  } catch (error) {
    yield put({ type: AXES_GLOBAL_PACT_FILTER_CHANGE_FAILURE, error: error.response })
  }
}
function* editAxesGlobalPactSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('_id', payload._id)
    formData.append('name', payload.name)
    formData.append('description', payload.description)
  
    if (payload.logoFile) {
      formData.append('logo', payload.logoFile)
    }

    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    yield put(showLoading())
    formData.append('historical', description)

    const insert = yield call(axios.put, '/api/axes-global-pact/' + payload._id, formData)
    yield put({ type: AXES_GLOBAL_PACT_EDIT_SUCCESS, payload: insert })
    yield delay(1500)
    yield put(push('/axesGlobalPact'))
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: AXES_GLOBAL_PACT_CREATE_FAILURE, error: error.response })
  }
}

function* removeAxesGlobalPactSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return

    const historical = { description }
    payload.historical = historical
    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/axes-global-pact/' + payload.id + '/deactivate',
      payload
    )
    yield put({ type: AXES_GLOBAL_PACT_REMOVE_SUCCESS, payload: insert })
    yield put({ type: AXES_GLOBAL_PACT_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/axesGlobalPact'))
    Swal.fire('Registro desactivado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: AXES_GLOBAL_PACT_REMOVE_FAILURE, error: error.response })
  }
}

function* reactivateAxesGlobalPactSaga({ payload }) {
  try {
    const description = yield justificationComponent()
      .then(detail => detail.value)
      .catch(error => error(error))
    if (!description) return
    const historical = { description }
    payload.historical = historical

    yield put(showLoading())
    const insert = yield call(
      axios.put,
      '/api/axes-global-pact/' + payload.id + '/reactivate',
      payload
    )
    yield put({ type: AXES_GLOBAL_PACT_REACTIVATE_SUCCESS, payload: insert })
    yield put({ type: AXES_GLOBAL_PACT_GET_ALL_REQUEST })
    yield delay(1500)
    yield put(push('/axesGlobalPact'))
    Swal.fire('Registro activado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: AXES_GLOBAL_PACT_REACTIVATE_FAILURE, error: error.response })
  }
}

function* getTotalsSaga() {
  try {
    const { data } = yield call(axios.get, `/api/axes-global-pact/totals`)
    yield delay(1000)
    yield put({ type: AXES_GLOBAL_PACT_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: AXES_GLOBAL_PACT_GET_TOTALS_FAILURE, error: error.response })
  }
}

export default function* AxesGlobalPactSagas() {
  yield takeLatest(AXES_GLOBAL_PACT_GET_ALL_REQUEST, getAllAxesGlobalPactSaga)
  yield takeLatest(AXES_GLOBAL_PACT_GET_ONE_REQUEST, getOneAxesGlobalPactSaga)
  yield takeLatest(AXES_GLOBAL_PACT_CREATE_REQUEST, createAxesGlobalPactSaga)
  yield takeLatest(AXES_GLOBAL_PACT_EDIT_REQUEST, editAxesGlobalPactSaga)
  yield takeLatest(AXES_GLOBAL_PACT_GET_ALL_FAILURE, manageError)
  yield takeLatest(AXES_GLOBAL_PACT_GET_ONE_FAILURE, manageError)
  yield takeLatest(AXES_GLOBAL_PACT_REMOVE_REQUEST, removeAxesGlobalPactSaga)
  yield takeLatest(AXES_GLOBAL_PACT_REMOVE_FAILURE, manageError)
  yield takeLatest(AXES_GLOBAL_PACT_FILTER_CHANGE_REQUEST, getAxesGlobalPactFiltersSaga)
  yield takeLatest(AXES_GLOBAL_PACT_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(AXES_GLOBAL_PACT_REACTIVATE_REQUEST, reactivateAxesGlobalPactSaga)
  yield takeLatest(AXES_GLOBAL_PACT_REACTIVATE_FAILURE, manageError)
  yield takeLatest(AXES_GLOBAL_PACT_GET_TOTALS_REQUEST, getTotalsSaga)
  yield takeLatest(AXES_GLOBAL_PACT_GET_TOTALS_FAILURE, manageError)
}
