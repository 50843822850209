import { put, call, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'

import { typePermissions } from './permissions.redux'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

function* getAllPermissionsSaga({ admin }) {
  try {
    yield put(showLoading())
    const { data } = yield call(axios.get, `/api/permissions/all?admin=${admin?._id}`)
    yield put({
      type: typePermissions.PERMISSIONS_GET_ALL_SUCCESS,
      payload: data?.data
    })
    yield put(hideLoading())
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({
      type: typePermissions.PERMISSIONS_GET_ALL_FAILURE,
      error: error.response
    })
  }
}
function* getConfigPermissionsSaga({ admin }) {
  const _id = admin._id
  try {
    yield put(showLoading())
    // Se obtiene el listado de permisos existentes
    const { data: permIni } = yield call(
      axios.get,
      `/api/permissions/all?admin=${_id}&active=true`
    )
    // Se obtiene el listado de modulos existentes
    const { data: modules } = yield call(axios.get, `/api/modules/all?active=true`)
    const pendientes = []

    // Si no se encuentra el modulo en el listado de permisos
    yield modules.data.map(module => {
      if (!permIni.data.find(perm => perm.module._id === module._id) && module.active) {
        let permit = {
          module: module._id,
          admin: _id
        }
        pendientes.push(permit)
      }
      return true
    })
    yield pendientes.map(permit => call(axios.post, `/api/permissions`, permit))
    const { data } = yield call(axios.get, `/api/permissions/all?admin=${_id}`)
    yield put({
      type: typePermissions.PERMISSIONS_GET_CONFIG_SUCCESS,
      payload: data?.data
    })
    yield put(hideLoading())
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({
      type: typePermissions.PERMISSIONS_GET_CONFIG_FAILURE,
      error: error.response
    })
  }
}

export default function* permissionsSagas() {
  yield takeLatest(typePermissions.PERMISSIONS_GET_REQUEST, getAllPermissionsSaga)
  yield takeLatest(typePermissions.PERMISSIONS_GET_CONFIG_REQUEST, getConfigPermissionsSaga)
}
