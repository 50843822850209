import React, { useEffect } from 'react'
import { columns } from './formCard.helpers'
import DataTable from 'components/Theme/Table/Table'
import Layout from 'components/Layout'
import { connect } from 'react-redux'
import TableContainer from 'components/Theme/Container/TableContainer'
import { formCardGetListRequest, formCardGetTotals, formCardReload } from './formCard.redux'
import FlashcardFilter from 'components/Theme/Buttons/ButtonFilter/FlashcardFilter'

function FormCardList({
  formCardReload,
  formCardGetListRequest,
  formCardGetTotals,
  filters,
  ...props
}) {
  useEffect(() => {
    formCardReload()
    formCardGetListRequest(filters)
    formCardGetTotals()
  }, [formCardReload, formCardGetListRequest, formCardGetTotals, filters])

  function handleClick(type) {
    filters.search = ''
    if (type === 'all') {
      filters.active = null
      filters.page = 1
      filters.status = null
      formCardGetListRequest()
    }
    if (type === 'active') {
      filters.page = 1
      filters.active = true
      filters.status = null
      formCardGetListRequest(filters)
    }
    if (type === 'inactive') {
      filters.page = 1
      filters.active = false
      filters.status = null
      formCardGetListRequest(filters)
    }
    if (type === 'rejected') {
      filters.page = 1
      filters.active = null
      filters.status = 3
      formCardGetListRequest(filters)
    }
  }

  const formCard = props.all?.docs || []
  const totalDocs = props.all?.totalDocs
  const pageAct = props.all?.page
  const limit = props.all?.limit

  const rowEvents = {
    onClick: (e, row) => {
      props.history.push(`/formCards/${row._id}`)
    }
  }

  return (
    <Layout icon='fas fa-question-circle' title='Panel de Administración Flashcards'>
      <FlashcardFilter
        all={props.totals.total}
        active={props.totals.active}
        inactive={props.totals.innactive}
        rejected={props.totals.rejected}
        handleClickAll={handleClick}
      />
      <TableContainer
        title='Empresas'
        type='flashcard'
        subtitle='Lista'
        route='/flashcards/create'
        showAddbuton={true}>
        <DataTable
          {...props}
          limit={limit}
          pageAct={pageAct}
          filter={filters}
          totalDocs={totalDocs}
          // Se pasa parámetro para llamar a la función de buscar
          // contenido de la tabla a filtrar
          onTableChange={formCardGetListRequest}
          data={formCard}
          rowEvents={rowEvents}
          structure={columns}
        />
      </TableContainer>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.formCards.all,
    one: state.formCards.one,
    error: state.formCards.error,
    userLoading: state.formCards.loading,
    filters: state.formCards.filters,
    totals: state.formCards.totals
  }
}

const mapDispatchToProps = {
  formCardGetListRequest,
  formCardGetTotals,
  formCardReload
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCardList)
