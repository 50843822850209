// Type's

export const typeAcl = {
  ACL_GET_CONFIG_REQUEST: '[ACL] ACL_GET_CONFIG_REQUEST',
  ACL_GET_CONFIG_SUCCESS: '[ACL] ACL_GET_CONFIG_SUCCESS',
  ACL_GET_CONFIG_FAILURE: '[ACL] ACL_GET_CONFIG_FAILURE'
}

// Action's
export const aclGetConfigRequest = admin => ({
  type: typeAcl.ACL_GET_CONFIG_REQUEST,
  admin
})
export const aclGetConfigSuccess = payload => ({
  type: typeAcl.ACL_GET_CONFIG_SUCCESS,
  payload
})
export const postgetConfigFailure = error => ({
  type: typeAcl.ACL_GET_CONFIG_FAILURE,
  error
})

// Reducer
const INITIAL_STATE = {
  loading: false,
  error: null,
  list: []
}

export function acl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case typeAcl.ACL_GET_CONFIG_REQUEST:
      return { ...state, error: null, loading: true }
    case typeAcl.ACL_GET_CONFIG_SUCCESS:
      return { ...state, error: null, loading: false, list: [...action.payload] }
    case typeAcl.ACL_GET_CONFIG_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
