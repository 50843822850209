// Type's
export const USER_GET_ALL_REQUEST = 'USER_GET_ALL_REQUEST'
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS'
export const USER_GET_ALL_FAILURE = 'USER_GET_ALL_FAILURE'

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE'

export const USER_GET_ONE_REQUEST = 'USER_GET_ONE_REQUEST'
export const USER_GET_ONE_SUCCESS = 'USER_GET_ONE_SUCCESS'
export const USER_GET_ONE_FAILURE = 'USER_GET_ONE_FAILURE'

export const USER_EDIT_REQUEST = 'USER_EDIT_REQUEST'
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS'
export const USER_EDIT_FAILURE = 'USER_EDIT_FAILURE'

export const USER_REMOVE_REQUEST = 'USER_REMOVE_REQUEST'
export const USER_REMOVE_SUCCESS = 'USER_REMOVE_SUCCESS'
export const USER_REMOVE_FAILURE = 'USER_REMOVE_FAILURE'

export const USER_FILTER_CHANGE_REQUEST = 'USER_FILTER_CHANGE_REQUEST'
export const USER_FILTER_CHANGE_SUCCESS = 'USER_FILTER_CHANGE_SUCCESS'
export const USER_FILTER_CHANGE_FAILURE = 'USER_FILTER_CHANGE_FAILURE'

export const USER_GET_TOTALS_REQUEST = 'USER_GET_TOTALS_REQUEST'
export const USER_GET_TOTALS_SUCCESS = 'USER_GET_TOTALS_SUCCESS'
export const USER_GET_TOTALS_FAILURE = 'USER_GET_TOTALS_FAILURE'

export const USER_REACTIVATE_REQUEST = 'USER_REACTIVATE_REQUEST'
export const USER_REACTIVATE_SUCCESS = 'USER_REACTIVATE_SUCCESS'
export const USER_REACTIVATE_FAILURE = 'USER_REACTIVATE_FAILURE'

export const USER_DEACTIVATE_REQUEST = 'USER_DEACTIVATE_REQUEST'
export const USER_DEACTIVATE_SUCCESS = 'USER_DEACTIVATE_SUCCESS'
export const USER_DEACTIVATE_FAILURE = 'USER_DEACTIVATE_FAILURE'
// Action's
export const userGetAllRequest = (filters) => ({
  type: USER_GET_ALL_REQUEST,
  filters
})
export const userGetAllSuccess = (payload) => ({
  type: USER_GET_ALL_SUCCESS,
  payload
})
export const postgetAllFailure = (error) => ({
  type: USER_GET_ALL_REQUEST,
  error
})
export const userFiltersChangeRequest = (filters) => ({
  type: USER_FILTER_CHANGE_REQUEST,
  filters
})
export const userGetOneRequest = (payload) => ({ type: USER_GET_ONE_REQUEST, payload })
export const userGetOneSuccess = (payload) => ({ type: USER_GET_ONE_SUCCESS, payload })
export const userGetOneFailure = (payload) => ({ type: USER_GET_ONE_FAILURE, payload })
export const userGetTotals = (payload) => ({ type: USER_GET_TOTALS_REQUEST, payload })
export const userDeactivateRequest = (payload) => ({ type: USER_DEACTIVATE_REQUEST, payload })
export const userReactivateRequest = (payload) => ({ type: USER_REACTIVATE_REQUEST, payload })

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null,
  totals: {},
  filters: {}
}

export function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_GET_ALL_REQUEST:
    case USER_CREATE_REQUEST:
    case USER_GET_ONE_REQUEST:
    case USER_EDIT_REQUEST:
    case USER_REMOVE_REQUEST:
    case USER_GET_TOTALS_REQUEST:
    case USER_REACTIVATE_REQUEST:
    case USER_DEACTIVATE_REQUEST:
      return { ...state, error: null, loading: true }

    case USER_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case USER_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case USER_EDIT_SUCCESS:
    case USER_REMOVE_SUCCESS:
    case USER_GET_ONE_SUCCESS:
    case USER_REACTIVATE_SUCCESS:
    case USER_DEACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case USER_GET_TOTALS_SUCCESS:
      return { ...state, error: null, loading: false, totals: { ...action.payload } }

    case USER_GET_ALL_FAILURE:
    case USER_CREATE_FAILURE:
    case USER_GET_ONE_FAILURE:
    case USER_EDIT_FAILURE:
    case USER_REMOVE_FAILURE:
    case USER_GET_TOTALS_FAILURE:
    case USER_REACTIVATE_FAILURE:
    case USER_DEACTIVATE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
