import React, { Component } from 'react'
import { Button } from 'reactstrap'

export default class ExportExcel extends Component {
  render() {
    return (
      <Button className='float-right' style={{ marginRight: '.8%' }} color='success'>
        <i className='fas fa-file-excel mr-2' />
        Exportar a Excel
      </Button>
    )
  }
}
