import React from 'react'
import { Formik, Form, Field } from 'formik'
import { connect, useDispatch } from 'react-redux'
import { CARDS_CREATE_REQUEST, CARDS_EDIT_REQUEST } from './flashcards.redux'
import FormInput from 'components/FormInput'

export const FlashcardForm = ({ mode, card }) => {
  const dispatch = useDispatch()

  const handleCardInsert = values => {
    dispatch(eventAddNewCard({ values }))
  }
  const handleCardEdit = values => {
    dispatch(eventEditCard({ values }))
  }

  const companyTypes = [
    { id: '1', value: 'PYMES' },
    { id: '2', value: 'Pólitica Publica' },
    { id: '3', value: 'Grandes empresas' }
  ]
  const ownership = [
    { id: '0', text: 'Seleccione', value: '' },
    { id: '1', text: 'Recursos Humanos' },
    { id: '2', text: 'People' },
    { id: '3', text: 'Operaciones' },
    { id: '4', text: 'Marketing' },
    { id: '5', text: 'Otro' }
  ]
  const gta = [
    { id: '0', text: 'Seleccione', value: '' },
    { id: '1', text: 'Anticorrupción', value: 'Anticorrupción' },
    {
      id: '2',
      text: 'Apertura de oportunidades',
      value: 'Apertura de oportunidades'
    },
    { id: '3', text: 'Cambio climático', value: 'Cambio climático' },
    {
      id: '4',
      text: 'Igualdad de género',
      value: 'Igualdad de género'
    }
  ]
  const workAxis = [
    { id: '0', text: 'Seleccione', value: '' },
    { id: '1', text: 'Objetivo1', value: 'Objetivo1' },
    { id: '2', text: 'Objetivo2', value: 'Objetivo2' },
    { id: '3', text: 'Objetivo3', value: 'Objetivo3' },
    { id: '4', text: 'Objetivo4', value: 'Objetivo4' },
    { id: '5', text: 'Objetivo5', value: 'Objetivo5' }
  ]
  const implementationTime = [
    { id: '0', text: 'Seleccione', value: '' },
    { id: '1', text: 'Corto plazo', value: 'Corto plazo' },
    { id: '2', text: 'Mediano plazo', value: 'Mediano plazo' },
    { id: '3', text: 'Largo plazo', value: 'Largo plazo' }
  ]

  let initialValues
  if (mode === 'edit') {
    const initialClimb = []
    if (card.c) initialClimb.push('c')
    if (card.l) initialClimb.push('l')
    if (card.i) initialClimb.push('i')
    if (card.m) initialClimb.push('m')
    if (card.b) initialClimb.push('b')
    initialValues = {
      _id: card._id,
      gta: card.gta,
      name: card.name,
      workAxis: card.workAxis,
      workAxisNumber: card.workAxisNumber,
      companyType: card.companyType,
      what: card.what,
      step1: card.step1,
      step2: card.step2,
      step3: card.step3,
      indicator: card.indicator,
      ownership: card.ownership,
      implementationTime: card.implementationTime,
      climbOpt: initialClimb,
      prioritizationResult: card.prioritizationResult,
      number: card.number
    }
  } else {
    initialValues = {
      gta: '',
      name: '',
      workAxis: '',
      workAxisNumber: 5,
      companyType: '',
      what: '',
      step1: '',
      step2: '',
      step3: '',
      indicator: '',
      ownership: '',
      implementationTime: '',
      prioritizationResult: 5
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'required'
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            const card = values
            if (values.climbOpt) {
              if (values.climbOpt.includes('c')) card.c = true
              if (values.climbOpt.includes('l')) card.l = true
              if (values.climbOpt.includes('i')) card.i = true
              if (values.climbOpt.includes('m')) card.m = true
              if (values.climbOpt.includes('b')) card.b = true
            }
            card.workAxisNumber = parseInt(card.workAxis.replace(/^\D+/g, ''), 10)
            if (mode === 'create') {
              card.number = parseInt((Math.random() * (500 - 180) + 180).toString())
              card.prioritizationResult = 5
              handleCardInsert(card)
              setSubmitting(false)
            }
            if (mode === 'edit') {
              handleCardEdit(values)
              setSubmitting(false)
            }
          }, 400)
        }}>
        {({ values, isSubmitting, handleChange }) => (
          <Form className='form '>
            <div className='row gutters'>
              <FormInput
                id='gta'
                label={'GTA2030'}
                type='select'
                onChange={handleChange}
                dataOptions={gta}
                value={values.gta}
              />
              <FormInput
                id='workAxis'
                label={'Eje de trabajo'}
                type='select'
                onChange={handleChange}
                dataOptions={workAxis}
                value={values.workAxis}
              />
            </div>
            <hr />
            <div className='row gutters'>
              <FormInput id='what' type='textarea' label={'¿Qué solución propnes?'} />
              <FormInput
                id='implementationTime'
                label={'Tiempo de implementación'}
                type='select'
                onChange={handleChange}
                dataOptions={implementationTime}
                value={values.implementationTime}
              />
              <FormInput id='name' label={'Nombre solución'} value={values.name} />
              <FormInput
                id='companyType'
                label={'Tipo de empresa'}
                type='radioButton'
                radioButton={companyTypes}
                placeholder=''
              />
              <h3>¿Cómo lo implementamos?</h3>
              <FormInput id='step1' label={'Paso 1'} />
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                <div id='climbOpt'>¿Qué ejes de cambio sistémico necesita tu solución?</div>
                <div role='group' aria-labelledby='Ejes de cambio'>
                  <label>
                    <Field type='checkbox' name='climbOpt' className='form-radio' value='c' />
                    Compromiso del CEO
                  </label>
                  <label>
                    <Field type='checkbox' name='climbOpt' className='form-radio' value='l' />
                    Liderazgo
                  </label>
                  <label>
                    <Field type='checkbox' name='climbOpt' className='form-radio' value='i' />
                    Infraestructura
                  </label>
                  <label>
                    <Field type='checkbox' name='climbOpt' className='form-radio' value='m' />
                    Metricas
                  </label>
                  <label>
                    <Field type='checkbox' name='climbOpt' className='form-radio' value='b' />
                    Behaviours
                  </label>
                </div>
              </div>
              <FormInput id='step2' label={'Paso 2'} />
              <FormInput
                id='ownership'
                label={'Área responsable'}
                type='select'
                onChange={handleChange}
                dataOptions={ownership}
                value={values.ownership}
              />
              <FormInput id='step3' label={'Paso 3'} />
              <FormInput id='indicator' label={'Indicador'} type='text' />
              <button
                type='submit'
                className='btn btn-info btn-block mt-3'
                disabled={isSubmitting}>
                {'Listo'}
              </button>
            </div>
            <hr />
          </Form>
        )}
      </Formik>
    </>
  )
}

FlashcardForm.propTypes = {}

const eventAddNewCard = payload => ({
  type: CARDS_CREATE_REQUEST,
  payload: payload.values
})
const eventEditCard = payload => ({
  type: CARDS_EDIT_REQUEST,
  payload: payload.values
})

export default connect(eventAddNewCard, eventEditCard)(FlashcardForm)
