// TYPES
export const CARDS_CREATE_REQUEST = 'CARDS_CREATE_REQUEST'
export const CARDS_CREATE_SUCCESS = 'CARDS_CREATE_SUCCESS'
export const CARDS_CREATE_FAILURE = 'CARDS_CREATE_FAILURE'

export const CARDS_GET_ALL_REQUEST = 'CARDS_GET_ALL_REQUEST'
export const CARDS_GET_ALL_SUCCESS = 'CARDS_GET_ALL_SUCCESS'
export const CARDS_GET_ALL_FAILURE = 'CARDS_GET_ALL_FAILURE'

export const CARDS_REMOVE_REQUEST = 'CARDS_REMOVE_REQUEST'
export const CARDS_REMOVE_SUCCESS = 'CARDS_REMOVE_SUCCESS'
export const CARDS_REMOVE_FAILURE = 'CARDS_REMOVE_FAILURE'

export const CARDS_EDIT_REQUEST = 'CARDS_EDIT_REQUEST'
export const CARDS_EDIT_SUCCESS = 'CARDS_EDIT_SUCCESS'
export const CARDS_EDIT_FAILURE = 'CARDS_EDIT_FAILURE'

export const CARDS_GET_ONE_REQUEST = 'CARDS_GET_ONE_REQUEST'
export const CARDS_GET_ONE_SUCCESS = 'CARDS_GET_ONE_SUCCESS'
export const CARDS_GET_ONE_FAILURE = 'CARDS_GET_ONE_FAILURE'

export const CARDS_FILTER_CHANGE_REQUEST = 'CARDS_FILTER_CHANGE_REQUEST'
export const CARDS_FILTER_CHANGE_SUCCESS = 'CARDS_FILTER_CHANGE_SUCCESS'
export const CARDS_FILTER_CHANGE_FAILURE = 'CARDS_FILTER_CHANGE_FAILURE'

export const typesCards = {
  cardsListRequest: '[ CARDS ] CARDS_LIST_REQUEST',
  cardsListSuccess: '[ CARDS ] CARDS_LIST_SUCESS',
  cardsListError: '[ CARDS ] CARDS_LIST_ERROR',
  loadMoreCardsRequest: '[ CARDS ] CARDS_LOAD_MORE_REQUEST',
  loadMoreCardsSuccess: '[ CARDS ] CARDS_LOAD_MORE_SUCESS',
  loadMoreCardsError: '[ CARDS ] CARDS_LOAD_MORE_ERROR'
}

// ACTIONS
export const cardsGetAllRequest = (page) => ({ type: CARDS_GET_ALL_REQUEST, page })
export const cardsFiltersChangeRequest = (filters) => ({
  type: CARDS_FILTER_CHANGE_REQUEST,
  filters
})
export const cardsListRequest = (filters) => ({ type: typesCards.cardsListRequest, filters })
export const cardsListSuccess = (payload) => ({ type: typesCards.cardsListSuccess, payload })
export const cardsListError = (payload) => ({ type: typesCards.cardsListError, payload })
export const loadMoreCardsRequest = (filters) => ({
  type: typesCards.loadMoreCardsRequest,
  filters
})
export const loadMoreCardsSuccess = (payload) => ({
  type: typesCards.loadMoreCardsSuccess,
  payload
})
export const loadMoreCardsError = (payload) => ({
  type: typesCards.loadMoreCardsError,
  payload
})

// REDUCER
const initialState = {
  cardsList: {},
  loading: false,
  loader: false,
  error: '',
  all: [],
  one: {},
  filters: {}
}

export function flashcards(state = initialState, action) {
  switch (action.type) {
    case typesCards.cardsListRequest:
      return { ...state, loading: true }
    case typesCards.cardsListSuccess:
      return { ...state, loading: false, cardsList: action.payload }
    case typesCards.cardsListError:
      return { ...state, loading: false, error: action.payload }
    case typesCards.loadMoreCardsRequest:
      return { ...state, loader: true, count: action.payload }
    case typesCards.loadMoreCardsError:
      return { ...state, loading: false, error: action.payload }
    case CARDS_CREATE_REQUEST:
    case CARDS_GET_ALL_REQUEST:
    case CARDS_REMOVE_REQUEST:
    case CARDS_EDIT_REQUEST:
    case CARDS_GET_ONE_REQUEST:
    case CARDS_FILTER_CHANGE_REQUEST:
      return { ...state, error: null, loading: true }

    case CARDS_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case CARDS_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case CARDS_EDIT_SUCCESS:
    case CARDS_REMOVE_SUCCESS:
    case CARDS_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CARDS_FILTER_CHANGE_SUCCESS:
      return { ...state, error: null, loading: false, filters: { ...action.payload } }

    case CARDS_CREATE_FAILURE:
    case CARDS_GET_ALL_FAILURE:
    case CARDS_REMOVE_FAILURE:
    case CARDS_EDIT_FAILURE:
    case CARDS_GET_ONE_FAILURE:
    case CARDS_FILTER_CHANGE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
