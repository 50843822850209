import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import TableContainer from 'components/Theme/Container/TableContainer'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  businessJoinTypeDeactivateRequest,
  businessJoinTypeGetOneRequest,
  businessJoinTypeReactivateRequest
} from './businessJoinType.redux'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'

function BusinessJoinTypeView({
  businessJoinTypeGetOneRequest,
  historicalListRequest,
  ...props
}) {
  const { _id } = props.match.params
  const { historical } = props

  const businessJoinType = props.one || {}
  useEffect(() => {
    businessJoinTypeGetOneRequest({ _id })
    historicalListRequest({ document: _id })
  }, [businessJoinTypeGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (businessJoinType.active) {
      props.businessJoinTypeDeactivateRequest({ id: _id })
    }
    if (!businessJoinType.active) {
      props.businessJoinTypeReactivateRequest({ id: _id })
    }
  }
  return (
    <Layout icon="fa fa-fw fa-briefcase" title="Ámbito de implementación">
      <TableContainer
        title="Área Responsable"
        subtitle="Información del registro"
        buttonAdd={false}
        view={`/business-join-types/${_id}/edit`}
        actionButton={true}
        active={businessJoinType.active}
        onToggle={handleActiveToggle}
      >
        {!isEmpty(businessJoinType) && (
          <Row id="view-businessJoinType">
            <Col>
              <ul>
                <ListItem label="Nombre" value={businessJoinType.name} />
                <ListItem
                  label="Descripción"
                  value={businessJoinType.description}
                />
                <ListItem label="Activo" value={businessJoinType.active} />
                <ListItem label="Creado" value={mdy(businessJoinType.createdAt)} />
              </ul>
            </Col>
          </Row>
        )}
      </TableContainer>
      <div
        className="overflow-box"
        style={{ height: 'auto', overflowY: 'inherit', marginTop: '50px' }}
      >
        <HistoricalTimeline values={historical?.docs} />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    all: state.businessJoinTypes.all,
    one: state.businessJoinTypes.one,
    error: state.businessJoinTypes.error,
    userLoading: state.businessJoinTypes.loading,
    filters: state.businessJoinTypes.filters,
    historical: state.historicals.list
  }
}
const mapDispatchToProps = {
  businessJoinTypeGetOneRequest,
  businessJoinTypeDeactivateRequest,
  businessJoinTypeReactivateRequest,
  historicalListRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(BusinessJoinTypeView)
