import React, { Fragment } from 'react'
// @ts-ignore
import logo from 'assets/img/logos/submarcaAzul.svg'
import { connect } from 'react-redux'

const SplashScreen = ({ loading }) => {
  return (
    <Fragment>
      {loading && (
        <div className='spinner animate__animated animate__fadeIn animate__faster'>
          <img src={logo} className='screen-logo' alt='logo' />
          <span>Cargando</span>
          <div className='half-spinner'></div>
        </div>
      )}
    </Fragment>
  )
}

const mapStateToProps = ({ loading }) => ({ loading })
export default connect(mapStateToProps)(SplashScreen)
