import React from 'react'
import { Table } from 'reactstrap'
import { Field } from 'formik'
import { labelsModules } from 'utils/auth.js'
import { showWarningMessage } from 'components/Theme/Alerts/exceptionUtils'

export const AdminCreatePermissions = ({ modules }) => {
  return (
    <>
      <Table className='matrix-permissions'>
        <thead>
          <tr className='text-center'>
            <th>Módulo</th>
            <th>Consultar</th>
            <th>Crear</th>
            <th>Actualizar</th>
            <th>Eliminar</th>
            <th>Aprobar</th>
          </tr>
        </thead>
        <tbody>
          {modules &&
            modules.map((p, index) => {
              return (
                <tr key={p?._id}>
                  <td className='text-center'>{labelsModules[p?.name]}</td>
                  <td style={{ paddingLeft: '10.5%', paddingBottom: '28px' }}>
                    <Field
                      checked={p?.read}
                      className='form-check-input'
                      onClick={e =>
                        p.create || p.update || p.destroy || p.approve
                          ? showWarningMessage(
                              'Aviso',
                              'Si tiene otros permisos activados el permiso de consultar no puede ser deshabilitado'
                            )
                          : (p.read = e.target.checked)
                      }
                      type='checkbox'
                      name={`read${index}`}
                      id={`read${index}`}
                      value='One'
                    />
                  </td>
                  <td style={{ paddingLeft: '7.4%', paddingBottom: '28px' }}>
                    <Field
                      checked={p?.create}
                      className='form-check-input'
                      onClick={e => {
                        p.read = p.read || e.target.checked
                        p.create = e.target.checked
                      }}
                      type='checkbox'
                      name={`create${index}`}
                      title='Activar permiso de crear'
                      disabled={[
                        'comments',
                        'contacts',
                        'flashcards',
                        'goodPractices',
                        'priorizations',
                        'users'
                      ].includes(p?.name)}
                      id={`create${index}`}
                      value='One'
                    />
                  </td>
                  <td style={{ paddingLeft: '10.8%', paddingBottom: '28px' }}>
                    <Field
                      checked={p?.update}
                      className='form-check-input'
                      onClick={e => {
                        p.read = p.read || e.target.checked
                        p.update = e.target.checked
                      }}
                      title='Activar permiso de actualizar'
                      type='checkbox'
                      disabled={[
                        'carouselCards',
                        'comments',
                        'goodPractices',
                        'contacts',
                        'users'
                      ].includes(p?.name)}
                      name={`update${index}`}
                      id={`update${index}`}
                      value='One'
                    />
                  </td>
                  <td style={{ paddingLeft: '9.1%', paddingBottom: '28px' }}>
                    <Field
                      checked={p?.destroy}
                      title='Activar permiso de eliminar'
                      className='form-check-input'
                      onClick={e => {
                        p.read = p.read || e.target.checked
                        p.destroy = e.target.checked
                      }}
                      type='checkbox'
                      disabled={[
                        'admins',
                        'carouselCards',
                        'goodPractices',
                        'contacts',
                        'flashcards',
                        'priorizations',
                        'formCards'
                      ].includes(p?.name)}
                      name={`delete${index}`}
                      id={`delete${index}`}
                      value='One'
                    />
                  </td>
                  <td style={{ paddingLeft: '10.2%', paddingBottom: '28px' }}>
                    <Field
                      checked={p?.approve}
                      title='Activar permiso de aprobar'
                      className='form-check-input'
                      onClick={e => {
                        p.read = p.read || e.target.checked
                        p.approve = e.target.checked
                      }}
                      type='checkbox'
                      disabled={p?.name !== 'formCards' && p?.name !== 'goodPractices'}
                      name={`approve${index}`}
                      id={`approve${index}`}
                      value='One'
                    />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </>
  )
}

export default AdminCreatePermissions
