import * as Yup from 'yup'

export const GoodPracticesValidationSchema = Yup.object().shape({
  name: Yup.string().min(2).max(220).required('Debe ingresar el nombre de la iniciativa'),
  objective: Yup.string().required('Debe ingresar el objetivo de la iniciativa'),
  description: Yup.string().required('Debe ingresar una descripción de la iniciativa'),
  promoterInstitution: Yup.string().required('Debe ingresar una institución promotora'),
  email: Yup.string()
    .email('Formato invalido')
    .required('Debe ingresar un correo de contacto'),
  information: Yup.string().required(
    'Ingrese fuentes de información sobre esta buena práctica.'
  )
})
