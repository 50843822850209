import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Layout from 'components/Layout'
import { isEmpty } from 'utils/crud'
import { mdy } from 'utils/dates'
import { ListItem } from 'components/Theme/Container/ListItem'
import { connect } from 'react-redux'
import {
  formCardGetOneRequest,
  formCardDeactivateRequest,
  formCardReactivateRequest
} from './formCard.redux'
import CardDetailFront from 'components/Modules/Cards/CardDetailFront.js'
import CardDetailBack from 'components/Modules/Cards/CardDetailBack.js'
import DetailContainer from 'components/Theme/Container/DetailContainer'
import ButtonStatus from 'components/Theme/Container/ButtonStatus'
import { historicalListRequest } from 'components/Historicals/historical.redux'
import HistoricalTimeline from 'components/Historicals/HistoricalTimeLine'
import Acl from 'components/ACL/Acl'
import CardDetailBackTwo from '../Cards/CardDetailBackTwo'
import ButtonStatusReview from 'components/Theme/Container/ButtonStatusReview'

function FormCardView({ historicalListRequest, formCardGetOneRequest, ...props }) {
  const { historical } = props
  const { _id } = props.match.params

  useEffect(() => {
    historicalListRequest({ document: _id })
    formCardGetOneRequest({ _id })
  }, [formCardGetOneRequest, historicalListRequest, _id])

  const handleActiveToggle = () => {
    if (formCard.active) {
      props.formCardDeactivateRequest({ id: _id })
    }
    if (!formCard.active) {
      props.formCardReactivateRequest({ id: _id })
    }
  }

  const formCard = props.one || {}
  const showActionButton = props.admin?.role !== 2 ? true : formCard.status < 1

  return (
    <>
      <Layout icon='fas fa-question-circle' title='Flashcard Creada'>
        <div className='container'>
          <div className='page-inner no-page-title'>
            {/* start page main wrapper */}
            <div id='main-wrapper'>
              <div className='row'>
                <DetailContainer
                  title='Flashcard Creada'
                  subtitle='Información de flashcard'
                  buttonAdd={false}
                  type='flashcard'
                  data={formCard}
                  view={`/flashcards/${_id}/edit`}
                  actionButton={showActionButton}
                  active={formCard.active}
                  onToggle={handleActiveToggle}>
                  {!isEmpty(formCard) && (
                    <Row id='view-formCard' style={{ marginTop: '20px' }}>
                      <Col>
                        <ListItem label='Activo' value={formCard.active} />
                      </Col>
                      <Col>
                        <ListItem label='Creado' value={mdy(formCard.createdAt)} />
                      </Col>
                      <Col>
                        <ListItem label='Actualizado' value={mdy(formCard.updatedAt)} />
                      </Col>
                      {formCard?.status === 0 ? (
                        <Col style={{ justifyContent: 'end', display: 'inline-flex' }}>
                          <ButtonStatusReview status={formCard?.status} />
                        </Col>
                      ) : props.admin?.role === 2 ? (
                        ''
                      ) : (
                        <Acl action={'approve'} module={'formCards'}>
                          <Col style={{ justifyContent: 'end', display: 'inline-flex' }}>
                            <ButtonStatus status={formCard?.status} />
                          </Col>
                        </Acl>
                      )}
                    </Row>
                  )}
                </DetailContainer>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='profile-timeline'>
                    <ul className='list-unstyled'>
                      <li className='timeline-item'>
                        <Row
                          id='view-user'
                          className='justify-content-center'
                          style={{ marginBottom: '40px' }}>
                          <Col
                            md='8'
                            className='flex justify-content-center'
                            style={{ display: 'contents' }}>
                            <CardDetailFront data={formCard} />
                            <CardDetailBack data={formCard} />
                            <CardDetailBackTwo data={formCard} />
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className='overflow-box'
                style={{
                  height: 'auto',
                  overflowY: 'inherit',
                  marginTop: '50px'
                }}>
                <HistoricalTimeline values={historical?.docs} seeMore={true} />
              </div>
              {/* Row */}
            </div>
            {/* end page main wrapper */}
          </div>
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.formCards.all,
    one: state.formCards.one,
    error: state.formCards.error,
    userLoading: state.formCards.loading,
    filters: state.formCards.filters,
    historical: state.historicals.list,
    admin: state.admin
  }
}

const mapDispatchToProps = {
  formCardGetOneRequest: formCardGetOneRequest,
  formCardReactivateRequest: formCardReactivateRequest,
  formCardDeactivateRequest: formCardDeactivateRequest,
  historicalListRequest: historicalListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCardView)
