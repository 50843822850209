import React, { useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import { axesGlobalPactCreateRequest, axesGlobalPactEditRequest } from './axesGlobalPact.redux'
import FormInput from './../../../FormInput'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { showWarningMessage } from '../../../Theme/Alerts/exceptionUtils'

export const AxesGlobalPactForm = ({ mode, axesGlobalPact, loading, ...props }) => {
  const handleCreateAxesGlobalPact = values => {
    let formData = new FormData()
    formData.append('file', values.logoFile)
    props.axesGlobalPactCreateRequest({ values })
  }
  const handleEditAxesGlobalPact = values => {
    props.axesGlobalPactEditRequest({ values })
  }
  const selectFile = (event, values) => {
    values.logoFile = event.target.files[0]
  }

  let initialValues = {
    name: '',
    description: '',
    logo: '',
    logoFile: {}
  }
  if (mode === 'edit') {
    initialValues = {
      _id: axesGlobalPact._id,
      name: axesGlobalPact.name,
      description: axesGlobalPact.description,
      logo: '',
      logoFile: {}
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          if (mode === 'create') {
            const requiredFields = ['name', 'description', 'logo']
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.name.length >= 1 && values.name.length < 5) {
              errors.name = 'Debe ser mayor de 5 caracteres'
            }
            if (values.name.length > 50) {
              errors.name = 'Debe ser menor de 50 caracteres'
            }
            if (values.description.length >= 1 && values.description.length < 5) {
              errors.description = 'Debe ser mayor de 5 caracteres'
            }
            if (values.description.length > 50) {
              errors.description = 'Debe ser menor de 50 caracteres'
            }
          } else {
            const requiredFields = ['name', 'description']
            for (const key in requiredFields) {
              if (!values[requiredFields[key]]) errors[requiredFields[key]] = 'Campo Requerido'
            }
            if (values.name.length >= 1 && values.name.length < 5) {
              errors.name = 'Debe ser mayor de 5 caracteres'
            }
            if (values.name.length > 50) {
              errors.name = 'Debe ser menor de 50 caracteres'
            }
            if (values.description.length >= 1 && values.description.length < 5) {
              errors.description = 'Debe ser mayor de 5 caracteres'
            }
            if (values.description.length > 50) {
              errors.description = 'Debe ser menor de 50 caracteres'
            }
          }
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          if (!values.logoFile.name && mode === 'create') {
            showWarningMessage(
              'Aviso',
              'Ingrese una imagen del Eje de Trabajo antes de guardar.'
            )
            setSubmitting(false)
            return
          }
          setTimeout(() => {
            if (mode === 'create') {
              handleCreateAxesGlobalPact(values)
            }
            if (mode === 'edit') {
              handleEditAxesGlobalPact(values)
            }
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, handleSubmit, isSubmitting, handleChange }) => (
          <Form enctype='multipart/form-data' className='form ' onSubmit={handleSubmit}>
            <Row>
              <Col lg='12'>
                <FormInput id='name' label={'Nombre'} value={values.name} />
              </Col>             
              <Col lg='12'>
                <FormInput
                  type='textarea'
                  id='description'
                  label={'Descripción'}
                  value={values.description}
                />
              </Col>
              <Col lg='12'>
                <div>
                  {mode === 'edit' && (
                    <img
                      src={axesGlobalPact.logo}
                      style={{ background: '#184f7a' }}
                      height={'80px'}
                      alt='logo'
                    />
                  )}
                  <div className='form-group'>
                    <label className='form-label auth__label'>Logo</label>
                    <Field
                      className='auth__input form-control'
                      type='file'
                      id='logo'
                      label='Logo'
                      value={values.logo}
                      onChange={e => {
                        selectFile(e, values)
                        handleChange(e)
                      }}
                    />
                  </div>
                  <ErrorMessage
                    name='logo'
                    component='div'
                    className='animate__animated animate__fadeIn animate__faster error-message'
                  />
                </div>
              </Col>
            </Row>
            <Button color='primary' type='submit' disabled={isSubmitting}>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: '35px', marginRight: '35px' }}
                  />
                </span>
              )}
              {!loading && <span>Guardar</span>}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  return {
    all: state.axesGlobalPact.all,
    one: state.axesGlobalPact.one,
    error: state.axesGlobalPact.error,
    filters: state.axesGlobalPact.filters
  }
}

const mapDispatchToProps = {
  axesGlobalPactCreateRequest: axesGlobalPactCreateRequest,
  axesGlobalPactEditRequest: axesGlobalPactEditRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AxesGlobalPactForm)
