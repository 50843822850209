import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'
import Swal from 'sweetalert2'

// Load the list of all records, set the loading to false
export const getAllSuccess = (state, action) => ({ ...state, list: [...action.payload] })

// Set the returned single document into the view property
export const getOneSuccess = (state, action) => ({ ...state, view: { ...action.payload } })

// Load the returned document into the edit property in order to edit it
export const editSuccess = (state, action) => ({ ...state, edit: { ...action.payload } })

// Successfully toggle a document an incorpore its updated version to the list
export const toggleSuccess = (state, action) => {
  const item = action.payload
  const list = state.list
  const index = list.findIndex((i) => i.id === item.id)

  return {
    ...state,
    list: [
      ...list.slice(0, index),
      { ...list[index], active: item.active },
      ...list.slice(index + 1)
    ]
  }
}

export function encodeFiltersData(data) {
  return Object.keys(data)
    .filter((f) => data[f] !== '' && data[f] !== null)
    .map((f) => encodeURIComponent(f) + '=' + encodeURIComponent(data[f]))
    .join('&')
}

export function* manageError(error) {
  try {
    if (error?.error?.status === 401) {
      yield put(push('/'))
    } else {
      if (error?.error?.status !== 400) {
        Swal.fire('Alerta', error?.error?.data.message, 'info')
      }
    }
  } catch (error) {
    // Swal.fire('Error', error, 'error')
  }
}

// Obtener el id del usuario
export const getUserId = (state) => state.admin?._id

// Obtener el gtaID del usuario
export const getUserGta = (state) => state.gta?._id

