import CompanyList from './CompanyList'
import CompanyView from './CompanyView'
import CompanyCreate from './CompanyCreate'
import CompanyEdit from './CompanyEdit'

const routes = [
  { path: '/companies', component: CompanyList },
  { path: '/companies/create', component: CompanyCreate },
  { path: '/companies/:_id', component: CompanyView },
  { path: '/companies/:id/edit', component: CompanyEdit }
]

export default routes
