import React from 'react'
import CardBig from 'components/Theme/Cards/CardBig'
import IntlText from 'i18n/i18nText'
import Layout from 'components/Layout'
import ProgressBar from 'components/Theme/ProgressBar/ProgressBar'

export const PageDashboard = props => {
  return (
    <Layout>
      <div className='col-lg-12'>
        <CardBig title={IntlText('db-m-home')}>
          <p>Bienvenido a tu dashboard!!</p>
        </CardBig>
      </div>
      <div className='col-lg-6'>
        <CardBig title={IntlText('db-m-home')}>
          <ProgressBar title='Progreso' fill={21} />
          <ProgressBar title='Panel de Administración Flashcards' small={true} fill={93} />
        </CardBig>
      </div>
    </Layout>
  )
}

export default PageDashboard
