import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import BusinessJoinTypeForm from './BusinessJoinSubTypeForm'
import LoadingBar from 'react-redux-loading-bar'

function BusinessJoinSubTypeEdit({ one, ...props }) {
  return (
    <Layout icon="fa fa-fw fa-building" title="Tipo de Empresa">
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{
          backgroundColor: '#7a9ec6',
          height: '5px',
          top: 0,
          left: 0,
          zIndex: 999999
        }}
      />
      <BusinessJoinTypeForm
        {...props}
        title="Editar"
        mode="edit"
        loading={false}
        businessJoinSubType={one}
      />
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    one: state.businessJoinSubTypes.one
  }
}

export default connect(mapStateToProps)(BusinessJoinSubTypeEdit)
